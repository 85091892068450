import {
  PROJECT_FOCUS_REQUEST,
  PROJECT_FOCUS_REQUEST_SUCCESS,
  PROJECT_FOCUS_REQUEST_ERROR
} from "../actionTypes/type";

export const getProjectFocusRequest = (paramsData, onSuccess, onError) => {
  return {
    type: PROJECT_FOCUS_REQUEST,
    params: paramsData,
    onSuccess,
    onError
  };
};

export const actionSuccess = (result) => {
  return {
    type: PROJECT_FOCUS_REQUEST_SUCCESS,
    payload: result,
    error: null
  };
};

export const actionError = (error) => {
  return {
    type: PROJECT_FOCUS_REQUEST_ERROR,
    error: error,
    payload: null
  };
};
import React, { useState, useEffect } from "react";
import { LeftBack, crossX, next, logoBlackV1 } from "../assets/images";
import { useNavigate, Link } from "react-router-dom";
import Utility from "../utility/utility";
import APP_STRING from "../constants/strings";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { registerRequest } from "../redux/actions/registerAction";
import { getProjectHelpRequest } from "../redux/actions/projectHelpAction";
import SignBlue from "../components/SignBlue";

var skillOptions;

const URL_TERMS_SERVICE = "/terms-service";
const URL_PRIVACY_POLICY = "/privacy-policy";
const URL_COPYRIGHT_POLICY = "/copyright-policy";

const Register = () => {

  const dispatch = useDispatch();  
  const navigate = useNavigate();
  const [isVisible, setVisible] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [skill, setSkill] = useState("");
  const [description, setDescription] = useState("");
  const [skillId, setSkillId] = useState([]);
  const [otherSkill, setOtherSkill] = useState(false);
  const [otherSkillList, setOtherSkillList] = useState([]);
  const [profilePic, setProfilePic] = useState("");
  const [acceptCheckbox, setAcceptCheckbox] = useState(false);
  const [state, setState] = useState(undefined);
  const [city, setCity] = useState(undefined);

  //Dispatch request for fetching skills on page load
  useEffect(()=> {
    dispatch(getProjectHelpRequest({}, onSuccess, onError));
  },[]);  

  const UserData = useSelector(
    (state: RootStateOrAny) => state.loginReducer.data
  );

  useEffect(() => {
    Utility.isSignedIn(UserData).then((value) => {
      if (value) {
        navigate("/profile");
      }
    });
  }, []);  

  //If "other" skill/expertise is selected, then toggle the corresponding textarea
  // useEffect(() => {

  //   const skillExpertiseText = document.getElementById("skillExpertiseText");
  //   if(skillExpertiseText != null){
  //     if(skillId.includes(8)){
  //       //skillExpertiseText.disabled = false;
  //       setOtherSkill(true);
  //     }else{
  //       //skillExpertiseText.disabled = true;
  //       setOtherSkill(false);
  //       setSkill("");
  //     }
  //   }

  // },[skillId]);

  const setSelectSkill = function(val, is_other){

    const value = parseInt(val);
    const index = skillId.indexOf(value);
    let newArr = skillId;
    
    if(index > -1){
      skillId.splice(index, 1);
      newArr = [...skillId];
      setSkillId(newArr);
    }
    else{
      newArr = [...skillId, value];
      setSkillId(newArr);
    }

    if(is_other){
      
      if(otherSkill){
        setOtherSkill(false);
        setSkill("");        
      }else{
        setOtherSkill(true);
      }

    }

  }

  function RenderSkillOptions(){

    return (
       <div>
          {
            skillOptions.map( (option, i) => (
              <div key={ i }>
                <label className="containers font-manrope-medium">
                  { option.name }
                  <input name="skillSelected" type="checkbox" checked={ skillId.includes(option.id) } onChange={ (e) => setSelectSkill(e.target.value, option.is_other) } value={option.id} />
                  <span className="checkmark"></span>
                </label> 
              </div>
            ))
          }       
        </div>
    )

  }

  const handleKeyDown = (e) => {

    if(e.key == 'Enter'){
      const val = e.target.value.trim();
      if(val != ''){
        setOtherSkillList([...otherSkillList, val]);
        setSkill("");
      }
    }

  }


  const removeSkill = (e) => {

    const removedList = otherSkillList.filter(function(value) {
      return value != e; 
    });
    setOtherSkillList(removedList);    

  }

  const uploadProfile = (e) => {

    const file = e.target.files[0];
    if(file != null && file != undefined){
      const reader = new FileReader();
      reader.readAsDataURL(file); 
      reader.onloadend = function() {       
        setProfilePic(reader.result);
      }
      document.querySelector("span#profile-pic").innerText = file.name;
    }

  }

  async function onSuccess(response) {
    skillOptions = response;
  }

  async function onRegisterSuccess(resp){
    navigate("/login");
  }

  async function onError() {
    //history.push("/overall-performance");
    console.log("error");
  }

  const openAccountClick = () => {
    if(validateForm2()){
      setVisible(true);
      if (isVisible){
        setLoading(true);
        const payload = {
          firstname : firstName,
          lastname : lastName,
          email,
          password,
          description,
          skills_expertise : otherSkillList,
          skills_expertise_id : skillId,
          profile_pic : profilePic,
          city,
          state
        }
        dispatch(registerRequest(payload, onRegisterSuccess, onError));         
      }
      else return;
    }
  }

  async function submitForm(evt){
    
    evt.preventDefault();
    
    if( validateForm1() ){   
      setVisible(true);  
    }

  }

  function validateForm1(){
    
    let message = "";

    if(Utility.isEmpty(firstName)){
      message = APP_STRING.ENTER_FIRSTNAME;
    } else if(Utility.isEmpty(lastName)){
      message = APP_STRING.ENTER_LASTNAME;
    } else if (Utility.isEmpty(email)) {
      message = APP_STRING.ENTER_EMAIL;
    } else if (!Utility.validateEmail(email)) {
      message = APP_STRING.ENTER_VALID_EMAIL;
    } else if (Utility.isEmpty(password)) {
      message = APP_STRING.ENTER_PASSWORD;
    } else if (password.length < 6) {
      message = APP_STRING.ENTER_SIX_DIGIT_PASSWORD;
    } else if(Utility.isEmpty(confirmPassword)){
      message = APP_STRING.ENTER_CONFIRM_PASSWORD;
    } else if(!Utility.matchPasswords(password, confirmPassword)){
      message = APP_STRING.PASSWORD_MITCHMATCH;
    }

    if (message === "") {
      return true;
    }
    
    Utility.showToast("error", message);
    return false;    

  }

  function validateForm2(){

    let message = "";

    if( skillId.length == 0 ){
      message = APP_STRING.ENTER_SKILL;
    }
    else if(acceptCheckbox == false){
      message = "Please accept the Terms & Conditions";
    }

    if (message === "") {
      return true;
    }
    
    Utility.showToast("error", message);
    return false;    

  }

  const navigateBack = () => {
    if(isVisible){
      setVisible(false); 
    }else{
      navigate("/login");
    }
    //isVisible ? setVisible(false) : setVisible(true);
  }

  const Header = () => {
    return (
      <>
        <div
          onClick={() => (isVisible ? setVisible(false) : navigate("/login"))}
          className="flex-row"
        >
          <img className="backLeft" src={LeftBack}></img>
          <h6 className="back-to-Landing">
            {isVisible ? "Back" : "Back to Sign In"}
          </h6>
        </div>
        <div>
          <h1 className="app-name">arete</h1>
          <h4 className="app-sign">Sign Up</h4>
        </div>
      </>
    );
  };

  const RegisterSecondStep = () => {

    return (
      
        <div className="layout px-5 py-3">
            
            <h1 className="signup-font font-manrope-bold fs-32">Profile Set Up</h1>

            <div className="col-md-12 mobile-padding">
              <div className="row mt-4">
                <div className="col-md-6 pl-0 mobile-padding">
                  <h5 className="signup-mobile-font font-manrope-extra-bold">Description</h5>
                  <div className="form-group">
                    <textarea className="fs-16 form-control rounded-0 font-manrope-semibold" id="exampleFormControlTextarea1 bg-signup-2"
                      rows="10" placeholder="Type Description" value={description} onChange = {(e) => setDescription(e.target.value)}></textarea>
                  </div>
                  <h5 className="signup-mobile-font font-manrope-extra-bold">City</h5>
                  <div className="form-group">
                    <input type="text" className="form-control drop-shadow font-manrope-semibold" placeholder="Enter city" value={city} onChange={e => setCity(e.target.value)} />
                  </div>
                  <h5 className="signup-mobile-font font-manrope-extra-bold">State</h5>
                  <div className="form-group">
                    <input type="text" className="form-control drop-shadow font-manrope-semibold" placeholder="Enter state" value={state} onChange={e => setState(e.target.value)} />
                  </div>                  
                  <h5 className="mt-5 signup-mobile-font font-manrope-extra-bold">Upload Profile Photo</h5>
                  <div className="form-group">
                    <label className="text-left p-0 col-md-12" htmlFor="profile"><input type="file" id="profile" accept="image/*" name="profile_pic" onChange={uploadProfile} /><p className="font-manrope-regular" id="profile-pic">Choose or Drop Files</p></label>
                  </div>
                </div>
                <div className="col-md-6">
                  <h5 className="signup-mobile-font font-manrope-extra-bold">
                    Do you have any special skills or expertises?
                  </h5>
                  {<RenderSkillOptions/>}
                  <div className="col-md-12">
                    <input id="skillExpertiseText" className={"form-control rounded-0 skill-expertise-text font-manrope-semibold" + (otherSkill == true ? "" : " hide")} value={skill} onChange={(e) => setSkill(e.target.value)} placeholder="Press Enter to Add" onKeyDown={(e)=>handleKeyDown(e)} />
                  </div>
                  <ul className="Other_skills_list">
                    {otherSkillList.map((item,index) => (
                      <li id={index} className="font-manrope-semibold fs-16" key={index}>{item}<span className="remove_Skill pointer" onClick={()=>removeSkill(item)}><img width="13.05" src={crossX} /></span></li>
                    ))}
                  </ul>               
                </div>             
              </div>
            </div>
            <div className="col-md-12 mt-5">
              <p className="simple-reg-terms fs-16 font-manrope-regular">
                <label>
                  <span className="checkbox"><input title="Please tick" name="accept_terms" type="checkbox" className="required" checked={acceptCheckbox} value={acceptCheckbox} onChange={() => setAcceptCheckbox(!acceptCheckbox)} id="js-accept-terms" /></span> 
                  <span title="Please tick">I accept the </span> 
                  <a target="_blank" href={URL_TERMS_SERVICE} title="Opens in a new tab">Terms &amp; Conditions</a>,&nbsp;
                  <a target="_blank" href={URL_PRIVACY_POLICY} title="Opens in a new tab">Privacy Policy</a>&nbsp;and&nbsp;
                  <a target="_blank" href={URL_COPYRIGHT_POLICY} title="Opens in a new tab">Copyright Policy</a>
                </label>
              </p>
            </div>
            <div className="col-md-12 mt-5">
              <div className="text-center mt-1 signip-2">
                <button
                  onClick={openAccountClick}
                  type="submit"
                  className="font-manrope-regular px-4 py-2 signup-button border-none bckg-color"
                >
                  Finish
                </button> &nbsp;
                <br/><br/>
              </div>
            </div>

        </div>

      
    );
  };

  const RegisterFirstStep = () => {

    return (

      <form onSubmit={e => submitForm(e)}>
  <div className="layout px-5 py-3">
            
            <h1 className="signup-font font-manrope-bold fs-32">Sign Up</h1>
            
            <div className="row mt-4">
              <div className="col-md-6">
                <div className="form-group mobile-signup">
                  <label htmlFor="usr"><h5 className="my-0 font-manrope-extra-bold">First name</h5></label>
                  <input type="text" className="form-control drop-shadow font-manrope-semibold" value={firstName} onChange={(e) => setFirstName(e.target.value)} placeholder="First" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mobile-width">
                  <label htmlFor="usr"><h5 className="my-0 font-manrope-extra-bold">Last name</h5></label>
                  <input type="text" className="form-control drop-shadow font-manrope-semibold" placeholder="Last" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                </div>
              </div>
            </div>

            <div className="mt-2 mobile-padding">
              <div className="form-group m-auto">
                <label htmlFor="usr"><h5 className="my-0 font-manrope-extra-bold">Email Or Username</h5></label>
                <input type="email" className="form-control drop-shadow font-manrope-semibold" placeholder="example@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>
            </div>
                
            <div className="mt-3 mobile-padding">
              <div className="form-group m-auto">
                <label htmlFor="usr"><h5 className="my-0 font-manrope-extra-bold">Password</h5></label>
                <input type="password" className="form-control drop-shadow font-manrope-semibold" placeholder="**********" value={password} onChange={(e) => setPassword(e.target.value)} />
              </div>
            </div>
                
            <div className="mt-3 mobile-padding">
              <div className="form-group m-auto">
                <label htmlFor="usr"><h5 className="my-0 font-manrope-extra-bold">Confirm Password</h5></label>
                <input type="password" className="form-control drop-shadow font-manrope-semibold" placeholder="**********" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
              </div>
            </div>
                
            <div className="col-md-12 mt-5">
              <div className="text-center mt-3">
                <button type="submit" className="signup-button border-none">
                  <img src={next} alt="Next" />
                    </button>
              </div>
            </div>            

          </div>
      </form>
              
    );
  };
  
  return (

    <main className="signup-container bckg-gray">

      <div className="col-md-12 mt-5">
        <div className="row pointer">
          {" "}
          <i onClick={navigateBack} className="fa fa-caret-left font-size-login mr-2 ml-2 backLeft"  aria-hidden="true" src={LeftBack}></i>
          <h6 onClick={navigateBack} className="text-left mt-1 font-manrope-extra-bold">
            {isVisible ? "Back" : "Back to Sign In"}
          </h6>
        </div>
      </div>

      <div className="form-group w-50-signup m-auto container signup">
        <div className="py-1"><img src={logoBlackV1} width={143} alt="black logo v1"/></div>
        {isVisible ? RegisterSecondStep() : RegisterFirstStep()}        
      </div>

      <SignBlue/>

      <footer className="col-md-12 fl shadow sf-pro">
        <div className="col-md-3 text-left display-inline-block fl">
          <span className="heading">Airatae</span>
        </div>
        <div className="col-md-6 text-center display-inline-block fl">
          <Link target='blank' to={URL_TERMS_SERVICE}>Terms of Service</Link>
          &nbsp;&nbsp;&nbsp;&nbsp;<Link target='blank' to={URL_PRIVACY_POLICY}>Privacy Policy</Link>
          &nbsp;&nbsp;&nbsp;&nbsp;<Link target='blank' to={URL_COPYRIGHT_POLICY}>Copyright Policy</Link>
        </div>
        <div className="col-md-3 text-right display-inline-block fl">
          <span className="copyright-text">
            <span>Copyright &nbsp; <i class="fa fa-copyright" aria-hidden="true"></i>
              &nbsp; {new Date().getFullYear()}</span><br/>
            <span>All Rights Reserved</span>
          </span>
        </div>
      </footer>

    </main>

  );
};

export default Register;

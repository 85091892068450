import {
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_REQUEST_ERROR,
  ADMIN_LOGIN_REQUEST_SUCCESS,
} from "../actionTypes/type";

const initialState = {
  data: null,
  isLoading: false,
};

const adminloginReducer = (state = initialState, action) => {
  let object;

  switch (action.type) {
    case ADMIN_LOGIN_REQUEST:
      object = {
        ...state,
        isLoading: true,
      };
      break;

    case ADMIN_LOGIN_REQUEST_ERROR:
      object = {
        ...state,
        isLoading: false,
      };
      break;

    case ADMIN_LOGIN_REQUEST_SUCCESS:
      object = {
        ...state,
        data: action.payload,
        isLoading: false,
      };
      break;

    default:
      object = state;
      break;
  }
  return object;
}

export default adminloginReducer;

import {
    ADMIN_DISREGARD_USER_REPORT_REQUEST,
    ADMIN_DISREGARD_USER_REPORT_REQUEST_SUCCESS,
    ADMIN_DISREGARD_USER_REPORT_REQUEST_ERROR,
  } from "../actionTypes/type";
  
  const initialState = {
    data: null,
    isLoading: false,
  };
  const adminDisregardUserReportReducer = (state = initialState, action) => {
    let object;
  
    switch (action.type) {
      case ADMIN_DISREGARD_USER_REPORT_REQUEST:
        object = {
          ...state,
          isLoading: true
        };
        break;
  
      case ADMIN_DISREGARD_USER_REPORT_REQUEST_ERROR:
        object = {
          ...state,
          isLoading: false
        };
        break;
  
      case ADMIN_DISREGARD_USER_REPORT_REQUEST_SUCCESS:
        object = {
          ...state,
          data: action.payload,
          isLoading: false
        };
        break;
  
      default:
        object = state;
        break;
    }
    return object;
  }
  
  export default adminDisregardUserReportReducer;
  
import React, { useState, useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import Geocode from "react-geocode";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { useNavigate } from "react-router-dom";
import { Navbar, Carousel, Nav, FormControl, Button, Form } from "react-bootstrap";
import { getProjectTypeRequest } from "../redux/actions/projectTypeAction";
import { getProjectFocusRequest } from "../redux/actions/projectFocusAction";
import { getUserListingRequest } from "../redux/actions/userListingAction";
import { getProjectHelpRequest } from "../redux/actions/projectHelpAction";
import { getProjectSuppliesRequest } from "../redux/actions/projectSuppliesAction";
import { getProjectEquipmentsRequest } from "../redux/actions/projectEquipmentsAction";
import { getLanguageRequest } from "../redux/actions/languageAction";
import { projectCreateRequest } from "../redux/actions/projectCreateAction";
import { projectInviteRequest } from "../redux/actions/projectInviteAction";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from 'react-bootstrap';
import { loader, leftBack, crossX } from "../assets/images";
import Utility from "../utility/utility";
import Select from 'react-select';
import SignProjectOverview from "../components/SignProjectOverview";
import UserGroupsList from "../components/user_groups_list";

var projectFocusAll = [], projectType = [], projectHelp = [], projectSupplies = [], projectEquipments = [], languageOptions = [];
var payLoad = {}
var userListingOffset = 0, userSearchTimeout = null, userScrollTimeout = null;

const LIMIT_USER_LISTING = 8;
const LOCATION_CITY = 'c';
const LOCATION_REGION = 'r';
const EVENT_TYPE_SINGLE = 's';
const EVENT_TYPE_ONGOING = 'o';
const ORGANIZER_TYPE_SELF = "self";
const ORGANIZER_TYPE_ANOTHER = "another";
const FUNDING_PROJECT_ORGANIZER_SELF = "self";
const FUNDING_PROJECT_ORGANIZER_DONATION = "donation";
const FUNDING_REQUIRED_TRUE = 1;
const FUNDING_REQUIRED_FALSE = 0;
const EVENT_WHEELCHAIR_ACCESSIBLE_TRUE = 1;
const EVENT_WHEELCHAIR_ACCESSIBLE_FALSE = 0;
const EVENT_ANIMAL_ALLERGIES_TRUE = 1;
const EVENT_ANIMAL_ALLERGIES_FALSE = 0;
const EVENT_HAY_FEVER_TRUE = 1;
const EVENT_HAY_FEVER_FALSE = 0;
const FUNDING_SELF = 'self';

const Project_Overview = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [active, addActiveClass] = useState(1);
  const [projectnameError, setProjectnameError] = useState("");
  const [descError, setDescError] = useState("");
  const [typeError, setTypeError] = useState("");
  const [focusError, setfocusError] = useState("");
  const [createAsTypeError, setCreateAsTypeError] = useState("");
  const [searchOrgName, setSearchOrgName] = useState("");
  const lock = <FontAwesomeIcon icon={faLock} />;
  const circle = <FontAwesomeIcon icon={faCircle} />;

  // Sidebar CODE start

  const [isActive1, setActive1] = useState(true);
  const [isActive2, setActive2] = useState(false);
  const [isActive3, setActive3] = useState(false);
  const [isActive4, setActive4] = useState(false);
  const [open_slider, setopen_slider] = useState(false);
  const [projectFocus, setProjectFocus] = useState([]);
  const [constributeAsSelf, setContributeAsSelf] = useState(false);
  const [contributeAsGroup, setContributeAsGroup] = useState(false);
  const [userGroupId, setUserGroupId] = useState(undefined);
  const [selectGroupModal, setSelectGroupModal] = useState(false);

  const UserData = useSelector(
    (state: RootStateOrAny) => state.loginReducer.data
  );

  const UserGroupListOptions = UserGroupsList({ type: 'react-select', groups: UserData.groups, selected_group: userGroupId, set_user_group_id: setUserGroupId });

  const handleSlider = (e) => {
    setopen_slider(!open_slider);
  }

  const handleToggle = (e) => {
    switch (e) {
      case 1:
        setActive1(!isActive1);
        break;
      case 2:
        setActive2(!isActive2);
        break;
      case 3:
        setActive3(!isActive3);
        break;
      case 4:
        setActive4(!isActive4);
        break;
    }
  };
  // sidebar end

  const [zipcodeError, setzipcodeError] = useState("");
  const [addressError, setaddressError] = useState("");
  const [dateError, setDateError] = useState("");
  const [monthError, setMonthError] = useState("");
  const [yearError, setyearError] = useState("");
  const [date_1Error, setDate_1Error] = useState("");
  const [month_1Error, setMonth_1Error] = useState("");
  const [year_1Error, setyear_1Error] = useState("");
  const [Location, setLocation] = useState(LOCATION_CITY);
  const [Event, setEvent] = useState(EVENT_TYPE_SINGLE);
  const [showAddress, setShowAddress] = useState(true);
  const [Organiser, setOrganiser] = useState(ORGANIZER_TYPE_SELF);
  const [Partnership, setPartnership] = useState("");
  const [NeedSkills, setNeedSkills] = useState(false);
  const [OtherSkills, SetOtherSkills] = useState("");
  const [Howmany, SetHowmany] = useState(0);
  const [ProjectType1, setProjectType1] = useState(false);
  const [ProjectType2, setProjectType2] = useState(false);
  const [ProjectType3, setProjectType3] = useState(false);
  const [ProjectType4, setProjectType4] = useState(false);
  const [ProjectType5, setProjectType5] = useState(false);
  const [ProjectType6, setProjectType6] = useState(false);
  const [ProjectType7, setProjectType7] = useState(false);
  const [ProjectHelpOther, setProjectHelpOther] = useState(false);
  const [ProjectHelpId, setProjectHelpId] = useState([]);
  const [skillList, setskilllist] = React.useState([]); 
  const [CertainSkills, SetCertainSkills] = useState(false);
  const [NeedServices, SetNeedServices] = useState(false);
  const [NeedSupplies, SetNeedSupplies] = useState(0);
  const [NeedEquipment, SetNeedEquipment] = useState(false);
  const [unlock1, setUnlock1] = useState(true);
  const [unlock2, setUnlock2] = useState(true);
  const [unlock3, setUnlock3] = useState(true);
  const [Focus, setFocusId] = useState(null);
  const [FocusisLoaded, setFocusisLoaded] = useState(false);
  const [HelpisLoaded, setHelpisLoaded] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [userListing, setUserListing] = useState([]);
  const [modalOrgLoader, setModalOrgLoader] = useState(false);
  const [resourceContribution, setResourceContribution] = useState('');
  const [funding, setFunding] = useState(FUNDING_SELF);
  const [ProjectSuppliesId, setProjectSuppliesId] = useState([]);
  const [ProjectEquipmentId, setProjectEquipmentId] = useState([]);
  const [ProjectSuppliesOther, setProjectSuppliesOther] = useState('');
  const [ProjectSuppliesOtherList, setProjectSuppliesOtherList] = useState([]);
  const [ProjectEquipmentOther, setProjectEquipmentOther] = useState('');
  const [ProjectEquipmentsOtherList, setProjectEquipmentsOtherList] = useState([]);
  const [fundingRequired, setFundingRequired] = useState(FUNDING_REQUIRED_FALSE);
  const [eventWheelChairAccessible, setEventWheelChairAccessible] = useState(EVENT_WHEELCHAIR_ACCESSIBLE_TRUE);
  const [eventAnimalAllergies, setEventAnimalAllergies] = useState(EVENT_ANIMAL_ALLERGIES_TRUE);
  const [eventHayFever, setEventHayFever] = useState(EVENT_HAY_FEVER_TRUE);
  const [languages, setLanguages] = useState(null);

  payLoad = {
    ...payLoad,
    uid : UserData.id,
    location: Location,
    event_type: Event,
    organizer: Organiser,
    funding_required: FUNDING_REQUIRED_FALSE,
    event_wheelchair: eventWheelChairAccessible,
    event_animal_allergies: eventAnimalAllergies,
    event_hay_fever: eventHayFever,
    languages,
    create_as_type: userGroupId,
    funding: FUNDING_SELF
  }

  console.log("PAyload 1: ", payLoad);

  const updateContributeAsSelf = () => {
    setContributeAsSelf(!constributeAsSelf);
    setContributeAsGroup(false);
    //If 'self' option is selected, then groupid is taken as 0
    //Else if the option is de-selected, then reset groupid is take as undefined (for validation)
    setUserGroupId(constributeAsSelf == false ? 0: undefined);  
  }

  const updateContributeAsGroup = () => {
    setContributeAsSelf(false);
    setContributeAsGroup(!contributeAsGroup);
  }

  const setGroupSelection = () => {

    if(userGroupId == undefined || isNaN(userGroupId)){
      Utility.showToast('error', 'Choose an option');
      return false;
    }
    
    setSelectGroupModal(false);

  }

  const handleLanguageChange = (options) => {
    const res = [];
    for(const i in options){
      res.push(options[i].value);
    }
    setLanguages(res);
  }

  const hanldeModalClose = () => setModalShow(false);
  
  const handleModalShow = () => setModalShow(true);

  const handleOrgListingScroll = (e) => {
    
    const listingDiv = e.target;

    if (listingDiv.offsetHeight + listingDiv.scrollTop >= listingDiv.scrollHeight) {  
      
      setModalOrgLoader(true);
      clearTimeout(userScrollTimeout);
      userScrollTimeout = setTimeout(function(){
        userListingOffset += LIMIT_USER_LISTING;
        dispatch(getUserListingRequest({offset:userListingOffset, limit:LIMIT_USER_LISTING, name:searchOrgName, include_groups: true, showSuccessToast:false}, onUserListingRequestSuccess, onError));
        setModalOrgLoader(false);
      }, 2500);

    }

  }

  const searchOrganizers = (event) => {

    setModalOrgLoader(true);
    const searchVal = event.target.value.trim(); 
    setSearchOrgName(searchVal);
    clearTimeout(userSearchTimeout);
    
    userSearchTimeout = setTimeout(function(){
      userListingOffset = 0;
      dispatch(getUserListingRequest({offset:userListingOffset, limit:LIMIT_USER_LISTING, name:searchVal, include_groups: true, showSuccessToast:false}, onUserSearchRequestSuccess, onError));
      setModalOrgLoader(false);
    }, 2500);

  }

  const inviteOrg = (evt) => {
    const elem = evt.target;
    const uidSendTo = parseInt( elem.getAttribute('uid') );
    elem.innerText = "Invited";
    elem.classList.add("invited");
    elem.setAttribute("disabled", true);
    Utility.showToast('success', "Invitation sent");
    //dispatch( projectInviteRequest({ project_id: projectId, uid_sender: uid, uid_receiver: uidSendTo }, onInvitationSuccess, onError) );
  }

  const toggleNeedSkills = function(val){
    if(val == false){ 
      SetHowmany("");
      document.getElementById('how-many-val').value = '';
    }
    setNeedSkills(val);
  }

  const OrganizerListing = (param) => {

    const organizerUsr = param.organizer;
    const key = param.key;

    return(
      <>
        <div key={key} className="col-md-3 text-center organizer-block">

            <div className="padding-0 col-md-12">
              {
                organizerUsr.type == 'u' ? <img className="col-md-6 padding-0 organizer-profile-pic" src={organizerUsr.profile_pic} /> : <i className="fa fa-group created-group-icon"></i>
              }
            </div>
            <div className="col-md-12">
              <span className="username block semi-bold font-manrope-semibold">
                {
                  organizerUsr.type == 'u' ? organizerUsr.firstname + ' ' + organizerUsr.lastname : organizerUsr.name
                }
              </span>
              <span className="location block">{organizerUsr.city} {organizerUsr.country ? ',' + organizerUsr.country : ''}</span>
              <button className="bckg-color border-none px-4 py-2 drop-shadow-3 font-manrope-regular" onClick={inviteOrg} uid={organizerUsr.id}>Invite { organizerUsr.type == 'u' ? 'Contributor' : 'Contributors'}</button>
            </div>

        </div>
      </> 
    );

  }

  const [state, setState] = React.useState({
    Projectname: "",
    Description: "",
    Category: "",
    Focus: [],
    zip_code: '',
    region: '',
    area_focus: '',
    address: "",
    date: "",
    year: "",
    month: "",
    date_1: "",
    year_1: "",
    month_1: "",
    organization: "",
    passion: "",
    vision: "",
    benefit: "",
    budget: '',
    awareness: "",
    plan_results: '',
    measure_results: '',
    government_approvals: ""
  });


  function RenderOrganizerListing(){

    if(userListing.length > 0){

        return(
          <>
            { 
              userListing.map( (option, i) => (
                <OrganizerListing key={i} organizer={option}/>
              ))
            } 
          </>
        );
    
    }else{

        return(
          <p className="text-center">No record found</p>
        );

    }

    
  }

  const handleProjectFocus = (e, id) => {

    const focusStateArr = state.Focus;
    let focusStateIdx = focusStateArr.indexOf(id);
    if(focusStateIdx > -1){
      focusStateArr.splice(focusStateIdx, 1);
    }else{
      focusStateArr.push(id);
    }

    setState({
      ...state,
      [e.target.name]: focusStateArr,
    });

  }

  const RenderFocusOptions = () => {

    if(projectFocus.length == 0) return (<span className="font-manrope-regular">No records</span>);

    return projectFocus.map( (option, i) => {
      return (<span key={i} className="w-50 display-inline-block"><label className="font-manrope-semibold fs-16"><input name="Focus" className="contibute-as-check" type="checkbox" checked={state.Focus.includes(option.id)} onChange={e => handleProjectFocus(e, option.id)} />&nbsp;&nbsp;&nbsp;&nbsp;{option.name}</label></span>);
    } );

  }

  // function RenderFocusOptions(){

  //     return (
  //             <>
  //             <option key="0" value>Select</option>
  //             { 
  //               projectFocus.map( (option, i) => (
  //                 <option key={i} value={option.id}>{option.name}</option>
  //               ))
  //             } 
  //             </>      
  //     );

  // }

  const setProjectHelp = function(val){

    const value = parseInt(val);
    const index = ProjectHelpId.indexOf(value);
    
    if(index > -1){
      ProjectHelpId.splice(index, 1);
      setProjectHelpId([...ProjectHelpId]);
    }
    else{
      setProjectHelpId([...ProjectHelpId, value]);
    }    

  }


  function RenderHelpOptions(){

      return (
              <>
              { 
                projectHelp.map( (option, i) => (
                  <div key={i} className="row"><label htmlFor={"project_help_" + option.id} className="containers font-manrope-medium">{option.name}<input type="checkbox" checked={ProjectHelpId.includes(option.id)} onChange={(e) => setProjectHelp(e.target.value)} value={option.id} id={"project_help_" + option.id} name="project_help" /><span className="checkmark"></span></label></div>
                ))
              } 
              </>      
      );

  }


  function RenderEquipmentsOptions(){

      return (
              <>
              { 
                projectEquipments.map( (option, i) => (
                  <div key={i} className="row"><label htmlFor={"project_equipment_" + option.id} className="containers font-manrope-medium">{option.name}<input type="checkbox" checked={ProjectEquipmentId.includes(option.id)} onChange={(e) => setProjectEquipment(e.target.value)} value={option.id} id={"project_equipment_" + option.id} name="project_equipment[]" /><span className="checkmark"></span></label></div>
                ))
              } 
              </>      
      );

  }

  const setProjectSupplies = function(val){

    const value = parseInt(val);
    const index = ProjectSuppliesId.indexOf(value);
    
    if(index > -1){
      ProjectSuppliesId.splice(index, 1);
      setProjectSuppliesId([...ProjectSuppliesId]);
    }
    else{
      setProjectSuppliesId([...ProjectSuppliesId, value]);
    }

  }


  const setProjectEquipment = function(val){

    const value = parseInt(val);
    const index = ProjectEquipmentId.indexOf(value);
    
    if(index > -1){
      ProjectEquipmentId.splice(index, 1);
      setProjectEquipmentId([...ProjectEquipmentId]);
    }
    else{
      setProjectEquipmentId([...ProjectEquipmentId, value]);
    }

  }


  const handleSuppliesKeyDown = function(e){

    if(e.key == 'Enter'){
      const val = e.target.value.trim();
      if(val != ''){
        setProjectSuppliesOtherList([...ProjectSuppliesOtherList, val]);
        setProjectSuppliesOther('');
      }
    }

  }


  const handleEquipmentKeyDown = function(e){

    if(e.key == 'Enter'){
      const val = e.target.value.trim();
      if(val != ''){
        setProjectEquipmentsOtherList([...ProjectEquipmentsOtherList, val]);
        setProjectEquipmentOther('');
      }
    }

  }  


  const removeOtherSupplies = (e) => {

    const removedList = ProjectSuppliesOtherList.filter(function(value) {
      return value != e; 
    });
    setProjectSuppliesOtherList(removedList);    

  }

  const removeOtherEquipments = (e) => {

    const removedList = ProjectEquipmentsOtherList.filter(function(value) {
      return value != e; 
    });
    setProjectEquipmentsOtherList(removedList);    

  }  

  function RenderSuppliesOptions(){

      return (
              <>
              { 
                projectSupplies.map( (option, i) => (
                  <div key={i} className="row"><label htmlFor={"project_supplies_" + option.id} className="containers font-manrope-medium">{option.name}<input type="checkbox" checked={ProjectSuppliesId.includes(option.id)} onChange={(e) => setProjectSupplies(e.target.value)} value={option.id} id={"project_supplies_" + option.id} name="project_supplies[]" /><span className="checkmark"></span></label></div>
                ))
              } 
              </>      
      );

  }


  function RenderTypeOptions(){

      return (
              <>
              <option key="0" value>Select</option>
              { 
                projectType.map( (option, i) => (
                  <option key={i} value={option.id}>{option.name}</option>
                ))
              } 
              </>      
      );

  }


  function handleChange(evt) {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  const handleProjectTypeChange = evt => {
    const val = evt.target.value;
    updateProjectFocus(val);
    //Reset Project Focus state because now project focus options have been changed
    setState({
      ...state,
      [evt.target.name]: val,
      ['Focus']: []
    });   
    
  }

  const updateProjectFocus = type_id => {

    const filteredProjectFocus = projectFocusAll.filter(focus => focus.type_id == type_id);
    setProjectFocus(filteredProjectFocus);

  }

  const CheckProjectOverview = (e) => {
    
    setProjectnameError('');
    setDescError('');
    setTypeError('');
    setfocusError('');
    setCreateAsTypeError('');
    e.preventDefault();
    let formIsValid = true;

    if (state.Projectname.trim() == "") {
      setProjectnameError("Enter Project Name");
      formIsValid = false;
      Utility.showToast('error', "Enter Project Name");
    }
    else if (state.Description.trim() == "") {
      setDescError("Description Cannot be empty");
      formIsValid = false;
      Utility.showToast('error', "Enter Description");
    }
    else if (state.Category == "") {
      setTypeError("Choose project type");
      formIsValid = false;
      Utility.showToast('error', "Choose project type");
    }
    else if (Array.isArray(state.Focus) == false || state.Focus.length == 0) {
      setfocusError("Choose project focus");
      formIsValid = false;
      Utility.showToast('error', "Choose project focus");
    }
    else if(userGroupId == undefined || isNaN(userGroupId)){
      setCreateAsTypeError('Choose an option');
      formIsValid = false;
      Utility.showToast('error', 'Choose an option');
    }

    if(formIsValid) addActiveClass(2);
    payLoad = {
      ...payLoad,
      name: state.Projectname.trim(),
      description: state.Description.trim(),
      category: parseInt(state.Category),
      focus: state.Focus
    }

  }

  const CheckProjectLocation = (e) => {
    const value = e.target.value;
    setzipcodeError("");
    setaddressError("");
    setDateError("");
    setMonthError("");
    setyearError("");
    setDate_1Error("");
    setMonth_1Error("");
    setyear_1Error("");

    e.preventDefault();
    let formIsValid = true;
    let startingStamp = null, startingDate = null, endingDate = null;

    //Current stamp initialize to today's day midnight
    let currentStamp = new Date;
    currentStamp.setHours(0,0,0,0);

    /*Location is false for city and true for Region*/

    if(Location == LOCATION_CITY){

      if (state.zip_code == null || state.zip_code.trim() == "") {
        setzipcodeError("Enter location/Zip code Name");
        formIsValid = false;
        Utility.showToast('error', "Enter location/Zip code name");
      }
      else if (isNaN(state.zip_code)) {
        setzipcodeError("Zip code invalid");
        formIsValid = false;
        Utility.showToast('error', "Zip code invalid");
      }      
      else if (state.address == null || state.address.trim() == "") {
        setaddressError("Address or Location cannot be empty");
        formIsValid = false;
        Utility.showToast('error', "Address or Location cannot be empty");
      }

    }else{

      if (state.region == null || state.region.trim() == "") {
        setzipcodeError("Enter region");
        formIsValid = false;
        Utility.showToast('error', "Enter region");
      }
      else if(state.area_focus == null || state.area_focus.trim() == ""){
        setaddressError("Area of focus cannot be empty");
        formIsValid = false;
        Utility.showToast('error', "Area of focus cannot be empty");
      }

    }

    if (state.date == "") {
      setDateError("Enter Date");
      formIsValid = false;
      Utility.showToast('error', "Enter starting date");
    }
    else if (state.month == "") {
      setMonthError("Enter Month");
      formIsValid = false;
      Utility.showToast('error', "Enter starting month");
    }
    else if (state.year == "") {
      setyearError("Enter Year");
      formIsValid = false;
      Utility.showToast('error', "Enter starting year");
    }else{

      startingDate = `${state.year}-${state.month}-${state.date}`;
      startingStamp = Date.parse(startingDate);
      if(startingStamp < currentStamp){
        formIsValid = false;
        Utility.showToast('error', "Starting date must be greater than the current date");        
      }

    }

    if(Event == EVENT_TYPE_ONGOING){

      if (state.date_1 == "") {
        setDate_1Error("Enter Date");
        formIsValid = false;
        Utility.showToast('error', "Enter ending date");
      }
      else if (state.month_1 == "") {
        setMonth_1Error("Enter Month");
        formIsValid = false;
        Utility.showToast('error', "Enter ending month");
      }
      else if (state.year_1 == "") {
        setyear_1Error("Enter Year");
        formIsValid = false;
        Utility.showToast('error', "Enter ending year");
      }else{
        endingDate = `${state.year_1}-${state.month_1}-${state.date_1}`;
        const endingStamp = Date.parse(endingDate);
        if(endingStamp < currentStamp || endingStamp < startingStamp){
          formIsValid = false;
          Utility.showToast('error', "Ending date must be greater than the current date and starting date");        
        }        
      }

    }

    if(formIsValid) addActiveClass(3);
    
    payLoad = {
      ...payLoad,
      event_start_date: startingDate,
      location: Location,
      event_type: Event,
      show_address: Number(showAddress)
    }

    if(state.address){
      payLoad = {
        ...payLoad,
        address: state.address
      }      
    }

    if(state.zip_code){
      payLoad = {
        ...payLoad,
        zip_code: parseInt(state.zip_code)
      }      
    }

    if(endingDate){
      payLoad = {
        ...payLoad,
        event_end_date: endingDate
      }
    }

    if(state.region){
      payLoad = {
        ...payLoad,
        region: state.region
      }      
    }

    if(state.area_focus){
      payLoad = {
        ...payLoad,
        area_focus: state.area_focus
      }       
    }

  }

  const CheckProjectOrganization = (e) => {
    
    let formIsValid = true;
    addActiveClass(4)
    setActive2(true);
    setUnlock1(true);
    
    payLoad = {
      ...payLoad,
      organizer: Organiser
    }
    
    if(Partnership){
      payLoad = {
        ...payLoad,
        partnership_organization: state.organization
      }
    }

  }

  const CheckPassion = (e) => {
    let formIsValid = true;
    if (state.passion.trim() == "") {
      formIsValid = false;
      Utility.showToast('error', "Enter Passion");
    }
    if(formIsValid) addActiveClass(5);
    payLoad = {
      ...payLoad,
      passion: state.passion.trim()
    }
  };

  const CheckVision = (e) => {
    let formIsValid = true;
    if (state.vision.trim() == "") {
      formIsValid = false;
      Utility.showToast('error', "Enter Impact");
    }
    else if(state.benefit.trim() == ""){
      formIsValid = false;
      Utility.showToast('error', "Enter Benefit");
    }
    if(formIsValid) addActiveClass(6);
    setActive3(true);
    setUnlock2(true);
    payLoad = {
      ...payLoad,
      impact: state.vision.trim(),
      benefit: state.benefit.trim()
    }
  };

   const HandleKeyDown=(e)=> {
    
    if (e.key === 'Enter') {
      const val = e.target.value.trim();
      if(val!=''){     
        setskilllist([...skillList, val]);
        SetOtherSkills('');
      }
    }
    
  }

  const removeSkill =(e)=>{
    
    let RemovedList = skillList.filter(function(value) {
      return value.OtherSkills != e; 
    });
    setskilllist(RemovedList);

  }

  const CheckTalent = (e) => {
    let formIsValid = true;
    if(NeedSkills == true && Howmany == 0){
      formIsValid = false;
      Utility.showToast('error', "No. of people with certain skills or talents required");
    }
    else if(ProjectHelpId.length == 0){
      formIsValid = false;
      Utility.showToast('error', "Select project need option");
    }
    if(formIsValid) addActiveClass(7);
    payLoad = {
      ...payLoad,
      resources_count: parseInt(Howmany),
      help: ProjectHelpId,
      help_extra: skillList
    }
  }
  
  const CheckCompensation = (e)=>{
    
    addActiveClass(8);
    
    payLoad = {
      ...payLoad,
      funding
    }

    if(resourceContribution){
      payLoad = {
        ...payLoad,
        resource_contribution: parseFloat(resourceContribution)
      }      
    }

  }


  const saveEquipmentSupplies = (e) => {
    payLoad = {
      ...payLoad,
      supplies: ProjectSuppliesId,
      equipment: ProjectEquipmentId,
      supplies_extra: ProjectSuppliesOtherList,
      equipment_extra: ProjectEquipmentsOtherList
    }
    addActiveClass(9);    
  }

  const saveFunding = (e) => {
    
    let formIsValid = true;
    const fundingReqdVal = parseInt(fundingRequired);
    
    if(fundingReqdVal == FUNDING_REQUIRED_TRUE && state.budget.trim() == ""){
      formIsValid = false;
      Utility.showToast('error', "Budget required");      
    }
    
    if(formIsValid){
      
      payLoad = {
        ...payLoad,
        funding_required: fundingReqdVal
      }

      if(state.budget){
        payLoad = {
          ...payLoad,
          budget: parseFloat(state.budget)
        }        
      }
      
      addActiveClass(10);
      setActive4(true);
      setUnlock3(true);    

    }

  }


  const saveAwareness = (e) => {

    let formIsValid = true;

    if(formIsValid){
      payLoad = {
        ...payLoad,
        plan_results: state.plan_results,
        measure_results: state.measure_results
      }
      addActiveClass(11);
    }

  }


  const saveConsiderations = (e) => {

    payLoad = {
      ...payLoad,
      event_wheelchair: eventWheelChairAccessible,
      event_animal_allergies: eventAnimalAllergies,
      event_hay_fever: eventHayFever,
      languages,
      create_as_type: Number(userGroupId)
    }

    
    console.log("PAyload final: ", payLoad);

    dispatch(projectCreateRequest(payLoad, onCreateSuccess, onError));         

  }

  async function onCreateSuccess(response) {
    navigate("/thankyou"); 
  }

    //Dispatch request for fetching project types and focueses on page load
    useEffect(() => {

      if(projectFocus.length == 0)
        dispatch(getProjectFocusRequest({}, onFocusRequestSuccess, onError));
      if(projectType.length == 0)
        dispatch(getProjectTypeRequest({}, onTypeRequestSuccess, onError));
      if(projectHelp.length == 0)
        dispatch(getProjectHelpRequest({}, onHelpRequestSuccess, onError));
      if(projectSupplies.length == 0)
        dispatch(getProjectSuppliesRequest({}, onSuppliesRequestSuccess, onError));
      if(projectEquipments.length == 0)
        dispatch(getProjectEquipmentsRequest({}, onEquipmentsRequestSuccess, onError));
      if(languageOptions.length == 0)
        dispatch(getLanguageRequest({}, onLanguageRequestSuccess, onError));

      dispatch(getUserListingRequest({offset:userListingOffset, limit:LIMIT_USER_LISTING, name:searchOrgName, include_groups: true, showSuccessToast:false}, onUserListingRequestSuccess, onError));

    },[]);  


    async function onLanguageRequestSuccess(resp){
      
      for(const i in resp){
        languageOptions.push({
          value: resp[i].id,
          label: resp[i].name
        });
      }

      console.log("languageOptions", languageOptions);

    }

    async function onUserListingRequestSuccess(resp){
      setUserListing([...userListing,...resp]);
    }

    async function onUserSearchRequestSuccess(resp){
      setUserListing(resp);
    }

    async function onFocusRequestSuccess(response) {
      projectFocusAll = response;
      setFocusisLoaded(true);
      RenderFocusOptions();
    }

    async function onTypeRequestSuccess(response) {
      projectType = response;
      RenderTypeOptions();
    }

    async function onHelpRequestSuccess(response){
      projectHelp = response;
      setHelpisLoaded(true);
    }    

    async function onSuppliesRequestSuccess(response){
      projectSupplies = response;
    }

    async function onEquipmentsRequestSuccess(response){
      projectEquipments = response;
    }

    async function onError(error) {
      console.log("error", error);
    }


  function validateOverviewCategory(e, index){

    if(index >= 2)
      addActiveClass(2);
    if(index >= 3)
      addActiveClass(3);

  }


  function validateImpactCategory(e, index){

    if(index >= 2){
      addActiveClass(5);
    }

  }

  function validateResourcesCategory(e, index){

    if(index >= 2)
      addActiveClass(7);
    if(index >= 3)
      addActiveClass(8);
    if(index >= 4)
      validateResourcesCategory(e);

  }  

  function validateSpecificsCategory(e, index){

    if(index >= 2){
      addActiveClass(11);
    }

  }

  const getAddressFromZipCode = zip_code => {

    if(zip_code){

      Geocode.setApiKey("AIzaSyBa06hb1HgYN0_264XzE_sgMPVuRaAk_7M");
      Geocode.setRegion("us");
  
      // Get latitude & longitude from address.
      Geocode.fromAddress(zip_code).then(
        (response) => {
          
          if(response.status == 'OK'){
            setState({
              ...state,
              'address': response.results[0].formatted_address
            });
          }
  
        },
        (error) => {
          //console.error("Geocoding error: ");
        }
      );    
  
    }else{

      setState({
        ...state,
        'address': ''
      });

    }

  }

  return (
    <>    
      <div className="slidepage">
        <div className="slideitem">
          {/* Sidebar start */}
        <div
            className={
              open_slider ? "sidebar pc_sidebar active" : "sidebar pc_sidebar"
            }
          >
            <div className="container margin-pc-header sidebar_wraper">
              <div className="sidebar_content">
                
                <div className="row">
                  <div className="mt-2">
                    <img className="img-fluid w-mobilw ml-3 mt-2" src={leftBack} width="14" />
                  </div>
                  <div className="ml-3 mt-3"><h5 className="font-manrope-extra-bold margin-home-btn fs-16"><Link to="/home">Back to Home</Link></h5></div>
                </div>

                <div className="slide_icon">
                  <h1 className="mt-5 mb-4 font-manrope-bold create-project-head">
                    Create a New Project
                  </h1>
                  <div className="drawer_icon">
                    <button
                      onClick={(e) => handleSlider(true)}
                      type="button"
                    ></button>
                  </div>
                </div><br/>
                <div className="col-md-12">
                  <Accordion>
                    <div className={isActive1 ? "open" : ""}>
                      <div
                        className="accordian_header font-manrope-bold"
                        onClick={() => handleToggle(1)}
                      >
                        Overview
                      </div>
                      <div className={isActive1 ? "show" : "hide_accord"}>
                        <div className="accordion-body">
                          <ul className="sidebar_list font-manrope-medium">
                            <Link
                              className={active == 1 ? "active" : null}
                              to="#"
                              onClick={(e) => addActiveClass(1)}
                            >
                              General
                            </Link>
                            <Link
                              className={active == 2 ? "active" : null}
                              to="#"
                              onClick={(e) => validateOverviewCategory(e, 2)}
                            >
                              When &amp; Where
                            </Link>
                            <Link
                              className={active == 3 ? "active" : null}
                              to="#"
                              onClick={(e) => validateOverviewCategory(e, 3)}
                            >
                              Organization
                            </Link>
                          </ul>
                        </div>
                      </div>
                    </div> <br/>
                    <div className={isActive2 ? "open" : ""}>
                      {unlock1 ? (
                        <div
                          className="accordian_header font-manrope-bold"
                          onClick={() => handleToggle(2)}
                        >
                          Impact
                        </div>
                      ) : (
                        <div
                          className="accordian_header  font-manrope-bold disabledlink"
                          onClick={(event) => event.preventDefault()}
                        >
                          Impact<span>{lock}</span>
                        </div>
                      )}
                      <div className={isActive2 ? "show" : "hide_accord"}>
                        <div className="accordion-body">
                          <ul className="sidebar_list font-manrope-medium">
                            <Link
                              className={active == 4 ? "active" : null}
                              to="#"
                              onClick={(e) => addActiveClass(4)}
                            >
                              Passion
                            </Link>
                            <Link
                              className={active == 5 ? "active" : null}
                              to="#"
                              onClick={(e) => validateImpactCategory(e, 5)}
                            >
                              Vision
                            </Link>
                          </ul>
                        </div>
                      </div>
                    </div><br/>
                    <div className={isActive3 ? "open" : ""}>
                      {unlock2 ? (
                        <div
                          className="accordian_header font-manrope-bold"
                          onClick={() => handleToggle(3)}
                        >
                          Resources
                        </div>
                      ) : (
                        <div
                          className="accordian_header font-manrope-bold disabledlink"
                          onClick={(event) => event.preventDefault()}
                        >
                          Resources<span>{lock}</span>
                        </div>
                      )}
                      {/* <div className="accordian_header font-manrope-bold" onClick={(e) => handleToggle(3)}>Resources<span>{lock}</span></div> */}
                      <div className={isActive3 ? "show" : "hide_accord"}>
                        <div className="accordion-body">
                          <ul className="sidebar_list font-manrope-medium">
                            <Link
                              className={active == 6 ? "active" : null}
                              to="#"
                              onClick={(e) => addActiveClass(6)}
                            >
                              Skills
                            </Link>
                            <Link
                              className={active == 7 ? "active" : null}
                              to="#"
                              onClick={(e) => validateResourcesCategory(e, 2)}
                            >
                              Compensation
                            </Link>
                            <Link
                              className={active == 8 ? "active" : null}
                              to="#"
                              onClick={(e) => validateResourcesCategory(e, 3)}
                            >
                              Equipment & Supplies
                            </Link>
                            <Link
                              className={active == 9 ? "active" : null}
                              to="#"
                              onClick={(e) => addActiveClass(9)}
                            >
                              Funding
                            </Link>
                          </ul>
                        </div>
                      </div>
                    </div> <br/>
                    <div className={isActive4 ? "open" : ""}>
                      {unlock3 ? (
                        <div
                          className="accordian_header font-manrope-bold"
                          onClick={() => handleToggle(4)}
                        >
                          Specifics
                        </div>
                      ) : (
                        <div
                          className="accordian_header font-manrope-bold disabledlink"
                          onClick={(event) => event.preventDefault()}
                        >
                          Specifics<span>{lock}</span>
                        </div>
                      )}

                      <div className={isActive4 ? "show" : "hide_accord"}>
                        <div className="accordion-body">
                          <ul className="sidebar_list font-manrope-medium">
                            <Link
                              className={active == 10 ? "active" : null}
                              to="#"
                              onClick={(e) => addActiveClass(10)}
                            >
                              Results
                            </Link>
                            <Link
                              className={active == 11 ? "active" : null}
                              to="#"
                              onClick={(e) => validateSpecificsCategory(e, 2)}
                            >
                              Consideration
                            </Link>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
          {/* Sidebar start */}
          <div className="project_overview content pl-5">
            <div
              className={
                active == 1 ? "container overview" : "container overview d-none"
              }
            >
              <div className="row">
                <div className="w-75 mt-5 pl-5 pr-5 pb-5 drop-shadow-4 project-overview-form">
                  <h2 className="text-center mobile-font font-manrope-bold mt-4">
                    Project Overview
                  </h2>
                  <Form>
                    <div className="">
                      <h5 className="mobile-overview mt-4 mb-3 font-manrope-bold">
                        What will be the name of your project?
                      </h5>
                      <div className="card bg-signup-2">
                        <div className="col-md-12">
                          <Form.Label className="mb-0 project-overview-font font-manrope-semibold">
                            Project Name
                          </Form.Label>
                          <Form.Control
                            type="text"
                            name="Projectname"
                            value={state.Projectname}
                            onChange={handleChange}
                            className="px-0 font-manrope-light"
                          />
                        </div>
                      </div>
                      <span className="red_span">{projectnameError}</span>
                      <div className="mt-3 mb-3">
                        <h5 className="mobile-overview font-manrope-bold">
                          Description
                        </h5>
                      </div>
                      <div className="card bg-signup-2">
                        <div className="col-md-12">
                          <Form.Label className="project-overview-font font-manrope-semibold">
                            Type Description{" "}
                          </Form.Label>
                          <div className="form-group">
                            <textarea
                              className="form-control rounded-0 pl-0 font-manrope-light"
                              id="exampleFormControlTextarea1 over-view"
                              rows="8"
                              name="Description"
                              value={state.Description}
                              onChange={handleChange}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <span className="red_span">{descError}</span>
                      <div className="mt-3 mb-3">
                        <h5 className="mobile-overview font-manrope-bold">
                          Select Project Type
                        </h5>
                      </div>
                      <div className="card bg-signup-2">
                        <div className="col-md-12">
                          <Form.Label className="project-overview-font font-manrope-semibold">
                            Choose Category
                          </Form.Label>
                          <Form.Select
                            name="Category"
                            className="font-manrope-medium form-select w-100 select-drop mb-2"
                            aria-label="Default select example"
                            onChange={handleProjectTypeChange} value={state.Category}
                          >
                            { <RenderTypeOptions/> }
                          </Form.Select>
                        </div>
                      </div>
                      <span className="red_span">{typeError}</span>
                      <div className="mt-3 mb-3">
                        <h5 className="mobile-overview font-manrope-bold">
                          Project Focus
                        </h5>
                      </div>
                      <div className="card bg-signup-2">
                        <div className="col-md-12">
                          <Form.Label className="project-overview-font font-manrope-semibold">
                            Select Focus
                          </Form.Label>
                          {/* <Form.Select
                            name="Focus"
                            className="font-manrope-medium form-select w-100 select-drop mb-2"
                            aria-label="Default select example"
                            onChange={handleChange} value={state.Focus}
                          >
                          
                          </Form.Select> */}
                          <fieldset>
                            { <RenderFocusOptions/> }
                          </fieldset>
                        </div>
                      </div>
                      <span className="red_span">{focusError}</span>
                      <div className="mt-3 mb-3">
                        <h5 className="mobile-overview font-manrope-bold">
                          Create Project as
                        </h5>
                      </div>
                      <div className="card bg-signup-2">
                        <div className="col-md-12">
                        <div className="text-left py-2">
                          <fieldset>
                            <label className="font-manrope-semibold fs-16"><input className="contibute-as-check" type="checkbox" checked={constributeAsSelf} onChange={() => updateContributeAsSelf()} />&nbsp;&nbsp;&nbsp;&nbsp;An Individual</label>
                          </fieldset>
                          <fieldset>
                            <span className="w-50 display-inline-block"><label className="font-manrope-semibold fs-16"><input className="contibute-as-check" type="checkbox" checked={contributeAsGroup} onChange={() => updateContributeAsGroup()} />&nbsp;&nbsp;&nbsp;&nbsp;A Group</label></span>
                            <span className="w-50 display-inline-block text-right">
                              {
                                contributeAsGroup ? <button type="button" onClick={e => setSelectGroupModal(true)} className="drop-shadow select-group font-manrope-medium px-3 py-2 bckg-color border-none fs-12">Select Group</button> : <></>
                              }
                            </span>
                          </fieldset>
                        </div>
                        </div>
                      </div>
                      <span className="red_span">{createAsTypeError}</span>                    
                    </div>
                  </Form>
                </div>
                <SignProjectOverview/>
              </div>
              <div className="row">
                <div className="col-md-10"></div>
                <div className="col-md-2 mt-3 mobile-center">
                  <button onClick={(e) => CheckProjectOverview(e)} type="button" className="border-none shadow float-right overviwe-next mt-51 font-manrope-regular fs-16 bckg-color">
                    Next
                  </button>
                </div>
              </div>
            </div>

            <div
              className={
                active == 2
                  ? "container overview project-location"
                  : "container overview project-location d-none"
              }
            >
              <div className="row">
                <div className="w-75 mt-5 pl-5 pr-5 pb-5 drop-shadow-4 project-overview-form">
                  <h2 className="text-center mobile-font mt-4 font-manrope-bold">
                    When & Where
                  </h2>
                  <Form>
                    <div className="">
                      <h5 className="project-location mt-5 font-manrope-bold">
                        Project Location
                      </h5>
                      <div className="col-md-12">
                        <div className="row">
                          <h6 className="text-dark font-manrope-bold">City</h6>
                          <label className="switch ml-3">
                            <input
                              type="checkbox"
                              onChange={(e) => setLocation(e.target.checked ? LOCATION_REGION : LOCATION_CITY)}
                              checked={Location == LOCATION_REGION ? true : false}
                            />
                            <span className="slider round"></span>
                          </label>
                          <h6 className="ml-3 text-dark font-manrope-bold">
                            Region
                          </h6>
                        </div>
                      </div>
                      <div className="card bg-signup-2 w-46 card-height-location">
                        <div className="col-md-12">
                          {Location == LOCATION_REGION ? (
                            <>
                            <h6 className="mb-0 location-font mt-2 font-manrope-bold">
                              Region
                            </h6>
                            <div className="form-group">
                              <input
                                type="text"
                                name="region"
                                className="form-control w-100 p-0 font-manrope-light"
                                onChange={handleChange}
                                value={state.region}
                              />
                            </div> 
                            </> 
                          ) : (
                            <>
                            <h6 className="mb-0 location-font mt-2 font-manrope-semibold">
                              Zip Code
                            </h6>
                            <div className="form-group">
                              <input
                                type="text"
                                name="zip_code"
                                className="form-control w-100 p-0 font-manrope-light"
                                onChange={handleChange}
                                value={state.zip_code}
                                onBlur={e => getAddressFromZipCode(e.currentTarget.value)}
                              />
                            </div>
                            </>                          
                          )}
                        </div>
                      </div>
                      <span className="red_span">{zipcodeError}</span>
                      <div className="mt-3 w-46">
                        <div className="row">
                          <div className="col-md-10 col-10">
                            <h5 className="project-location font-manrope-bold">
                              Only Show Specific Address to Approved Individuals
                            </h5>
                          </div>
                          <div className="col-md-2 col-2">
                            <label className="switch ml-3 float-right">
                              <input type="checkbox" checked={!showAddress} onChange={e => setShowAddress(!e.target.checked)} />
                              <span className="slider round"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="card bg-signup-2 w-46 card-height-location">
                        <div className="col-md-12">
                          {Location == LOCATION_REGION ? (
                            <>
                            <h6 className="mb-0 location-font mt-2 font-manrope-semibold">
                              Area(s) of Focus
                            </h6>
                            <div className="form-group">
                              <input type="text" name="area_focus" className="form-control w-100 p-0 font-manrope-light"
                              onChange={handleChange} value={state.area_focus} />
                            </div>                          
                            </>
                          ) : (
                            <>
                            <h6 className="mb-0 location-font mt-2 font-manrope-semibold">
                              Address/Location
                            </h6>
                            <div className="form-group">
                              <input type="text" name="address" className="form-control w-100 p-0 font-manrope-light"
                              onChange={handleChange} value={state.address} />
                            </div>
                            </>
                          )}
                        </div>
                      </div>
                      <span className="red_span">{addressError}</span>

                      <div className="w-46 mt-3">
                        <h5 className="project-location font-manrope-bold">
                          Events Dates
                        </h5>
                      </div>
                      <div className="col-md-12">
                        <div className="row mt-4">
                          <h6 className="text-dark font-manrope-bold">
                            Single-Event
                          </h6>
                          <label className="switch ml-2">
                            <input
                              type="checkbox"
                              onChange={(e) => setEvent(e.target.checked ? EVENT_TYPE_ONGOING : EVENT_TYPE_SINGLE)}
                            />
                            <span className="slider round"></span>
                          </label>
                          <h6 className="ml-2 text-dark font-manrope-bold">
                            Ongoing Event
                          </h6>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="row">
                          <div className="card bg-signup-2 w-6 card-height-locations">
                            <div className="col-md-12">
                              <h6 className="mb-0 location-font mt-2 font-manrope-semibold">
                                Month
                              </h6>
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control w-100 p-0 font-manrope-light"
                                  placeholder="MM"
                                  name="month"
                                  value={state.month}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <span className="red_span">{monthError}</span>
                          </div>
                          <div className="card bg-signup-2 w-6 card-height-locations ml-3">
                            <div className="col-md-12">
                              <h6 className="mb-0 location-font mt-2 font-manrope-semibold">
                                Day
                              </h6>
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control w-100 p-0 font-manrope-light"
                                  placeholder="DD"
                                  name="date"
                                  value={state.date}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <span className="red_span">{dateError}</span>
                          </div>
                          <div className="card bg-signup-2 w-9 card-height-locations ml-3">
                            <div className="col-md-12">
                              <h6 className="mb-0 location-font mt-2 font-manrope-semibold">
                                Year
                              </h6>
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control w-100 p-0 font-manrope-light"
                                  placeholder="YYYY"
                                  name="year"
                                  value={state.year}
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <span className="red_span">{yearError}</span>
                          </div>
                        </div>
                        {Event == EVENT_TYPE_ONGOING ? (
                          <div className="event_dates">
                            <div className="row mt-2 mb-2">
                              <h6>to</h6>
                            </div>

                            <div className="row">
                              <div className="card bg-signup-2 w-6 card-height-locations">
                                <div className="col-md-12">
                                  <h6 className="mb-0 location-font mt-2 font-manrope-extra-bold">
                                    Month
                                  </h6>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control w-100 p-0 font-manrope-light"
                                      placeholder="MM"
                                      name="month_1"
                                      value={state.month_1}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                <span className="red_span">{month_1Error}</span>
                              </div>
                              <div className="card bg-signup-2 w-6 card-height-locations ml-3">
                                <div className="col-md-12">
                                  <h6 className="mb-0 location-font mt-2 font-manrope-extra-bold">
                                    Day
                                  </h6>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control w-100 p-0 font-manrope-light"
                                      placeholder="DD"
                                      name="date_1"
                                      value={state.date_1}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                <span className="red_span">{date_1Error}</span>
                              </div>
                              <div className="card bg-signup-2 w-9 card-height-locations ml-3">
                                <div className="col-md-12">
                                  <h6 className="mb-0 location-font mt-2 font-manrope-extra-bold">
                                    Year
                                  </h6>
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      className="form-control w-100 p-0 font-manrope-light"
                                      placeholder="YYYY"
                                      name="year_1"
                                      value={state.year_1}
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                <span className="red_span">{year_1Error}</span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </Form>
                </div>
                <SignProjectOverview/>
              </div>
              <div className="row">
                <div className="col-md-12 mobile-center">
                  <button onClick={(e) => CheckProjectLocation(e)} type="button"
                    className="float-right shadow overviwe-next mt-51 font-manrope-regular bckg-color border-none">
                    Next
                  </button>
                </div>                
              </div>
            </div>

            <div
              className={
                active == 3
                  ? "container organization"
                  : "container organization d-none"
              }
            >
              <div className="row">
                <div className="w-75 mt-5 pl-5 pr-5 pb-5 drop-shadow-4 project-overview-form">
                  <h2 className="mt-4 text-center mobile-font font-manrope-bold">
                    Organization
                  </h2>
                  <div className="">
                    <h5 className="project-location mt-5 font-manrope-bold">
                      Project Organizer
                    </h5>
                    <div className="col-md-12">
                      <div className="row mt-3">
                        <h6 className="text-dark font-manrope-bold">Myself</h6>
                        <label className="switch ml-3">
                          <input value={Organiser} type="checkbox"
                            onChange={(e) => setOrganiser(e.target.checked ? ORGANIZER_TYPE_ANOTHER : ORGANIZER_TYPE_SELF)}
                          />
                          <span className="slider round"></span>
                        </label>
                        <h6 className="ml-3 text-dark font-manrope-bold">
                          Another Organizer
                        </h6>
                      </div>
                      <div className="">
                        {Organiser == ORGANIZER_TYPE_ANOTHER ?
                        <h6 className="mt-3 mb-3 font-manrope-semibold invite_btn" onClick={handleModalShow}>
                          Invite Organizers
                        </h6>:<h6 className="text-grey mt-3 mb-3 font-manrope-semibold">
                          Invite Organizers
                        </h6>}
                      </div>
                      <div className="row mt-2">
                        <h5 className="font-manrope-bold">
                          Partner Organization (Optional)
                        </h5>
                      </div>
                    </div>
                    <div className="mt-5 w-46">
                      <div className="row">
                        <div className="col-md-10 col-10">
                          <h5 className="project-location font-manrope-bold">
                            This project is in partnership with an organization
                          </h5>
                        </div>
                        <div className="col-md-2 col-2">
                          <label className="switch ml-3 float-right">
                            <input
                              type="checkbox"
                              onChange={(e) => setPartnership(e.target.checked)}
                            />
                            <span className="slider slider-color round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                    {Partnership ? (
                      <div className="drop-shadow card bg-organization w-46 card-height-location mt-4">
                        <div className="col-md-12">
                          <p className="mb-0 location-font mt-2 font-manrope-semibold color-black">
                            Organization name
                          </p>
                          <div className="form-group">
                            <input
                              type="text"
                              name="organization"
                              onChange={handleChange}
                              className="form-control w-100 p-0 font-manrope-light"
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="drop-shadow card disable bg-organization w-46 card-height-location mt-4">
                        <div className="col-md-12">
                          <p className="mb-0 location-font mt-2 font-manrope-semibold color-black">
                            Organization name
                          </p>
                          <div className="form-group">
                            <input disabled 
                              type="text"
                              name="organization"
                              onChange={handleChange}
                              className="form-control w-100 p-0 disable"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <SignProjectOverview/>
              </div>
              <div className="row">
                <div className="col-md-12 mobile-center">
                  <button type="button"
                    onClick={(e) => CheckProjectOrganization(e)}
                    className="float-right shadow overviwe-next mt-51 font-manrope-regular bckg-color border-none"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>

            <div
              className={
                active == 4 ? "container passion" : "container passion d-none"
              }
            >
              <div className="row">
                <div className="w-75 mt-5 pl-5 pr-5 pb-5 drop-shadow-4 project-overview-form">
                  <h2 className="text-center mt-4 w-46 mobile-font font-manrope-bold">
                    Passion
                  </h2>
                  <div className="">
                    <h5 className="project-location mt-5 font-manrope-bold">
                      Why are you passionate about this project?
                    </h5>
                    <div className="card bg-signup-2 drop-shadow">
                      <div className="col-md-12">
                        <h6 className="mb-0 location-font mt-2 font-manrope-semibold color-black">
                          Type Description
                        </h6>
                        <div className="row">
                          <div className="form-group w-100">
                            <textarea
                              name="passion"
                              className="form-control rounded-0 passion-border font-manrope-light"
                              id="exampleFormControlTextarea1" onChange={handleChange}
                              rows="15" value={state.passion}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <SignProjectOverview/>
              </div>
              <div className="row">
                <div className="col-md-12 mobile-center">
                  <button
                    // onClick={() => navigate("/vision")}
                    onClick={(e) => CheckPassion(e)}
                    type="button"
                    className="float-right shadow overviwe-next mt-51 font-manrope-regular fs-16 border-none bckg-color"
                  >
                  Next
                  </button>
                </div>
              </div>
            </div>

            <div
              className={
                active == 5 ? "container passion" : "container passion d-none"
              }
            >
              <div className="row">
                <div className="w-75 mt-5 pl-5 pr-5 pb-5 drop-shadow-4 project-overview-form">
                  <h2 className="text-center mt-4 w-46 mobile-font font-manrope-bold">
                    Vision
                  </h2>
                  <div className="">
                    <h5 className="project-location mt-5 font-manrope-bold">
                      What do you think will be the impact of this project?
                    </h5>
                    <div className="card bg-signup-2 drop-shadow">
                      <div className="col-md-12">
                        <h6 className="mb-0 location-font mt-2 font-manrope-semibold color-black">
                          Type Description
                        </h6>
                        <div className="row">
                          <div className="form-group w-100">
                            <textarea
                              name="vision"
                              className="form-control rounded-0 passion-border font-manrope-light"
                              id="exampleFormControlTextarea1" onChange={handleChange}
                              rows="15" value={state.vision}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h5 className="project-location mt-5 font-manrope-bold">
                      Who will benefit most from this project?
                    </h5>
                    <div className="card bg-signup-2 drop-shadow">
                      <div className="col-md-12">
                        <h6 className="mb-0 location-font mt-2 font-manrope-semibold color-black">
                          Type Description
                        </h6>
                        <div className="row">
                          <div className="form-group w-100">
                            <textarea
                              name="benefit"
                              className="form-control rounded-0 passion-border font-manrope-light"
                              id="exampleFormControlTextarea1" onChange={handleChange}
                              rows="15" value={state.benefit}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>                    
                  </div>
                </div>
                <SignProjectOverview/>
              </div>
              <div className="row">
                <div className="col-md-12 mobile-center">
                  <button
                    // onClick={() => navigate("/talent")}
                    onClick={(e) => CheckVision(e)}
                    type="button"
                    className="float-right shadow overviwe-next mt-51 mb-3 font-manrope-regular fs-16 border-none bckg-color"
                  >
                  Next
                  </button>
                </div>
              </div>
            </div>

            <div
              className={
                active == 6 ? "container talent" : "container talent d-none"
              }
            >
              <div className="row">
                <div className="w-75 mt-5 pl-5 pr-5 pb-5 drop-shadow-4 project-overview-form">
                  <h2 className="text-center mt-4 mobile-font font-manrope-bold">
                    Skills
                  </h2>
                  <div className="">
                    <h6 className="project-location mt-5 font-manrope-bold">
                      Are you needing people with certain skills or talents to
                      work on this project?
                    </h6>
                    <div className="col-md-12">
                      <div className="row mt-4">
                        <h6 className="text-dark mt-3 font-manrope-bold">
                          No
                        </h6>
                        <label className="switch ml-3 mt-3">
                          <input
                            type="checkbox"
                            onChange={(e) => toggleNeedSkills(e.target.checked)}
                          />
                          <span className="slider slider-color round"></span>
                        </label>
                        {NeedSkills ? (
                          <>
                            <h6 className="ml-3 text-dark mt-3 font-manrope-bold">
                              Yes
                            </h6>
                            <div className="card-talent ml-3 drop-shadow">
                              <p className="talent-font font-manrope-semibold color-black">
                                How Many..?
                              </p>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <input
                                    type="text" id="how-many-val"
                                    className="form-control talent-input fs-16 font-manrope-light"
                                    onChange={(e)=>SetHowmany(e.target.value)}
                                    value={Howmany}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <h6 className="ml-3 text-dark mt-3 font-manrope-bold">
                              Yes
                            </h6>
                            <div className="card-talent ml-3 drop-shadow disable">
                              <p className="talent-font font-manrope-semibold color-black">
                                How Many?
                              </p>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <input
                                    type="text" id="how-many-val"
                                    className="form-control talent-input disable" disabled
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="row mt-5">
                        <h5 className="font-manrope-bold">
                          What kinds of help will your project need?
                        </h5>
                      </div>
                      { <RenderHelpOptions/> }
                      {/* {ProjectHelpId.includes(8) ? (
                        <div className="col-md-12 gagan mobile-signup w-50">
                          <div className="card talent-card-height drop-shadow">
                            <div className="col-md-12">
                              <p className="font-skill mt-2 font-manrope-semibold">
                                Type Project Need
                              </p>
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="OtherSkills"
                                  className="form-control talent-background-input font-manrope-light"
                                  placeholder={"Press Enter to Add"}
                                  value={OtherSkills}
                                  onChange={(e)=>SetOtherSkills(e.target.value)}
                                  onKeyDown={(e)=>HandleKeyDown(e)}
                                />
                              </div>
                            </div>
                          </div>
                          <ul className="Other_skills_list font-manrope-medium padding-0">
                            {skillList.map((item,index) => (
                              <li id={index} key={index}>{item}<span className="remove_Skill pointer" title="Remove" onClick={()=>removeSkill(item.OtherSkills)}><img width="10" src={crossX} /></span></li>
                            ))}
                          </ul>
                        </div>
                      ) : (
                        <div className="col-md-12 hide mobile-signup w-50">
                          <div className="card sign-3-grey talent-card-height drop-shadow disable">
                            <div className="col-md-12">
                              <p className="font-skill mt-2 font-manrope-semibold">
                                Type Project Need
                              </p>
                              <div className="form-group">
                                <input
                                  type="text"
                                  className="form-control talent-background-input disable font-manrope-light"
                                  readOnly={true}
                                  value={''}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )} */}
                    </div>
                  </div>
                </div>
                <SignProjectOverview/>
              </div>
              <div className="row">
                <div className="col-md-12 mobile-center">
                    <button
                      onClick={(e) => CheckTalent(e)}
                      // onClick={() => navigate("/comphensation")}
                      type="button"
                      className="float-right shadow overviwe-next mt-51 font-manrope-regular fs-16 border-none bckg-color"
                    >
                      Next
                    </button>
                </div>
              </div>
            </div>

            <div
              className={
                active == 7
                  ? "container compensation"
                  : "container compensation d-none"
              }
            >
              <div className="row">
                <div className="w-75 mt-5 pl-5 pr-5 pb-5 drop-shadow-4 project-overview-form">
                  <h2 className="text-center mobile-font font-manrope-bold mt-4">
                    Compensation
                  </h2>
                  <div className="">
                    <h5 className="project-location mt-5 w-75 font-manrope-bold">
                      Are you looking to compensate your project contributors?
                    </h5>
                    <div className="col-md-12">
                      <div className="row mt-4">
                        <h6 className="text-dark mt-3 font-manrope-bold">
                          No
                        </h6>
                        <label className="switch ml-3 mt-3">
                          <input type="checkbox" 
                          onClick={(e)=>SetCertainSkills(e.target.checked)}
                          />
                          <span className="slider slider-color round"></span>
                        </label>
                        <h6 className="ml-3 text-dark mt-3 font-manrope-bold">
                          Yes
                        </h6>
                      </div>
                      {CertainSkills?
                      <>
                      <div className="row mt-5">
                      <h5 className="font-size-comps mt-2 font-manrope-bold">
                        How much per contributor?
                      </h5>
                    </div>
                    <div className="row">
                      <div className="card-compensation drop-shadow">
                        <p className="usd-compe ml-2 mt-1 font-manrope-semibold color-black talent-font">
                          {"(USD)"}$
                        </p>
                        <div className="form-group">
                          <input
                            type="number"
                            className="form-control compensation-input font-manrope-light" onChange={(e) => setResourceContribution(e.target.value)} name="resourceContribution" value={resourceContribution}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="row mt-5">
                      <h5 className="font-size-comps mt-2 font-manrope-extra-bold">
                        Project Organizer
                      </h5>
                    </div>
                    <div className="row mt-2">
                      <h6 className="font-size-comp font-manrope-extra-bold">
                        Self-Funded
                      </h6>
                      <label className="switch ml-2">
                        <input type="checkbox" 
                        onClick={(e)=> e.target.checked ? setFunding(FUNDING_PROJECT_ORGANIZER_DONATION) : setFunding(FUNDING_PROJECT_ORGANIZER_SELF)}
                        />
                        <span className="slider round"></span>
                      </label>
                      <h6 className="font-size-comp  ml-2 donate-width font-manrope-extra-bold">
                        Seek Donations through the Marketplace
                      </h6>
                    </div> */}
                    </>:
                      
                    <>
                      <div className="row mt-5">
                        <h5 className="font-size-comps mt-2 font-manrope-bold">
                          How much per contributor?
                        </h5>
                      </div>
                      <div className="row">
                        <div className="card-compensation disable">
                          <p className="usd-compe ml-2 mt-1 font-manrope-semibold color-black talent-font">
                            {"(USD)"}$
                          </p>
                          <div className="form-group">
                            <input disabled
                              type="text"
                              className="form-control compensation-input font-manrope-light"
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="row mt-5">
                        <h5 className="font-size-comps mt-2 font-manrope-extra-bold">
                          Project Organizer
                        </h5>
                      </div>
                      <div className="row mt-2">
                        <h6 className="font-size-comp font-manrope-extra-bold">
                          Self-Funded
                        </h6>
                        <label className="switch ml-2">
                          <input type="checkbox" />
                          <span className="slider disable round"></span>
                        </label>
                        <h6 className="font-size-comp  ml-2 donate-width font-manrope-extra-bold">
                          Seek Donations through the Marketplace
                        </h6>
                      </div> */}
                      
                    </>
                  }

                    </div>

                  </div>
                </div>
                <SignProjectOverview/>
              </div>
              <div className="row">
                <div className="col-md-12 mobile-center">
                    <button
                      onClick={(e) => CheckCompensation(e)}
                      // onClick={() => navigate("/equipment")}
                      type="button"
                      className="float-right shadow overviwe-next mt-51 font-manrope-regular fs-16 border-none bckg-color"
                    >
                      Next
                    </button>
                </div>
              </div>
            </div>

            <div className={active == 8 ? "container equipment" : "container equipment d-none"}>
              <div className="row">
                <div className="w-75 mt-5 pl-5 pr-5 pb-5 drop-shadow-4 project-overview-form">
                  <h2 className="text-center mt-4 mobile-font mt-3 font-manrope-bold">
                    Equipment & Supplies
                  </h2>
                  <div className="">
                    <h5 className="project-location mt-5 font-manrope-bold">
                      Are you needing any services, equipment, and/or supplies for
                      the project?
                    </h5>
                    <div className="col-md-12">
                      <div className="row mt-3">
                        <h6 className="text-dark font-manrope-bold">No</h6>
                        <label className="switch ml-3">
                          <input type="checkbox"
                            onClick={(e) => SetNeedServices(e.target.checked)}
                          />
                          <span className="slider slider-color round"></span>
                        </label>
                        <h6 className="ml-3 text-dark font-manrope-bold">Yes</h6>
                      </div>
                    </div>
                    <h5 className="project-location mt-5 font-manrope-bold">
                      Do you need supplies?
                    </h5>
                    <div className="col-md-12">
                      <div className="row mt-3">
                        <h6 className="text-dark font-manrope-bold">No</h6>
                        <label className="switch ml-3">
                          <input type="checkbox"
                            onClick={(e) => SetNeedSupplies(e.target.checked)}
                          />
                          <span className="slider slider-color round"></span>
                        </label>
                        <h6 className="ml-3 text-dark font-manrope-bold">Yes</h6>
                      </div>

                      <p className={"equip-font mt-2 font-manrope-bold" + (NeedSupplies ? ' color-black' : '')} data-toggle="modal"
                        data-target="#suppliesModal">Add Supplies</p>

                      <div className="modal fade" id="suppliesModal" tabIndex="-1" role="dialog"
                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-lg" role="document">
                          <div className="modal-content">
                            <div className="modal-body">
                              <div className="row">

                                <div className="col-md-2">
                                  <button type="button" className="close mr-5" data-dismiss="modal"
                                    aria-label="Close">
                                    <span aria-hidden="true"><img src={crossX} /></span>
                                  </button>
                                </div>

                                <div className="col-md-8">
                                  <h3 className="text-center font-manrope-bold">Choose Supplies</h3>
                                </div>

                              </div>

                              <div className="container">
                                <div className="col-md-12 mt-5">
                                  <div className="row">
                                    <h5 className="containersr font-manrope-bold">
                                      What Supplies will your project need?
                                    </h5>
                                  </div>
                                  {<RenderSuppliesOptions />}
                                  <div className="col-md-12 mobile-signup w-85 m-auto">
                                    <div className={"card sign-3-grey margin-left-model drop-shadow" + (ProjectSuppliesId.includes(9) ? '' : ' disable hide')}>
                                      <div className="col-md-12">
                                        <p className="font-skill mt-2 font-manrope-semibold color-black">Type Supply</p>
                                        <div className="form-group">
                                          <input type="text" className={"form-control padding-0 font-manrope-light equipment-background-input" + (ProjectSuppliesId.includes(9) ? '' : ' disable')} disabled={!ProjectSuppliesId.includes(9)} value={ProjectSuppliesOther} onChange={(e) => setProjectSuppliesOther(e.target.value)} placeholder="Press Enter to Add" onKeyDown={(e) => handleSuppliesKeyDown(e)} />
                                        </div>
                                      </div>
                                      <br />
                                      <ul className="Other_skills_list padding-0">
                                        {ProjectSuppliesOtherList.map((item, index) => (
                                          <li id={index} className="font-manrope-medium fs-16" key={index}>{item}<span className="remove_equipment float-right pointer" onClick={() => removeOtherSupplies(item)}><img width="10" src={crossX} /></span></li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="col-md-12 mt-5">
                                    <div className="float-right">
                                      <button data-dismiss="modal" type="text" className="model-button-equipment mb-5 font-manrope-regular border-none bckg-color">ADD</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                  <h5 className="project-location mt-5 font-manrope-bold">
                    Do you need Equipment?
                  </h5>

                  <div className="col-md-12">
                    <div className="row mt-3">
                      <h6 className="text-dark font-manrope-bold">No</h6>
                      <label className="switch ml-3">
                        <input type="checkbox"
                          onClick={(e) => SetNeedEquipment(e.target.checked)}
                        />

                        <span className="slider slider-color round"></span>
                      </label>
                      <h6 className="ml-3 text-dark font-manrope-bold">Yes</h6>
                    </div>
                  </div>

                  <p data-toggle="modal" data-target="#equipmentModal" className={"equip-font project-location mt-2 font-manrope-bold" + (NeedEquipment ? ' color-black' : '')}>
                    Add Equipment
                  </p>

                  <div className="modal fade" id="equipmentModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                      <div className="modal-content">
                        <div className="modal-body">

                          <div className="row">
                            <div className="col-md-2">
                              <button type="button" className="close mr-5" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true"><img src={crossX} /></span>
                              </button>
                            </div>
                            <div className="col-md-8">
                              <h3 className="text-center font-manrope-bold">Choose Equipment</h3>
                            </div>
                          </div>

                          <div className="container">
                            <div className="col-md-12 mt-5">

                              <div className="row">
                                <h5 className="containersr font-manrope-bold">
                                  What Kinds of equipment will your project need?
                                </h5>
                              </div>
                              {<RenderEquipmentsOptions />}
                              <div className="col-md-12 mobile-signup w-85 m-auto">
                                <div className={"card drop-shadow sign-3-grey margin-left-model" + (ProjectEquipmentId.includes(8) ? '' : ' disable hide')}>
                                  <div className="col-md-12">
                                    <p className="font-skill mt-2 font-manrope-semibold color-black">Type Equipment</p>
                                    <div className="form-group">
                                      <input type="text" className={"form-control padding-0 equipment-background-input font-manrope-light " + (ProjectEquipmentId.includes(8) ? '' : 'disable')} disabled={!ProjectEquipmentId.includes(8)} value={ProjectEquipmentOther} onChange={(e) => setProjectEquipmentOther(e.target.value)} placeholder="Press Enter to Add" onKeyDown={(e) => handleEquipmentKeyDown(e)} />
                                    </div>
                                  </div>
                                  <br />
                                  <ul className="Other_skills_list padding-0">
                                    {ProjectEquipmentsOtherList.map((item, index) => (
                                      <li id={index} className="font-manrope-medium fs-16" key={index}>{item}<span className="remove_equipment float-right pointer" onClick={() => removeOtherEquipments(item)}><img width="10" src={crossX} /></span></li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                              <div className="col-md-12 mt-5">
                                <div className="float-right">
                                  <button data-dismiss="modal" type="text" className="model-button-equipment mb-5 font-manrope-regular border-none bckg-color">ADD</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>                 
                  </div>
                </div>
            
                <div className={active == 9 ? "container compensation funding": "container compensation funding d-none"}>

                    <div className="row">
                      <div className="w-75 mt-5 pl-5 pr-5 pb-5 drop-shadow-4 project-overview-form">
                        <h2 className="text-center mt-4 mobile-font mt-2 font-manrope-bold">
                          Funding
                        </h2>
                        <div className="">
                          <h5 className="project-location mt-5 w-75 font-manrope-bold">
                            Is funding needed for your project?
                          </h5>
                          <div className="col-md-12">
                            <div className="row mt-2">
                              <h6 className="text-dark mt-3 font-manrope-bold">No</h6>
                              <label className="switch ml-3 mt-3">
                                <input type="checkbox" checked onChange={e => console.log(e)} />
                                <span className="slider slider-color round"></span>
                              </label>
                              <h6 className="ml-3 text-dark mt-3 font-manrope-bold">Yes</h6>
                            </div>
                            <div className="row mt-5">
                              <h5 className="font-size-comps mt-2 font-manrope-bold">
                                What's the budget for the project?
                              </h5>
                            </div>
                            <div className="row">
                              <div className="card-compensation">
                                <p className="usd-compe ml-2 mt-1 font-manrope-semibold color-black">
                                  {"(USD)"}$
                                </p>
                                <div className="form-group">
                                  <input type="text" className="form-control compensation-input fs-16 font-manrope-light"/>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-5">
                              <h6 className="font-size-comps mt-2 font-manrope-bold">
                                How would you like to raise money?
                              </h6>
                            </div>
                            <div className="row mt-2">
                              <h6 className="font-size-comp font-manrope-bold">
                                Self-Funded
                              </h6>
                              <label className="switch ml-2">
                                <input type="checkbox"/>
                                <span className="slider round"></span>
                              </label>
                              <h6 className="font-size-comp  ml-2 donate-width font-manrope-bold">
                                Seek Donations through the Marketplace
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <SignProjectOverview/>
                    </div>
                    </div>
                  </div>


                  <div className="col-md-12 mobile-center">
                    <button
                      onClick={saveEquipmentSupplies}
                      type="button"
                      className="float-right shadow overviwe-next mt-51 font-manrope-regular fs-16 border-none bckg-color"
                    >
                      Next
                    </button>
                  </div>
                </div>

            <div className={active == 9 ? "container compensation funding" : "container compensation funding d-none"}>

              <div className="row">
                <div className="w-75 mt-5 pl-5 pr-5 pb-5 drop-shadow-4 project-overview-form">
                  <h2 className="text-center mt-4 mobile-font mt-2 font-manrope-bold">
                    Funding
                  </h2>
                  <div className="">
                    <h6 className="project-location mt-5 w-75 font-manrope-bold">
                      Is funding needed for your project?
                    </h6>
                    <div className="col-md-12">
                      <div className="row mt-2">
                        <h6 className="text-dark mt-3 font-manrope-bold">No</h6>
                        <label className="switch ml-3 mt-3">
                          <input type="checkbox" checked={fundingRequired == FUNDING_REQUIRED_TRUE} name="funding_required" value={fundingRequired} onChange={(e) => setFundingRequired(e.target.checked ? FUNDING_REQUIRED_TRUE : FUNDING_REQUIRED_FALSE)} />
                          <span className="slider slider-color round"></span>
                        </label>
                        <h6 className="ml-3 text-dark mt-3 font-manrope-bold">Yes</h6>
                      </div>
                      <div className="row mt-5">
                        <h5 className="font-size-comps mt-2 font-manrope-bold">
                          What's the budget for the project?
                        </h5>
                      </div>
                      <div className="row">
                        <div className={"card-compensation" + (fundingRequired == FUNDING_REQUIRED_TRUE ? '' : ' disable')}>
                          <p className="usd-compe ml-2 mt-1 font-manrope-semibold color-black">
                            {"(USD)"}$
                          </p>
                          <div className="form-group">
                            <input min="0"
                              type="number" name="budget" value={state.budget} onChange={handleChange}
                              className={"form-control font-manrope-light compensation-input" + (fundingRequired == FUNDING_REQUIRED_TRUE ? '' : ' disable')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mt-5">
                        <h5 className="font-size-comps mt-2 font-manrope-bold">
                          How would you like to raise money?
                        </h5>
                      </div>
                      <div className="row mt-2">
                        <h6 className="font-size-comp font-manrope-bold">
                          Self-Funded
                        </h6>
                        <label className="switch ml-2">
                          <input type="checkbox" />
                          <span className="slider round"></span>
                        </label>
                        <h6 className="font-size-comp  ml-2 donate-width font-manrope-bold">
                          Seek Donations through the Marketplace
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <SignProjectOverview/>
              </div>
              <div className="row">
                  <div className="col-md-12 mobile-center">
                    <button
                      type="button"
                      onClick={saveFunding}
                      className="float-right shadow overviwe-next mt-51 font-manrope-regular fs-16 border-none bckg-color"
                    >
                      Next
                    </button>
                  </div>                
              </div>
            </div>

            <div className={active == 10 ? "container passion awareness" : "container passion awareness d-none"}>

              <div className="row">
                <div className="w-75 mt-5 pl-5 pr-5 pb-5 drop-shadow-4 project-overview-form">
                  <h2 className="text-center mt-4 w-46 mobile-font font-manrope-bold">
                    Results
                  </h2>
                  {/* <div className="">
                    <h5 className="project-location mt-5 font-manrope-bold">
                      Describe any community support, outreach, or awareness that
                      will be needed to carry out your project.
                    </h5>
                    <div className="card bg-signup-2 mt-3">
                      <div className="col-md-12">
                        <h6 className="mb-0 location-font mt-2 color-black font-manrope-semibold">
                          Type Description
                        </h6>
                        <div className="row">
                          <div className="form-group w-100">
                            <textarea name="awareness" value={state.awareness} onChange={handleChange}
                              className="form-control rounded-0 passion-border font-manrope-light"
                              id="exampleFormControlTextarea1"
                              rows="8"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="">
                    <h5 className="project-location mt-4 font-manrope-bold">
                      Describe any government approvals, permits, or fees that apply
                      to your project.
                    </h5>
                    <div className="card bg-signup-2 mt-3">
                      <div className="col-md-12">
                        <h6 className="mb-0 color-black location-font mt-2 font-manrope-semibold">
                          Type Description
                        </h6>
                        <div className="row">
                          <div className="form-group w-100">
                            <textarea name="government_approvals" value={state.government_approvals} onChange={handleChange}
                              className="form-control rounded-0 passion-border font-manrope-light"
                              id="exampleFormControlTextarea1"
                              rows="8"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="">
                    <h5 className="project-location mt-5 font-manrope-bold">
                      How do you plan on showing results?
                    </h5>
                    <div className="card bg-signup-2 mt-3">
                      <div className="col-md-12">
                        <h6 className="mb-0 location-font mt-2 color-black font-manrope-semibold">
                          Type Description
                        </h6>
                        <div className="row">
                          <div className="form-group w-100">
                            <textarea name="plan_results" value={state.plan_results} onChange={handleChange}
                              className="form-control rounded-0 passion-border font-manrope-light"
                              id="exampleFormControlTextarea1"
                              rows="8"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <h5 className="project-location mt-5 font-manrope-bold">
                    Describe any data or information you will be collecting to help measure your project’s results
                    </h5>
                    <div className="card bg-signup-2 mt-3">
                      <div className="col-md-12">
                        <h6 className="mb-0 location-font mt-2 color-black font-manrope-semibold">
                          Type Description
                        </h6>
                        <div className="row">
                          <div className="form-group w-100">
                            <textarea name="measure_results" value={state.measure_results} onChange={handleChange}
                              className="form-control rounded-0 passion-border font-manrope-light"
                              id="exampleFormControlTextarea1"
                              rows="8"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <SignProjectOverview/>
              </div>
              <div className="row">
                <div className="col-md-12 mobile-center">
                  <button
                    onClick={saveAwareness}
                    type="button"
                    className="float-right shadow overviwe-next mt-51 font-manrope-regular fs-16 border-none bckg-color"
                  >
                    Next
                  </button>
                </div>                
              </div>
            </div>                    


                    <div className={active == 11 ? "container equipment considerations": "container equipment considerations d-none"}>

          <div className="row">
            <div className="w-75 mt-5 pl-5 pr-5 pb-5 drop-shadow-4 project-overview-form">
              <h2 className="text-center mt-4 mobile-font mt-3 font-manrope-bold">
                Considerations
              </h2>
              <div className="">
                <h5 className="project-location mt-5 font-manrope-bold">
                  What languages will need to be spoken, read, or understood?
                </h5>   
                <Select placeholder="Press Enter to Add" className="w-50 drop-shadow" options={languageOptions} isMulti onChange={handleLanguageChange} />
                <h5 className="project-location mt-5 font-manrope-bold">
                  Is this project wheelchair accessible?
                </h5>
                <div className="col-md-12">
                  <div className="row mt-3">
                    <h6 className="text-dark font-manrope-bold">No</h6>
                    <label className="switch ml-3">
                      <input type="checkbox" checked={eventWheelChairAccessible == EVENT_WHEELCHAIR_ACCESSIBLE_TRUE} onChange={(e) => setEventWheelChairAccessible(e.target.checked ? EVENT_WHEELCHAIR_ACCESSIBLE_TRUE : EVENT_WHEELCHAIR_ACCESSIBLE_FALSE) } />
                      <span className="slider slider-color round"></span>
                    </label>
                    <h6 className="ml-3 text-dark font-manrope-bold">Yes</h6>
                  </div>
                </div>
                <h5 className="project-location mt-3 font-manrope-bold">
                  Is this project open to accommodating other access needs, including for the visually or hearing-impaired?
                </h5>
                <div className="col-md-12">
                  <div className="row mt-3">
                    <h6 className="text-dark font-manrope-bold">No</h6>
                    <label className="switch ml-3">
                      <input type="checkbox" checked={eventAnimalAllergies == EVENT_ANIMAL_ALLERGIES_TRUE} onChange={(e) => setEventAnimalAllergies(e.target.checked ? EVENT_ANIMAL_ALLERGIES_TRUE : EVENT_ANIMAL_ALLERGIES_FALSE)} />
                      <span className="slider slider-color round"></span>
                    </label>
                    <h6 className="ml-3 text-dark font-manrope-bold">Yes</h6>
                  </div>
                </div>
                <h5 className="project-location mt-3 font-manrope-bold">
                  Could this project trigger allergies or potential health-related issues?
                </h5>
                <div className="col-md-12">
                  <div className="row mt-3">
                    <h6 className="text-dark font-manrope-bold">No</h6>
                    <label className="switch ml-3">
                      <input type="checkbox" checked={eventHayFever == EVENT_HAY_FEVER_TRUE} onChange={(e) => setEventHayFever(e.target.checked ? EVENT_HAY_FEVER_TRUE : EVENT_HAY_FEVER_FALSE)} />
                      <span className="slider slider-color round"></span>
                    </label>
                    <h6 className="ml-3 text-dark font-manrope-bold">Yes</h6>
                  </div>
                </div>
              </div>
            </div>
            <SignProjectOverview/>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="col-md-12 mobile-center">
                <button onClick={saveConsiderations} type="button" className="drop-shadow float-right mr-4 Skip-payment font-manrope-regular">
                  Start Project
                </button>
              </div>
            </div>
            {/* <div className="col-md-12 mt-4">
              <div className="col-md-12 mobile-center">
                <a href="#">
                  <button type="button" className="float-right continue-payent font-manrope-regular shadow border-none bckg-color" data-toggle="modal" data-target="#stripeModal">
                    Connect/Verify Payment
                  </button>
                </a>
              </div>
            </div> */}
          </div>
                    </div>
  


              </div>
            </div>

      </div>

    <Modal size="lg" show={modalShow} onHide={hanldeModalClose}>
      <Modal.Header closeButton>
        <Modal.Title className="font-manrope-bold">Invite Organizers</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <h6 className="font-manrope-extra-bold"><strong>Search</strong></h6>
        <div className="padding-0 col-md-8"><input type="text" value={searchOrgName} className="form-control search-organizers border-none fs-14 font-manrope-light drop-shadow" placeholder="Find an Organizer to Invite" onChange={searchOrganizers} /></div> 
        <div className={"listing" + (modalOrgLoader ? " loader" : '')} onScroll={handleOrgListingScroll}>
          {<RenderOrganizerListing/>}
        </div>
        <br></br>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={hanldeModalClose} variant="secondary">Close</Button>
      </Modal.Footer>
    </Modal>

      <Modal show={selectGroupModal}>
        
        <Modal.Header>
          <section className="text-center w-100">
            <h3 className="font-manrope-bold ">Select Group</h3>
            <h4 className="font-manrope-bold ">to create this project as</h4>
          </section>
          <Modal.Title>
          <img onClick={e => setSelectGroupModal(false)} className="pointer" src={crossX}/>
          </Modal.Title>
        </Modal.Header>

          <Modal.Body>
            <div className="w-100 fl user-groups-listing">
              {UserGroupListOptions}
            </div>
          </Modal.Body>

          <Modal.Footer className="text-center">
            <div className="w-100"><button onClick={() => setGroupSelection()} type='button' className="drop-shadow select-group font-manrope-medium mt-2 mb-2 px-3 py-2 bckg-color border-none fs-14">Select Group</button></div>
          </Modal.Footer>

      </Modal>

    </>
  );
};

export default Project_Overview;

import { put, call, takeLatest } from "redux-saga/effects";
import { SKILL_REQUEST } from "../actionTypes/type";
import { actionSuccess, actionError } from "../actions/skillAction";
import { getApiCall } from "../../service/GetApiCall";
import { SKILLS_URL } from "../../service/apiUrl";

export function* skillSaga(action) {
  try {
    const response = yield call(getApiCall, SKILLS_URL, action.params);
    yield put(actionSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionError(error));
    action.onError();
  }
}

export function* watchSkillSaga() {
  yield takeLatest(SKILL_REQUEST, skillSaga);
}

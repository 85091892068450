import React from "react";
import { Provider, useSelector } from "react-redux";
import { store, persistor } from "../redux/store/store";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

import Register from "../views/Register";
import Splash from "../views/Splash";
import LogIn from "../views/LogIn";
import AdminLogIn from "../views/AdminLogin";
import Home from "../views/Home";
// import Profile from "../views/Profile";
import Profile2 from "../views/Profile2";
import Group from "../views/Group";
// import Contributor from "../views/Contributor"
// import ContributorInfo from "../views/ContributorInfo";
// import Messages from "../views/Messages";
// import CreateProject from "../views/CreateProject";
// import ProjectOverView from "../views/ProjectOverView";
import Project_Overview from "../views/Project-OverView";
// import Project_edit from "../views/Project-edit";
import Project from "../views/Project";
import Messageindividual from "../views/message-individual";
import Messagegroup from "../views/message-group";
import { PersistGate } from "redux-persist/integration/react";
// import Location from "../views/Location";
// import Organization from "../views/Organization";
// import Vision from "../views/Vision";
// import Passion from "../views/Passion";
// import Talent from "../views/Talent";
// import Equipment from "../views/Equipment";
// import Comphensation from "../views/Comphensation";
// import Funding from "../views/Funding";
// import Awareness from "../views/Awarness";
// import Considerations from "../views/Considerations";
import Thankyou from "../components/Thankyou";
import ProjectContribution from "../views/ProjectContribution";
import Projects from "../views/Projects";
import Service from "../views/Service";
import Contributors from "../views/Contributors";
import ForgotPassword from "../views/ForgotPassword";
import ChangePassword from "../views/ChangePassword";
import CopyRightPolicy from "../views/CopyrightPolicy";
import PrivacyPolicy from "../views/PrivacyPolicy";
import TermsService from "../views/Terms&Service";
import ServiceCreate from "../views/ServiceCreate";
import ServiceContributor from "../views/ServiceContributor";

import Admin from "../views/Admin";

import Blank from "../views/Blank";

const RequireAuth = ({children}) => {
  
  //Authenticate if user is logged in
  const UserData = useSelector(
    (state: RootStateOrAny) => state.loginReducer.data
  );
  const userIsLogged = !Boolean(UserData == null);
  
  return userIsLogged ? children : <Navigate to="/login" replace={true} />;

}


function Navigator() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Routes>
            <Route path="/" element={<Splash />} />
            <Route path="/login" element={<LogIn />} />
            <Route path="/admin" element={<Admin/>} />
            <Route path="/admin/login" element={<AdminLogIn />} />
            <Route path="/register" element={<Register />} />
            {/* <Route path="/projectedit" element={<Project_edit/>}/> */}
            <Route path="/project/:id" element={<RequireAuth><Project/></RequireAuth>}/>
            <Route path="/project/:id/contribution" element={<RequireAuth><ProjectContribution/></RequireAuth>}/>
            {/* <Route path="/Contributor" element={<RequireAuth><Contributor /></RequireAuth>} /> */}
            <Route path="/home" element={<Home />} />
            <Route path="/profile" element={<RequireAuth><Profile2 /></RequireAuth>} />
            {/* <Route path="/profile2" element={<Profile />} /> */}
            <Route path="/profile/:uid" element={<RequireAuth><Profile2 /></RequireAuth>} />
            <Route path="/group/:id" element={<RequireAuth><Group /></RequireAuth>} />
            <Route path="/messageindividual" element={<RequireAuth><Messageindividual/></RequireAuth>} />
            <Route path="/messagegroup" element={<RequireAuth><Messagegroup/></RequireAuth>} />
            {/* <Route path="/contributorinfo" element={<ContributorInfo />} /> */}
            {/* <Route path="/messages" element={<Messages />} /> */}
            {/* <Route path="/createproject" element={<CreateProject />} /> */}
            {/* <Route path="/projectoverView" element={<ProjectOverView />} /> */}
            <Route path="/project_OverView" element={<RequireAuth><Project_Overview /></RequireAuth>} />
            {/* <Route path="/location" element={<Location />} /> */}
            {/* <Route path="/organization" element={<Organization />} /> */}
            {/* <Route path="/passion" element={<Passion />} /> */}
            {/* <Route path="/vision" element={<Vision />} /> */}
            {/* <Route path="/talent" element={<Talent />} /> */}
            {/* <Route path="/comphensation" element={<Comphensation />} /> */}
            {/* <Route path="/equipment" element={<Equipment />} />
            <Route path="/funding" element={<Funding />} />
            <Route path="/awareness" element={<Awareness />} />
            <Route path="/considerations" element={<Considerations />} /> */}
            <Route path="/projects" element={<Projects/>} />
            <Route path="/contributors" element={<Contributors/>} />
            <Route path="/thankyou" element={<Thankyou message='You have successfully made a Social Action Project.' />} />
            <Route path="/thankyou/service-offer" element={<Thankyou message='You have successfully offered a service to the Airatae Community!' />} />
            <Route path="/thankyou/service-create" element={<Thankyou message='You have successfully made a Social Action Service.' />} />
            <Route path="/forgot-password" element={<ForgotPassword/>} />
            <Route path="/change-password" element={<ChangePassword/>} />
            <Route path="/copyright-policy" element={<CopyRightPolicy/>} />
            <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
            <Route path="/terms-service"element={<TermsService/>} />
            <Route path="/service-create"element={<RequireAuth><ServiceCreate/></RequireAuth>} />
            <Route path="/service/:id" element={<RequireAuth><Service/></RequireAuth>} />
            <Route path="/service-contributor" element={<RequireAuth><ServiceContributor/></RequireAuth>} />
            <Route path="/blank" element={<Blank/>} />
          </Routes>
        </Router>
      </PersistGate>
    </Provider>
  );
}
export default Navigator;

import { put, call, takeLatest } from "redux-saga/effects";
import { COUNTRIES_REQUEST } from "../actionTypes/type";
import { actionSuccess, actionError } from "../actions/countriesAction";
import { getApiCall } from "../../service/GetApiCall";
import { COUNTRIES_URL } from "../../service/apiUrl";

export function* countriesSaga(action) {
  try {
    const response = yield call(getApiCall, COUNTRIES_URL, action.params);
    yield put(actionSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionError(error));
    action.onError();
  }
}

export function* watchCountriesSaga() {
  yield takeLatest(COUNTRIES_REQUEST, countriesSaga);
}

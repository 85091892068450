import React from 'react';
import {NotificationManager} from 'react-notifications';

class showToastNotification extends React.Component {
  
  createNotification = (type, message, time = 0) => {

      switch (type) {
        case 'info':
          NotificationManager.info(message, "", time);
          break;
        case 'success':
          NotificationManager.success(message, "", time);
          break;
        case 'warning':
          NotificationManager.warning(message, "", time);
          break;
        case 'error':
          NotificationManager.error(message, "", time);
          break;
        default:
          NotificationManager.error(
               "Some error occured, please check your intenet connection or try again later!",
               "",
               time
          );
          break;
      }
  
  };
  
}

export default showToastNotification;
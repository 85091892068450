import {
    VALIDATE_FORGOT_PASSWORD_REQUEST,
    VALIDATE_FORGOT_PASSWORD_REQUEST_ERROR,
    VALIDATE_FORGOT_PASSWORD_REQUEST_SUCCESS,
  } from "../actionTypes/type";
  const initialState = {
    data: null
  };
  
  const validateForgotPasswordTokenReducer = (state = initialState, action) => {
    let object;
  
    switch (action.type) {
      case VALIDATE_FORGOT_PASSWORD_REQUEST:
        object = {
          ...state
        };
        break;
  
      case VALIDATE_FORGOT_PASSWORD_REQUEST_ERROR:
        object = {
          ...state,
        };
        break;
  
      case VALIDATE_FORGOT_PASSWORD_REQUEST_SUCCESS:
        object = {
          ...state,
          // data: action.payload,
        };
        break;
  
      default:
        object = state;
        break;
    }
    return object;
  };
  
  export default validateForgotPasswordTokenReducer;
  
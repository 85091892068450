import { put, call, takeLatest } from "redux-saga/effects";
import { ADMIN_DISREGARD_USER_REPORT_REQUEST } from "../actionTypes/type";
import { actionSuccess, actionError } from "../actions/adminDisregardUserReportAction";
import { postApiCall } from "../../service/PostApiCall";
import { ADMIN_DISREGARD_USER_REPORT_URL } from "../../service/apiUrl";

export function* adminDisregardUserReportSaga(action) {
  try {
    const response = yield call(postApiCall, action.params, ADMIN_DISREGARD_USER_REPORT_URL);
    yield put(actionSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionError(error));
    action.onError();
  }
}

export function* watchAdminDisregardUserReportSaga() {
  yield takeLatest(ADMIN_DISREGARD_USER_REPORT_REQUEST, adminDisregardUserReportSaga);
}

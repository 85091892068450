import { put, call, takeLatest } from "redux-saga/effects";
import { GROUP_CREATE_REQUEST } from "../actionTypes/type";
import { actionSuccess, actionError } from "../actions/groupCreateAction";
import { postApiCall } from "../../service/PostApiCall";
import { GROUP_CREATE_URL } from "../../service/apiUrl";

export function* groupCreateSaga(action) {
  try {
    const response = yield call(postApiCall, action.params, GROUP_CREATE_URL);
    yield put(actionSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionError(error));
    action.onError(error);
  }
}

export function* watchGroupCreateSaga() {
  yield takeLatest(GROUP_CREATE_REQUEST, groupCreateSaga);
}

import { put, call, takeLatest } from "redux-saga/effects";
import { PROJECT_LISTING_REQUEST } from "../actionTypes/type";
import { actionSuccess, actionError } from "../actions/projectListingAction";
import { postApiCall } from "../../service/PostApiCall";
import { PROJECT_LISTING_URL } from "../../service/apiUrl";

export function* projectListingSaga(action) {
  try {
    const response = yield call(postApiCall, action.params, PROJECT_LISTING_URL);
    yield put(actionSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionError(error));
    action.onError();
  }
}

export function* watchProjectListingSaga() {
  yield takeLatest(PROJECT_LISTING_REQUEST, projectListingSaga);
}

import {
  ADMIN_PROJECTS_REQUEST,
  ADMIN_PROJECTS_REQUEST_SUCCESS,
  ADMIN_PROJECTS_REQUEST_ERROR,
  ADMIN_PROJECT_VIEW_REQUEST,
  ADMIN_PROJECT_VIEW_REQUEST_SUCCESS,
  ADMIN_PROJECT_VIEW_REQUEST_ERROR,
  ADMIN_PROJECT_DELETE_REQUEST,
} from "../actionTypes/type";

export const adminProjectListRequest = (paramsData, onSuccess, onError) => {
  return {
    type: ADMIN_PROJECTS_REQUEST,
    params: paramsData,
    onSuccess,
    onError
  };
};

export const actionSuccess = (result) => {
  return {
    type: ADMIN_PROJECTS_REQUEST_SUCCESS,
    payload: result,
    error: null,
  };
};

export const actionError = (error) => {
  return {
    type: ADMIN_PROJECTS_REQUEST_ERROR,
    error: error,
    payload: null,
  };
};

export const adminProjectViewRequest = (paramsData, onSuccess, onError) => {
  return {
    type: ADMIN_PROJECT_VIEW_REQUEST,
    params: paramsData,
    onSuccess,
    onError
  };
};

export const actionProjectViewSuccess = (result) => {
  return {
    type: ADMIN_PROJECT_VIEW_REQUEST_SUCCESS,
    payload: result,
    error: null,
  };
};

export const actionProjectViewError = (error) => {
  return {
    type: ADMIN_PROJECT_VIEW_REQUEST_ERROR,
    error: error,
    payload: null,
  };
};

export const adminProjectDeleteRequest = (paramsData, onSuccess, onError) => {
  return {
    type: ADMIN_PROJECT_DELETE_REQUEST,
    params: paramsData,
    onSuccess,
    onError
  };
};



import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { logo2 } from "../assets/images";
import Utility from "../utility/utility";
import APP_STRING from "../constants/strings";
import { useDispatch } from "react-redux";
import { buttonLoader } from "../assets/images";
import { forgotPasswordRequest } from "../redux/actions/forgotPasswordAction";

const ForgotPassword = () => { 

    const dispatch = useDispatch();

    const [email, setEmail] = useState("");
    const [isLoading, setLoading] = useState(false);

    const validateFields = () => {
        let message = "";
        if (Utility.isEmpty(email)) {
          message = APP_STRING.ENTER_EMAIL;
        } else if (!Utility.validateEmail(email)) {
          message = APP_STRING.ENTER_VALID_EMAIL;
        }
        if (message === "") {
          return true;
        }
        Utility.showToast("error", message);
        return false;
    }

    async function submitForm(e) {
        e.preventDefault();
        if (validateFields()) 
            dispatch(forgotPasswordRequest({email: email}, onSuccess, onError));
        else
            setLoading(false);
    }

    const onSuccess = async(resp) => {
        console.log("resp: ", resp);
        setLoading(false);
    }

    const onError = async(err) => {
        console.log("error: ", err);
        setLoading(false);
    }

    const sendForgotPasswordConfirmation = e => {
        e.preventDefault();
        setLoading(true);
        submitForm(e);
    }

    return (<>

        <div className="col-md-12 flex-row mt-5">
            <div className="row">
                <i className="fa fa-caret-left font-size-login mr-2 ml-2" aria-hidden="true"></i>
                <h6 className="text-left mt-1">
                    <Link className="VisiaPro-Bold" to="/register">Back to Sign Up</Link>
                </h6>
            </div>
        </div>

        <div className="container signup">

            <div>
                <h1 className="text-center font-size-signup VisiaPro-ExtraBold"><img src={logo2} width="250" alt="logo" /></h1>
                <h1 className="text-center signup-font VisiaPro-ExtraBold margin-login">Forgot Password</h1>
            </div>

            <div className="col-12">

                <br/>
                <form onSubmit={sendForgotPasswordConfirmation}>
                    
                    <div className="form-group w-50-signup m-auto">
                        <label htmlFor="email"><h5 className="my-0 VisiaPro-ExtraBold">Email</h5></label>
                        <input id="email" type="email" className="form-control drop-shadow" value={email} onChange={(email) => setEmail(email.target.value)} placeholder="example@example.com"/>
                    </div>

                    <div className="col-md-12 mt-5">
                        <div className="text-center mt-3">
                            { isLoading ? <img src={buttonLoader} width="16" /> : '' }&nbsp;&nbsp;
                            <button disabled={isLoading} type="submit" className="btn btn-primary sf-pro forgot-pwd drop-shadow">Send Confirmation</button>
                        </div>
                    </div>

                </form>

            </div>

        </div>

    </>);

}

export default ForgotPassword;
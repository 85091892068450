import React from "react";
import { LeftBack } from "../assets/images";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { loginRequest } from "../redux/actions/loginAction";
import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Utility from "../utility/utility";
import APP_STRING from "../constants/strings";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { logo2, logoBlackV1, login2 } from "../assets/images";
import SignBlue from "../components/SignBlue";
import {LANDING_PAGE_URL} from "../service/apiUrl";

const LogIn = () => {

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const UserData = useSelector(
    (state: RootStateOrAny) => state.loginReducer.data
  );
  //const history = useHistory();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  //const [resetEmail, setResetEmail] = useState("");
  const [show, setShow] = useState(false);
  const [fildtype, setFildtype] = useState("password");
  const [isLoading, setLoading] = useState(false);
  const [passToogle, setPassToogle] = useState("fa fa-eye-slash p-1");

  async function loggingIn(e) {
    e.preventDefault();
    if (validateLoginFields()) {
      setLoading(true);
      var payload = {
        email: email,
        password: password
      }
      dispatch(loginRequest(payload, onSuccess, onError));
    }
  }

  async function onSuccess() {
    setLoading(false);
    navigate("/home");
  }

  async function onError(err) {
    //history.push("/overall-performance");
    console.log("error", err);
    setLoading(false);
  }

  const validateLoginFields = () => {
    var message = "";
    if (Utility.isEmpty(email)) {
      message = APP_STRING.ENTER_EMAIL;
    } else if (!Utility.validateEmail(email)) {
      message = APP_STRING.ENTER_VALID_EMAIL;
    } else if (Utility.isEmpty(password)) {
      message = APP_STRING.ENTER_PASSWORD;
    } else if (password.length < 6) {
      message = APP_STRING.ENTER_SIX_DIGIT_PASSWORD;
    }
    if (message === "") {
      return true;
    }
    Utility.showToast("error", message);
    return false;
  }

  // const validateResetPasswordFields = () => {
  //   var message = "";
  //   if (Utility.isEmpty(email)) {
  //     message = APP_STRING.ENTER_EMAIL;
  //   } else if (!Utility.validateEmail(email)) {
  //     message = APP_STRING.ENTER_VALID_EMAIL;
  //   }
  //   if (message === "") {
  //     return true;
  //   }
  //   Utility.showToast("error", message);
  //   return false;
  // }

  const PassViewToggle = () => {
    if (fildtype === "password") {
      setPassToogle("fa fa-eye p-1");
      setFildtype("text");
    } else {
      setPassToogle("fa fa-eye-slash p-1");
      setFildtype("password");
    }
  }
  
  // const handleClose = () => {
  //   setShow(false);
  // }

  useEffect(() => {
    Utility.isSignedIn(UserData).then((value) => {
      console.log("user data value", UserData, value);
      if (value) {
        navigate("/profile");
      }
    });
  }, []);

  // const onResetPasswordClick = () => {
  //   if (validateResetPasswordFields()) {
  //     handleClose();
  //   }
  // };

  const formSubmit = (event) => {
    loggingIn(event);
  };    

  return (

    <main className="sign-in-container bckg-gray">

        <div className="col-md-12 flex-row mt-5">
          <div className="row">
            <i className="fa fa-caret-left font-size-login mr-2 ml-2" aria-hidden="true"></i>
            <h6 className="text-left mt-1">
              <a className="font-manrope-extra-bold" rel="noopener" href={LANDING_PAGE_URL}>Back to Landing</a>
            </h6>
          </div>
        </div>

        <div className="form-group w-50-signup m-auto container signup">
          <div className="py-1"><img src={logoBlackV1} width={143} alt="black logo v1"/></div>
          <div>
            <form onSubmit={formSubmit}>

              <div className="layout px-5 py-3">

                <h1 className="fs-32 font-manrope-bold">Sign In</h1>

                <div className="col-12 mt-5 p-0 mobile-padding">
                  <label htmlFor="email"><h5 className="my-0 font-manrope-extra-bold">Email Or Username</h5></label>
                  <input id="email" type="email" className="form-control form-control-2 font-manrope-light" value={email} onChange={(email) => setEmail(email.target.value)} placeholder="example@example.com"/>          
                  <div className="text-right"><span className="fs-14 font-manrope-extra-bold">New User? &nbsp; &nbsp; <Link to="/register"><u>Sign Up Here</u></Link></span></div>
                </div>

                <div className="col-12 mt-3 p-0 mobile-padding">
                  <label htmlFor="password"><h5 className="my-0 font-manrope-extra-bold">Password</h5></label>
                  <input id="password" type={fildtype} className="form-control form-control-2 font-manrope-light" value={password} onChange={(password) => {setPassword(password.target.value)}} placeholder="**********"/>
                  <span className="password-eye pointer" onClick={() => PassViewToggle()}><FontAwesomeIcon icon={faEye} /></span>
                  <div className="text-right"><span className="fs-14 font-manrope-extra-bold"><Link to="/forgot-password"><u>Forgot Password</u></Link></span></div>
                </div>
                
                <div className="col-md-12 mt-5 p-0">
                    <div className="text-center mt-3">
                        <button type="submit" className="btn sf-pro signup-button"><img width={223} src={login2} alt="login" /></button>
                    </div>
                </div>

              </div>
              
            </form>           
               
          </div>
        </div>

        <SignBlue/>

    </main>

  );
};

export default LogIn;

import {
  ADMIN_REPORTED_LIST_REQUEST,
  ADMIN_REPORTED_LIST_REQUEST_SUCCESS,
  ADMIN_REPORTED_LIST_REQUEST_ERROR,

} from "../actionTypes/type";

const initialState = {
  data: null,
  isLoading: false,
};
const adminReportedListReducer = (state = initialState, action) => {
  let object;

  switch (action.type) {
    case ADMIN_REPORTED_LIST_REQUEST:
      object = {
        ...state,
        isLoading: true,
      };
      break;

    case ADMIN_REPORTED_LIST_REQUEST_ERROR:
      object = {
        ...state,
        isLoading: false,
      };
      break;

    case ADMIN_REPORTED_LIST_REQUEST_SUCCESS:
      object = {
        ...state,
        data: action.payload,
        isLoading: false,
      };
      break;

    default:
      object = state;
      break;
  }
  return object;
}



export default adminReportedListReducer;

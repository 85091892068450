import {
    SERVICE_GET_TYPES_FOCUS_REQUEST,
    SERVICE_GET_TYPES_FOCUS_REQUEST_SUCCESS,
    SERVICE_GET_TYPES_FOCUS_REQUEST_ERROR
  } from "../actionTypes/type";
  
  export const getTypesFocusRequest = (paramsData, onSuccess, onError) => {
    return {
      type: SERVICE_GET_TYPES_FOCUS_REQUEST,
      params: paramsData,
      onSuccess,
      onError
    }
  }
  
  export const actionSuccess = (result) => {
    return {
      type: SERVICE_GET_TYPES_FOCUS_REQUEST_SUCCESS,
      payload: result,
      error: null
    };
  }
  
  export const actionError = (error) => {
    return {
      type: SERVICE_GET_TYPES_FOCUS_REQUEST_ERROR,
      error: error,
      payload: null
    };
  }
import { put, call, takeLatest } from "redux-saga/effects";
import { SERVICE_GET_TYPES_FOCUS_REQUEST } from "../actionTypes/type";
import { actionSuccess, actionError } from "../actions/serviceGetTypesFocusAction";
import { getApiCall } from "../../service/GetApiCall";
import { SERVICE_GET_TYPES_FOCUS } from "../../service/apiUrl";

export function* serviceGetTypesFocusSaga(action) {
  try {
    const response = yield call(getApiCall, SERVICE_GET_TYPES_FOCUS, action.params);
    yield put(actionSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionError(error));
    action.onError();
  }
}

export function* watchServiceGetTypesFocusSaga() {
  yield takeLatest(SERVICE_GET_TYPES_FOCUS_REQUEST, serviceGetTypesFocusSaga);
}

import { put, call, takeLatest } from "redux-saga/effects";
import { PROJECT_CONTRIBUTION_SKILL_REQUEST } from "../actionTypes/type";
import { actionSuccess, actionError } from "../actions/projectContributionSkillAction";
import { postApiCall } from "../../service/PostApiCall";
import { PROJECT_CONTRIBUTION_SKILL_URL } from "../../service/apiUrl";

export function* projectContributionSkillSaga(action) {
  try {
    const response = yield call(postApiCall, action.params, PROJECT_CONTRIBUTION_SKILL_URL);
    yield put(actionSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionError(error));
    action.onError(error);
  }
}

export function* watchProjectContributionSkillSaga() {
  yield takeLatest(PROJECT_CONTRIBUTION_SKILL_REQUEST, projectContributionSkillSaga);
}

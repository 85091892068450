import React, { useState, useEffect } from "react";
import {Link, useNavigate, useLocation} from "react-router-dom";
import {cards, leftBack, buttonLoader, signBlue} from "../assets/images";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {getCountriesRequest} from "../redux/actions/countriesAction";
import {paymentRequest} from "../redux/actions/paymentAction";
import { stripeDetailsRequest } from "../redux/actions/stripeDetailsAction";
import { serviceInvitationSendRequest } from "../redux/actions/serviceInvitationSendAction";
import Utility from "../utility/utility";

const arrowLeft = <FontAwesomeIcon className="return-home" icon={faArrowLeft} />

const payLoad = {};

const ServiceContributor = props => {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeClass, setActiveClass] = useState(2);
  const [countriesList, setCountriesList] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [countryId, setCountryId] = useState(0);
  const [city, setCity] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [phone, setPhone] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardName, setCardName] = useState('');
  const [cardExpDate, setCardExpDate] = useState('');
  const [cardExpMonth, setCardExpMonth] = useState(null);
  const [cardExpYear, setCardExpYear] = useState(null);
  const [cardCvv, setCardCvv] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [serviceId, setServiceId] = useState(null);
  const [contributorAmt, setContributorAmt] = useState(null);
  const [contributeTypeAs, setContributeTypeAs] = useState(undefined);

  //Fetch project id and contributor amount from the '/project' page
  useEffect( () => {

    loadStripe('pk_test_51HKPzLEcxzPvmWXhRqfN1IYHvuGloBut1z7dqpdU3dHewNUqs76Tq1Nt8c8UIfaQMcIh2gW0WcbsTwtFP45AGRC300nbwrnpNR');

    if(location.state != null){
      
      setServiceId(location.state.service_id);
      setContributorAmt(location.state.contribution_amount);

    //   dispatch(stripeDetailsRequest({ 'service_id' : location.state.service_id, showSuccessToast: false }, async function(resp){
    //     if(resp != null){
    //       loadStripe(resp.p_key);
    //     }
    //   }, onError));

    }

  },[location.state] );

  const UserData = useSelector(
    (state: RootStateOrAny) => state.loginReducer.data
  );

  const validateContributorForm = (e) => {
    e.preventDefault();
    let formIsValid = true;

    if (firstName.trim() == "") {
      formIsValid = false;
      Utility.showToast('error', "Enter First name");
    }
    else if (lastName.trim() == "") {
      formIsValid = false;
      Utility.showToast('error', "Enter Last name");
    }
    else if (address.trim() == "") {
      formIsValid = false;
      Utility.showToast('error', "Enter address");
    }
    else if (isNaN(countryId) || countryId == 0) {
      formIsValid = false;
      Utility.showToast('error', "Select country id");
    }
    else if(city.trim() == ''){
      formIsValid = false;
      Utility.showToast('error', "Enter city");
    }
    else if(postalCode.trim() == ''){
      formIsValid = false;
      Utility.showToast('error', "Enter Postal code");      
    }
    else if(phone.trim() == ''){
      formIsValid = false;
      Utility.showToast('error', "Enter phone");      
    }

    if(formIsValid){
      payLoad.first_name = firstName.trim();
      payLoad.last_name = lastName.trim();
      payLoad.address = address.trim();
      payLoad.country = parseInt(countryId);
      payLoad.city = city.trim();
      payLoad.postal_code = postalCode.trim();
      payLoad.phone = phone.trim();
      console.log("payload", payLoad);
      setActiveClass(2);
    }

  }

  const pay = function(e){

    setButtonLoading(true);

    e.preventDefault();
    let formIsValid = true;

    if (cardNumber.trim() == "") {
      formIsValid = false;
      Utility.showToast('error', "Enter Card number");
    }
    else if (cardName.trim() == "") {
      formIsValid = false;
      Utility.showToast('error', "Enter Card name");
    }
    else if (cardExpDate.trim() == "") {
      formIsValid = false;
      Utility.showToast('error', "Enter Card expiration date");
    }
    else if (cardCvv.trim() == "") {
      formIsValid = false;
      Utility.showToast('error', "Enter Card CVV");
    }    

    //Split expiration date into month and year (by slash '/')
    const cardExpArr = cardExpDate.split('/');
    if(cardExpArr[1] == undefined){
      formIsValid = false;
      Utility.showToast('error', "Invalid Card expiration date");
    }

    if(formIsValid){

      setCardExpMonth(cardExpArr[0]);
      setCardExpYear(cardExpArr[1]);

      window.Stripe.card.createToken({
        number: cardNumber,
        exp_month: cardExpArr[0],
        exp_year: cardExpArr[1],
        cvc: cardCvv
      }, (status, response) => {
         
        if (status === 200) {
          //message: `Success! Card token ${response.card.id}.`,
          //Server side payment
          submitPayment(response.id);
        } else {
          Utility.showToast('error', response.error.message);
          setButtonLoading(false);
        }

      });    

    }else{

      setButtonLoading(false);

    }

  }

  function onError(){
    console.log("Api Error");
    setButtonLoading(false);
  }

  const loadStripe = (stripe_p_key) => {
     
    console.log("stripe p key", stripe_p_key);
    if(!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://js.stripe.com/v2/";
      s.onload = () => {
        window['Stripe'].setPublishableKey(stripe_p_key);
      }
      window.document.body.appendChild(s);
    }
  }

  const setCardExp = function(e){

    //Prevent alphabetical from entering, allow only numerical chars
    const valid = e.charCode === 0 || e.charCode == 47 || /\d/.test(String.fromCharCode(e.charCode) );
    if(valid){

      const digit = e.key;
      setCardExpDate(cardExpDate + digit);
      // if(digit == 0)
      //   cardExpDate ? setCardExpDate(cardExpDate + digit) : console.log("Do nothing");
      // else if(digit == 1)
      //   cardExpDate ? setCardExpDate(cardExpDate + digit + ' / ') : setCardExpDate(digit);
      // else if(digit > 1 && digit <= 9)
      //   cardExpDate ? setCardExpDate(cardExpDate + digit + ' / ') : setCardExpDate('0' + digit + ' / ');
      // else if(digit > 9 && digit <= 12)
      //   setCardExpDate(digit + ' / ');

    }

  }


  //Remove last char or digit from the card exp string
  const removeCardExp = function(e){
    if(e.key == 'Backspace'){
      console.log("card exp date value", cardExpDate, typeof(cardExpDate));
      setCardExpDate( cardExpDate.substring(0, cardExpDate.length-1) );
    }
  }

  const RenderCountries = function(){

    return(
      <>
      <option key="0" value>Select</option>
      {
        countriesList.map((option, i) => (
          <option key={i} value={option.id}>{option.name}</option>
        ))
      }
      </>
    );

  }


  //Handle server side payment
  const submitPayment = token_id => {

    const payLoad = {
        service_id: serviceId,
        uid: UserData.id,
        token_id: token_id
    }
    dispatch(serviceInvitationSendRequest(payLoad, onInvitationSendSuccess, onError));

  }

  const onInvitationSendSuccess = resp => {
    setButtonLoading(false);
    navigate('/thankyou/service-offer');
  }


  return (
    <>
      <div className="contributor markplace-pc tabcontent">
        <div className="contributor-container">
          <div className="col-md-8 px-4">
            <div className="card border-none">
              <div className="">
                <div className="container">
                  <div className="d-flex carousel-nav">
                    {/* <Link to='#' className={"col mt-5 visiapro-bold fs-14" + (activeClass == 1 ? " active" : '')} onClick={(e) => setActiveClass(1)}>
                      Contributor Info
                    </Link>
                    <Link to='#' className={"col mt-5 visiapro-bold fs-14" + (activeClass == 2 ? " active" : '')} onClick={(e) => setActiveClass(2)}>
                      Payment Selection
                    </Link> */}
                  </div>
                  <div className="owl-carousel owl-1">
                    <div className={"media-29101 w-100" + (activeClass == 1 ? " active" : '')}>
                      <div className="row">
                        <div className="col-md-12 VisiaPro-ExtraBold fs-26 fw-800">
                          <h3>Contributor Information</h3>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="first_name"><h5 className="VisiaPro-ExtraBold fs-16 fw-800">First Name</h5></label>
                            <input value={firstName} onChange={(e) => setFirstName(e.target.value)} type="text" id="first_name" className="form-control" required />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="last_name"><h5 className="VisiaPro-ExtraBold fs-16 fw-800">Last Name</h5></label>
                            <input value={lastName} onChange={(e) => setLastName(e.target.value)} type="text" id="last_name" className="form-control" />
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="address"><h5 className="VisiaPro-ExtraBold fs-16 fw-800">Address</h5></label>
                            <input value={address} onChange={(e) => setAddress(e.target.value)} type="text" id="address" className="form-control" />
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="country"><h5 className="VisiaPro-ExtraBold fs-16 fw-800">Country</h5></label>
                            <select id="country" onChange={(e) => setCountryId(e.target.value)} value={countryId} className="form-select w-100" aria-label="Default select example">
                              {<RenderCountries/>}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="city"><h5 className="VisiaPro-ExtraBold fs-16 fw-800">City</h5></label>
                            <input value={city} onChange={(e) => setCity(e.target.value)} type="text" id="city" className="form-control" />
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="postal_code"><h5 className="VisiaPro-ExtraBold fs-16 fw-800">Postal Code</h5></label>
                            <input value={postalCode} onChange={(e) => setPostalCode(e.target.value)} type="text" id="postal_code" className="form-control" />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="phone"><h5 className="VisiaPro-ExtraBold fs-16 fw-800">Phone</h5></label>
                            <input value={phone} onChange={(e) => setPhone(e.target.value)} type="text" id="phone" className="form-control" />
                          </div>
                        </div>
                      </div>
                      <div className="row mb-5 button-top">
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-12"><Link to="/home">{arrowLeft}&nbsp;<span className="text-left return-home">Return to Home</span></Link></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <span className="pointer button-width float-right visiapro-heavy fs-14 next" onClick={(e) => validateContributorForm(e)}></span>
                        </div>
                      </div>
                    </div>
                    <div className={"media-29101 w-100" + (activeClass == 2 ? " active" : '')}>
                      <div className="row">
                        <div className="col-md-12">
                          <h3 className="VisiaPro-ExtraBold fs-26">Payment Selection</h3>
                        </div>
                      </div>
                      <form onSubmit={pay}>
                        <div className="card mt-3">
                          <div className="container payment-selection-container">
                            <div className="row mt-3">
                              <div className="col-md-6">
                                <h5 className="visiapro-heavy"><strong>Credit Card</strong></h5>
                              </div>
                              <div className="col-md-6">
                                <div className="float-right">
                                  <div className="row">
                                    <img src={cards} className="img-fluid width-contributore" alt="Payment Cards" width="75"/>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-10">
                                <p className="VisiaPro-Bold fs-16 color-text-gray">
                                  Safe money transfer using your bank account.
                                  Visa, Masetro, Discover, American Express
                                </p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label htmlFor="card_number" className="VisiaPro-ExtraBold"><strong>Card Number</strong></label>
                                  <input value={cardNumber} onChange={(e) => setCardNumber(e.target.value)} id="card_number" type="tel" inputMode="numeric" pattern="[0-9\s]{13,19}" autoComplete="cc-number" maxLength="19" className="card form-control color-text-gray" placeholder="0000 00000 00000"/>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label htmlFor="name_card" className="VisiaPro-ExtraBold"><strong>Name On Card</strong></label>
                                  <input value={cardName} onChange={(e) => setCardName(e.target.value)} type="text" id="name_card" className="form-control color-text-gray" placeholder="Enter your card name" />
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label htmlFor="date_expiry" className="VisiaPro-ExtraBold"><strong>Expiry Date</strong></label>
                                  <input inputMode="numeric" value={cardExpDate} onKeyDown={(e) => removeCardExp(e)} onKeyPress={(e) => setCardExp(e)} type="text" id="date_expiry" className="form-control color-text-gray" placeholder="MM/YY"/>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="form-group">
                                  <label className="VisiaPro-ExtraBold" htmlFor="cvv"><strong>CVV Code</strong></label>
                                  <input value={cardCvv} onChange={(e) => setCardCvv(e.target.value)} id="cvv" type="text" className="form-control color-text-gray" placeholder="Enter cvv" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                      <div className="row mb-5 button-top">
                        <div className="col-md-6">
                          <div className="row vertical-center">
                            <div className="col-md-12"><Link to="/home">{arrowLeft}&nbsp;<span className="text-left return-home">Return to Home</span></Link></div>
                          </div>
                        </div>
                        <div className="col-md-6 text-right">
                          { buttonLoading ? <img src={buttonLoader} width="16" /> : '' }
                          <span className="submit-donation-button button-width float-right fs-14 visiapro-heavy pointer" onClick={(e) => pay(e)} disabled={buttonLoading}></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 bg-color-4 P-0">
            <div className="container">
              <div className="margin-offer">
                <h2 className="fs-24 regular-font offer-funds-text">Offer Funds</h2>
                <div className="border-bottom mt-4"></div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <p className="color-text-gray fs-14">Amount</p>
                  </div>
                  <div className="col-md-6">
                    <p className="text-right color-text-gray VisiaPro-Bold fs-14">${contributorAmt}</p>
                  </div>
                </div>
                <div className="border-top margin-border"></div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <p className="VisiaPro-Bold fs-16 offer-funds-text">Total</p>
                  </div>
                  <div className="col-md-6">
                    <p className="text-right VisiaPro-Bold fs-16 offer-funds-text">${contributorAmt}</p>
                  </div>
                </div>
              </div>
              <div className="logo-block text-right">
                {activeClass == 2 
                ? 
                <img className="contributor-sign-logo" width={176} src={signBlue} />
                : 
                <></>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceContributor;

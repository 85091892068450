/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Bell,
  Message,
  ProfileImage,
  Background,
  LeftBack,
  Setting,
  Flag,
  BackgroundLight,
  Cross,
  preLoader
} from "../assets/images";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Sidebar from "../components/Sidebar";
import firebase from '../../firebase';
import moment from "moment";

const CHAT_GROUPS = "chats/groups/";
const CHAT_INDIVIDUALS = "chats/indviduals/"
const COLLECTION_GROUPS = "groups/";
const COLLECTION_USERS = "users/";

const Messageindividual = () => {

  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showNew, setShowNew] = useState(true);
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [activeGroupIdx, setActiveGroupIdx] = useState(0);
  const [chatMsg, setChatMsg] = useState('');
  const [chatImg, setChatImg] = useState(null);
  const [chatImgName, setChatImgName] = useState('');
  const [chats, setChats] = useState([]);
  const [loader, setLoader] = useState(true);
  const [isGroup, setIsGroup] = useState(true);

  const [state, setState] = React.useState({
    activeGroupKey: null
  });

  const UserData = useSelector(
    (state: RootStateOrAny) => state.loginReducer.data
  );

  const uid = UserData.id;

  useEffect(() => {

    const fetchData = async () => {
      firebase.database().ref(COLLECTION_GROUPS).on('value', resp => {
        setGroups([]);
        setGroups(snapshotToArray(resp));
        setLoader(false);
      });
      firebase.database().ref(COLLECTION_USERS).on('value', resp => {
        setUsers(snapshotToArray(resp));
      });
    };
      
    fetchData();

  }, []);


  useEffect(() => {

    (function(){

      const MESSAGE_TO = isGroup ? CHAT_GROUPS : CHAT_INDIVIDUALS;
      firebase.database().ref(`${MESSAGE_TO}${state.activeGroupKey}/`).on('value', resp => {
        setChats(snapshotToArray(resp));
      });

    })();

  },[state.activeGroupKey]);

  const RenderChats = function(){

    return(
      <>
      {
        chats.map( (option, i) => (
          <>
          <div className="row mt-5">
            <div className="col-md-5 col-5"><div className="border mt-2"></div></div>
            <div className="col-md-2 col-2"><p className="font-time">{ option.date  }</p></div>
            <div className="col-md-5 col-5"><div className="border mt-2"></div></div>
          </div>
          <div className="row">
            {<SetMsgAlignment uid={option.uid} />}
            <div className="col-md-6 col-10">
              {<RenderChatMessage message={option.message} uid={option.uid} image={option.image} />}
            </div>
          </div>   
          </>  
        ))
      }
      </>
    );

  }

  const SetMsgAlignment = function(prop){

    //If the message's author is current user
    if(prop.uid == uid){
      return(
        <>
        {
          <div className="col-md-6 col-2"></div>
        }
        </>
      );
    }else{
      return(<></>);
    }

  }

  const RenderChatMessage = function(prop){

    if(prop.message){
        return(
        <>
          {
            <div className={"card " + (prop.uid == uid ? "bg-message-pink" : "bg-message-grey") + " mt-3 w-96 float-right drop-shadow-2"}>
              <div className="col-md-12">
                <h6 className="message-font-individual mt-1 VisiaPro-SemiBold">
                  {prop.message}
                </h6>
              </div>
            </div>        
          }
        </>
        );
    }
    else if(prop.image){
      return(
        <>
        {
          <div className="mt-4">
            <img src={prop.image} className="img-fluid message-border-top w-96 float-right drop-shadow-2" alt="chat image" />
          </div>
        }
        </>
      );
    }

  }

  const snapshotToArray = (snapshot) => {
    const returnArr = [];

    snapshot.forEach((childSnapshot) => {
      const item = childSnapshot.val();
      item.key = childSnapshot.key;
      returnArr.push(item);
    });

    return returnArr;
  }

  const setActiveGroup = function(target){
    setLoader(true);
    setActiveGroupIdx(target.getAttribute('srKey'));
    setState({ activeGroupKey: target.getAttribute('dataId') });
    setLoader(false);
    //Set if the selected is a group or an user
    setIsGroup(target.classList.contains('groups') ? true : false);
  }

  const setActiveGroupKeyByIdx = function(idx, key){
    if(idx == 0 && state.activeGroupKey == null){
      setState({ activeGroupKey: key });
    } 
  }

  //To submit a new chat message (when user press enter)
  const handleChatKeyDown = function(e){

    if(e.key == "Enter"){
      const msg = e.target.value.trim();
      if(msg != '' || chatImg != null) submitChatMsg(msg);
    }

  }

  const submitChatMsg = async function(msg){
    
    setLoader(true);

    const dateObj = new Date();
    const chat = {
      type: 'message',
      message: msg,
      timestamp: dateObj.getTime(),
      uid: uid,
      date: moment().format('lll')
    }

    if(chatImg == null)
      chat.image = null;
    else
      chat.image = await getChatImgUrl(chatImg);

    const MESSAGE_TO = isGroup ? CHAT_GROUPS : CHAT_INDIVIDUALS;
    const newMessage = firebase.database().ref(`${MESSAGE_TO}${state.activeGroupKey}/`).push();
    newMessage.set(chat);
    setChatMsg('');
    updateChatImgName('');
    setLoader(false);

  }

  const uploadChatImg = (e) => {

    const file = e.target.files[0];
    const fileName = getFileName(file.type);

    if(file != null && file != undefined){
      const reader = new FileReader();
      reader.readAsDataURL(file); 
      reader.onload = function() {   
        setChatImg(reader.result);
      }
      updateChatImgName(fileName);
      document.getElementById('close-chat-img-modal').click();
    }

  }

  const getFileName = function(fileType){

    const fileExt = {'image/png' : '.png', 'image/jpg' : '.jpg', 'image/jpeg' : '.jpg'};
    const dateObj = new Date();
    return dateObj.getTime() + fileExt[fileType];

  }

  const uploadImageToCloud = async function(chat_img_data){

    // Create a root reference
    const storageRef = firebase.storage().ref();

    // Create a reference to the image
    const imageRef = storageRef.child(`${CHAT_GROUPS}${chatImgName}`);

    return imageRef.putString(chat_img_data, 'data_url').then((snapshot) => {
  
      if(snapshot.state == "success"){
        return snapshot.ref.getDownloadURL().then(function(downloadURL) {
          return downloadURL;
        });
      }

      return null;

    });

  }

  const getChatImgUrl = async function(chat_img_data){
    return await uploadImageToCloud(chat_img_data);
  }

  const updateChatImgName = function(name){
    setChatImgName(name);
    //document.querySelector("span#chat-image-name").innerText = name;
  }

  const RenderGroups = function(){

    return(
      <>
      {
        groups.map( (option, i) => (
                    
          <>{ setActiveGroupKeyByIdx(i, option.key) }
          <div key={i} srKey={i} dataId={option.key} onClick={(e) => setActiveGroup(e.currentTarget)} className={"messages groups pointer" + (i == activeGroupIdx ? ' active' : '')}>
            <div className="col-md-12 p-3 col-12">
              <div className="row">
                <div className="col-md-3 col-3">
                  <img src={Background} className="img-fluid sidebar-message" alt="..." />
                </div>
                <div className="col-md-6 col-6 p-0 margin-left-font">
                  <h6 className="VisiaPro-Bold">{option.name}</h6>
                  <p className="sidebar-font regular-font color-text-gray">City, State</p>
                </div>
                <div className="col-md-3 col-3">
                  <i className="fa fa-users" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="border-bottom"></div>
          </div>
          </>

        ))
      }
      </>
    );

  }


  const RenderUsers = function(){

    return(
      <>
      {
        users.map( (option, i) => (
                    
          <>
          <div key={i+2} srKey={i+2} dataId={option.key} onClick={(e) => setActiveGroup(e.currentTarget)} className={"messages users pointer" + (option.key == state.activeGroupKey ? ' active' : '')}>
            <div className="col-md-12 p-3 col-12">
              <div className="row">
                <div className="col-md-3 col-3">
                  <img src={option.profile_pic} className="img-fluid sidebar-message" alt="..." />
                </div>
                <div className="col-md-6 col-6 p-0 margin-left-font">
                  <h6 className="VisiaPro-Bold">{option.first_name} {option.last_name}</h6>
                  <p className="sidebar-font regular-font color-text-gray">City, State</p>
                </div>
                <div className="col-md-3 col-3">
                  <i className="fa fa-users" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="border-bottom"></div>
          </div>
          </>

        ))
      }
      </>
    );

  }


  return (
    <>
      <div className="container individual-message" style={{"maxWidth":"100%"}}>
        <div className="row mess-pos">
          <div className="">
        <div className="sidebar">
          <div className="container" style={{"maxWidth":"100%"}}>
            <div className="col-md-12 col-12">
              <h1 className="mt-5 visiapro-heavy msg-text">Messages</h1>
            </div>
            <div className="borders mt-3"></div>
          </div>
          <div className="overflow">
            {<RenderGroups/>}
            {<RenderUsers/>}
          </div>
          <div className="container">
            <div className="borders mb-5"></div>
          </div>
          <div className="col-md-12 hide position-abs ml-3 col-12">
            <div className="row positon-bottom-sidebar">
              <div className="mt-1">
                <img src={Bell} className="img-fluid  w-mobilw" alt="..." />
              </div>
              <div className="ml-3">
                <h5 className="VisiaPro-ExtraBold">Back to Home</h5>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="">
        <div className="side-slider-message"> <i className="fa fa-caret-left d-none" aria-hidden="true"></i></div>
        </div>
        </div>
        <div className="content">
          <div className="container">
            <div className="overflow-y">
              {<RenderChats/>}
              <br/>
              <div className="col-md-12 position-input-set">
                <div className="row">
                  <div className="col-md-12">
                    <div className="bg-camera text-center drop-shadow">
                      <i className="fa fa-camera mt-1" aria-hidden="true" data-target="#exampleModalsr" data-toggle="modal"></i>
                    </div>
                    <span id="chat-image-name">{setChatImgName}</span>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-11 col-11">
                    <div className="card bg-signup-2 card-height-locations w-38">
                      <div className="col-md-12">
                        <h6 className="mb-0 location-font mt-2 VisiaPro-SemiBold color-text-gray">
                          Press Enter To Send
                        </h6>
                        <div className="form-group">
                          <input onKeyDown={(e)=>handleChatKeyDown(e)}
                          type="text" value={chatMsg}
                          onChange={(e)=>setChatMsg(e.target.value)}
                          className="form-control input-message-enter VisiaPro-Bold color-text-gray fs-16"
                          placeholder="Type Message"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade centered"
          id="exampleModals"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog centered modal-sm">
            <div className="modal-content siteImageButton m-50">
              <div className="modal-body">
                <div className="text-center">
                  <h1 className="model-font-side">Block/Report User?</h1>
                </div>
                <div className="row mt-4">
                  <div className="col-md-5">
                    <img
                      src={ProfileImage}
                      className="img-fluid  float-right pop--img-size"
                      alt="..."
                    />
                  </div>
                  <div className="col-md-6 p-0 mt-1">
                    <h6>Johany Blank</h6>
                    <p className="model-font-side">New York,New York</p>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-6 float-right">
                    <button
                      type="text"
                      className="float-right popup-cancel-message"
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col-md-6">
                    <button type="text" className="popup-report-message">
                      Report User
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade centered"
          id="exampleModalsr"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog centered">
            <div className="modal-content siteImageButton m-50 radius-choose-camera">
              <div className="modal-body">
               <div className="card set-width-message">
                 <div className="text-center">
                  <div className="chat-image-upload">
                    <input type="file" className="chat-image" accept="image/*" onChange={uploadChatImg} />
                    <i className="fa fa-camera font-camera-message pointer" aria-hidden="true"></i>
                  </div>
                  <h4 className="choose-photo-text">Choose Photo</h4>
               </div>
               </div>
               <br/>
               <div className="text-right"><button id="close-chat-img-modal" type="button" className="hide btn-close btn btn-secondary" data-dismiss="modal" aria-label="Close">Close</button></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"chat-loader" + (loader ? '' : ' hide')}><p className="color-black"><img src={preLoader} />Please Wait</p></div>
    </>
  );
};

export default Messageindividual;

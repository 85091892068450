import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBell, faUserCircle, faLocationArrow, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../redux/actions/loginAction";

import { useDispatch } from "react-redux";
import { clearDashboard } from "../redux/actions/dashboardAction";

const MenuToggle = props => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logoutUser = () => {
        dispatch(logout());
        dispatch(clearDashboard());
        //navigate('/blankuhuh');
    };

    return(
        <div className="pointer display-inline-block" style={{'position': 'relative'}}>
            {props.notification_count > 0 ? <span className="notification-count outer">{props.notification_count}</span> : <></>}<FontAwesomeIcon icon={faBars} onClick={() => props.set_show_dropdown_content(!props.show_dropdown_content)} />  
            <div className={"dropdown-content dropdown-2 text-left fs-16" + (props.show_dropdown_content == false ? ' hide': ' show')}>
                <Link onClick={() => props.set_alerts_modal_show(true)} className="py-2" to="#"><span className="notification-count">{props.notification_count > 0 ? props.notification_count : 0}</span><FontAwesomeIcon icon={faBell}/>&nbsp;&nbsp;<span className="mobile-mark font-manrope-semibold profile">Notifications</span></Link>
                <Link className="py-2 dropdown-content-border" to="/profile"><FontAwesomeIcon icon={faUserCircle}/>&nbsp;&nbsp;<span className="mobile-mark font-manrope-semibold profile">Profile</span></Link>
                <Link className="py-2" to="/messagegroup"><FontAwesomeIcon icon={faLocationArrow}/>&nbsp;&nbsp;<span className="mobile-mark profile font-manrope-semibold">Messages</span></Link>
                <Link to="/blank" onClick={() => logoutUser()} className="py-2"><FontAwesomeIcon icon={faSignOutAlt}/>&nbsp;&nbsp;<span className="mobile-mark profile font-manrope-semibold">Logout</span></Link>
            </div>        
        </div>
    );

}

export default MenuToggle;
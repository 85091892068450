import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { useNavigate, Link, useParams } from "react-router-dom";
import "../assets/css/admin.css";
import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import {logo2} from "../assets/images";
import { AiOutlineLeft, AiOutlineRight, AiOutlineSearch } from "react-icons/ai";

import {
  adminContributorsListRequest
} from "../redux/actions/adminContributorsListingAction";

const Projects = () => {

  const dispatch = useDispatch();

  const [ProjectList, setProjectList] = useState([]);
  const [ProjectTotalPages, setProjectTotalPages] = useState([]);

  useEffect( () => {

    dispatch(adminContributorsListRequest({showSuccessToast: false}, onProjectListSuccess, onError));

  },[] );

  let ProjectselctedPage = (page) => {
    if (page > 0 && page <= ProjectTotalPages) {
      // setProjectActivePage(page);
      var payload = {
        page: page,
        showSuccessToast: false
      };
      dispatch(adminContributorsListRequest(payload, onProjectListSuccess, onError));
    }
  };

  const onProjectListSuccess = resp => {
    setProjectList(resp.contributors);
    setProjectTotalPages(resp.total_pages);
  }

  const ProjectPageChange = (data) => {
    ProjectselctedPage(data.selected + 1);
  };


  const onError = error => {
    console.log("Api error", error);
  }

  const ProjectListData = ProjectList.map((item, index) => (
    <tr key={index.toString()}>
      <td>{index+1}</td>
      <td>{item.name}</td>
    </tr>
  ));

  return(

    <>
    <div className="overlay">
      <div className="header_container home-page">
        <nav className="header_navbar navbar  navbar-expand-lg navbar-light ">
          <div className="logo VisiaPro-BoldItalic">
            <Link to="/blank">
              <img src={logo2} width="75" />
            </Link>
          </div>
        </nav>
      </div>
    </div>    

    <div className="admincontent tabs mb-5">

      <div className="row mt-4">
        <div className="col-md-4 ">
          <h1 className="VisiaPro-ExtraBold">Contributors</h1>
        </div>
      </div>

      <div className="row mt-4">
          <div className="col-md-12">
            <Table striped bordered hover size="sm">
              <thead className="VisiaPro-ExtraBold">
                <tr>
                  <th scope="col">ID No.</th>
                  <th scope="col">Contributor Name</th>
                </tr>
              </thead>
              <tbody className="regular-font">
                {ProjectList && ProjectList.length > 0 ? (
                  <>{ProjectListData}</>
                ) : (
                  <tr>
                    <td colSpan={7}>No Record Found!!!</td>
                  </tr>
                )}
              </tbody>
            </Table>
            {ProjectTotalPages > 0 ? (
              <nav className="d-flex justify-content-center">
                <ReactPaginate
                  previousLabel={<AiOutlineLeft />}
                  nextLabel={<AiOutlineRight />}
                  breakLabel={"..."}
                  pageCount={ProjectTotalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={ProjectPageChange}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                ></ReactPaginate>
              </nav>
            ) : null}
          </div>
        </div>

    </div>

    </>
  );

}

export default Projects;

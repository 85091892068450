import {
    SERVICE_INVITATION_SEND_REQUEST,
    SERVICE_INVITATION_SEND_REQUEST_SUCCESS,
    SERVICE_INVITATION_SEND_REQUEST_ERROR,
  } from "../actionTypes/type";
  const initialState = {
    data: null,
  };
  
  const serviceInvitationSendReducer = (state = initialState, action) => {
    let object;
  
    switch (action.type) {
      case SERVICE_INVITATION_SEND_REQUEST:
        object = {
          ...state,
        };
        break;
  
      case SERVICE_INVITATION_SEND_REQUEST_ERROR:
        object = {
          ...state,
        };
        break;
  
      case SERVICE_INVITATION_SEND_REQUEST_SUCCESS:
        object = {
          ...state,
          // data: action.payload,
        };
        break;
  
      default:
        object = state;
        break;
    }
    return object;
  };
  
  export default serviceInvitationSendReducer;
  
import { combineReducers } from "redux";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as ActionType from "../actionTypes/type";
import loginReducer from "./loginReducer";
import adminloginReducer from "./adminloginReducer";
import registerReducer from "./registerReducer";
import skillReducer from "./skillReducer";
import projectFocusReducer from "./projectFocusReducer";
import projectTypeReducer from "./projectTypeReducer";
import projectHelpReducer from "./projectHelpReducer";
import userListingReducer from "./userListingReducer";
import projectSuppliesReducer from "./projectSuppliesReducer";
import projectEquipmentsReducer from "./projectEquipmentsReducer";
import languageReducer from "./languageReducer";
import countriesReducer from "./countriesReducer";
import userProfileReducer from "./userProfileReducer";
import projectCreateReducer from "./projectCreateReducer";
import projectUpdateReducer from "./projectUpdateReducer";
import projectListingReducer from "./projectListingReducer";
import paymentReducer from "./paymentReducer";
import authorizedProjectsListingReducer from "./authorizedProjectsListingReducer";
import projectDetailsReducer from "./projectDetailsReducer";
import projectInviteReducer from "./projectInviteReducer";
import projectInvitationMessageReducer from "./projectInvitationMessageReducer";
import projectInvitationRespondReducer from "./projectInvitationRespondReducer";
import projectInvitationReadReducer from "./projectInvitationReadReducer";
import projectImageInsertReducer from "./projectImageInsertReducer";
import projectImageDeleteReducer from "./projectImageDeleteReducer";
import projectContributionSkillReducer from "./projectContributionSkillReducer";
import projectContributionMaterialReducer from "./projectContributionMaterialReducer";
import groupCreateReducer from "./groupCreateReducer";
import stripeDetailsReducer from "./stripeDetailsReducer";
import stripeSaveReducer from "./stripeSaveReducer";
import userGroupsListingReducer from "./userGroupsListingReducer";
import groupUsersListingReducer from "./groupUsersListingReducer";
import userImagesReducer from "./userImagesReducer";
import userUpdateProfileReducer from "./userUpdateProfileReducer";
import stripeBankAccountUpdateReducer from "./stripeBankAccountReducer";
import userAuthorizedProjectsReducer from "./userAuthorizedProjectReducer";
import adminContributorsListReducer from "./adminContributorsListReducer";

import { adminProjectListReducer, adminProjectViewReducer, adminProjectDeleteReducer}  from "./adminProjectlistReducer";
import {adminUserListReducer, adminUserProfileReducer, adminUserDeleteReducer }from "./adminUserlistReducer";

import adminReportedListReducer from "./adminReportedlistReducer";
import stripeAccountPaymentTransferReducer from "./stripeAccountPaymentTransferReducer";

import userReportReducer from "./userReportReducer";
import userReportGetReducer from "./userReportGetReducer";

import adminDisregardUserReportReducer from "./adminDisregardUserReportReducer";

import forgotPasswordReducer from "./forgotPasswordReducer";
import validateForgotPasswordTokenReducer from "./validateForgotPasswordTokenReducer";
import userPasswordUpdateReducer from './userPasswordUpdateReducer'; 

import groupPageCreateReducer from "./groupPageCreateReducer";
import groupProfileReducer from "./groupProfileReducer";
import groupInvitationReducer from "./groupInvitationReducer";
import groupProfileUpdateReducer from "./groupProfileUpdateReducer";
import groupPageDeleteReducer from "./groupPageDeleteReducer";

import serviceCreateReducer from "./serviceCreateReducer";
import serviceGetReducer from "./serviceGetReducer";
import serviceGetTypesFocusReducer from "./serviceGetTypesFocusReducer";
import serviceInvitationSendReducer from "./serviceInvitationSendReducer";

import contributorsListingReducer from "./contributorsListingReducer";

import socialEntrepreneursListingReducer from "./socialEntrepreneursListingReducer";

// Redux: Root Reducer
const appReducer = combineReducers({
  adminloginReducer: adminloginReducer,
  loginReducer: loginReducer,
  registerReducer: registerReducer,
  skillReducer : skillReducer,
  projectFocusReducer : projectFocusReducer,
  projectTypeReducer : projectTypeReducer,
  projectHelpReducer : projectHelpReducer,
  userListingReducer : userListingReducer,
  projectSuppliesReducer : projectSuppliesReducer,
  projectEquipmentsReducer : projectEquipmentsReducer,
  languageReducer: languageReducer,
  userProfileReducer: userProfileReducer,
  projectCreateReducer: projectCreateReducer,
  projectListingReducer: projectListingReducer,
  countriesReducer: countriesReducer,
  paymentReducer: paymentReducer,
  authorizedProjectsListingReducer: authorizedProjectsListingReducer,
  projectDetailsReducer: projectDetailsReducer,
  projectUpdateReducer: projectUpdateReducer,
  projectInviteReducer: projectInviteReducer,
  projectInvitationMessageReducer: projectInvitationMessageReducer,
  projectInvitationRespondReducer: projectInvitationRespondReducer,
  projectImageInsertReducer: projectImageInsertReducer,
  projectImageDeleteReducer: projectImageDeleteReducer,
  projectContributionSkillReducer: projectContributionSkillReducer,
  projectContributionMaterialReducer: projectContributionMaterialReducer,
  projectInvitationReadReducer: projectInvitationReadReducer,
  groupCreateReducer: groupCreateReducer,
  adminProjectListReducer: adminProjectListReducer,
  adminUserListReducer: adminUserListReducer,
  stripeDetailsReducer: stripeDetailsReducer,
  stripeSaveReducer: stripeSaveReducer,
  adminUserProfileReducer:adminUserProfileReducer,
  adminUserDeleteReducer:adminUserDeleteReducer,
  adminProjectViewReducer:adminProjectViewReducer,
  adminProjectDeleteReducer:adminProjectDeleteReducer,
  adminReportedListReducer:adminReportedListReducer,
  userGroupsListingReducer: userGroupsListingReducer,
  groupUsersListingReducer: groupUsersListingReducer,
  userImagesReducer: userImagesReducer,
  userUpdateProfileReducer: userUpdateProfileReducer,
  stripeBankAccountUpdateReducer: stripeBankAccountUpdateReducer,
  userAuthorizedProjectsReducer: userAuthorizedProjectsReducer,
  stripeAccountPaymentTransferReducer: stripeAccountPaymentTransferReducer,
  adminContributorsListReducer: adminContributorsListReducer,
  userReportReducer: userReportReducer,
  userReportGetReducer: userReportGetReducer,
  adminDisregardUserReportReducer: adminDisregardUserReportReducer,
  forgotPasswordReducer: forgotPasswordReducer,
  validateForgotPasswordTokenReducer: validateForgotPasswordTokenReducer,
  userPasswordUpdateReducer: userPasswordUpdateReducer,
  groupPageCreateReducer: groupPageCreateReducer,
  groupProfileReducer: groupProfileReducer,
  groupInvitationReducer: groupInvitationReducer,
  groupProfileUpdateReducer: groupProfileUpdateReducer,
  serviceCreateReducer: serviceCreateReducer,
  serviceGetReducer: serviceGetReducer,
  serviceGetTypesFocusReducer: serviceGetTypesFocusReducer,
  serviceInvitationSendReducer: serviceInvitationSendReducer,
  contributorsListingReducer: contributorsListingReducer,
  socialEntrepreneursListingReducer: socialEntrepreneursListingReducer,
  groupPageDeleteReducer: groupPageDeleteReducer
});

const RootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === ActionType.LOGOUT_USER_REQUEST || action.type === ActionType.ADMIN_LOGOUT_REQUEST) {
    AsyncStorage.removeItem("persist:root");
    state = undefined;
  }
  return appReducer(state, action);
};

// Exports
export default RootReducer;

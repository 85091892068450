import { signBlue } from "../assets/images";

const SignBlue = () => {

    return (
        <div className="text-right px-4 py-4">
            <img width={57} src={signBlue} alt="sign blue" />
        </div>
    );
    
}

export default SignBlue;
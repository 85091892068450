import { all } from "redux-saga/effects";
import { watchLoginSaga } from "./loginSaga";
import { watchadminLoginSaga } from "./adminloginSaga";
import { watchRegisterSaga } from "./registerSaga";
import { watchSkillSaga } from "./skillSaga";
import { watchProjectFocusSaga } from "./projectFocusSaga";
import { watchProjectTypeSaga } from "./projectTypeSaga";
import { watchProjectHelpSaga } from "./projectHelpSaga";
import { watchUserListingSaga } from "./userListingSaga";
import { watchProjectSuppliesSaga } from "./projectSuppliesSaga";
import { watchProjectEquipmentsSaga } from "./projectEquipmentsSaga";
import { watchLanguageSaga } from "./languageSaga";
import { watchCountriesSaga } from "./countriesSaga";
import { watchUserProfileSaga } from "./userProfileSaga";
import { watchProjectCreateSaga } from "./projectCreateSaga";
import { watchProjectListingSaga } from "./projectListingSaga";
import { watchPaymentSaga } from "./paymentSaga";
import { watchAuthorizedProjectsListingSaga } from "./authorizedProjectsListingSaga";
import { watchProjectAuthorizedUsersListingSaga } from "./projectAuthorizedUsersListingSaga";
import { watchProjectDetailsSaga } from "./projectDetailsSaga";
import { watchProjectUpdateSaga } from "./projectUpdateSaga";
import { watchProjectInviteSaga } from "./projectInviteSaga";
import { watchProjectInvitationMessageSaga } from "./projectInvitationMessageSaga";
import { watchProjectInvitationRespondSaga } from "./projectInvitationRespondSaga";
import { watchProjectInvitationReadSaga } from "./projectInvitationReadSaga";
import { watchProjectImageInsertSaga } from "./projectImageInsertSaga";
import { watchProjectImageDeleteSaga } from "./projectImageDeleteSaga";
import { watchProjectContributionSkillSaga } from "./projectContributionSkillSaga";
import { watchProjectContributionMaterialSaga } from "./projectContributionMaterialSaga";
import { watchGroupCreateSaga } from "./groupCreateSaga";
import { watchadminProjectListSaga, watchadminProjectViewSaga, watchadminProjectDeleteSaga} from "./adminProjectListSaga";
import { watchadminUserListSaga, watchadminUserProfileSaga, watchadminUserDeleteSaga } from "./adminUserListSaga";
import { watchStripeDetailsSaga } from "./stripeDetailsSaga";
import { watchStripeSaveSaga } from "./stripeSaveSaga";
import { watchadminReportedListSaga } from "./adminReportedListSaga";
import { watchUserGroupsListingSaga } from "./userGroupsListingSaga";
import { watchGroupUsersListingSaga } from "./groupUsersListingSaga";
import { watchUserImagesSaga } from "./userImagesSaga";
import { watchUserUpdateProfileSaga } from "./userUpdateProfileSaga";
import { watchStripeBankAccountUpdateSaga } from "./stripeBankAccountSaga";
import { watchUserAuthorizedProjectSaga } from "./userAuthorizedProjectSaga";
import { watchStripeAccountPaymentTransferSaga } from "./stripeAccountPaymentTransferSaga";
import { watchAdminContributorsListSaga } from "./adminContributorsListSaga";
import { watchUserReportSaga } from "./userReportSaga";
import { watchUserReportGetSaga } from "./userReportGetSaga";
import { watchAdminDisregardUserReportSaga } from "./adminDisregardUserReportSaga";
import { watchForgotPaswordSaga } from "./forgotPasswordSaga";
import { watchValidateForgotPaswordTokenSaga } from "./validateForgotPasswordTokenSaga";
import { watchUserPasswordUpdateSaga } from "./userPasswordUpdateSaga";
import { watchGroupPageCreateSaga } from "./groupPageCreateSaga";
import { watchGroupProfileSaga } from "./groupProfileSaga";
import { watchGroupInvitationSaga } from "./groupInvitationSaga";
import { watchGroupProfileUpdateSaga } from "./groupProfileUpdateSaga";
import { watchServiceCreateSaga } from "./serviceCreateSaga";
import { watchServiceGetSaga } from "./serviceGetSaga";
import { watchServiceGetTypesFocusSaga } from "./serviceGetTypesFocusSaga";
import { watchServiceInvitationSendSaga } from "./serviceInvitationSendSaga";
import { watchContributorsListingSaga } from "./contributorsListingSaga";
import { watchSocialEntrepreneursListingSaga } from "./socialEntrepreneursListingSaga";
import { watchGroupPageDeleteSaga } from "./groupPageDeleteSaga";

function* RootSaga() {
  yield all([
    watchadminLoginSaga(),
    watchLoginSaga(),
    watchRegisterSaga(),
    watchSkillSaga(),
    watchProjectFocusSaga(),
    watchProjectTypeSaga(),
    watchProjectHelpSaga(),
    watchUserListingSaga(),
    watchProjectSuppliesSaga(),
    watchProjectEquipmentsSaga(),
    watchLanguageSaga(),
    watchUserProfileSaga(),
    watchProjectCreateSaga(),
    watchProjectListingSaga(),
    watchCountriesSaga(),
    watchPaymentSaga(),
    watchAuthorizedProjectsListingSaga(),
    watchProjectAuthorizedUsersListingSaga(),
    watchProjectDetailsSaga(),
    watchProjectUpdateSaga(),
    watchProjectInviteSaga(),
    watchProjectInvitationMessageSaga(),
    watchProjectInvitationRespondSaga(),
    watchProjectInvitationReadSaga(),
    watchProjectImageInsertSaga(),
    watchProjectImageDeleteSaga(),
    watchProjectContributionSkillSaga(),
    watchProjectContributionMaterialSaga(),
    watchGroupCreateSaga(),
    watchadminProjectListSaga(),
    watchadminUserListSaga(),
    watchStripeDetailsSaga(),
    watchStripeSaveSaga(),
    watchadminUserProfileSaga(),
    watchadminProjectViewSaga(),
    watchadminProjectDeleteSaga(),
    watchadminUserDeleteSaga(),
    watchadminReportedListSaga(),
    watchUserGroupsListingSaga(),
    watchGroupUsersListingSaga(),
    watchUserImagesSaga(),
    watchUserUpdateProfileSaga(),
    watchStripeBankAccountUpdateSaga(),
    watchUserAuthorizedProjectSaga(),
    watchStripeAccountPaymentTransferSaga(),
    watchAdminContributorsListSaga(),
    watchUserReportSaga(),
    watchUserReportGetSaga(),
    watchAdminDisregardUserReportSaga(),
    watchForgotPaswordSaga(),
    watchValidateForgotPaswordTokenSaga(),
    watchUserPasswordUpdateSaga(),
    watchGroupPageCreateSaga(),
    watchGroupProfileSaga(),
    watchGroupInvitationSaga(),
    watchGroupProfileUpdateSaga(),
    watchServiceCreateSaga(),
    watchServiceGetSaga(),
    watchServiceGetTypesFocusSaga(),
    watchServiceInvitationSendSaga(),
    watchContributorsListingSaga(),
    watchSocialEntrepreneursListingSaga(),
    watchGroupPageDeleteSaga()
  ]);
}

export default RootSaga;

import React from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import {adminloginRequest} from "../redux/actions/adminloginAction";
import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Utility from "../utility/utility";
import APP_STRING from "../constants/strings";
import { logo2 } from "../assets/images";
import { LANDING_PAGE_URL } from "../service/apiUrl";

const AdminLogIn = () => {

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const AdminData = useSelector((state) => state.adminloginReducer.data);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);


  useEffect(() => {
    Utility.isSignedIn(AdminData).then((value) => {
      // console.log("Admin Login Page", AdminData, value);
      if (value) {
        navigate("/admin");
      }
    });
  }, []);

  async function loggingIn(e) {
    e.preventDefault();
    if (validateLoginFields()) {
      setLoading(true);
      var payload = {
        email: email,
        password: password
      }
      dispatch(adminloginRequest(payload, onSuccess, onError));
    }
  }

  async function onSuccess() {
    setLoading(false);
    navigate("/admin");
  }

  async function onError(err) {
    console.log("error", err);
    setLoading(false);
  }

  const validateLoginFields = () => {
    var message = "";
    if (Utility.isEmpty(email)) {
      message = APP_STRING.ENTER_EMAIL;
    } else if (!Utility.validateEmail(email)) {
      message = APP_STRING.ENTER_VALID_EMAIL;
    } else if (Utility.isEmpty(password)) {
      message = APP_STRING.ENTER_PASSWORD;
    } else if (password.length < 6) {
      message = APP_STRING.ENTER_SIX_DIGIT_PASSWORD;
    }
    if (message === "") {
      return true;
    }
    Utility.showToast("error", message);
    return false;
  }

  const formSubmit = (event) => {
    loggingIn(event);
  };    

  return (

    <>

      <div className="col-md-12 flex-row mt-5">
        <div className="row">
      <i className="fa fa-caret-left font-size-login mr-2 ml-2" aria-hidden="true"></i>
        <h6 className="text-left mt-1">
        <a className="font-manrope-extra-bold" rel="noopener" href={LANDING_PAGE_URL}>Back to Landing</a>
        </h6>
        </div>
      </div>

      <div className="container signup">

        <div>
          <h1 className="text-center font-size-signup VisiaPro-ExtraBold"><img src={logo2} width="250" /></h1>&nbsp;
          <h1 className="text-center signup-font visiapro-heavy margin-login">Admin Sign In</h1>
        </div>

        <div className="col-12">

            <form onSubmit={formSubmit}>

                <div className="col-12 mt-5 mobile-padding">
                  <div className="form-group w-50-signup m-auto">
                      <label htmlFor="email"><h5 className="my-0 VisiaPro-ExtraBold">Email Or Username</h5></label>
                      <input id="email" type="email" className="form-control drop-shadow" value={email} onChange={(email) => setEmail(email.target.value)} placeholder="example@example.com"/>
                  </div>            
                </div>

                <div className="col-12 mt-3 mobile-padding">
                  <div className="form-group w-50-signup m-auto">
                    <label htmlFor="password"><h5 className="my-0 VisiaPro-ExtraBold">Password</h5></label>
                      <input id="password" type="password" className="form-control drop-shadow" value={password} onChange={(password) => {setPassword(password.target.value)}} placeholder="**********"/>
                  </div>
                </div>

                {/* <div className="col-md-12">
                  <h4 className="text-center mt-4 VisiaPro-ExtraBold">
                    <Link className="signup-link" to="/register"><u>New User? Sign Up Here</u></Link>
                  </h4>
                </div> */}

                <div className="col-md-12 mt-5">
                    <div className="text-center mt-3">
                        <button type="submit"
                          className="btn btn-primary signup-button drop-shadow"> Login </button>
                    </div>
                </div>
                {/* <div className="row">
                <div className="col-md-12 col-12">
                  <div className="text-center">
                  <p className="border-black bottom-0 p-fixed">
                    </p>
                  </div>
                  </div>
                </div> */}

            </form>

            <br/>

        </div>
      
      </div>

    </>

  );
};

export default AdminLogIn;

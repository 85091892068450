import {
  ADMIN_USER_REQUEST,
  ADMIN_USER_REQUEST_SUCCESS,
  ADMIN_USER_REQUEST_ERROR,
  ADMIN_USER_PROFILE_REQUEST,
  ADMIN_USER_DELETE_REQUEST,
} from "../actionTypes/type";

const initialState = {
  data: null,
  isLoading: false,
};
const adminUserListReducer = (state = initialState, action) => {
  let object;

  switch (action.type) {
    case ADMIN_USER_REQUEST:
      object = {
        ...state,
        isLoading: true,
      };
      break;

    case ADMIN_USER_REQUEST_ERROR:
      object = {
        ...state,
        isLoading: false,
      };
      break;

    case ADMIN_USER_REQUEST_SUCCESS:
      object = {
        ...state,
        data: action.payload,
        isLoading: false,
      };
      break;

    default:
      object = state;
      break;
  }
  return object;
}
const adminUserProfileReducer = (state = initialState, action) => {
  let object;

  switch (action.type) {
    case ADMIN_USER_PROFILE_REQUEST:
      object = {
        ...state,
        isLoading: true,
      };
      break;

    

    default:
      object = state;
      break;
  }
  return object;
}
const adminUserDeleteReducer = (state = initialState, action) => {
  let object;

  switch (action.type) {
    case ADMIN_USER_DELETE_REQUEST:
      object = {
        ...state,
        isLoading: true,
      };
      break;

    default:
      object = state;
      break;
  }
  return object;
}
export {adminUserListReducer, adminUserProfileReducer, adminUserDeleteReducer} ;

import {
  LANGUAGE_REQUEST,
  LANGUAGE_REQUEST_ERROR,
  LANGUAGE_REQUEST_SUCCESS,
} from "../actionTypes/type";

const initialState = {
  data: null,
  isLoading: false
};

const languageReducer = (state = initialState, action) => {
  let object;
  switch (action.type) {
    case LANGUAGE_REQUEST:
      object = {
        ...state,
        isLoading: true
      };
      break;

    case LANGUAGE_REQUEST_ERROR:
      object = {
        ...state,
        isLoading: false
      };
      break;

    case LANGUAGE_REQUEST_SUCCESS:
      object = {
        ...state,
        data: action.payload,
        isLoading: false
      };
      break;

    default:
      object = state;
      break;
  }
  return object;
}

export default languageReducer;

import React from "react";
import Modal from 'react-bootstrap/Modal';
import { crossX } from "../assets/images";

const Alert = props => {

    const RenderAcceptedMessageText = params => {

        return (
          <>
          {
            <div className={"accepted-message" + (params.hide_class == 1 ? ' hide' : '') + (params.accepted_val == -1 ? ' red' : '')}>
              <h5 className="text-center margin-organizer">{(params.type == 's' || params.type == 'm') ? 'Contributor' : (params.type == 'f' ? 'Sponsor' : (params.type == 'i' ? '' : 'Organizer') )}</h5>
              <h5 className="text-center margin-organizers respond-text">{params.type == 'i' ? 'Invite' : params.type == 'f' ? 'Donation' : 'Request'} { (params.accepted_val == -1) ? 'Denied' : 'Accepted'}</h5>
            </div>      
          }
          </>
        );
  
    }    

    /*
        * Function to render the text depending upon the accepted value
        * 
        * @params:
        *   {int} 'accepted' : If accepted = 1 => user has already accepted the invitation, else not
        *   If accepted = -1 => user has declined, if accepted = 0 => not responded yet
        */
    const RenderOrgInviteAcceptedBlock = (params) => {

        if(params.accepted_val != 0){
          
          return(
              <>
              {<RenderAcceptedMessageText type={params.type} read_status={params.read_status} accepted_val={params.accepted_val} hide_class={0}/>}
              </>
          );

        }else{
          
          return(
              <>
              {
                <>
                <RenderAcceptedMessageText type={params.type} read_status={params.read_status} accepted_val={params.accepted_val} hide_class={1}/>
                <div className="invitation-message">
                    
                    <h6 className="text-center margin-home-contributor font-manrope-semibold" dangerouslySetInnerHTML={{ __html: params.message }} />
                    
                    <div className="card w-75 m-auto home-card-square mb-5">
                    <div className="row">
                        <div className="col-md-6 text-center pointer" onClick={(e) => props.respond_project_request(params.id, -1, e.target, params.type)}>
                        <i className="fa fa-window-close home-page" aria-hidden="true"></i>
                        <h6 className="text-center margin-hidden font-manrope-regular">Deny</h6>
                        </div>
                        <div className="col-md-6 text-center pointer" onClick={(e) => props.respond_project_request(params.id, 1, e.target, params.type)}>
                        <i className="fa fa-check-square home-page" aria-hidden="true"></i>
                        <h6 className="text-center margin-hidden font-manrope-regular">Approved</h6>
                        </div>
                    </div>
                    </div>
                    <br/>

                </div>
                </>
              }
              </>
          );

        }

    }

    const RenderInvitationMessages = () => {

        if(props.invitation_messages.length == 0){
    
          return(
            <>
            {
              <p className="text-center">No record found</p>
            }
            </>
          );
    
        }else{
    
          return(
            <>
            {
              props.invitation_messages.map( (option, i) => (
    
                <div key={i} className="card alert-back-box mt-3 drop-shadow">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12 mt-2">
                        <h4 className="font-manrope-bold fs-20">{option.project_name}<i className={"fa fa-close fr pointer" + (option.accepted == 0 ? ' hide' : '')} onClick={(e) => props.read_alert_msg(e, option.id)}></i></h4>
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-md-2">
                        <img src={option.profile_pic} className="img-fluid model-home-img-radius" alt="user profile"/>
                      </div>
                      <div className="col-md-5 p-0 margin-rescue mt-1">
                        <h6 className="font-manrope-medium">{option.firstname} {option.lastname}</h6>
                        <p className="home-rescue font-manrope-bold">City, State</p>
                      </div>
                      <div className="col-md-5 p-0">
                        {<RenderOrgInviteAcceptedBlock message={option.message} type={option.type} accepted_val={option.accepted} id={option.id} read_status={option.read_status} />}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
            <div className="card alert-back-box mt-3"></div>
            </>
          );
    
        }
    
    }
    
    return (

        <Modal show={props.alerts_modal_show} onHide={() => props.set_alerts_modal_show(false)}>
        
            <Modal.Header>
                <Modal.Title className="font-manrope-bold">Alerts</Modal.Title>
                <img width={18} onClick={() => props.set_alerts_modal_show(false)} className="pointer" src={crossX}/>
            </Modal.Header>

            <Modal.Body className="font-manrope-regular">
            <div onScroll={props.handle_message_scroll} className="col-md-12 scroll-alerts-listing">
                {<RenderInvitationMessages/>}
            </div>          
            </Modal.Body>

        </Modal>

    );
    
}

export default Alert;
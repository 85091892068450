import { put, call, takeLatest } from "redux-saga/effects";
import { PROJECT_EQUIPMENTS_REQUEST } from "../actionTypes/type";
import { actionSuccess, actionError } from "../actions/projectTypeAction";
import { getApiCall } from "../../service/GetApiCall";
import { PROJECT_EQUIPMENTS_URL } from "../../service/apiUrl";

export function* projectEquipmentsSaga(action) {
  try {
    const response = yield call(getApiCall, PROJECT_EQUIPMENTS_URL, action.params);
    yield put(actionSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionError(error));
    action.onError();
  }
}

export function* watchProjectEquipmentsSaga() {
  yield takeLatest(PROJECT_EQUIPMENTS_REQUEST, projectEquipmentsSaga);
}

/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import {
  leftBack,
  crossX, 
  addNewPhoto
} from "../assets/images";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useParams } from "react-router-dom";
import { serviceGetRequest } from "../redux/actions/serviceGetAction";

const PROFILE_TYPE_GROUP = 'g';
const PROFILE_TYPE_PERSON = 'p';

const Service = (props) => {

  //Get project id
  const { id } = useParams();
  const serviceId = parseInt(id);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showNew, setShowNew] = useState(true);
  const [searchOrgName, setSearchOrgName] = useState("");
  const [projectDetails, setProjectDetails] = useState({});
  const [projectUsers, setProjectUsers] = useState([]);
  const [userListing, setUserListing] = useState([]);
  const [modalOrgLoader, setModalOrgLoader] = useState(false);
  const [contributorAmt, setContributorAmt] = useState('');
  const [contributorAmtErrorMsg, setContributorAmtErrorMsg] = useState('');
  const [contributorTalentSkillErrorMsg, setContributorTalentSkillErrorMsg] = useState('');
  const [contributorMaterialErrorMsg, setContributorMaterialErrorMsg] = useState('');
  const [currUserContributedAmnt, setCurrUserContributedAmnt] = useState(0);
  const [isEditable, setIsEditable] = useState(false);
  const [isEditAuthentic, setIsEditAuthentic] = useState(false);
  const [requirement, setRequirement] = useState('');
  const [requirements, setRequirements] = useState([]);
  const [consideration, setConsideration] = useState('');
  const [considerations, setConsiderations] = useState([]);
  const [projectDesc, setProjectDesc] = useState('');
  const [projectRequirements, setProjectRequirements] = useState([]);
  const [projectConsiderations, setProjectConsiderations] = useState([]);
  const [projectPassion, setProjectPassion] = useState('');
  const [projectImpact, setProjectImpact] = useState('');
  const [projectBenefit, setProjectBenefit] = useState('');
  const [projectAwareness, setProjectAwareness] = useState('');
  const [projectOrganizer, setProjectOrganizer] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [projectOtherSkill, setProjectOtherSkill] = useState('');
  const [projectOtherSkillList, setProjectOtherSkillList] = useState([]);
  const [projectSupplies, setProjectSupplies] = useState([]);
  const [projectOtherSupplies, setProjectOtherSupplies] = useState('');
  const [projectOtherSuppliesList, setProjectOtherSuppliesList] = useState([]);
  const [projectOtherEquipment, setProjectOtherEquipment] = useState('');
  const [projectOtherEquipmentList, setProjectOtherEquipmentList] = useState([]);  
  const [projectEquipments, setProjectEquipments] = useState([]);
  const [projectHelp, setProjectHelp] = useState([]);
  const [projectBudget, setProjectBudget] = useState(null);
  const [projectResourcesCount, setProjectResourcesCount] = useState(0);
  const [projectCompensation, setProjectCompensation] = useState(0);
  //save the image that used to be crop
  const [image, setImage] = useState(null);
  const [srcImg, setSrcImg] = useState(null);
  //save the resulted image
  const [croppedResult, setCroppedResult] = useState(null);
  const [crop, setCrop] = useState({aspect: 1, unit: '%', x: 25, y: 0, width: 50, height: 100});
  const [result, setResult] = useState(null);
  const [imageFeatured, setImageFeatured] = useState(0);
  const [projectImages, setProjectImages] = useState([]);
  const [role, setRole] = useState(null);
  const [talentSkill, setTalentSkill] = useState(null);
  const [materialSupplies, setMaterialSupplies] = useState(null);
  const [materialEquipments, setMaterialEquipments] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [contributorPayModalShow, setContributorPayModalShow] = useState(false);
  const [contributorPayAmt, setContributorPayAmt] = useState('');
  const [contributorPayAcc, setContributorPayAcc] = useState(null);
  const [showInviteOrgModal, setShowInviteOrgModal] = useState(false);
  const [inviteOrgUser, setInviteOrgUser] = useState({});
  const [invitedOrgUids, setInvitedOrgUids] = useState([]);
  const [isStripeValid, setIsStripeValid] = useState(false);
  const [selectGroupModal, setSelectGroupModal] = useState(false);
  const [userGroupId, setUserGroupId] = useState(undefined);  
  const [serviceDetails, setServiceDetails] = useState({});

  const UserData = useSelector(
    (state: RootStateOrAny) => state.loginReducer.data
  );

  const uid = UserData.id;


  useEffect( () => {

    dispatch( serviceGetRequest({showSuccessToast: false, id: serviceId}, onServiceGetSuccess, onError) );

  },[]);


  const onServiceGetSuccess = resp => {
    setServiceDetails(resp);
  }
  
  useEffect( () => {

    //Calculate total amount (funds) contributed by the current user for the given project
    const contributedAmntTotal = projectUsers.filter( u => {
      return u.id == uid && u.amount > 0
    } ).reduce( (acc, u) => {
      return acc + Number( parseFloat(u.amount) );
    }, 0);

    setCurrUserContributedAmnt(contributedAmntTotal);

  },[projectUsers]);


  const validateContributorAmt = (val) => {
    if( isNaN(Number(val)) == false ){
      setContributorAmt(val);
    }
  }

  function onProjectDetailsSuccess(resp){
    
    setProjectDetails(resp);
    setProjectDesc(resp.description);
    setProjectPassion(resp.passion);
    setProjectImpact(resp.impact);
    setProjectBenefit(resp.benefit);
    setProjectAwareness(resp.awareness);
    setProjectOrganizer(resp.partnership_organization);
    setProjectRequirements(resp.requirements);
    setProjectConsiderations(resp.considerations);
    setProjectImages(resp.images);
    setProjectBudget(resp.budget);
    setProjectResourcesCount( Number(resp.resources_count) );
    setProjectCompensation( Number(resp.resource_contribution) * Number(resp.resources_count) );

    const supplies = [], equipments = [], help = [];

    resp.requirements.forEach(function(val){
      help.push({val: val.id, label: val.name, is_other: val.is_other});
    });
    setProjectHelp(help);

    resp.supplies.forEach(function(val){
      supplies.push({val: val.id, supply_id: val.id, label: val.name, is_other: val.is_other});
    });
    setProjectSupplies(supplies);

    resp.equipments.forEach(function(val){
      equipments.push({val: val.id, equipment_id: val.id, label: val.name, is_other: val.is_other});
    });
    setProjectEquipments(equipments);

    setLanguages(resp.languages);

  }

  function onProjectUsersSuccess(resp){
    
    setProjectUsers(resp);
    //If current user is creator or contributor or organizer or sponsor. If yes, then enabled
    //the EDIT option and INVITE button
    const ifUserAuthentic = resp.reduce(function(initial, val){
      return initial || (val.id == uid);
    }, false);
    setIsEditAuthentic(ifUserAuthentic);

  }

  function onError(){
    console.log("Api error");
  }


  const RenderProjectUser = () => {

    const uids = new Array();

    return(
      <>
      {
        projectUsers.filter(function(user){

          if(uids.includes(user.id) == false){
            uids.push(user.id);
            return true;
          }

        }).map( (option, i) => {

          const linkTo = option.profile_type == PROFILE_TYPE_PERSON ? `/profile/${option.id}` : `/group/${option.id}`;
          return (
            <>
              <Link className="font-manrope-bold" to={linkTo}><img src={option.profile_pic} className={"img-fluid current-image-width mt-2" + (i > 0 ? ' ml-5' : '') } alt="user profile" /></Link>
              <h6 className="ml-3 mt-3 project-team-user"><Link to={linkTo}>{option.firstname} {option.lastname}</Link><br></br>
                <span className="text-grey font-creatore project-team-designation font-manrope-medium">{option.role}</span>
              </h6> 
            </>
          );

        })
      }
      </>
    );

  }


  const RenderContributors =  () => {

    const projectContributors = projectUsers.filter( (option) => option.role == 'Contributor' );

    if(projectContributors.length == 0){

        return(
          <>
          {
            <p className="text-center font-manrope-regular">No contributors</p>
          }
          </>
        );

    }else{

        return(
        <>
        {
          projectContributors.map( (option, i) => (
            <>
            <div className={"border" + (i == 0 ? "mt-4" : "mt-3")}></div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-8">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-4 p-0">
                        <div className="height">
                          <Link to={"/profile/" + option.id}><img src={option.profile_pic} className="img-fluid current-image-width mt-2" alt="contributor image"/></Link>
                        </div>
                      </div>
                      <div className="col-md-8 p-0">
                        <h6 className="ml-2 mt-2 font-manrope-bold"><Link to={"/profile/" + option.id}>{option.firstname} {option.lastname}</Link></h6>
                        <p className="ml-2 edit-skills-current font-manrope-regular">Talent/skills</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 p-0">
                  <p className={"edit-skills-current font-manrope-extra-bold mb-0" + (isEditAuthentic && option.stripe.account.id != null ? ' mt-2' : ' mt-4')}>23 Other Projects</p>
                  {isEditAuthentic && option.stripe.account.id != null ? <p className="edit-skills-current pointer font-manrope-regular" onClick={e => openContributorPayModal(option.stripe.account.id)}><u>Pay</u></p> : <></>}
                </div>
              </div>
            </div>
            </>
          ))
        }
        </>
        );

    }

  }


  const openContributorPayModal = account_id => {

    if(account_id){
      setContributorPayAcc(account_id);
      setContributorPayModalShow(true);
    }

  }


  const closeContributorPayModal = () => {
    setContributorPayAcc(null);
    setContributorPayModalShow(false);
    setContributorPayAmt('');
  }


  function RenderOrganizerListing(){

    if(userListing.length > 0){

        return(
          <>
            { 
              userListing.map( (option, i) => (
                <OrganizerListing key={i} organizer={option}/>
              ))
            } 
          </>
        );
    
    }else{

        return(
          <p className="text-center">No record found</p>
        );

    }
 
  }

  const OrganizerListing = (param) => {

    const organizerUsr = param.organizer;
    const key = param.key;

    return(
      <>
        <div key={key} className="col-md-3 text-center organizer-block">

            <div className="padding-0 col-md-12">
              {
                organizerUsr.type == 'u' ? <img className="col-md-6 padding-0 organizer-profile-pic" src={organizerUsr.profile_pic} /> : <i className="fa fa-group created-group-icon"></i>
              }
            </div>

            <div className="col-md-12">
              <span className="username block semi-bold font-manrope-semibold">
                {
                  organizerUsr.type == 'u' ? organizerUsr.firstname + ' ' + organizerUsr.lastname : organizerUsr.name
                }
              </span>
              <span className="location block">{organizerUsr.city} {organizerUsr.country ? ',' + organizerUsr.country : ''}</span>
              
            </div>

        </div>
      </> 
    );

  }

  //When enter key is pressed to add a new requirement
  const addRequirement = (e) => {

    if(e.key == 'Enter' && requirement != ''){
      setRequirements([...requirements, requirement]);
      setRequirement('');
    }

  }


  //When enter key is pressed to add a new consideration
  const addConsideration = (e) => {

    const val = e.target.value.trim();
    if(e.key == 'Enter' && val != ''){
      setConsiderations([...considerations, val]);
      setConsideration('');
    }

  }




  function onProjectUpdateSuccess(resp){
    setIsEditable(false);
  }


  function handleOtherSkillPress(key, val){

    if(key == 'Enter' && val.trim()!=''){
      setProjectOtherSkillList([...projectOtherSkillList, val.trim()]);
      setProjectOtherSkill('');
    }

  }


  function removeOtherSkillList(val){

    const removedList = projectOtherSkillList.filter(function(value) {
      return value != val; 
    });
    setProjectOtherSkillList(removedList);  

  }

  function removeOtherSuppliesList(val){

    const removedList = projectOtherSuppliesList.filter(function(value) {
      return value != val; 
    });
    setProjectOtherSuppliesList(removedList); 

  }


  function removeOtherEquipmentList(val){

    const removedList = projectOtherEquipmentList.filter(function(value) {
      return value != val; 
    });
    setProjectOtherEquipmentList(removedList); 

  }


  const uploadProjectImage = e => {

    // const modal = document.getElementById('uploadPhotoModal');
    // const modalBody = modal.querySelector('.modal-body');

    const srcImg = URL.createObjectURL(e.target.files[0]);
    setSrcImg(srcImg);
    //console.log("image", srcImg);
    // const img = <ReactCrop style={{ maxWidth: "50%" }} src={srcImg} onImageLoaded={setImage} crop={crop} onChange={setCrop}/>
    // modalBody.appendChild(img);

    document.getElementById('openUploadPhotoModal').click();

  }

  const getCroppedImg = async () => {

    try {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );

        const base64Image = canvas.toDataURL("image/jpeg", 1);

        setResult(base64Image);

    } catch (e) {
        console.log("crop the image");
    }

  };



  return (
    <>
      <div className="container project-edit">
        <div className="row">
          <div className="col-md-12 mt-5">
            <h6 className="font-manrope-regular text-left">
              <Link className="font-manrope-extra-bold fs-14" to="/home">
                <img width="15" src={leftBack}></img>
                &nbsp; Back to Home
              </Link>
            </h6>
          </div>
        </div>
        {isEditAuthentic ? 
          <div className="row">
            <div className="col-md-12">
              <a href="#">
                <h6 className="text-right">
                  
                </h6>
              </a>
            </div>
          </div>
        : 
          <></>
        }
        
        <div className="row">
          <div className="col-md-12">
            <h1 className="head-name font-manrope-extra-bold">{serviceDetails.name}</h1>
          </div>
        </div>
        <div className="col-md-12">
          <div className="row font-manrope-bold fs-15 color-text-gray">
            {
              (serviceDetails.show_address) && (serviceDetails.address) ? <>
                <h6 className="font-manrope-regular fs-15"><u>{serviceDetails.address}</u></h6>
                <div className="dot-project ml-2 mt-2"></div>              
              </> : 
              <>
                <h6 className="font-manrope-regular fs-15"><u>{serviceDetails.region}</u></h6>
                <div className="dot-project ml-2 mt-2"></div>
              </>
            }
            <h6 className="ml-2 font-manrope-regular fs-15">{serviceDetails.event_start_date}</h6>
            <div className="dot-project ml-2 mt-2"></div>
            <h6 className="ml-2 font-manrope-regular fs-15">{serviceDetails.category}</h6>
            <div className="dot-project ml-2 mt-2"></div>
            <h6 className="ml-2 font-manrope-regular fs-15">{serviceDetails.focus}</h6>
          </div>
        </div>
        <div className="row mt-3">
          <ul className="project-images-scroll font-manrope-regular">
            {isEditable ? 
            <li>
              <input id="file-input" accept="image/*" type="file" className="hide" onChange={(e) => uploadProjectImage(e)}/>
              <label for="file-input">
                <img src={addNewPhoto} className="img-fluid project-edit-image drop-shadow pointer" alt="project image"/>
              </label>  
            </li>
            :
            <></>
            } 
          </ul>

        </div>

        {/* <div className={"mt-4" + (isEditable ? " hide" : '')}>
          <div className="card project-team drop-shadow-2">
            <div className="col-md-12">
              <div className={"row mt-2" + (isEditAuthentic ? '' : " mb-2")}>
                <div className="col-md-3 col-3-width">
                  <h2 className={"text-center project-team-text font-manrope-bold" + (isEditAuthentic ? '' : " mt-3")}>Service Team</h2>
                  <div className="text-center">
                    <button type="text" data-toggle="modal" data-target="#inviteOrgModal" className={"mt-2 mb-2 invite-button drop-shadow bckg-color border-none font-manrope-regular" + (isEditAuthentic ? '': ' hide')}>
                      Invite
                    </button>
                  </div>
                </div>
                <div className="col-md-9 project-team-listing margin-left">
                  <div className={"row width-overflow" + (isEditAuthentic ? " mt-2" : '')}>
                    {<RenderProjectUser/>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        
        <div className="row mt-3">
          <div className="col-md-8">
            
            <h2 className="font-manrope-extra-bold fs-28 fw-800">Description</h2>
            {isEditable ? 
            <div className="card bg-signup-2 w-100">
              <div className="col-md-12">
                <h6 className="mb-0 location-font mt-2 font-manrope-extra-bold">Type Vision</h6>
                <div className="row">
                  <div className="form-group w-100">
                    <textarea onChange={(e) => setProjectImpact(e.target.value)} className="form-control rounded-0 passion-border font-manrope-regular fs-14" rows="4">{projectImpact}</textarea>
                  </div>
                </div>
              </div>
            </div>
            :
            <div className="form-group w-100">
              <p className="font-manrope-regular fs-14 color-black">{serviceDetails.description}</p>
            </div>
            }

          </div>
          
        </div>
    
    
        <button id="openUploadPhotoModal" className="hide" data-toggle="modal" data-target="#uploadPhotoModal"></button>

      </div>
    </>
  );
};

export default Service;

import {
  PROJECT_LISTING_REQUEST,
  PROJECT_LISTING_REQUEST_ERROR,
  PROJECT_LISTING_REQUEST_SUCCESS,
} from "../actionTypes/type";

const initialState = {
  data: null,
  isLoading: false,
};

const projectListingReducer = (state = initialState, action) => {
  let object;
  switch (action.type) {
    case PROJECT_LISTING_REQUEST:
      object = {
        ...state,
        isLoading: true,
      };
      break;

    case PROJECT_LISTING_REQUEST_ERROR:
      object = {
        ...state,
        isLoading: false
      };
      break;

    case PROJECT_LISTING_REQUEST_SUCCESS:
      object = {
        ...state,
        data: action.payload,
        isLoading: false
      };
      break;

    default:
      object = state;
      break;
  }
  return object;
}

export default projectListingReducer;

import { put, call, takeLatest } from "redux-saga/effects";
import { SERVICE_GET_REQUEST } from "../actionTypes/type";
import { actionSuccess, actionError } from "../actions/serviceGetAction";
import { postApiCall } from "../../service/PostApiCall";
import { SERVICE_GET } from "../../service/apiUrl";

export function* serviceGetSaga(action) {
  try {
    const response = yield call(postApiCall, action.params, SERVICE_GET);
    yield put(actionSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionError(error));
    action.onError();
  }
}

export function* watchServiceGetSaga() {
  yield takeLatest(SERVICE_GET_REQUEST, serviceGetSaga);
}

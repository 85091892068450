import { put, call, takeLatest } from "redux-saga/effects";
import { PROJECT_IMAGE_INSERT_REQUEST } from "../actionTypes/type";
import { actionSuccess, actionError } from "../actions/projectImageInsertAction";
import { postApiCall } from "../../service/PostApiCall";
import { PROJECT_IMAGE_INSERT_URL } from "../../service/apiUrl";

export function* projectImageInsertSaga(action) {
  try {
    const response = yield call(postApiCall, action.params, PROJECT_IMAGE_INSERT_URL);
    yield put(actionSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionError(error));
    action.onError(error);
  }
}

export function* watchProjectImageInsertSaga() {
  yield takeLatest(PROJECT_IMAGE_INSERT_REQUEST, projectImageInsertSaga);
}

const STRING = {
   APP_NAME:'arete',
   SIGN_IN:'Sign In',
   CONTRIBUTOR  :'Contributors',
   PROJECTS:"Projects",
   GROUPS:"Groups",
   SPLASH_DESC:'Social Change at your Fingertips',
   ENTER_EMAIL: "Please enter your email.",
   ENTER_VALID_EMAIL: "Please enter valid email.",
   ENTER_PASSWORD: "Please enter your password.",
   ENTER_SIX_DIGIT_PASSWORD: "Password contain at least 6 digits.",
   ENTER_CONFIRM_PASSWORD : "Please enter your confirm password",
   PASSWORD_MITCHMATCH: "Your password not match with confirm password.",
   ENTER_FIRSTNAME: "Please enter your first name.",
   ENTER_LASTNAME: "Please enter your last name.",
   ENTER_DESCRIPTION : "Please enter description",
   ENTER_SKILL : "Please select your skill or expertise"
}
export default STRING;
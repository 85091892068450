import Select from 'react-select'

const UserGroupsList = props => {

    if(props.type == 'react-select'){

        const onSelectGroup = id => {
            props.set_user_group_id(id);
        }

        if(props.groups.length > 0){
       
            return(
                props.groups.map( (val, i) => {
                    return (<GroupsListing id={val.id} onSelectGroup={onSelectGroup} active={props.selected_group == val.id} key={i} group={val}/>);
                } )
            );

        }else{
           
            return(
                <p className="font-manrope-regular text-center">No record found</p>
            );            

        }

    }

    else if(props.type == 'select-input-control'){

        const onSelectGroup = e => {
            props.set_user_group_id(e);
        }

        const options = props.groups.map( val => {
            return {value: val.id, label: val.name, image: val.image};
        } );

        return (
            <Select value={props.selected_group} className='font-manrope-semibold fs-16' id={props.id} onChange={e => onSelectGroup(e)} options={options} formatOptionLabel={option => (
                <div>
                    <img width={27} src={option.image} alt='group image'/>
                    &nbsp;&nbsp;
                    <span>{option.label}</span>
                </div>
            )} />
        );

    }

    return(
        <>
            <option value='0'>Self (Individual)</option>
            {
                props.groups.map( val => {
                    return <option value={val.id}>Group: {val.name}</option>
                })
            }         
        </>         
    )
    
}

const GroupsListing = param => {

    const groupObj = param.group;

    return(
        <div onClick={e => param.onSelectGroup(param.id)} key={param.key} className={"col-md-12 mb-1 pointer fl group-block py-2" + (param.active ? ' active': '')}>
  
            <div className="col-md-2 fl inline-block text-center p-0">
                { groupObj.image ? <img width={27} className="col-md-6 padding-0" src={groupObj.image} /> : <i className="fa fa-group group-icon fs-28"></i> }
            </div>

            <div className="col-md-10 fl text-left inline-block">
              <span className="group-name block semi-bold font-manrope-semibold fs-16">{groupObj.name}</span>
            </div>

        </div>
    );

}

export default UserGroupsList;
import {
    GROUP_PROFILE_UPDATE_REQUEST,
    GROUP_PROFILE_UPDATE_REQUEST_ERROR,
    GROUP_PROFILE_UPDATE_REQUEST_SUCCESS,
  } from "../actionTypes/type";
  
  const initialState = {
    data: null,
    isLoading: false
  };
  
  const groupProfileUpdateReducer = (state = initialState, action) => {
    let object;
    switch (action.type) {
      case GROUP_PROFILE_UPDATE_REQUEST:
        object = {
          ...state,
          isLoading: true
        };
        break;
  
      case GROUP_PROFILE_UPDATE_REQUEST_ERROR:
        object = {
          ...state,
          isLoading: false
        };
        break;
  
      case GROUP_PROFILE_UPDATE_REQUEST_SUCCESS:
        object = {
          ...state,
          data: action.payload,
          isLoading: false
        };
        break;
  
      default:
        object = state;
        break;
    }
    return object;
  }
  
  export default groupProfileUpdateReducer;
  
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import showToastNotification from "./showToast";

class Utils {
       static validateEmail = (email) => {
         var pattern = new RegExp(
           /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
         );
         return pattern.test(email);
       };
       static passCheck = (password) => {
         const upperCase = new RegExp("(?=.*[A-Z])");
         const lowerCase = new RegExp("(?=.*[a-z])");
         const number = new RegExp("(?=.*[0-9])");
         const eightChar = new RegExp("(?=.{8,})");
         if (
           eightChar.test(password) &&
           upperCase.test(password) &&
           lowerCase.test(password) &&
           number.test(password)
         )
           return true;
         else return false;
       };
       static addressCheck = (address) => {
         const data = new RegExp("([a-zA-Z0-9s,'-]*$)");
         return data.test(address);
       };
     
       static showToast(type, message, time = 2000) {
          const notificationObj = new showToastNotification();
          notificationObj.createNotification(type, message, time);
       }
     
       static isSignedIn = (data) => {
         return new Promise((resolve, reject) => {
           if (!Utils.isObjEmpty(data)) {
             resolve(true);
           } else {
             resolve(false);
           }
         });
       };
     
    
       static getTimeStamp = () => {
         return new Date().getTime() + "_image.png";
       };
      
    
     
       static isEmpty(item_to_check) {
         if (
           item_to_check === null ||
           item_to_check === undefined ||
           item_to_check === "" ||
           item_to_check === "null"
         )
           return true;
         else return false;
       }
     
       static isEmptyString(item_to_check) {
         if (item_to_check === "") return true;
         else return false;
       }
       
       static isObjEmpty(obj) {
         for (var key in obj) {
           if (obj.hasOwnProperty(key)) return false;
         }
         return true;
       }

       static matchPasswords(password, confirm_password){
          return password.trim().toLowerCase() === confirm_password.trim().toLowerCase();
       }

     }
     
     export default Utils;
import { put, call, takeLatest } from "redux-saga/effects";
import { LOGIN_REQUEST } from "../actionTypes/type";
import { actionSuccess, actionError } from "../actions/loginAction";
import { postApiCall } from "../../service/PostApiCall";
import { LOGIN_URL } from "../../service/apiUrl";

export function* loginSaga(action) {
  try {
    const response = yield call(postApiCall, action.params, LOGIN_URL);
    yield put(actionSuccess(response.data.data));
    action.onSuccess();
  } catch (error) {
    yield put(actionError(error));
    action.onError();
  }
}

export function* watchLoginSaga() {
  yield takeLatest(LOGIN_REQUEST, loginSaga);
}

/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import {
  leftBack,
  crossX
} from "../assets/images";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { getGroupProfileRequest } from "../redux/actions/groupProfileAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBell, faUserCircle, faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import Alert from "../components/Alert";
import { groupProfileUpdateRequest } from "../redux/actions/groupProfileUpdateAction";
import { groupPageDeleteRequest } from "../redux/actions/groupPageDeleteAction";
import Utility from "../utility/utility";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Modal from 'react-bootstrap/Modal'
import { actionSuccess } from "../redux/actions/loginAction";
import SweetAlert from "react-bootstrap-sweetalert";

const LIMIT_PROJECT_LISTING = 4;

const Group = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showDropdownContent, setShowDropdownContent] = useState(false);
  const [profileResult, setProfileResult] = useState(null);
  const [groupUsers, setGroupUsers] = useState([]);
  const [alertsModalShow, setAlertsModalShow] = useState(false);
  const [settings, setSettings] = useState(false);
  const [editDescription, setEditDesctiption] = useState(false);
  const [srcProfilePic, setSrcProfilePic] = useState(undefined);
  const [profilePicModalOpen, setProfilePicModalOpen] = useState(false);
  const [profilePicCrop, setProfilePicCrop] = useState({aspect: 1, unit: '%', x: 25, y: 25, width: 50, height: 50});
  const [tempProfilePic, setTempProfilePic] = useState('');
  const [profilePicCropImg, setProfilePicCropImg] = useState('');
  const [isNewProfilePic, setIsNewProfilePic] = useState(false);
  const [toggleDeleteGroupModal, setToggleDeleteGroupModal] = useState(false);
  const [isUpdating, setUpdating] = useState(false);

  const UserData = useSelector(
    (state: RootStateOrAny) => state.loginReducer.data
  );

  //Current loggedin user
  const currentUid = UserData.id;
  //User whose profile we need to get
  const { id } = useParams();
  const groupId = Number(id);

  const currentUserCreatedGroup = UserData.groups.filter(g => g.creator == currentUid && g.id == groupId);
  const isEditable = Boolean(currentUserCreatedGroup.length > 0);

  //Dispatch request for fetching user profile on page load
  useEffect(() => {
    dispatch(getGroupProfileRequest({ id: groupId, showSuccessToast: false }, onGroupProfileRenderSuccess, onError));
  }, []); 

  async function onGroupProfileRenderSuccess(resp){
    setProfileResult(resp.details);
    setGroupUsers(resp.users);
  }

  async function onError(err) {
    console.log("Error", err);
  }

  const deleteGroup = () => {

    dispatch(groupPageDeleteRequest({ id: groupId }, onGroupDeleteSuccess, onError));

  }

  const onGroupDeleteSuccess = resp => {

    navigate('/profile');

  }

  const DeleteGroupModal = () => {

    return (
      <SweetAlert title="Delete group" confirmBtnText="CONFIRM"
      confirmBtnBsStyle="success" onConfirm={() => deleteGroup()}
      onCancel={() => setToggleDeleteGroupModal(false)}
      >
        <h6>Are you sure you want to delete this group?</h6>
      </SweetAlert>   
    );

  }
  
  const RenderGroupUsers = () => {

    if(groupUsers.length == 0) return (<p className="fs-14 font-manrope-regular">No records found</p>);

    return(
        groupUsers.map( (user, i) => {
            
            const uid = Number(user.id);
            
            return(
                <div key={i} className="list-block pr-3">
                    <Link to={"/profile/" + uid}>
                        <img src={user.profile_image} className="img-fluid current-image-width mt-2" alt="user profile"/>
                    </Link>
                    <h6 className="ml-3 mt-3 project-team-user">
                        <Link className="font-manrope-bold" to={`/profile/${uid}`}>{user.firstname} {user.lastname}</Link><br/>
                        <span className="text-grey font-creatore project-team-designation font-manrope-medium">{profileResult && (user.id == profileResult.creator) ? 'Group Creator': 'User'}</span>
                    </h6>
                </div>            
            );
        
        } )
    );

  }

  const updateGroupProfile = (field, val) => {
    profileResult[field] = val;
    setProfileResult(profileResult);
    setUpdating(!isUpdating);
  }

  const validateProfileSettings = () => {

    let valid = true;
    if(profileResult == null || profileResult.description == undefined || profileResult.description.trim() == ''){
      Utility.showToast('error', 'Please enter description');
      valid = false;
    }

    if(isNewProfilePic == true && !profileResult.image){
      Utility.showToast('error', 'Please set your profile image');
      valid = false;      
    }

    return valid;

  }

  const editFinishSettings = () => {
    
    if(settings && validateProfileSettings()){
      const payLoad = {
        id: groupId,
        description: profileResult.description
      }
      if(isNewProfilePic){
        payLoad.image = profileResult.image
      }
      dispatch(groupProfileUpdateRequest(payLoad, onProfileUpdateSuccess, onError));
    }

    setSettings(!settings);

  }

  const onProfileUpdateSuccess = resp => {
    setEditDesctiption(false);
    //Update current user's groups so that its reflected
    let UserDataStore = UserData;
    UserDataStore.groups = UserDataStore.groups.map( g => {
      return (g.id == groupId) ? resp: g;
    });
    dispatch(actionSuccess(UserDataStore));
  }

  const uploadProfilePic = file_obj => {
    
    const srcImg = URL.createObjectURL(file_obj);
    setSrcProfilePic(srcImg);
    setProfilePicModalOpen(true);

  }  

  const getProfileCroppedPic = async () => {

    try {
      const canvas = document.createElement("canvas");
      const scaleX = tempProfilePic.naturalWidth / tempProfilePic.width;
      const scaleY = tempProfilePic.naturalHeight / tempProfilePic.height;
      canvas.width = profilePicCrop.width;
      canvas.height = profilePicCrop.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(
        tempProfilePic,
        profilePicCrop.x * scaleX,
        profilePicCrop.y * scaleY,
        profilePicCrop.width * scaleX,
        profilePicCrop.height * scaleY,
        0,
        0,
        profilePicCrop.width,
        profilePicCrop.height
      );

      const base64Image = canvas.toDataURL("image/jpeg", 1);

      setProfilePicCropImg(base64Image);

    } catch (e) {
        console.log("Error in cropping the image");
    }

  }

  const resetGroupProfileFields = () => {
    setProfilePicModalOpen(false);
    setProfilePicCropImg('');
    setSrcProfilePic('');
  }

  const saveProfileCroppedPic = () => {

    if(profilePicCropImg == ''){
      alert("Please select and crop image");
      return;
    }

    updateGroupProfile('image', profilePicCropImg);
    setIsNewProfilePic(true);
    resetGroupProfileFields();

  } 

  return (
    <main className="group-profile-page bckg-gray">
      <div className="profile bckg">
        <div className="">
          <div className="col-md-12 p-0">
            <div>
              <div className="container">
                <div className="row">
                  <div className="col-md-6 markplace-pc">
                    <div className="col-md-12 p-0">
                      <div className="row">
                        <div className="mt-2">
                          <img
                            src={leftBack}
                            className="img-fluid  w-mobilw ml-3 mt-2"
                          />
                        </div>
                        <div className="ml-3 mt-3">
                          <h5 className="font-manrope-extra-bold margin-home-btn fs-16">
                            <Link to="/home">Back to Home</Link>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="display-inline-block profile-upload2">
                        {settings && isEditable ? (
                          <input
                          id="profile-input"
                          className="file-input"
                          type="file"
                          accept="image/*"
                          onChange={e => uploadProfilePic(e.target.files[0])}
                          />
                        ) 
                        : 
                        ("")
                        }

                        {
                          isEditable && settings ? <div className="text-center edit-photo-item font-manrope-regular">
                            <i className="fa fa-camera"></i><br/>
                            <span className="fs-16 font-manrope-semibold">Change Photo</span>
                          </div> : <></>
                        }

                        <img
                            htmlFor="profile-input"
                            width="92"
                            src={profileResult ? profileResult.image : ''}
                            className={"img-fluid profile-image-radius"}
                            alt="..."
                        />
                      </div>   
                    &nbsp;&nbsp;&nbsp;   
                    <div className="display-inline-block">
                      <span className="fs-24 font-manrope-bold">{profileResult ? profileResult.name: ''}</span>  
                    </div>                
                    </div>
                    <br></br><br></br>
                  </div>
                  <div className="col-md-6 col-6">
                    <div className="float-right cursor-pointer toggle-settings">
                        <div className="ml-3 mt-3 text-right">
                          {isEditable ? 
                            <>
                              <span onClick={() => setToggleDeleteGroupModal(true)}><h5 className="display-inline-block fs-16 font-manrope-bold">Delete</h5></span>
                              &nbsp;
                              <span onClick={() => editFinishSettings()}><h5 className="display-inline-block fs-16 font-manrope-bold">{settings ? 'Finish Editing' : 'Edit'}</h5>&nbsp;&nbsp;</span>
                            </>
                            : 
                            <></>
                          }                          
                          <FontAwesomeIcon icon={faBars} onClick={() => setShowDropdownContent(!showDropdownContent)} />  
                          <div className={"dropdown-content dropdown-2 text-left fs-16" + (showDropdownContent == false ? ' hide': ' show')}>
                            <Link onClick={() => setAlertsModalShow(true)} className="py-2" to="#"><FontAwesomeIcon icon={faBell}/>&nbsp;&nbsp;<span className="mobile-mark font-manrope-semibold profile">Notifications</span></Link>
                            <Link className="py-2 dropdown-content-border" to="/profile"><FontAwesomeIcon icon={faUserCircle}/>&nbsp;&nbsp;<span className="mobile-mark font-manrope-semibold profile">Profile</span></Link>
                            <Link className="py-2" to="/messagegroup"><FontAwesomeIcon icon={faLocationArrow}/>&nbsp;&nbsp;<span className="mobile-mark profile font-manrope-semibold">Messages</span></Link>
                          </div>
                        </div>                       
                      </div>
                    </div>
                </div> 
                
                <div className="row mt-4">
                    <div className="col-md-2-5 padding-0 moble-padding-profile">
                        <div className="col-md-12">
                            <div className="card profile-sidebar profile-radius drop-shadow">
                                <div className="col-md-12 padding-0">
                                    <div className="col-md-12">
                                        <div className="col-md-8 fl">
                                            <h4 className="text-center mt-3 font-skill font-manrope-bold">About</h4>  
                                        </div>    
                                        {
                                          settings && isEditable ? 
                                          <div className="col-md-4 p-0 text-right float-left">
                                            <h6 className="mt-3 font-manrope-regular pointer" onClick={e => setEditDesctiption(!editDescription)}><u>{editDescription ? 'Cancel' : 'Edit'}</u></h6>
                                          </div> : 
                                          <></>
                                        } 
                                    </div>
                                </div>

                                <div className="col-md-12 font-manrope-semibold fs-14 mb-3">
                                  {
                                    editDescription ? 
                                    <textarea rows="5" className="col-md-12 font-manrope-regular" onChange={e => updateGroupProfile('description', e.target.value)} value={profileResult ? profileResult.description: ''}></textarea>
                                    : 
                                    (profileResult ? profileResult.description: '')
                                  }
                                </div>                                

                            </div>
                        </div>
                    </div>
                    <div className="col-md-9-5 marhgin-top-progress col-6">
                        <div className="col-md-12 markplace-pc card project-team drop-shadow-2">
                            <h2 className="mt-1 mb-1 fs-26 font-manrope-bold">Our Team</h2>
                            <div className="mt-2 mb-2 horizontal-listing-scroll group-team-listing margin-left">
                                {<RenderGroupUsers/>}
                            </div>
                        </div>
                    </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal className="modal-upload-profile" show={profilePicModalOpen}>
        <Modal.Header closeButton>
          <Modal.Title className="font-manrope-bold">Upload Profile</Modal.Title>
          <img width={18} onClick={e => resetGroupProfileFields()} className="pointer" src={crossX}/>
        </Modal.Header>

        <Modal.Body>
          {srcProfilePic && (
            <div>
              <ReactCrop  style={{maxWidth: "100%"}}  src={srcProfilePic}
                onImageLoaded={(img) => {
                  setTempProfilePic(img);
                  //this.makeClientCrop(profilePicCrop);
                }}
                crop={profilePicCrop}
                onChange={setProfilePicCrop}
              />
                <div>
                  <button className="crop image-button" onClick={getProfileCroppedPic}>Crop</button>
                </div>
                <br/>
              </div>
            )}
            {profilePicCropImg && (
              <div>
                <img src={profilePicCropImg} alt="profile cropped image"/>
              </div>
            )}
        </Modal.Body>

        <Modal.Footer>
          <button className="save-project-crop-image image-button" onClick={saveProfileCroppedPic}>Save</button>
        </Modal.Footer>
      </Modal>

      <Alert alerts_modal_show={alertsModalShow} set_alerts_modal_show={setAlertsModalShow} />

      {toggleDeleteGroupModal ? <DeleteGroupModal/>: <></>}

    </main>
  );
};

export default Group;

import { put, call, takeLatest } from "redux-saga/effects";
import { FORGOT_PASSWORD_REQUEST } from "../actionTypes/type";
import { actionSuccess, actionError } from "../actions/forgotPasswordAction";
import { postApiCall } from "../../service/PostApiCall";
import { FORGOT_PASSWORD_URL } from "../../service/apiUrl";

export function* forgotPasswordSaga(action) {
  try {
    const response = yield call(postApiCall, action.params, FORGOT_PASSWORD_URL);
    yield put(actionSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionError(error));
    action.onError(error);
  }
}

export function* watchForgotPaswordSaga() {
  yield takeLatest(FORGOT_PASSWORD_REQUEST, forgotPasswordSaga);
}

import { signBlue } from "../assets/images";

const SignProjectOverview = () => {

    return (
        <div className="w-25 mt-5 text-right">
            <img width={149} src={signBlue} alt="sign blue" />
        </div>
    );
    
}

export default SignProjectOverview;
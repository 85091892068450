import React, { Component } from 'react';

class RangeSlider extends React.Component {

	// constructor
  constructor(props) {
    super(props);
    this.state = {
      value: props.start || 0,
      handle_slider_change: props.handleSliderChange
    };
  }
  
  trackSlider() {
    const slider = this.refs.slider;
    //let val = (slider.value - slider.getAttribute('min')) / slider.getAttribute('max') - slider.getAttribute('min');
    const val = this.props.value[slider.value];
    this.setState({sliderBarValue: val/1000}, () => {
      this.state.handle_slider_change(val);
    });
  }

  handleChange(event, index) {
    const { value } = this.state;
    this.setState({ value: event.target.value});
    this.trackSlider();
  }

  render() {
    const { value, sliderBarValue } = this.state;
    const { label, labelNext, handleSliderChange } = this.props;

    return (
      <div className="mb4">
        <label className="f5 lh-2 regular-font">{label} <b className="fw7">US$ {this.props.value[value]}</b> {labelNext}</label>
        <input
          ref="slider"
          className="w-100 appearance-none bg-transparent range-slider-thumb-custom"
          style={{ backgroundImage: '-webkit-gradient(linear, left top, right top, '
                + 'color-stop(' + sliderBarValue + ', #4751C6), '
                + 'color-stop(' + sliderBarValue + ', #E3E3E3)'
                + ')'}}
          type="range"
          min={0}
          max={this.props.value.length - 1}
          step={1}
          value={value}
          onChange={this.handleChange.bind(this)}
        />
        <div className="regular-font">
            <label className="float-left">50</label>
            <label className="float-right">1000</label>
        </div>
      </div>
    );
  }
}

export default RangeSlider;
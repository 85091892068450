import {
  ADMIN_USER_REQUEST,
  ADMIN_USER_REQUEST_SUCCESS,
  ADMIN_USER_REQUEST_ERROR,
  ADMIN_USER_PROFILE_REQUEST,
  ADMIN_USER_PROFILE_REQUEST_SUCCESS,
  ADMIN_USER_PROFILE_REQUEST_ERROR,
  ADMIN_USER_DELETE_REQUEST,


} from "../actionTypes/type";

export const adminUserListRequest = (paramsData, onSuccess, onError) => {
  return {
    type: ADMIN_USER_REQUEST,
    params: paramsData,
    onSuccess,
    onError
  };
};

export const actionSuccess = (result) => {
  // console.log('actionSucess', result);
  return {
    type: ADMIN_USER_REQUEST_SUCCESS,
    payload: result,
    error: null,
  };
};

export const actionError = (error) => {
  return {
    type: ADMIN_USER_REQUEST_ERROR,
    error: error,
    payload: null,
  };
};

export const adminUserProfileRequest = (paramsData, onSuccess, onError) => {
  return {
    type: ADMIN_USER_PROFILE_REQUEST,
    params: paramsData,
    onSuccess,
    onError
  };
};

export const actionProfileSuccess = (result) => {
  // console.log('actionSucess', result);
  return {
    type: ADMIN_USER_PROFILE_REQUEST_SUCCESS,
    payload: result,
    error: null,
  };
};

export const actionProfileError = (error) => {
  return {
    type: ADMIN_USER_PROFILE_REQUEST_ERROR,
    error: error,
    payload: null,
  };
};

export const adminUserDeleteRequest = (paramsData, onSuccess, onError) => {
  return {
    type: ADMIN_USER_DELETE_REQUEST,
    params: paramsData,
    onSuccess,
    onError
  };
};





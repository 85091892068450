import {
  ADMIN_REPORTED_LIST_REQUEST,
  ADMIN_REPORTED_LIST_REQUEST_SUCCESS,
  ADMIN_REPORTED_LIST_REQUEST_ERROR,
} from "../actionTypes/type";

export const adminReportedListRequest = (paramsData, onSuccess, onError) => {
  return {
    type: ADMIN_REPORTED_LIST_REQUEST,
    params: paramsData,
    onSuccess,
    onError
  };
};

export const actionSuccess = (result) => {
  // console.log('actionSucess', result);
  return {
    type: ADMIN_REPORTED_LIST_REQUEST_SUCCESS,
    payload: result,
    error: null,
  };
};

export const actionError = (error) => {
  return {
    type: ADMIN_REPORTED_LIST_REQUEST_ERROR,
    error: error,
    payload: null,
  };
};



export const ADMIN_LOGIN_REQUEST = "ADMIN_LOGIN_REQUEST";
export const ADMIN_LOGIN_REQUEST_SUCCESS = "ADMIN_LOGIN_REQUEST_SUCCESS";
export const ADMIN_LOGIN_REQUEST_ERROR = "ADMIN_LOGIN_REQUEST_ERROR";
export const ADMIN_LOGOUT_REQUEST = "ADMIN_LOGOUT_REQUEST";
export const ADMIN_PROJECTS_REQUEST = "ADMIN_PROJECTS_REQUEST";
export const ADMIN_PROJECTS_REQUEST_SUCCESS = "ADMIN_PROJECTS_REQUEST_SUCCESS";
export const ADMIN_PROJECTS_REQUEST_ERROR = "ADMIN_PROJECTS_REQUEST_ERROR";
export const ADMIN_PROJECT_VIEW_REQUEST = "ADMIN_PROJECT_VIEW_REQUEST";
export const ADMIN_PROJECT_VIEW_REQUEST_SUCCESS = "ADMIN_PROJECT_VIEW_REQUEST_SUCCESS";
export const ADMIN_PROJECT_VIEW_REQUEST_ERROR = "ADMIN_PROJECT_VIEW_REQUEST_ERROR";
export const ADMIN_PROJECT_DELETE_REQUEST = "ADMIN_PROJECT_DELETE_REQUEST";
export const ADMIN_PROJECT_DELETE_REQUEST_SUCCESS = "ADMIN_PROJECT_DELETE_REQUEST_SUCCESS";
export const ADMIN_PROJECT_DELETE_REQUEST_ERROR = "ADMIN_PROJECT_DELETE_REQUEST_ERROR";

export const ADMIN_USER_REQUEST = "ADMIN_USER_REQUEST";
export const ADMIN_USER_REQUEST_SUCCESS = "ADMIN_USER_REQUEST_SUCCESS";
export const ADMIN_USER_REQUEST_ERROR = "ADMIN_USER_REQUEST_ERROR";
export const ADMIN_USER_PROFILE_REQUEST = "ADMIN_USER_PROFILE_REQUEST";
export const ADMIN_USER_PROFILE_REQUEST_SUCCESS = "ADMIN_USER_PROFILE_REQUEST_SUCCESS";
export const ADMIN_USER_PROFILE_REQUEST_ERROR = "ADMIN_USER_PROFILE_REQUEST_ERROR";
export const ADMIN_USER_DELETE_REQUEST = "ADMIN_USER_DELETE_REQUEST";
export const ADMIN_USER_DELETE_REQUEST_SUCCESS = "ADMIN_USER_DELETE_REQUEST_SUCCESS";
export const ADMIN_USER_DELETE_REQUEST_ERROR = "ADMIN_USER_DELETE_REQUEST_ERROR";


export const ADMIN_REPORTED_LIST_REQUEST = "ADMIN_REPORTED_LIST_REQUEST";
export const ADMIN_REPORTED_LIST_REQUEST_SUCCESS = "ADMIN_REPORTED_LIST_REQUEST_SUCCESS";
export const ADMIN_REPORTED_LIST_REQUEST_ERROR = "ADMIN_REPORTED_LIST_REQUEST_ERROR";

export const ADMIN_DISREGARD_USER_REPORT_REQUEST = "ADMIN_DISREGARD_USER_REPORT_REQUEST";
export const ADMIN_DISREGARD_USER_REPORT_REQUEST_SUCCESS = "ADMIN_DISREGARD_USER_REPORT_REQUEST_SUCCESS";
export const ADMIN_DISREGARD_USER_REPORT_REQUEST_ERROR = "ADMIN_DISREGARD_USER_REPORT_REQUEST_ERROR";


export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_REQUEST_SUCCESS = "LOGIN_REQUEST_SUCCESS";
export const LOGIN_REQUEST_ERROR = "LOGIN_REQUEST_ERROR";
export const LOGOUT_USER_REQUEST = "LOGOUT_USER_REQUEST";
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_REQUEST_SUCCESS = "REGISTER_REQUEST_SUCCESS";
export const REGISTER_REQUEST_ERROR = "REGISTER_REQUEST_ERROR";
export const PAYMENT_CONTRIBUTION_REQUEST = "PAYMENT_CONTRIBUTION_REQUEST";
export const PAYMENT_CONTRIBUTION_REQUEST_SUCCESS = "PAYMENT_CONTRIBUTION_REQUEST_SUCCESS";
export const PAYMENT_CONTRIBUTION_REQUEST_ERROR = "PAYMENT_CONTRIBUTION_REQUEST_ERROR";
export const SKILL_REQUEST = "SKILL_REQUEST";
export const SKILL_REQUEST_SUCCESS = "SKILL_REQUEST_SUCCESS";
export const SKILL_REQUEST_ERROR = "SKILL_REQUEST_ERROR";
export const PROJECT_CREATE_REQUEST = "PROJECT_CREATE_REQUEST";
export const PROJECT_CREATE_REQUEST_SUCCESS = "PROJECT_CREATE_REQUEST_SUCCESS";
export const PROJECT_CREATE_REQUEST_ERROR = "PROJECT_CREATE_REQUEST_ERROR";
export const PROJECT_UPDATE_REQUEST = "PROJECT_UPDATE_REQUEST";
export const PROJECT_UPDATE_REQUEST_SUCCESS = "PROJECT_UPDATE_REQUEST_SUCCESS";
export const PROJECT_UPDATE_REQUEST_ERROR = "PROJECT_UPDATE_REQUEST_ERROR";
export const PROJECT_FOCUS_REQUEST = "PROJECT_FOCUS_REQUEST";
export const PROJECT_FOCUS_REQUEST_SUCCESS = "PROJECT_FOCUS_REQUEST_SUCCESS";
export const PROJECT_FOCUS_REQUEST_ERROR = "PROJECT_FOCUS_REQUEST_ERROR";
export const PROJECT_TYPE_REQUEST = "PROJECT_TYPE_REQUEST";
export const PROJECT_TYPE_REQUEST_SUCCESS = "PROJECT_TYPE_REQUEST_SUCCESS";
export const PROJECT_TYPE_REQUEST_ERROR = "PROJECT_TYPE_REQUEST_ERROR";
export const PROJECT_LISTING_REQUEST = "PROJECT_LISTING_REQUEST";
export const PROJECT_LISTING_REQUEST_SUCCESS = "PROJECT_LISTING_REQUEST_SUCCESS";
export const PROJECT_LISTING_REQUEST_ERROR = "PROJECT_LISTING_REQUEST_ERROR";
export const AUTHORIZED_PROJECTS_LISTING_REQUEST = "AUTHORIZED_PROJECTS_LISTING_REQUEST";
export const AUTHORIZED_PROJECTS_LISTING_REQUEST_SUCCESS = "AUTHORIZED_PROJECTS_LISTING_REQUEST_SUCCESS";
export const AUTHORIZED_PROJECTS_LISTING_REQUEST_ERROR = "AUTHORIZED_PROJECTS_LISTING_REQUEST_ERROR";
export const PROJECT_SUPPLIES_REQUEST = "PROJECT_SUPPLIES_REQUEST";
export const PROJECT_SUPPLIES_REQUEST_SUCCESS = "PROJECT_SUPPLIES_REQUEST_SUCCESS";
export const PROJECT_SUPPLIES_REQUEST_ERROR = "PROJECT_SUPPLIES_REQUEST_ERROR";
export const PROJECT_EQUIPMENTS_REQUEST = "PROJECT_EQUIPMENTS_REQUEST";
export const PROJECT_EQUIPMENTS_REQUEST_SUCCESS = "PROJECT_EQUIPMENTS_REQUEST_SUCCESS";
export const PROJECT_EQUIPMENTS_REQUEST_ERROR = "PROJECT_EQUIPMENTS_REQUEST_ERROR";
export const PROJECT_HELP_REQUEST = "PROJECT_HELP_REQUEST";
export const PROJECT_HELP_REQUEST_SUCCESS = "PROJECT_HELP_REQUEST_SUCCESS";
export const PROJECT_HELP_REQUEST_ERROR = "PROJECT_HELP_REQUEST_ERROR";
export const USER_LISTING_REQUEST = "USER_LISTING_REQUEST";
export const USER_LISTING_REQUEST_SUCCESS = "USER_LISTING_REQUEST_SUCCESS";
export const USER_LISTING_REQUEST_ERROR = "USER_LISTING_REQUEST_ERROR";
export const USER_PROFILE_REQUEST = "USER_PROFILE_REQUEST";
export const USER_PROFILE_REQUEST_SUCCESS = "USER_PROFILE_REQUEST_SUCCESS";
export const USER_PROFILE_REQUEST_ERROR = "USER_PROFILE_REQUEST_ERROR";
export const DASHBOARD_REQUEST = "DASHBOARD_REQUEST";
export const DASHBOARD_REQUEST_SUCCESS = "DASHBOARD_REQUEST_SUCCESS";
export const DASHBOARD_REQUEST_ERROR = "DASHBOARD_REQUEST_ERROR";
export const CLEAR_DASHBOARD_DATA = "CLEAR_DASHBOARD_DATA";
export const LANGUAGE_REQUEST = "LANGUAGE_REQUEST";
export const LANGUAGE_REQUEST_SUCCESS = "LANGUAGE_REQUEST_SUCCESS";
export const LANGUAGE_REQUEST_ERROR = "LANGUAGE_REQUEST_ERROR";
export const COUNTRIES_REQUEST = "COUNTRIES_REQUEST";
export const COUNTRIES_REQUEST_SUCCESS = "COUNTRIES_REQUEST_SUCCESS";
export const COUNTRIES_REQUEST_ERROR = "COUNTRIES_REQUEST_ERROR";
export const PROJECT_AUTHORIZED_USERS_LISTING_REQUEST = "PROJECT_AUTHORIZED_USERS_LISTING_REQUEST";
export const PROJECT_AUTHORIZED_USERS_LISTING_REQUEST_SUCCESS = "PROJECT_AUTHORIZED_USERS_LISTING_REQUEST_SUCCESS";
export const PROJECT_AUTHORIZED_USERS_LISTING_REQUEST_ERROR = "PROJECT_AUTHORIZED_USERS_LISTING_REQUEST_ERROR";
export const PROJECT_DETAILS_REQUEST = "PROJECT_DETAILS_REQUEST";
export const PROJECT_DETAILS_REQUEST_SUCCESS = "PROJECT_DETAILS_REQUEST_SUCCESS";
export const PROJECT_DETAILS_REQUEST_ERROR = "PROJECT_DETAILS_REQUEST_ERROR";
export const PROJECT_INVITE_REQUEST = "PROJECT_INVITE_REQUEST";
export const PROJECT_INVITE_REQUEST_SUCCESS = "PROJECT_INVITE_REQUEST_SUCCESS";
export const PROJECT_INVITE_REQUEST_ERROR = "PROJECT_INVITE_REQUEST_ERROR";
export const PROJECT_INVITATION_MESSAGE_REQUEST = "PROJECT_INVITATION_MESSAGE_REQUEST";
export const PROJECT_INVITATION_MESSAGE_REQUEST_SUCCESS = "PROJECT_INVITATION_MESSAGE_REQUEST_SUCCESS";
export const PROJECT_INVITATION_MESSAGE_REQUEST_ERROR = "PROJECT_INVITATION_MESSAGE_REQUEST_ERROR";
export const PROJECT_INVITATION_RESPOND_REQUEST = "PROJECT_INVITATION_RESPOND_REQUEST";
export const PROJECT_INVITATION_RESPOND_REQUEST_SUCCESS = "PROJECT_INVITATION_RESPOND_REQUEST_SUCCESS";
export const PROJECT_INVITATION_RESPOND_REQUEST_ERROR = "PROJECT_INVITATION_RESPOND_REQUEST_ERROR";
export const PROJECT_INVITATION_READ_REQUEST = "PROJECT_INVITATION_READ_REQUEST";
export const PROJECT_INVITATION_READ_REQUEST_SUCCESS = "PROJECT_INVITATION_READ_REQUEST_SUCCESS";
export const PROJECT_INVITATION_READ_REQUEST_ERROR = "PROJECT_INVITATION_READ_REQUEST_ERROR";
export const PROJECT_IMAGE_INSERT_REQUEST = "PROJECT_IMAGE_INSERT_REQUEST";
export const PROJECT_IMAGE_INSERT_REQUEST_SUCCESS = "PROJECT_IMAGE_INSERT_REQUEST_SUCCESS";
export const PROJECT_IMAGE_INSERT_REQUEST_ERROR = "PROJECT_IMAGE_INSERT_REQUEST_ERROR";
export const PROJECT_IMAGE_DELETE_REQUEST = "PROJECT_IMAGE_DELETE_REQUEST";
export const PROJECT_IMAGE_DELETE_REQUEST_SUCCESS = "PROJECT_IMAGE_DELETE_REQUEST_SUCCESS";
export const PROJECT_IMAGE_DELETE_REQUEST_ERROR = "PROJECT_IMAGE_DELETE_REQUEST_ERROR";
export const PROJECT_CONTRIBUTION_SKILL_REQUEST = "PROJECT_CONTRIBUTION_SKILL_REQUEST";
export const PROJECT_CONTRIBUTION_SKILL_REQUEST_SUCCESS = "PROJECT_CONTRIBUTION_SKILL_REQUEST_SUCCESS";
export const PROJECT_CONTRIBUTION_SKILL_REQUEST_ERROR = "PROJECT_CONTRIBUTION_SKILL_REQUEST_ERROR";
export const PROJECT_CONTRIBUTION_MATERIAL_REQUEST = "PROJECT_CONTRIBUTION_MATERIAL_REQUEST";
export const PROJECT_CONTRIBUTION_MATERIAL_REQUEST_SUCCESS = "PROJECT_CONTRIBUTION_MATERIAL_REQUEST_SUCCESS";
export const PROJECT_CONTRIBUTION_MATERIAL_REQUEST_ERROR = "PROJECT_CONTRIBUTION_MATERIAL_REQUEST_ERROR";
export const GROUP_CREATE_REQUEST = "GROUP_CREATE_REQUEST";
export const GROUP_CREATE_REQUEST_SUCCESS = "GROUP_CREATE_REQUEST_SUCCESS";
export const GROUP_CREATE_REQUEST_ERROR = "GROUP_CREATE_REQUEST_ERROR";
export const STRIPE_DETAILS_GET_REQUEST = "STRIPE_DETAILS_GET_REQUEST";
export const STRIPE_DETAILS_GET_REQUEST_SUCCESS = "STRIPE_DETAILS_GET_REQUEST_SUCCESS";
export const STRIPE_DETAILS_GET_REQUEST_ERROR = "STRIPE_DETAILS_GET_REQUEST_ERROR";
export const STRIPE_DETAILS_SAVE_REQUEST = "STRIPE_DETAILS_SAVE_REQUEST";
export const STRIPE_DETAILS_SAVE_REQUEST_SUCCESS = "STRIPE_DETAILS_SAVE_REQUEST_SUCCESS";
export const STRIPE_DETAILS_SAVE_REQUEST_ERROR = "STRIPE_DETAILS_SAVE_REQUEST_ERROR";
export const USER_GROUPS_REQUEST = "USER_GROUPS_REQUEST";
export const USER_GROUPS_REQUEST_SUCCESS = "USER_GROUPS_REQUEST_SUCCESS";
export const USER_GROUPS_REQUEST_ERROR = "USER_GROUPS_REQUEST_ERROR";
export const GROUP_USERS_REQUEST = "GROUP_USERS_REQUEST";
export const GROUP_USERS_REQUEST_SUCCESS = "GROUP_USERS_REQUEST_SUCCESS";
export const GROUP_USERS_REQUEST_ERROR = "GROUP_USERS_REQUEST_ERROR";
export const USER_IMAGES_REQUEST = "USER_IMAGES_REQUEST";
export const USER_IMAGES_REQUEST_SUCCESS = "USER_IMAGES_REQUEST_SUCCESS";
export const USER_IMAGES_REQUEST_ERROR = "USER_IMAGES_REQUEST_ERROR";
export const USER_UPDATE_PROFILE_REQUEST = "USER_UPDATE_PROFILE_REQUEST";
export const USER_UPDATE_PROFILE_REQUEST_SUCCESS = "USER_UPDATE_PROFILE_REQUEST_SUCCESS";
export const USER_UPDATE_PROFILE_REQUEST_ERROR = "USER_UPDATE_PROFILE_REQUEST_ERROR";
export const STRIPE_BANK_ACCOUNT_REQUEST = "STRIPE_BANK_ACCOUNT_REQUEST";
export const STRIPE_BANK_ACCOUNT_REQUEST_SUCCESS = "STRIPE_BANK_ACCOUNT_REQUEST_SUCCESS";
export const STRIPE_BANK_ACCOUNT_REQUEST_ERROR = "STRIPE_BANK_ACCOUNT_REQUEST_ERROR";
export const USER_AUTHORIZED_PROJECTS_REQUEST = "USER_AUTHORIZED_PROJECTS_REQUEST";
export const USER_AUTHORIZED_PROJECTS_REQUEST_SUCCESS = "USER_AUTHORIZED_PROJECTS_REQUEST_SUCCESS";
export const USER_AUTHORIZED_PROJECTS_REQUEST_ERROR = "USER_AUTHORIZED_PROJECTS_REQUEST_ERROR";
export const STRIPE_ACCOUNT_PAYMENT_TRANSFER_REQUEST = "STRIPE_ACCOUNT_PAYMENT_TRANSFER_REQUEST";
export const STRIPE_ACCOUNT_PAYMENT_TRANSFER_REQUEST_SUCCESS = "STRIPE_ACCOUNT_PAYMENT_TRANSFER_REQUEST_SUCCESS";
export const STRIPE_ACCOUNT_PAYMENT_TRANSFER_REQUEST_ERROR = "STRIPE_ACCOUNT_PAYMENT_TRANSFER_REQUEST_ERROR";
export const ADMIN_CONTRIBUTORS_LISTING_REQUEST = "ADMIN_CONTRIBUTORS_LISTING_REQUEST";
export const ADMIN_CONTRIBUTORS_LISTING_REQUEST_SUCCESS = "ADMIN_CONTRIBUTORS_LISTING_REQUEST_SUCCESS";
export const ADMIN_CONTRIBUTORS_LISTING_REQUEST_ERROR = "ADMIN_CONTRIBUTORS_LISTING_REQUEST_ERROR";
export const USER_REPORT_REQUEST = "USER_REPORT_REQUEST";
export const USER_REPORT_REQUEST_SUCCESS = "USER_REPORT_REQUEST_SUCCESS";
export const USER_REPORT_REQUEST_ERROR = "USER_REPORT_REQUEST_ERROR";
export const USER_REPORT_GET_REQUEST = "USER_REPORT_GET_REQUEST";
export const USER_REPORT_GET_REQUEST_SUCCESS = "USER_REPORT_GET_REQUEST_SUCCESS";
export const USER_REPORT_GET_REQUEST_ERROR = "USER_REPORT_GET_REQUEST_ERROR";
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_REQUEST_SUCCESS = "FORGOT_PASSWORD_REQUEST_SUCCESS";
export const FORGOT_PASSWORD_REQUEST_ERROR = "FORGOT_PASSWORD_REQUEST_ERROR";
export const VALIDATE_FORGOT_PASSWORD_REQUEST = "VALIDATE_FORGOT_PASSWORD_REQUEST";
export const VALIDATE_FORGOT_PASSWORD_REQUEST_SUCCESS = "VALIDATE_FORGOT_PASSWORD_REQUEST_SUCCESS";
export const VALIDATE_FORGOT_PASSWORD_REQUEST_ERROR = "VALIDATE_FORGOT_PASSWORD_REQUEST_ERROR";
export const USER_PASSWORD_UPDATE_REQUEST = "USER_PASSWORD_UPDATE_REQUEST";
export const USER_PASSWORD_UPDATE_REQUEST_SUCCESS = "USER_PASSWORD_UPDATE_REQUEST_SUCCESS";
export const USER_PASSWORD_UPDATE_REQUEST_ERROR = "USER_PASSWORD_UPDATE_REQUEST_ERROR";
export const GROUP_PAGE_CREATE_REQUEST = "GROUP_PAGE_CREATE_REQUEST";
export const GROUP_PAGE_CREATE_REQUEST_SUCCESS = "GROUP_PAGE_CREATE_REQUEST_SUCCESS";
export const GROUP_PAGE_CREATE_REQUEST_ERROR = "GROUP_PAGE_CREATE_REQUEST_ERROR";

export const GROUP_PROFILE_REQUEST = "GROUP_PROFILE_REQUEST";
export const GROUP_PROFILE_REQUEST_SUCCESS = "GROUP_PROFILE_REQUEST_SUCCESS";
export const GROUP_PROFILE_REQUEST_ERROR = "GROUP_PROFILE_REQUEST_ERROR";

export const GROUP_INVITATION_REQUEST = "GROUP_INVITATION_REQUEST";
export const GROUP_INVITATION_REQUEST_SUCCESS = "GROUP_INVITATION_REQUEST_SUCCESS";
export const GROUP_INVITATION_REQUEST_ERROR = "GROUP_INVITATION_REQUEST_ERROR";

export const GROUP_PROFILE_UPDATE_REQUEST = "GROUP_PROFILE_UPDATE_REQUEST";
export const GROUP_PROFILE_UPDATE_REQUEST_SUCCESS = "GROUP_PROFILE_UPDATE_REQUEST_SUCCESS";
export const GROUP_PROFILE_UPDATE_REQUEST_ERROR = "GROUP_PROFILE_UPDATE_REQUEST_ERROR";

export const SERVICE_CREATE_REQUEST = "SERVICE_CREATE_REQUEST";
export const SERVICE_CREATE_REQUEST_SUCCESS = "SERVICE_CREATE_REQUEST_SUCCESS";
export const SERVICE_CREATE_REQUEST_ERROR = "SERVICE_CREATE_REQUEST_ERROR";

export const SERVICE_GET_REQUEST = "SERVICE_GET_REQUEST";
export const SERVICE_GET_REQUEST_SUCCESS = "SERVICE_GET_REQUEST_SUCCESS";
export const SERVICE_GET_REQUEST_ERROR = "SERVICE_GET_REQUEST_ERROR";

export const SERVICE_GET_TYPES_FOCUS_REQUEST = "SERVICE_GET_TYPES_FOCUS_REQUEST";
export const SERVICE_GET_TYPES_FOCUS_REQUEST_SUCCESS = "SERVICE_GET_TYPES_FOCUS_REQUEST_SUCCESS";
export const SERVICE_GET_TYPES_FOCUS_REQUEST_ERROR = "SERVICE_GET_TYPES_FOCUS_REQUEST_ERROR";

export const SERVICE_INVITATION_SEND_REQUEST = "SERVICE_INVITATION_SEND_REQUEST";
export const SERVICE_INVITATION_SEND_REQUEST_SUCCESS = "SERVICE_INVITATION_SEND_REQUEST_SUCCESS";
export const SERVICE_INVITATION_SEND_REQUEST_ERROR = "SERVICE_INVITATION_SEND_REQUEST_ERROR";

export const SERVICE_INVITATION_RESPOND_REQUEST = "SERVICE_INVITATION_RESPOND_REQUEST";
export const SERVICE_INVITATION_RESPOND_REQUEST_SUCCESS = "SERVICE_INVITATION_RESPOND_REQUEST_SUCCESS";
export const SERVICE_INVITATION_RESPOND_REQUEST_ERROR = "SERVICE_INVITATION_RESPOND_REQUEST_ERROR";

export const CONTRIBUTORS_LIST_REQUEST = "CONTRIBUTORS_LIST_REQUEST";
export const CONTRIBUTORS_LIST_REQUEST_SUCCESS = "CONTRIBUTORS_LIST_REQUEST_SUCCESS";
export const CONTRIBUTORS_LIST_REQUEST_ERROR = "CONTRIBUTORS_LIST_REQUEST_ERROR";

export const SOCIAL_ENTREPRENEURS_LIST_REQUEST = "SOCIAL_ENTREPRENEURS_LIST_REQUEST";
export const SOCIAL_ENTREPRENEURS_LIST_REQUEST_SUCCESS = "SOCIAL_ENTREPRENEURS_LIST_REQUEST_SUCCESS";
export const SOCIAL_ENTREPRENEURS_LIST_REQUEST_ERROR = "SOCIAL_ENTREPRENEURS_LIST_REQUEST_ERROR";

export const GROUP_PAGE_DELETE_REQUEST = "GROUP_PAGE_DELETE_REQUEST";
export const GROUP_PAGE_DELETE_REQUEST_SUCCESS = "GROUP_PAGE_DELETE_REQUEST_SUCCESS";
export const GROUP_PAGE_DELETE_REQUEST_ERROR = "GROUP_PAGE_DELETE_REQUEST_ERROR";
import { put, call, takeLatest } from "redux-saga/effects";
import { USER_REPORT_GET_REQUEST } from "../actionTypes/type";
import { actionSuccess, actionError } from "../actions/userReportGetAction";
import { postApiCall } from "../../service/PostApiCall";
import { USER_REPORT_GET_URL } from "../../service/apiUrl";

export function* userReportGetSaga(action) {
  try {
    const response = yield call(postApiCall, action.params, USER_REPORT_GET_URL);
    yield put(actionSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionError(error));
    action.onError();
  }
}

export function* watchUserReportGetSaga() {
  yield takeLatest(USER_REPORT_GET_REQUEST, userReportGetSaga);
}

import {
    GROUP_INVITATION_REQUEST,
    GROUP_INVITATION_REQUEST_SUCCESS,
    GROUP_INVITATION_REQUEST_ERROR,
  } from "../actionTypes/type";
  const initialState = {
    data: null,
  };
  
  const groupInvitationReducer = (state = initialState, action) => {
    let object;
  
    switch (action.type) {
      case GROUP_INVITATION_REQUEST:
        object = {
          ...state,
        };
        break;
  
      case GROUP_INVITATION_REQUEST_ERROR:
        object = {
          ...state,
        };
        break;
  
      case GROUP_INVITATION_REQUEST_SUCCESS:
        object = {
          ...state,
          // data: action.payload,
        };
        break;
  
      default:
        object = state;
        break;
    }
    return object;
  };
  
  export default groupInvitationReducer;
  
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from "react";
import {
  loaderSmall,
  logoBlackV1
} from "../assets/images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getProjectListingRequest } from "../redux/actions/projectListingAction";
import AlertModal from "../components/Alert";
import MenuToggle from "../components/MenuToggle";
import { projectInvitationMessageRequest } from "../redux/actions/projectInvitationMessageAction";
import { projectInvitationRespondRequest } from "../redux/actions/projectInvitationRespondAction";
import { projectInvitationReadRequest } from "../redux/actions/projectInvitationReadAction";
import ServiceInvitation from "../components/ServiceInvitation";
import { serviceGetRequest } from "../redux/actions/serviceGetAction";
import { serviceInvitationSendRequest } from "../redux/actions/serviceInvitationSendAction";
import Modal from 'react-bootstrap/Modal';
import Login from "../components/Login";
import ConditionalLink from "../components/ConditionalLink";
import { getSocialEntrepreneursListingRequest } from "../redux/actions/socialEntrepreneursListingAction";

const LIMIT_INVITATION_MESSAGE_LISTING = 8;
const LIMIT_PROJECTS_CONTAINER_DIV = 4;
const LIMIT_PROJECT_LISTING = 10;

const TOGGLE_LISTING_IDX_CONTRIBUTORS = 'c';
const TOGGLE_LISTING_IDX_PROJECTS = 'p';
const TOGGLE_LISTING_IDX_SERVICES = 's';

const CONTRIBUTOR_PROFILE_TYPE_USER = 'u';
const CONTRIBUTOR_PROFILE_TYPE_GROUP = 'g';

const Home = () => {

  let contributorsScrollTimeout = null, contributorsSearchTimeout = null;
  let projectScrollTimeout = null, projectSearchTimeout = null;
  //let offsetServicesListing = 0, servicesScrollTimeout = null, servicesSearchTimeout = null;
  let alertScrollTimeout = null, alertListingOffset = 0, offsetInvitationMessageListing = 0;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [projectListing, setProjectListing] = useState([]);
  const projectListingTemp = useRef([]);
  const contributorsListingTemp = useRef([]);
  const [searchProject, setSearchProject] = useState("");
  const searchProjectNameTemp = useRef('');
  const [isListingLoading, setIsListingLoading] = useState(false);
  const [isUpdating, setUpdating] = useState(false);  
  const [toggleStartChangeButton, setToggleStartChangeButton] = useState(false);
  const [alertsModalShow, setAlertsModalShow] = useState(false);
  const [showDropdownContent, setShowDropdownContent] = useState(false);
  const [isListingFinished, setIsListingFinished] = useState(false);
  const [newNotification, setNewNotification] = useState(0);  
  const [invitationMessages, setInvitationMessages] = useState([]);  
  const [serviceInvitationModal, setServiceInvitationModal] = useState(false);
  const [serviceInvitationData, setServiceInvitationData] = useState({});
  const [signInModal, setSignInModal] = useState(false);
  const [contributorsListing, setContributorsListing] = useState([]);
  const [toggleListingIdx, setToggleListingIdx] = useState(TOGGLE_LISTING_IDX_CONTRIBUTORS);
  const toggleListingIdxTemp = useRef(TOGGLE_LISTING_IDX_CONTRIBUTORS);
  let offsetContributorsListing = useRef(0);
  let offsetProjectListing = useRef(0);

  const UserData = useSelector(
    (state: RootStateOrAny) => state.loginReducer.data
  );

  const uid = UserData ? UserData.id : null;

  const navigateContributionRequest = (id, amount) => {
  
    if(amount == null || isNaN(amount)){

      const payLoad = {
        service_id: id,
        uid: uid
      }
      dispatch(serviceInvitationSendRequest(payLoad, onInvitationSendSuccess, onError));

    }else{

      navigate('/service-contributor', {state: {service_id: id, contribution_amount: parseFloat(amount)}});

    }
  
  }

  const onInvitationSendSuccess = resp => {
    onServiceModalHide();
    navigate('/thankyou/service-offer')
  }

  useEffect( () => {

    const projectListingParam = {
      offset: 0,
      limit: LIMIT_PROJECT_LISTING,
      showSuccessToast: false
    };    
    dispatch(getProjectListingRequest(projectListingParam, onProjectListingRequestSuccess, onError));

    if(UserData){
      dispatch( projectInvitationMessageRequest({ uid_receiver: uid, offset: offsetInvitationMessageListing, limit: LIMIT_INVITATION_MESSAGE_LISTING, showSuccessToast: false  }, onInvitationMessageSuccess, onError) )
    }

    dispatch( getSocialEntrepreneursListingRequest(projectListingParam, onSocialEntrepreneursListingSuccess, onError) );
  
  }, []);

  useEffect( () => {
    searchProjectNameTemp.current = searchProject;
    setIsListingFinished(false);
    offsetContributorsListing.current = 0;
    console.log("offset 0", offsetContributorsListing.current);
  }, [searchProject]);

  useEffect( () => {
    setSearchProject('');
    setIsListingFinished(false);
    searchProjectNameTemp.current = '';
  }, [toggleListingIdx]);

  const onSocialEntrepreneursListingSuccess = resp => {

    
    if(resp.length == 0){
      setIsListingFinished(true);
    }else{
      const newArr = contributorsListingTemp.current.concat(resp);
      contributorsListingTemp.current = newArr;
      setContributorsListing(newArr);
    }

    setIsListingLoading(false);
  
  }

  const searchMarketplace = val => {

    if(toggleListingIdx == TOGGLE_LISTING_IDX_CONTRIBUTORS)
      searchContributors(val);
    else
      searchProjects(val);

  }


  const searchContributors = val => {

    setIsListingLoading(true);
    setSearchProject(val);
    clearTimeout(contributorsSearchTimeout);
    contributorsSearchTimeout = setTimeout(() => {
      const params = {
        offset: 0,
        limit: LIMIT_PROJECT_LISTING,
        search_name: val,
        showSuccessToast: false
      }
      dispatch(getSocialEntrepreneursListingRequest(params, onContributorSearchSuccess, onError));
    }, 2500);

  }

  const searchProjects = val => {

    setIsListingLoading(true);
    setSearchProject(val);
    clearTimeout(projectSearchTimeout);
    projectSearchTimeout = setTimeout(() => {
      const projectListingParam = {
        offset: 0,
        limit: LIMIT_PROJECT_LISTING,
        creator_name: val,
        project_name: val,
        showSuccessToast: false
      }
      dispatch(getProjectListingRequest(projectListingParam, onProjectSearchSuccess, onError));
    }, 1500);

  }

  function onError(err){
    setIsListingLoading(false);
    console.log(err);
  }

  const onInvitationMessageSuccess = resp => {
    const arr = invitationMessages.concat(resp.alerts);
    setInvitationMessages(arr);
    setNewNotification(resp.total_unread_alerts);
  }

  const readAlertMsg = (e, id) => {

      dispatch( projectInvitationReadRequest({ id: id}, async function(){
        const updatedInvitationMessages = invitationMessages.filter(msg => {
          if(msg.id == id) return false;
          return true;
        });
        setInvitationMessages(updatedInvitationMessages);
        setNewNotification(newNotification-1);
      }, onError) )

  }

  /*
    * Function to respond to project request
    *
    * @params:
    *   {int} 'id': Record id
    *   {int} 'if_accepted' : 1 => accepted, 0 => denied
    */
  const RespondProjectRequest = (id, if_accepted, this_obj, type) => {
        
      dispatch( projectInvitationRespondRequest({'id': id, 'accepted': if_accepted}, function(){

        const updatedInvitationMessages = invitationMessages.filter(val => {
          return !Boolean(val.id == id);
        });
        setInvitationMessages(updatedInvitationMessages);
        setNewNotification(newNotification-1);

      }, onError) );

  }

  const handleAlertMessagesScroll = e => {

    const listingDiv = e.target;

    if (listingDiv.offsetHeight + listingDiv.scrollTop >= listingDiv.scrollHeight-1) {  
      
      clearTimeout(alertScrollTimeout);
      alertScrollTimeout = setTimeout(function(){
        alertListingOffset += LIMIT_INVITATION_MESSAGE_LISTING;
        dispatch(projectInvitationMessageRequest({uid_receiver: uid, offset: alertListingOffset, limit: LIMIT_INVITATION_MESSAGE_LISTING, showSuccessToast:false}, onInvitationMessageSuccess, onError));
        //setModalOrgLoader(false);
      }, 2500);

    }

  }  

  const onContributorSearchSuccess = resp => {
    setIsListingLoading(false);
    contributorsListingTemp.current = resp;
    setContributorsListing(resp);    
  }

  const onProjectSearchSuccess = resp => {
    setIsListingLoading(false);
    projectListingTemp.current = resp;
    setProjectListing(resp);
  }

  function onProjectListingRequestSuccess(resp){
    setIsListingLoading(false);
    if(resp.length == 0){
      setIsListingFinished(true);
    }else{
      const newArr = projectListingTemp.current.concat(resp);
      projectListingTemp.current = newArr;
      setProjectListing(newArr);
    }
  }

  /**
   * Rearragne project divs so that max 4 projects are shown under a single div (in a line) 
   * 
   * @returns:
   *  An array of (sub)arrays containing project items - max 4 items in each subarray
  */
  const getRearrangedProjectList = project_list => {

    let itemArr = [];
    const projectsListDiv = project_list.reduce((arr, item, i) => {

      itemArr.push(item);

      if(itemArr.length == LIMIT_PROJECTS_CONTAINER_DIV || (i == project_list.length-1) ){
        arr.push(itemArr)
        itemArr = [];
      }

      return arr;

    }, []);

    return projectsListDiv;

  }


  const openServiceInvitationModal = service_id => {

    if(uid == null){
      setSignInModal(true);
    }else{
      dispatch( serviceGetRequest({showSuccessToast: false, id: service_id}, onServiceGetSuccess, onError) );
    }

  }


  const onServiceGetSuccess = resp => {
    setServiceInvitationData(resp);
    setServiceInvitationModal(true);
    setUpdating(!isUpdating);
  }


  const onServiceModalHide = () => {
    setServiceInvitationData({});
    setServiceInvitationModal(false);
  }

  const RenderNoRecordListing = () => {
    return (<p className="text-center">No result found</p>);
  }

  const RenderListing = () => {

    if(toggleListingIdx == TOGGLE_LISTING_IDX_CONTRIBUTORS){

      if(contributorsListing.length == 0) return <RenderNoRecordListing/>

      return contributorsListing.map( (item, i) => {

        const id = Number(item.id);
        const LinkTo = `/profile/${id}`;

        return (
          <>
            <ConditionalLink set_state_call={setSignInModal} condition={uid != null} key={i} to={LinkTo}>
              <div className="col-md-12 py-4 pointer center-mobile-mark project-images-container contributors home-marketplace">
                <img src={item.image} className="img-fluid mark-img-radius drop-shadow" alt="Project image" />
                <span className="project-image-sticker fs-14 font-manrope-bold">User</span>
                <div>
                  <h6 className="mt-2 mobile-mark-text font-manrope-bold">{item.name}</h6>
                  <p className="m-0 mobile-mark-text font-manrope-bold text-dark fs-16 color-text-gray">Location: {item.location}</p>
                  <p className="mobile-mark-text font-manrope-bold text-dark fs-16 color-text-gray">{item.count} {item.count == 1 ? 'project' : 'projects'}</p>
                  <div><button className="profile-contribute-project border-none bckg-color px-3 py-2 font-manrope-regular fs-16">View</button></div>
                </div>
              </div>
            </ConditionalLink>
          </>
        )

      } );

    }else{

      return (<RenderProjectsListing/>);

    }

  }

  const RenderProjectsListing = () => {
    
    const projectsListDiv = getRearrangedProjectList(projectListing.filter(item => item.type == toggleListingIdx));

    if(projectsListDiv.length == 0) return (<p className="text-center">No result found</p>);

    return projectsListDiv.map( (arr_item, i) => {

      return (
        <div key={i} className="outer">
          {
            arr_item.map( (item, j) => {

              const itemType = item.type;
              const LinkTo = itemType == 'p' ? `/project/${item.id}`: `/service/${item.id}`;
              const buttonText = itemType == 'p' ? 'Contribute': 'Invite to Work';
              
              return (
                itemType == 'p' ? 
                <>
                  <ConditionalLink set_state_call={setSignInModal} condition={uid != null} key={j} to={LinkTo}>
                    <div className="col-md-12 py-4 pointer center-mobile-mark project-images-container home-marketplace">
                      <img src={item.image} className="img-fluid mark-img-radius drop-shadow" alt="Project image" />
                      <span className="project-image-sticker fs-14 font-manrope-bold">{item.type == 'p' ? 'Project': 'Service'}</span>
                      <div>
                        <h6 className="mt-2 mobile-mark-text font-manrope-bold">{item.name}</h6>
                        <p className="mobile-mark-text font-manrope-bold text-dark fs-16 color-text-gray">{item.address} {item.region}&nbsp;</p>
                        <div className="py-1"><p className="m-0"><span className="boundary-word">{item.category}</span></p></div>
                        <div className="fs-14 font-manrope-medium py-2"><span className="boundary-word">${item.money ? item.money: 0}</span>&nbsp;<span className="boundary-word">{item.resources_count} people</span></div>
                        <div><button className="profile-contribute-project border-none bckg-color px-3 py-2 font-manrope-regular fs-16">{buttonText}</button></div>
                      </div>
                    </div>
                  </ConditionalLink>
                </> 
                : 
                <>
                  <div key={j} style={{'display': 'inline'}}>
                    <div className="col-md-12 py-4 center-mobile-mark project-images-container home-marketplace">
                      <img src={item.image} className="img-fluid mark-img-radius drop-shadow" alt="Project image" />
                      <span className="project-image-sticker fs-14 font-manrope-bold">{item.type == 'p' ? 'Project': 'Service'}</span>
                      <div>
                        <h6 className="mt-2 mobile-mark-text font-manrope-bold">{item.name}</h6>
                        <p className="mobile-mark-text font-manrope-bold text-dark fs-16 color-text-gray">{item.address} {item.region}&nbsp;</p>
                        <div className="py-1"><p className="m-0"><span className="boundary-word">{item.category}</span></p></div>
                        <div className="fs-14 font-manrope-medium py-2"><span className="boundary-word">${item.money ? item.money: 0}</span>&nbsp;</div>
                        <div><button onClick={() => openServiceInvitationModal(item.id)} className="pointer profile-contribute-project border-none bckg-color px-3 py-2 font-manrope-regular fs-16">{buttonText}</button></div>
                      </div>
                    </div>
                  </div>
                </>
              );

            })
          }
        </div>
      );

    } );

  }

  const executeListingScroll = () => {

    if(toggleListingIdxTemp.current == TOGGLE_LISTING_IDX_CONTRIBUTORS)
      executeContributorListingScroll();
    else
      executeProjectListingScroll();

  }

  const executeContributorListingScroll = () => {

    setIsListingLoading(true);
    clearTimeout(contributorsScrollTimeout);
    
    contributorsScrollTimeout = setTimeout(function(){

      offsetContributorsListing.current += LIMIT_PROJECT_LISTING;
console.log("offset 1:", offsetContributorsListing.current);
      const params = {
        offset: offsetContributorsListing.current,
        limit: LIMIT_PROJECT_LISTING
      };
      if(searchProjectNameTemp.current){
        params.search_name = searchProjectNameTemp.current;
      }
      params.showSuccessToast = false;

      dispatch(getSocialEntrepreneursListingRequest(params, onSocialEntrepreneursListingSuccess, onError));
    
    }, 1500);

  }

  const executeProjectListingScroll = () => {

    setIsListingLoading(true);
    clearTimeout(projectScrollTimeout);
    projectScrollTimeout = setTimeout(function(){

      offsetProjectListing.current += LIMIT_PROJECT_LISTING;

      const projectListingParam = {
        offset: offsetProjectListing.current,
        limit: LIMIT_PROJECT_LISTING
      };
      if(searchProjectNameTemp.current){
        projectListingParam.creator_name = searchProjectNameTemp.current;
        projectListingParam.project_name = searchProjectNameTemp.current;
      }
      projectListingParam.showSuccessToast = false;
      dispatch(getProjectListingRequest(projectListingParam, onProjectListingRequestSuccess, onError));
    
    }, 1500);

  }


  useEffect( () => {
    
    window.addEventListener('scroll', function(){
      if (isListingFinished == false && window.innerHeight + window.scrollY >= document.body.offsetHeight-1) {  
        executeListingScroll();
      }
    });

  }, []);

  const updateToggleListingIdx = idx => {
    setToggleListingIdx(idx);
    toggleListingIdxTemp.current = idx;
  }

  return (
    <div>
      <div className="overlay" >
        <div className="header_container home-page">
          <nav className="header_navbar navbar  navbar-expand-lg navbar-light ">
            <div className="logo VisiaPro-BoldItalic">
              <Link to="/blank">
                <img src={logoBlackV1} width="75" />
              </Link>
            </div>
            {
              uid != null ?
              <MenuToggle notification_count={newNotification} set_show_dropdown_content={setShowDropdownContent} show_dropdown_content={showDropdownContent} set_alerts_modal_show={setAlertsModalShow} />
              :
              <></>
            }
          </nav>
        </div>
      </div>
      <div className="signup markplace-pc home-marketplace">
        <div className="container">
          <div className="row">
            <div className="col-md-2 mobile-markplace">
              <nav><label>&nbsp;</label></nav>
              
                <button onClick={() => setToggleStartChangeButton(!toggleStartChangeButton)} type="button" className="drop-shadow button-markplace font-manrope-bold bckg-color">
                  <FontAwesomeIcon icon={faPlus}/>&nbsp;&nbsp;Start Change
                </button>
                {
                  toggleStartChangeButton ? 
                  <nav className="text-center start-change-menu">
                    <div><ConditionalLink set_state_call={setSignInModal} condition={uid != null} className="font-manrope-semibold fs-16" to="/project_overview">Create a Project</ConditionalLink></div>
                    <div><ConditionalLink set_state_call={setSignInModal} condition={uid != null} className="font-manrope-semibold fs-16" to="/service-create">Offer a Service</ConditionalLink></div>
                  </nav>                 
                  :
                  <></>
                }
              
            </div>
            <div className="col-md-1 mobile-markplace"></div>

            <div className="col-md-5">
              <div className="form-group m-auto mt-5 mark-place">
                <label htmlFor="usr" className="font-manrope-medium search">
                  Search
                </label>
                <input
                  type="text" value={searchProject} onChange={(e) => searchMarketplace(e.target.value)}
                  className="form-control box-marketplace drop-shadow font-manrope-medium"
                  placeholder="Find a Project, Service or Creator"
                />
                {
                  isListingLoading ? 
                  <div className="text-center"><img src={loaderSmall}/></div>
                  : <></>
                }
              </div>        
            </div>
            <div className="col-md-1 mobile-markplace"></div>
            {/* <div className="col-md-1 p-0 mobile-mark"></div>*/}
            <div className="col-md-3 mobile-markplace dropdown">
              <nav><label>&nbsp;</label></nav>
              {
                uid != null ? 
                  <Link to="/profile"><img src={UserData.profile_pic}
                    className="img-fluid font-back text-dark float-right size-width"
                    alt="..."
                  /></Link>
                :
                <></>
              }
            </div>
          </div>
        </div>

        <div className="col-md-12 mt-5 px-4 mark-img">
          <button className={"fs-14 font-manrope-bold profile-toggle-gallery border-none px-3 py-2" + (toggleListingIdx == TOGGLE_LISTING_IDX_CONTRIBUTORS ? ' active': '')} onClick={() => updateToggleListingIdx(TOGGLE_LISTING_IDX_CONTRIBUTORS)}>Social Entrepreneurs</button>&nbsp;&nbsp;&nbsp;
          <button className={"fs-14 font-manrope-bold profile-toggle-gallery border-none px-3 py-2" + (toggleListingIdx == TOGGLE_LISTING_IDX_PROJECTS ? ' active': '')} onClick={() => updateToggleListingIdx(TOGGLE_LISTING_IDX_PROJECTS)} >Projects</button>&nbsp;&nbsp;&nbsp;
          <button className={"fs-14 font-manrope-bold profile-toggle-gallery border-none px-3 py-2" + (toggleListingIdx == TOGGLE_LISTING_IDX_SERVICES ? ' active': '')} onClick={() => updateToggleListingIdx(TOGGLE_LISTING_IDX_SERVICES)}>Services</button>
        </div>
        
        <div className="col-md-12 mt-2">
          {<RenderListing/>}
          {
            (isListingLoading && isListingFinished == false) ?
            <div className="text-center"><img src={loaderSmall} alt="loader"/></div>
            :<></>
          }
        </div>

      </div>      

      <AlertModal invitation_messages={invitationMessages} alerts_modal_show={alertsModalShow} set_alerts_modal_show={setAlertsModalShow} handle_message_scroll={handleAlertMessagesScroll}  read_alert_msg={readAlertMsg} respond_project_request={RespondProjectRequest} />

      <ServiceInvitation submit_invitation_handler={navigateContributionRequest} data={serviceInvitationData} modal_show={serviceInvitationModal} set_modal_show={() => setServiceInvitationModal(!serviceInvitationModal)} set_modal_hide={() => onServiceModalHide()} />

      <Modal className="sign-in" show={signInModal} onHide={e => setSignInModal(false)}>
      
        <Modal.Header closeButton>
          <Modal.Title className="font-manrope-bold fs-32">Login</Modal.Title>
        </Modal.Header>

        <Modal.Body className="px-5">
          <p className="font-manrope-extra-bold color-black fs-20 m-0">To use this feature, you must be logged in</p>
          <p className="font-manrope-extra-bold color-black fs-20 m-0">New to airatae? <Link className="highlight-link" to="/register">Sign Up Now</Link></p>
          <Login toggle_sign_modal={setSignInModal} />
        </Modal.Body>

      </Modal>

    </div>
  );
};

export default Home;

import { put, call, takeLatest } from "redux-saga/effects";
import { GROUP_PROFILE_UPDATE_REQUEST } from "../actionTypes/type";
import { actionSuccess, actionError } from "../actions/groupProfileUpdateAction";
import { postApiCall } from "../../service/PostApiCall";
import { GROUP_PAGE_PROFILE_UPDATE } from "../../service/apiUrl";

export function* groupProfileUpdateSaga(action) {
  try {
    const response = yield call(postApiCall, action.params, GROUP_PAGE_PROFILE_UPDATE);
    yield put(actionSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionError(error));
    action.onError();
  }
}

export function* watchGroupProfileUpdateSaga() {
  yield takeLatest(GROUP_PROFILE_UPDATE_REQUEST, groupProfileUpdateSaga);
}

import { put, call, takeLatest } from "redux-saga/effects";
import { SERVICE_CREATE_REQUEST } from "../actionTypes/type";
import { actionSuccess, actionError } from "../actions/serviceCreateAction";
import { postApiCall } from "../../service/PostApiCall";
import { SERVICE_CREATE } from "../../service/apiUrl";

export function* serviceCreateSaga(action) {
  try {
    const response = yield call(postApiCall, action.params, SERVICE_CREATE);
    yield put(actionSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionError(error));
    action.onError(error);
  }
}

export function* watchServiceCreateSaga() {
  yield takeLatest(SERVICE_CREATE_REQUEST, serviceCreateSaga);
}

import React, {useRef} from "react";
import Modal from 'react-bootstrap/Modal';
import { crossX } from "../assets/images";

const ServiceInvitation = props => {

    const data = props.data;

    return (
        
        <Modal className="service-info" show={props.modal_show} onHide={() => props.set_modal_hide()}>
        
            <Modal.Header>
                <img title='close' width={18} onClick={() => props.set_modal_hide()} className="pointer" src={crossX}/>
            </Modal.Header>

            <Modal.Body>

                <div className="col-md-12">

                    <div className="row"><h1 className="head-name font-manrope-bold">{data.name}</h1></div>    
                    <div className="row">
                        <h6 className="font-manrope-regular fs-15"><u>{data.region}</u></h6>    
                        <div className="dot-project ml-2 mt-2"></div>
                        <h6 className="ml-2 font-manrope-regular fs-15"></h6>
                        <div className="dot-project ml-2 mt-2"></div>
                        <h6 className="ml-2 font-manrope-regular fs-15">{data.category}</h6>
                    </div>    
                    <div className="row mt-3"><h2 className="head-name fs-28 font-manrope-bold">Provided By</h2></div>
                    <div className="font-manrope-bold fs-15">
                        <h6 className="row font-manrope-regular fs-15">
                            {
                                data.group_id == null ?
                                <><img width={20} src={data.user ? data.user.profile_pic_url: ''} alt='user profile' />&nbsp;{data.user ? data.user.firstname + ' ' + data.user.lastname : ''}</>
                                :
                                <><img width={20} src={data.group ? data.group.image: ''} alt='group profile' />&nbsp;{data.group ? data.group.name: ''}</>
                            }
                        </h6>
                    </div>
                    <div className="row mt-3"><h2 className="head-name fs-28 font-manrope-bold">About our Services</h2></div>
                    <div className="row font-manrope-regular fs-15">
                        <span>{data.description}</span>
                    </div>
                    <div className="row mt-3"><h2 className="head-name fs-28 font-manrope-bold">Areas of Expertise</h2></div>
                    <div className="row">
                        <ul className="fs-15 font-manrope-regular">
                            <li>Comfortable with Animals</li>
                            <li>Nursing/Healthcare Professional</li>
                            <li>Experience in Caregiving</li>
                        </ul>
                    </div>
                    <div className="row">
                        <span className="money-green">${data.rate?? 0} Compensation</span>
                    </div>
                    <div className="text-center">
                        <button onClick={ () => props.submit_invitation_handler(data.id, data.hourly_rate)} className="fs-15 bckg-color invite-work border-none px-3 py-2 font-manrope-regular">Submit Request</button>
                    </div>

                </div>
                
            </Modal.Body>

        </Modal>

    );

}

export default ServiceInvitation;
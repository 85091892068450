import {
  PROJECT_EQUIPMENTS_REQUEST,
  PROJECT_EQUIPMENTS_REQUEST_SUCCESS,
  PROJECT_EQUIPMENTS_REQUEST_ERROR
} from "../actionTypes/type";

export const getProjectEquipmentsRequest = (paramsData, onSuccess, onError) => {
  return {
    type: PROJECT_EQUIPMENTS_REQUEST,
    params: paramsData,
    onSuccess,
    onError
  };
};

export const actionSuccess = (result) => {
  return {
    type: PROJECT_EQUIPMENTS_REQUEST_SUCCESS,
    payload: result,
    error: null
  };
};

export const actionError = (error) => {
  return {
    type: PROJECT_EQUIPMENTS_REQUEST_ERROR,
    error: error,
    payload: null
  };
};
export const API_STATUS = {
  SUCCESS: 200,
  CREATED: 201,
  ACCEPED: 202,
  ALREADY_REPORTED: 208,
  UNAUTHORIZED: 401,
  INVAILDCREDENTIALS: 1001,
  NOT_FOUND: 404,
  FORBIDDEN: 422,
  ACCESS_DENIED: 403,
};

/**
 * Api Response erros
 */
export const API_STANDRAD_ERRORS = {
  NETWORK_ERROR: "Internet Error",
};


export const ADMIN_LOGIN_URL = "/api/admin/login";
export const ADMIN_PROJECT_LISTING_URL = "/api/admin/projects";
export const ADMIN_PROJECT_DETAIL_URL = "/api/admin/project/get";
export const ADMIN_PROJECT_DELETE_URL = "/api/admin/project/delete";

export const ADMIN_USER_LISTING_URL = "/api/admin/users";
export const ADMIN_USER_PROFILE_URL = "/api/admin/user/get";
export const ADMIN_USER_DELETE_URL = "/api/admin/user/delete";

export const ADMIN_REPORT_LISTING_URL = "/api/admin/user/reports";
export const ADMIN_REPORT_VIEW_URL = "/api/admin/user/reports";
export const ADMIN_REPORT_DELETE_URL = "/api/admin/user/reports";
export const ADMIN_CONTRIBUTORS_LISTING_URL = "/api/admin/contributors";
export const ADMIN_DISREGARD_USER_REPORT_URL = "/api/admin/user-report/disregard";

export const LOGIN_URL = "/api/login";
export const REGISTER_URL = "/api/register";
export const SKILLS_URL = "/api/skills";
export const PROJECT_DETAILS_URL = "/api/project/get";
export const PROJECT_LISTING_URL = "/api/project/list";
export const PROJECT_TYPE_URL = "/api/project/categories";
export const PROJECT_FOCUS_URL = "/api/project/focus";
export const PROJECT_HELP_URL = "/api/project/help";
export const PROJECT_CREATE_URL = "/api/project/add";
export const PROJECT_UPDATE_URL = "/api/project/update";
export const PROJECT_SUPPLIES_URL = "/api/project/supplies";
export const PROJECT_EQUIPMENTS_URL = "/api/project/equipments";
export const LANGUAGE_URL = "/api/languages";
export const COUNTRIES_URL = "/api/countries";
export const USER_LISTING_URL = "/api/users";
export const USER_PROFILE_URL = "/api/user/get-profile";
export const PAYMENT_CONTRIBUTOR_URL = "/api/payment/project/contribution/fund";
export const PROJECT_CONTRIBUTION_SKILL_URL = "/project/contribution/skills";
export const PROJECT_CONTRIBUTION_MATERIAL_URL = "/project/contribution/materials";
export const AUTHORIZED_PROJECTS_LISTING_URL = "/api/user/projects/authorized";
export const PROJECT_AUTHORIZED_USERS_LISTING_URL = "/api/project/users/authorized";
export const PROJECT_INVITE_URL = "/api/notification/invite";
export const PROJECT_INVITATION_MESSAGE_URL = "/api/notification/invitations";
export const PROJECT_INVITATION_RESPOND_URL = "/api/notification/invitation/respond";
export const PROJECT_INVITATION_READ_URL = "/api/notification/invitation/read";
export const PROJECT_IMAGE_INSERT_URL = "/api/project/image/insert";
export const PROJECT_IMAGE_DELETE_URL = "/api/project/image/delete";
export const GROUP_CREATE_URL = "/api/group/create";
export const STRIPE_DETAILS_GET_URL = "/api/stripe/get";
export const STRIPE_DETAILS_SAVE_URL = "/api/stripe/save";
export const USER_GROUPS_LISTING_URL = "/api/user/groups";
export const GROUP_USERS_LISTING_URL = "/api/group/users";
export const UPLOAD_USER_IMAGES_URL = "/api/user/update-images";
export const USER_UPDATE_PROFILE_URL = "/api/user/update-profile";
export const STRIPE_BANK_ACCOUNT_SAVE_URL = "/api/stripe/bank-account/save";
export const USER_AUTHORIZED_PROJECTS_URL = "/api/user/authorized/projects"
export const STRIPE_ACCOUNT_PAYMENT_TRANSFER_URL = "/api/stripe/payout/transfer";
export const USER_REPORT_SUBMIT_URL = "/api/user/report/submit";
export const USER_REPORT_GET_URL = "/api/user/report/get"
export const FORGOT_PASSWORD_URL = "/api/user/forgot-password";
export const VALIDATE_FORGOT_PASSWORD_URL = "/api/user/validate-forgot-password-token";
export const USER_UPDATE_PASSWORD_URL = "/api/user/password";
export const GROUP_PAGE_CREATE_URL = "/api/group-page/create";
export const GROUP_PROFILE_URL = "/api/group-page/get-profile";
export const GROUP_INVITATION_URL = "/api/group-page/invite";
export const GROUP_PAGE_PROFILE_UPDATE = "/api/group-page/update";
export const GROUP_PAGE_DELETE_URL = "/api/group-page/delete";
export const SERVICE_CREATE = "/api/service/create";
export const SERVICE_GET = "/api/service/get";
export const SERVICE_GET_TYPES_FOCUS = "/api/service/get/types-focus";
export const SERVICE_INVITATION_SEND = "/api/service/invitation/send";
export const SERVICE_INVITATION_RESPOND = "/api/service/invitation/respond";
export const CONTRIBUTORS_GET = "/api/contributors";
export const SOCIAL_ENTREPRENEURS_GET = "/api/social-entrepreneurs";

export const LANDING_PAGE_URL = "https://airatae.com";
import { put, call, takeLatest } from "redux-saga/effects";
import { USER_AUTHORIZED_PROJECTS_REQUEST } from "../actionTypes/type";
import { actionSuccess, actionError } from "../actions/userAuthorizedProjectAction";
import { postApiCall } from "../../service/PostApiCall";
import { USER_AUTHORIZED_PROJECTS_URL } from "../../service/apiUrl";

export function* userAuthorizedProjectSaga(action) {
  try {
    const response = yield call(postApiCall, action.params, USER_AUTHORIZED_PROJECTS_URL);
    yield put(actionSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionError(error));
    action.onError();
  }
}

export function* watchUserAuthorizedProjectSaga() {
  yield takeLatest(USER_AUTHORIZED_PROJECTS_REQUEST, userAuthorizedProjectSaga);
}

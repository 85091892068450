import {
    CONTRIBUTORS_LIST_REQUEST,
    CONTRIBUTORS_LIST_REQUEST_ERROR,
    CONTRIBUTORS_LIST_REQUEST_SUCCESS,
  } from "../actionTypes/type";
  
  const initialState = {
    data: null,
    isLoading: false,
  };
  
  const contributorsListingReducer = (state = initialState, action) => {
    let object;
    switch (action.type) {
      case CONTRIBUTORS_LIST_REQUEST:
        object = {
          ...state,
          isLoading: true
        };
        break;
  
      case CONTRIBUTORS_LIST_REQUEST_ERROR:
        object = {
          ...state,
          isLoading: false
        };
        break;
  
      case CONTRIBUTORS_LIST_REQUEST_SUCCESS:
        object = {
          ...state,
          data: action.payload,
          isLoading: false
        };
        break;
  
      default:
        object = state;
        break;
    }
    return object;
  }
  
  export default contributorsListingReducer;
  
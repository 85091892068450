import {
    GROUP_PROFILE_REQUEST,
    GROUP_PROFILE_REQUEST_SUCCESS,
    GROUP_PROFILE_REQUEST_ERROR
  } from "../actionTypes/type";
  
  export const getGroupProfileRequest = (paramsData, onSuccess, onError) => {
    return {
      type: GROUP_PROFILE_REQUEST,
      params: paramsData,
      onSuccess,
      onError
    };
  };
  
  export const actionSuccess = (result) => {
    return {
      type: GROUP_PROFILE_REQUEST_SUCCESS,
      payload: result,
      error: null
    };
  };
  
  export const actionError = (error) => {
    return {
      type: GROUP_PROFILE_REQUEST_ERROR,
      error: error,
      payload: null
    };
  };
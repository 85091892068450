const ENVIRONMENT_TYPES = {
  dev: "dev",
  prod: "prod"
};
const ENVOIRMENT_URL = {
  dev: "https://api.airatae.com",
  prod: "https://api.airatae.com"
};
const selectedEnv = ENVIRONMENT_TYPES.prod;

export const BASE_URL = ENVOIRMENT_URL[selectedEnv];

export const STRIPE_PKEY="pk_test_51H3FX2A9yiVQX5rHOEIr0laKapRV7uhzJfk9eUoj4ghpA5K1ho5rGa6aanz3lsnH7CZTsMaASc2I7XbNfOlW3kyn00cjl1Ravc";

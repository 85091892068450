import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { login2 } from "../assets/images";
import Utility from "../utility/utility";
import APP_STRING from "../constants/strings";
import { useDispatch } from "react-redux";
import { loginRequest } from "../redux/actions/loginAction";

const Login = props => {

    const dispatch = useDispatch();

    const [email, setEmail] = useState(undefined);
    const [password, setPassword] = useState(undefined);
    const [fildtype, setFildtype] = useState('password');    
    const [isLoading, setLoading] = useState(false);
    const [passToogle, setPassToogle] = useState("fa fa-eye-slash p-1");

    const PassViewToggle = () => {
        if (fildtype === "password") {
          setPassToogle("fa fa-eye p-1");
          setFildtype("text");
        } else {
          setPassToogle("fa fa-eye-slash p-1");
          setFildtype("password");
        }
    }

    const formSubmit = (event) => {
        loggingIn(event);
    } 

    async function loggingIn(e) {
        e.preventDefault();
        if (validateLoginFields()) {
          setLoading(true);
          var payload = {
            email: email,
            password: password
          }
          dispatch(loginRequest(payload, onSuccess, onError));
        }
    }
    
    async function onSuccess() {
        setLoading(false);
        //navigate("/home");
        props.toggle_sign_modal(false);
    }
    
    async function onError(err) {
        console.log("error", err);
        setLoading(false);
    }
    
    const validateLoginFields = () => {
        var message = "";
        if (Utility.isEmpty(email)) {
          message = APP_STRING.ENTER_EMAIL;
        } else if (!Utility.validateEmail(email)) {
          message = APP_STRING.ENTER_VALID_EMAIL;
        } else if (Utility.isEmpty(password)) {
          message = APP_STRING.ENTER_PASSWORD;
        } else if (password.length < 6) {
          message = APP_STRING.ENTER_SIX_DIGIT_PASSWORD;
        }
        if (message === "") {
          return true;
        }
        Utility.showToast("error", message);
        return false;
    }

    return (
        <>
          
          <form onSubmit={formSubmit}>

            <div className="col-12 mt-4 p-0 mobile-padding">
                  <label htmlFor="email"><h5 className="my-0 font-manrope-extra-bold">Email Or Username</h5></label>
                  <input id="email" type="email" className="form-control form-control-2 font-manrope-light" value={email} onChange={(email) => setEmail(email.target.value)} placeholder="example@example.com"/>          
                  <div className="text-right"><span className="fs-14 font-manrope-extra-bold">New User? &nbsp; &nbsp; <Link to="/register"><u>Sign Up Here</u></Link></span></div>
              </div>

              <div className="col-12 mt-3 p-0 mobile-padding">
                  <label htmlFor="password"><h5 className="my-0 font-manrope-extra-bold">Password</h5></label>
                  <input id="password" type={fildtype} className="form-control form-control-2 font-manrope-light" value={password} onChange={(password) => {setPassword(password.target.value)}} placeholder="**********"/>
                  <span className="password-eye pointer" onClick={() => PassViewToggle()}><FontAwesomeIcon icon={faEye} /></span>
                  <div className="text-right"><span className="fs-14 font-manrope-extra-bold"><Link to="/forgot-password"><u>Forgot Password</u></Link></span></div>
              </div>
                  
              <div className="col-md-12 mt-5 p-0">
                  <div className="text-center">
                      <button type="submit" className="btn sf-pro signup-button"><img width={223} src={login2} alt="login" /></button>
                  </div>
              </div>

          </form>

        </>
    );

}

export default Login;
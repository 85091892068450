import { useEffect } from "react";
import {loaderBig} from "../assets/images";
import { LANDING_PAGE_URL } from "../service/apiUrl";

const Blank = () => {

    useEffect(() => {
        const timeout = setTimeout(() => {
          //Redirects to an external URL
          window.location.replace(LANDING_PAGE_URL);
        });
    
        return () => clearTimeout(timeout);
    }, []);
    
    return (<div className="blank-container text-center">
        <img src={loaderBig} alt="loader"/>
    </div>);

}

export default Blank;
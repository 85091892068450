import { put, call, takeLatest } from "redux-saga/effects";
import { ADMIN_REPORTED_LIST_REQUEST } from "../actionTypes/type";
import { actionSuccess, actionError } from "../actions/adminReportedListAction";
import { postApiCall } from "../../service/PostApiCall";
import { ADMIN_REPORT_LISTING_URL } from "../../service/apiUrl";

export function* adminReportedListSaga(action) {
  try {
    const response = yield call(postApiCall, action.params, ADMIN_REPORT_LISTING_URL);
    yield put(actionSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionError(error));
    action.onError();
  }
}

export function* watchadminReportedListSaga() {
  yield takeLatest(ADMIN_REPORTED_LIST_REQUEST, adminReportedListSaga);
}

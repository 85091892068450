import { put, call, takeLatest } from "redux-saga/effects";
import { STRIPE_BANK_ACCOUNT_REQUEST } from "../actionTypes/type";
import { actionSuccess, actionError } from "../actions/stripeSaveAction";
import { postApiCall } from "../../service/PostApiCall";
import { STRIPE_BANK_ACCOUNT_SAVE_URL } from "../../service/apiUrl";

export function* stripeBankAccountUpdateSaga(action) {
  try {
    const response = yield call(postApiCall, action.params, STRIPE_BANK_ACCOUNT_SAVE_URL);
    yield put(actionSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionError(error));
    action.onError(error);
  }
}

export function* watchStripeBankAccountUpdateSaga() {
  yield takeLatest(STRIPE_BANK_ACCOUNT_REQUEST, stripeBankAccountUpdateSaga);
}

import { put, call, takeLatest } from "redux-saga/effects";
import { SOCIAL_ENTREPRENEURS_LIST_REQUEST } from "../actionTypes/type";
import { actionSuccess, actionError } from "../actions/socialEntrepreneursListingAction";
import { postApiCall } from "../../service/PostApiCall";
import { SOCIAL_ENTREPRENEURS_GET } from "../../service/apiUrl";

export function* socialEntrepreneursListingSaga(action) {
  try {
    const response = yield call(postApiCall, action.params, SOCIAL_ENTREPRENEURS_GET);
    yield put(actionSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionError(error));
    action.onError();
  }
}

export function* watchSocialEntrepreneursListingSaga() {
  yield takeLatest(SOCIAL_ENTREPRENEURS_LIST_REQUEST, socialEntrepreneursListingSaga);
}

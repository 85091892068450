import * as firebase from 'firebase';
import firestore from 'firebase/firestore'

//const settings = {timestampsInSnapshots: true};

const config = {
    projectId: 'arete-b5922', 
    apiKey: 'AIzaSyCD6vYLtXY-sA4BGv_jVhHlzlPEANmTYBQ',
    databaseURL: 'https://arete-b5922-default-rtdb.firebaseio.com',
    storageBucket: 'gs://arete-b5922.appspot.com'
  };
firebase.initializeApp(config);

//firebase.firestore().settings(settings);

export default firebase;
import { put, call, takeLatest } from "redux-saga/effects";
import { ADMIN_PROJECTS_REQUEST, ADMIN_PROJECT_VIEW_REQUEST, ADMIN_PROJECT_DELETE_REQUEST } from "../actionTypes/type";
import { actionSuccess, actionError, actionProjectViewSuccess, actionProjectViewError } from "../actions/adminProjectListAction";
import { postApiCall } from "../../service/PostApiCall";
import { ADMIN_PROJECT_LISTING_URL, ADMIN_PROJECT_DETAIL_URL, ADMIN_PROJECT_DELETE_URL  } from "../../service/apiUrl";

export function* adminProjectListSaga(action) {
  try {
    const response = yield call(postApiCall, action.params, ADMIN_PROJECT_LISTING_URL);
    yield put(actionSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionError(error));
    action.onError();
  }
}

export function* watchadminProjectListSaga() {
  yield takeLatest(ADMIN_PROJECTS_REQUEST, adminProjectListSaga);
}

export function* adminprojectViewSaga(action) {
  try {
    const response = yield call(postApiCall, action.params, ADMIN_PROJECT_DETAIL_URL);
    yield put(actionProjectViewSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionProjectViewError(error));
    action.onError();
  }
}

export function* watchadminProjectViewSaga() {
  yield takeLatest(ADMIN_PROJECT_VIEW_REQUEST, adminprojectViewSaga);
}

export function* adminprojectDeleteSaga(action) {
  try {
    const response = yield call(postApiCall, action.params, ADMIN_PROJECT_DELETE_URL);
    yield put(actionSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionError(error));
    action.onError();
  }
}

export function* watchadminProjectDeleteSaga() {
  yield takeLatest(ADMIN_PROJECT_DELETE_REQUEST, adminprojectDeleteSaga);
}
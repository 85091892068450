import { put, call, takeLatest } from "redux-saga/effects";
import { ADMIN_LOGIN_REQUEST } from "../actionTypes/type";
import { actionSuccess, actionError } from "../actions/adminloginAction";
import { postApiCall } from "../../service/PostApiCall";
import { ADMIN_LOGIN_URL } from "../../service/apiUrl";

export function* adminloginSaga(action) {
  try {
    const response = yield call(postApiCall, action.params, ADMIN_LOGIN_URL);
    yield put(actionSuccess(response.data.data));
    action.onSuccess();
  } catch (error) {
    yield put(actionError(error));
    action.onError();
  }
}

export function* watchadminLoginSaga() {
  yield takeLatest(ADMIN_LOGIN_REQUEST, adminloginSaga);
}

import { put, call, takeLatest } from "redux-saga/effects";
import { PROJECT_TYPE_REQUEST } from "../actionTypes/type";
import { actionSuccess, actionError } from "../actions/projectTypeAction";
import { getApiCall } from "../../service/GetApiCall";
import { PROJECT_TYPE_URL } from "../../service/apiUrl";

export function* projectTypeSaga(action) {
  try {
    const response = yield call(getApiCall, PROJECT_TYPE_URL, action.params);
    yield put(actionSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionError(error));
    action.onError();
  }
}

export function* watchProjectTypeSaga() {
  yield takeLatest(PROJECT_TYPE_REQUEST, projectTypeSaga);
}

import {
  PROJECT_TYPE_REQUEST,
  PROJECT_TYPE_REQUEST_SUCCESS,
  PROJECT_TYPE_REQUEST_ERROR
} from "../actionTypes/type";

export const getProjectTypeRequest = (paramsData, onSuccess, onError) => {
  return {
    type: PROJECT_TYPE_REQUEST,
    params: paramsData,
    onSuccess,
    onError
  }
}

export const actionSuccess = (result) => {
  return {
    type: PROJECT_TYPE_REQUEST_SUCCESS,
    payload: result,
    error: null,
  };
}

export const actionError = (error) => {
  return {
    type: PROJECT_TYPE_REQUEST_ERROR,
    error: error,
    payload: null,
  };
}
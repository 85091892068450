import { put, call, takeLatest } from "redux-saga/effects";
import { PROJECT_UPDATE_REQUEST } from "../actionTypes/type";
import { actionSuccess, actionError } from "../actions/registerAction";
import { postApiCall } from "../../service/PostApiCall";
import { PROJECT_UPDATE_URL } from "../../service/apiUrl";

export function* projectUpdateSaga(action) {
  try {
    const response = yield call(postApiCall, action.params, PROJECT_UPDATE_URL);
    yield put(actionSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionError(error));
    action.onError(error);
  }
}

export function* watchProjectUpdateSaga() {
  yield takeLatest(PROJECT_UPDATE_REQUEST, projectUpdateSaga);
}

import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Multiselect from "multiselect-react-dropdown";
import { useNavigate, Link, useParams } from "react-router-dom";
import { adminLogout } from "../redux/actions/adminloginAction";
import { adminDisregardUserReportRequest } from "../redux/actions/adminDisregardUserReportAction";
import Utility from "../utility/utility";
import "../assets/css/admin.css";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  adminProjectListRequest,
  adminProjectViewRequest,
  adminProjectDeleteRequest,
} from "../redux/actions/adminProjectListAction";
import {
  adminUserListRequest,
  adminUserProfileRequest,
  adminUserDeleteRequest,
} from "../redux/actions/adminUserListAction";
import {logo2} from "../assets/images";
import { adminReportedListRequest } from "../redux/actions/adminReportedListAction";

import { useDispatch, useSelector } from "react-redux";
import ReactPaginate from "react-paginate";
import { AiOutlineLeft, AiOutlineRight, AiOutlineSearch } from "react-icons/ai";
import firebase from "../../firebase";

const Admin = () => {
  const [active, addActiveClass] = useState(1);
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [Others, setOthers] = useState("");
  // const [requirements, setrequirements] = useState([]);
  const dispatch = useDispatch();
  const [ProjectList, setProjectList] = useState([]);
  const [UserList, setUserList] = useState([]);
  const [ReportedList, setReportedList] = useState([]);
  const [ProjectTotalPages, setProjectTotalPages] = useState([]);
  const [UserTotalPages, setUserTotalPages] = useState([]);
  const [ReportedTotalPages, setReportedTotalPages] = useState([]);

  const [projectSearchValue, setprojectSearchValue] = useState();
  const [userSearchValue, setuserSearchValue] = useState();
  const [reportingSearchValue, setreportingSearchValue] = useState();

  const [projectdata, setprojectdata] = useState([]);
  const [profiledata, setprofiledata] = useState([]);
  const [reporteddata, setReportedData] = useState([]);
  const [chat, setchat] = useState([]);
  const [groupProjectchat, setgroupProjectchat] = useState([]);

  const [projectschat, setprojectschat] = useState([]);
  const [projectsIds, setprojectsIds] = useState([]);

  const [groupschat, setgroupschat] = useState([]);
  const [groupIds, setgroupIds] = useState([]);
  const [delProject, setDelProject] = useState(false);
  const [delUser, setDelUser] = useState(false);
  const [delProjectId, setDelProjectId] = useState(null);
  const [delUserId, setDelUserId] = useState(null);
  const [reportedId, setreportedId] = useState("");
  const [reportedname, setreportedname] = useState("");
  const [reportingname, setreportingname] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [Showchat, setShowchat] = useState("");
  const [chatModalTitle, setChatModalTitle] = useState('');
  const [deleteProjectName, setDeleteProjectName] = useState('');
  const [deleteUserName, setDeleteUserName] = useState('');
  const [cancelUserReport, setCancelUserReport] = useState(false);
  const [cancelReportId, setCancelReportId] = useState(null);
  const [delReportedUser, setDelReportedUser] = useState(false);

  const CHAT_GROUPS = "chats/groups/";
  const CHAT_PROJECTS = "chats/projects/";
  const CHAT_INDIVIUALS = "chats/individuals/";
  const COLLECTION_GROUPS = "groups/";
  const COLLECTION_INDIVIDUALS = "users/";

  const AdminData = useSelector((state) => state.adminloginReducer.data);
  const Projects = useSelector(
    (state) => state.adminProjectListReducer.data || []
  );
  const Users = useSelector((state) => state.adminUserListReducer.data || []);
  const Reported = useSelector(
    (state) => state.adminReportedListReducer.data || []
  );
  //console.log("reported", Reported);

  // console.log("AdminData", AdminData);
  // console.log("Users reducer", Users);
  //console.log("Projects", Projects);
  // console.log("Reported", Reported);

  // console.log("chat", chat);

  useEffect(() => {
    Utility.isSignedIn(AdminData).then((value) => {
      if (value) {
        var payload = {
          showSuccessToast: false,
        };
        dispatch(adminReportedListRequest(payload, onSuccess, onError));
        dispatch(adminProjectListRequest(payload, onSuccess, onError));
        dispatch(adminUserListRequest(payload, onSuccess, onError));
      } else {
        navigate("/admin/login");
      }
    });
  }, [AdminData]);

  useEffect(() => {
    if (Projects.projects) {
      setProjectList(Projects.projects);
      setProjectTotalPages(Projects.total_pages);
    }
  }, [Projects]);

  useEffect(() => {
    if (Users.users) {
      setUserList(Users.users);
      setUserTotalPages(Users.total_pages);
    }
  }, [Users]);

  useEffect(() => {
    if (Reported.list) {
      setReportedList(Reported.list);
      setReportedTotalPages(Reported.total_pages);
    }
  }, [Reported]);

  const viewProject = (id) => {
    console.log("Project Id", id);

    var payload = {
      id: id,
      showSuccessToast: false,
    };
    dispatch(adminProjectViewRequest(payload, onprojectsuccess, onError));

    addActiveClass(6);
  };

  const viewReported = (item) => {
    console.log("Reported Id", item);
    setreportedId(item.uid_reported);
    setreportedname(item.user_reported);
    setreportingname(item.user_reporting);
    let chatid = item.uid_reported + "-" + item.uid_reporting;
    let projectsData = item.projects;
    let groupsData = item.groups;
    //console.log("projectsData", projectsData);

    setprojectsIds(item.projects);
    setgroupIds(item.groups)

    // let chatid = "27-39";
    // alert(chatid);
    setReportedData(item);
    addActiveClass(7);

    firebase
      .database()
      .ref(CHAT_INDIVIUALS + chatid)
      .on("value", (snapshot) => {
        let chatlist = [];
        snapshot.forEach((snap) => {
          chatlist.push(snap.val());
        });

        setchat(chatlist);
      });

    let projectsdataarray = [];
    projectsData.forEach((item, index) => {
      firebase
        .database()
        .ref(CHAT_PROJECTS + item.id)
        .on("value", (snapshot) => {
          console.log("snapshot", snapshot.val());
          // projectsdataarray.push(snapshot.val());
          projectsdataarray.push(filterArray(snapshot));
          //var x = projectschat.concat(projectsdataarray);
          setprojectschat(projectsdataarray);
        });

    });
    

    let groupsdataarray = [];
    groupsData.forEach((item, index) => {
      firebase
        .database()
        .ref(CHAT_GROUPS + item.id)
        .on("value", (snapshot) => {
          groupsdataarray.push(filterArray(snapshot));
          //console.log("snapshot groups", groupsdataarray);
          //var y = groupschat.concat(groupsdataarray);
          setgroupschat(groupsdataarray);
        });
    });

  };

  const filterArray = (snapshot) => {
    
    const returnArr = [];

    snapshot.forEach((childSnapshot) => {
      const item = childSnapshot.val();
        returnArr.push(item);
    });

    return returnArr;

  }


  const viewchat = (type, item, chat_name) => {
    setShowchat(type);
    setgroupProjectchat(item);
    setModalShow(true);
    setChatModalTitle(chat_name)
  };

  async function onprojectsuccess(result) {
    console.log("onprojectsuccess", result);
    setprojectdata(result);
  }

  const viewUser = (id) => {
    console.log("user Id", id);

    var payload = {
      uid: id,
      showSuccessToast: false,
    };
    dispatch(adminUserProfileRequest(payload, onprofilesuccess, onError));

    addActiveClass(4);
  };

  async function onprofilesuccess(result) {
    console.log("onprofileSuccess", result);
    setprofiledata(result);
  }

  const toggleDeleteProjectModal = (id, name) => {

    setDelProject(true);
    setDelProjectId(id);
    setDeleteProjectName(name);

  }

  const toggleDeleteUserModal = (id, name, delete_reported_user = false) => {

    setDelUser(true);
    setDelUserId(id);
    setDeleteUserName(name);
    setDelReportedUser(delete_reported_user);

  }


  const deleteProject = () => {

    const projectId = delProjectId;

    if(projectId != null){

      const UpdatedProjects = Projects.projects.filter((array) => {
        return array.id != projectId;
      });
      setProjectList(UpdatedProjects);
      var payload = {
        id: projectId
      };
      dispatch(
        adminProjectDeleteRequest(payload, ondeleteProjectSuccess, onError)
      );
      setDelProject(false);
      addActiveClass(1);

    }

  };

  async function ondeleteProjectSuccess(result) {
    addActiveClass(1);
  }

  const deleteUser = () => {

    const uid = delUserId;

    if(uid != null){

      const UpdatedUser = Users.users.filter((array) => {
        return array.id != uid;
      });
  
      setUserList(UpdatedUser);

      if(delReportedUser){
        const updatedReportedList = ReportedList.filter( obj => {
          return obj.uid_reported != uid;
        } );
        setReportedList(updatedReportedList);
      }

      var payload = {
        id: uid
        // showSuccessToast: false,
      };
      dispatch(adminUserDeleteRequest(payload, ondeleteUserSuccess, onError));
      addActiveClass(2);

    }

  };

  async function ondeleteUserSuccess(result) {
    console.log("ondeleteUserSuccess", result);
    addActiveClass(2);
    setDelUser(false);
    setDelReportedUser(false);
  }

  const ProjectListData = ProjectList.map((item, index) => (
    <tr key={index.toString()}>
      <td>{index+1}</td>
      <td>{item.name}</td>
      <td>{item.creator_name}</td>
      <td>
        {item.event_start_date +
          (item.event_end_date ? "-" + item.event_end_date : "")}
      </td>
      <td>{item.type}</td>
      <td>{item.address}</td>
      <td>
        <Button onClick={() => viewProject(item.id)} variant="dark">
          View
        </Button>
      </td>
    </tr>
  ));

  const UserListData = UserList.map((item, index) => (
    <tr key={index.toString()}>
      <td>{item.firstname + " " + item.lastname}</td>
      <td>{item.address}</td>
      <td>{item.projects_count}</td>
      <td>{item.email}</td>
      <td>
        <Button onClick={() => viewUser(item.id)} variant="dark">
          View
        </Button>
      </td>
    </tr>
  ));

  const ReportedListData = ReportedList.map((item, index) => (
    <tr key={index.toString()}>
      <td>{item.id}</td>
      <td>{item.user_reported}</td>
      <td>{item.user_reporting}</td>
      <td>{item.reported_date}</td>
      <td>
        <Button onClick={() => viewReported(item)} variant="dark">
          View
        </Button>
      </td>
    </tr>
  ));

  let ProjectselctedPage = (page) => {
    if (page > 0 && page <= ProjectTotalPages) {
      // setProjectActivePage(page);
      var payload = {
        page: page,
        showSuccessToast: false,
      };
      dispatch(adminProjectListRequest(payload, onSuccess, onError));
    }
  };

  let projectsearch = (search) => {
    setprojectSearchValue(search);
    if (!search) {
      var payload = {
        showSuccessToast: false,
      };
      dispatch(adminProjectListRequest(payload, onSuccess, onError));
    } else {
      var payload = {
        project_name: search,
        showSuccessToast: false,
      };
      dispatch(adminProjectListRequest(payload, onSuccess, onError));
    }
  };

  const ProjectPageChange = (data) => {
    ProjectselctedPage(data.selected + 1);
  };

  let UserselectedPage = (page) => {
    if (page > 0 && page <= UserTotalPages) {
      // setUserActivePage(page);
      var payload = {
        page: page,
        showSuccessToast: false,
      };
      dispatch(adminUserListRequest(payload, onSuccess, onError));
    }
  };

  let usersearch = (search) => {
    setuserSearchValue(search);
    if (!search) {
      var payload = {
        showSuccessToast: false,
      };
      dispatch(adminUserListRequest(payload, onSuccess, onError));
    } else {
      var payload = {
        name: search,
        showSuccessToast: false,
      };
      dispatch(adminUserListRequest(payload, onSuccess, onError));
    }
  };

  const UserPageChange = (data) => {
    UserselectedPage(data.selected + 1);
  };

  let ReportedselectedPage = (page) => {
    if (page > 0 && page <= UserTotalPages) {
      // setUserActivePage(page);
      var payload = {
        page: page,
        showSuccessToast: false,
      };
      dispatch(adminReportedListRequest(payload, onSuccess, onError));
    }
  };

  let reportingsearch = (search) => {
    setreportingSearchValue(search);
    if (!search) {
      var payload = {};
      dispatch(adminReportedListRequest(payload, onSuccess, onError));
    } else {
      var payload = {
        name: search,
        showSuccessToast: false,
      };
      dispatch(adminReportedListRequest(payload, onSuccess, onError));
    }
  };

  const ReportingPageChange = (data) => {
    ReportedselectedPage(data.selected + 1);
  };

  const adminLogut = () => {
    dispatch(adminLogout());
    navigate("/login");
  };

  async function onSuccess(result) {
    console.log("onSuccess", result);
  }

  async function onError(err) {
    console.log("error", err);
  }

  function Projects_array(item, index) {
    console.log("Projects_array", item);
    let arr = [];

    for(let i in item){
        arr.push(item[i])
    }
    console.log("Projects_array arr", arr);

    arr.map( (i, index) => {
console.log("arr i", i)
      return(
      <>
        <tr key={index.toString()}>
          <td>Projects</td>
          <td className="text-center">

            { 
            projectsIds[index].name
            }
          </td>
          <td className="text-center">
            {/* <Button
              onClick={() => viewchat("Projects", arr[i])}
              variant="dark"
            >
              View chat
            </Button> */}
          </td>
        </tr>
      </>
      
    );

  } );  

  }

  function Render (){
    return(<span>Hello</span>);
  }

  function deleteProjectModal(){

    return(
        <SweetAlert title="" confirmBtnText="Delete Project"
          confirmBtnBsStyle="success" onConfirm={deleteProject}
          onCancel={() => setDelProject(false)}
        >
          <h6>Are you sure you want to delete this project?</h6>
          <p style={{ color: "#F0924E", marginTop: "2rem" }}>
            {deleteProjectName}        
          </p>
        </SweetAlert>      
    );

  }


  function deleteUserModal(){

    return(
        <SweetAlert title="" confirmBtnText="Delete User"
          confirmBtnBsStyle="success" onConfirm={deleteUser}
          onCancel={() => setDelUser(false)}
        >
          <h6>Are you sure you want to delete this user?</h6>
          <p style={{ color: "#F0924E", marginTop: "2rem" }}>
            {deleteUserName}        
          </p>
        </SweetAlert>      
    );

  }
  
  
  function disregardUserReportModal(){

    return(
        <SweetAlert title="" confirmBtnText="Disregard Report"
          confirmBtnBsStyle="success" onConfirm={cancelReport}
          onCancel={() => setCancelUserReport(false)}
        >
          <h6>Are you sure you want to disregard this user report?</h6>
        </SweetAlert>      
    );

  }  

  const closeChatModal = () => {
    setModalShow(false);
    setChatModalTitle('');
  }


  function Chat_modal() {
    return (
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="chatmodal"
      >
        <Modal.Header>
          <Modal.Title className="VisiaPro-ExtraBold fs-20">{chatModalTitle}</Modal.Title>
          <Button className="regular-font" variant="light" onClick={closeChatModal}>
            Close X
          </Button>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Container>
            {Showchat == "DM"
              ? chat.map((item, index) => (
                  <div key={index + 1}>
                    <div className="row mt-5">
                      <div className="col-md-5 col-5">
                        <div className="border mt-2"></div>
                      </div>
                      <div className="col-md-2 text-center col-2">
                        <p className="font-time">
                          {new Intl.DateTimeFormat("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          }).format(item.timestamp)}
                        </p>
                      </div>
                      <div className="col-md-5 col-5">
                        <div className="border mt-2"></div>
                      </div>
                    </div>

                    {item.uid == reportedId ? (
                      <div
                        key={index.toString() + 2}
                        className="card left_card"
                      >
                        <div className="card-body">
                          <p className="card-text regular-font fs-16 color-black">{item.message}</p>
                          <div className="card_bottom regular-font">
                            <p className="float-left">{reportedname}</p>
                            <p className="float-right">
                              {new Intl.DateTimeFormat("en-US", {
                                hour: "2-digit",
                                minute: "2-digit",
                              }).format(item.timestamp)}
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        key={index.toString() + 3}
                        className="card right_card"
                      >
                        <div className="card-body">
                          <p className="card-text">{item.message}</p>
                          <div className="card_bottom">
                            <p className="float-left">{reportingname}</p>
                            <p className="float-right">
                              {new Intl.DateTimeFormat("en-US", {
                                hour: "2-digit",
                                minute: "2-digit",
                              }).format(item.timestamp)}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))
              : null}

            {Showchat == "Projects"
              ? groupProjectchat.map((item, index) => (
                
                  <div key={index + 4}>
                    <div className="row mt-5">
                      <div className="col-md-5 col-5">
                        <div className="border mt-2"></div>
                      </div>
                      <div className="col-md-2 text-center col-2">
                        <p className="font-time">
                          {new Intl.DateTimeFormat("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          }).format(item.timestamp)}
                        </p>
                      </div>
                      <div className="col-md-5 col-5">
                        <div className="border mt-2"></div>
                      </div>
                    </div>

                    {item.uid == reportedId ? (
                      <div
                        key={index.toString() + 5}
                        className="card left_card"
                      >
                        <div className="card-body">
                          <p className="card-text">{item.message}</p>
                          <div className="card_bottom">
                            <p className="float-left">{reportedname}</p>
                            <p className="float-right">
                              {new Intl.DateTimeFormat("en-US", {
                                hour: "2-digit",
                                minute: "2-digit",
                              }).format(item.timestamp)}
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        key={index.toString() + 6}
                        className="card right_card"
                      >
                        <div className="card-body">
                          <p className="card-text">{item.message}</p>
                          <div className="card_bottom">
                            <p className="float-left">{reportingname}</p>
                            <p className="float-right">
                              {new Intl.DateTimeFormat("en-US", {
                                hour: "2-digit",
                                minute: "2-digit",
                              }).format(item.timestamp)}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))
              : null}

              {Showchat == "Groups"
              ? groupProjectchat.map((item, index) => (
                
                  <div key={index + 4}>
                    <div className="row mt-5">
                      <div className="col-md-5 col-5">
                        <div className="border mt-2"></div>
                      </div>
                      <div className="col-md-2 text-center col-2">
                        <p className="font-time">
                          {new Intl.DateTimeFormat("en-US", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          }).format(item.timestamp)}
                        </p>
                      </div>
                      <div className="col-md-5 col-5">
                        <div className="border mt-2"></div>
                      </div>
                    </div>

                    {item.uid == reportedId ? (
                      <div
                        key={index.toString() + 5}
                        className="card left_card"
                      >
                        <div className="card-body">
                          <p className="card-text">{item.message}</p>
                          <div className="card_bottom">
                            <p className="float-left">{reportedname}</p>
                            <p className="float-right">
                              {new Intl.DateTimeFormat("en-US", {
                                hour: "2-digit",
                                minute: "2-digit",
                              }).format(item.timestamp)}
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        key={index.toString() + 6}
                        className="card right_card"
                      >
                        <div className="card-body">
                          <p className="card-text">{item.message}</p>
                          <div className="card_bottom">
                            <p className="float-left">{reportingname}</p>
                            <p className="float-right">
                              {new Intl.DateTimeFormat("en-US", {
                                hour: "2-digit",
                                minute: "2-digit",
                              }).format(item.timestamp)}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))
              : null}


          </Container>
        </Modal.Body>
      </Modal>
    );
  }


  const toggleCancelReportModal = report_id => {

    setCancelUserReport(true);
    setCancelReportId(report_id);

  }

  const cancelReport = () => {
    
    if(cancelReportId != null){

      dispatch(adminDisregardUserReportRequest({id: cancelReportId}, function(resp){
        addActiveClass(3);
        setCancelUserReport(false);
        setCancelReportId(null);
        const updatedReportedList = ReportedList.filter( obj => {
          return obj.id != cancelReportId
        } );
        setReportedList(updatedReportedList);
      }, onError) )

    }

  }

  return (
    <div className="proucts_page">
      <div className="adminsidebar">
        <div className="mt-3 text-center">
          <Link className="not-hover" to="/">
            <img src={logo2} width="125" />
          </Link>
        </div>
        <ul className="adminsidebar_list bullet_none VisiaPro-ExtraBold">
          <li>
            <Link
              className={active == 1 ? "active" : null}
              to="#"
              onClick={(e) => addActiveClass(1)}
            >
              Projects
            </Link>
          </li>
          <li>
            <Link
              className={active == 2 ? "active" : null}
              to="#"
              onClick={(e) => addActiveClass(2)}
            >
              Users
            </Link>
          </li>
          <li>
            <ul className="sub_list">
              <li>
                <Link
                  className={active == 3 ? "active" : null}
                  to="#"
                  onClick={(e) => addActiveClass(3)}
                >
                  Reported
                </Link>
              </li>
            </ul>
          </li>
          <li className="mt-5">
            <Link to="#" onClick={(e) => adminLogut()}>
              Logout
            </Link>
          </li>
        </ul>
      </div>
      {/* view Project Listing code Starts here................................. */}

      <div
        className={
          active == 1 ? "admincontent mb-5" : "admincontent mb-5 d-none"
        }
      >
        <div className="row mt-4">
          <div className="col-md-4 ">
            <h1 className="VisiaPro-ExtraBold">Projects</h1>
          </div>

          <div className="col-md-6 search">
            <input
              className="form-control m-auto input-search"
              id="ProjectSerachInput"
              type="text"
              placeholder="Search.."
              onChange={(e) => projectsearch(e.target.value)}
            />
            <AiOutlineSearch
              onClick={() => projectsearch(projectSearchValue)}
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-12">
            <Table striped bordered hover size="sm">
              <thead className="VisiaPro-ExtraBold">
                <tr>
                  <th scope="col">ID No.</th>
                  <th scope="col">Project Name</th>
                  <th scope="col">Creator</th>
                  <th scope="col">Event Date</th>
                  <th scope="col">Project Type</th>
                  <th scope="col">Location</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody className="regular-font">
                {ProjectList && ProjectList.length > 0 ? (
                  <>{ProjectListData}</>
                ) : (
                  <tr>
                    <td colSpan={7}>No Record Found!!!</td>
                  </tr>
                )}
              </tbody>
            </Table>
            {ProjectTotalPages > 0 ? (
              <nav className="d-flex justify-content-center">
                <ReactPaginate
                  previousLabel={<AiOutlineLeft />}
                  nextLabel={<AiOutlineRight />}
                  breakLabel={"..."}
                  pageCount={ProjectTotalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={ProjectPageChange}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                ></ReactPaginate>
              </nav>
            ) : null}
          </div>
        </div>
      </div>

      {/* view Project Listing code ends here................................. */}

      {/* view User Listing code Starts here................................. */}

      <div
        className={
          active == 2 ? "admincontent mb-5" : "admincontent mb-5 d-none"
        }
      >
        <div className="row mt-4">
          <div className="col-md-4 ">
            <h1 className="VisiaPro-ExtraBold">Users</h1>
          </div>

          <div className="col-md-6 search">
            <input
              className="form-control m-auto input-search"
              id="myInput"
              type="text"
              value={userSearchValue}
              placeholder="Search.."
              onChange={(e) => usersearch(e.target.value)}
            />
            <AiOutlineSearch onClick={() => usersearch(userSearchValue)} />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <Table striped bordered hover size="sm">
              <thead className="VisiaPro-ExtraBold">
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Location</th>
                  <th scope="col">Project Count</th>
                  <th scope="col">Email</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody className="regular-font">
                {UserList && UserList.length > 0 ? (
                  <>{UserListData}</>
                ) : (
                  <tr>
                    <td colSpan={5}>No Record Found!!!</td>
                  </tr>
                )}
              </tbody>
            </Table>
            {UserTotalPages > 0 ? (
              <nav className="d-flex justify-content-center">
                <ReactPaginate
                  previousLabel={<AiOutlineLeft />}
                  nextLabel={<AiOutlineRight />}
                  breakLabel={"..."}
                  pageCount={UserTotalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={UserPageChange}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                ></ReactPaginate>
              </nav>
            ) : null}
          </div>
        </div>
      </div>
      {/* view User Listing code ends here................................. */}

      {/* view project code Starts here................................. */}

      <div
        className={
          active == 6 ? "admincontent mb-5" : "admincontent mb-5 d-none"
        }
      >
        {" "}
        {projectdata.details ? (
          <Form noValidate autoFocus autoComplete="off">
            <div className="text-center">
              <Row className="mt-3 mb-3">
                <div className="col-md-2 mt-4">
                  <p className="backbutton VisiaPro-ExtraBold" onClick={() => addActiveClass(1)}>
                    <i className="fa fa-chevron-left mr-2"></i>
                    Back
                  </p>
                </div>
                <div className="col-md-8 mt-4">
                  <h5 className="text-center VisiaPro-ExtraBold">{projectdata.details.name}</h5>
                </div>
              </Row>
              <Row className="mt-3 mb-3">
                <Col>
                  <Form.Group>
                    <Form.Label className="regular-font">Project Creator/Owner</Form.Label>
                    <h4 className="VisiaPro-ExtraBold fs-20">{projectdata.details.username}</h4>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label className="regular-font">Creation Date</Form.Label>
                    <h4 className="regular-font fs-20 color-text-gray">{projectdata.details.created_date}</h4>
                  </Form.Group>
                </Col>
              </Row>

              <div className="col-md-12 mt-4">
                <h5 className="text-center">
                  <span className="VisiaPro-ExtraBold">Single Date</span>{" "}
                  {projectdata.details.event_end_date ? (
                    <span className="regular-font color-text-gray">/ Date Range</span>
                  ) : (
                    <span className="blur regular-font color-text-gray">/ Date Range</span>
                  )}{" "}
                </h5>
              </div>

              <Row className="mt-3 mb-3">
                <Col>
                  <Form.Group>
                    <Form.Label className="regular-font">Event Date</Form.Label>
                    <h4 className="VisiaPro-ExtraBold fs-20">{projectdata.details.event_start_date}</h4>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label className="regular-font color-text-gray">End Date</Form.Label>
                    <h4 className="VisiaPro-ExtraBold fs-20">
                      {projectdata.details.event_end_date ? (
                        projectdata.details.event_end_date
                      ) : (
                        <span className="blur">MM/DD/YYYY</span>
                      )}
                    </h4>
                  </Form.Group>
                </Col>
              </Row>

              <div className="col-md-12 mt-4">
                <h5 className="text-center">
                  <span className="VisiaPro-ExtraBold">Address</span>{" "}
                  {projectdata.details.region ? (
                    <span className="regular-font color-text-gray">/ Region</span>
                  ) : (
                    <span className="blur regular-font color-text-gray">/ Region</span>
                  )}{" "}
                </h5>
              </div>

              <Row className="mt-3 mb-3">
                <Col>
                  <Form.Group>
                    <Form.Label className="regular-font">Address</Form.Label>
                    <h4 className="VisiaPro-ExtraBold fs-20">{projectdata.details.address}</h4>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label className="regular-font">Zip</Form.Label>
                    <h4 className="VisiaPro-ExtraBold fs-20">{projectdata.details.zip_code}</h4>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-3 mb-3">
                <Col>
                  <Form.Group>
                    <Form.Label className="regular-font">Project Type</Form.Label>
                    <h4 className="VisiaPro-ExtraBold fs-20">{projectdata.details.category}</h4>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label className="regular-font">Project Focus</Form.Label>
                    <h4 className="VisiaPro-ExtraBold fs-20">{projectdata.details.focus}</h4>
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <Row className="mt-3 mb-3">
              <Col>
                <Form.Group
                  size="lg"
                  className="input-container"
                  controlId="name"
                >
                  <Form.Label className="regular-font">Passion</Form.Label>
                  <Form.Control
                    readOnly
                    as="textarea"
                    value={
                      projectdata.details.passion
                        ? projectdata.details.passion
                        : ""
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-3 mb-3">
              <Col>
                <Form.Group
                  size="lg"
                  className="input-container"
                  controlId="name"
                >
                  <Form.Label className="regular-font">Impact</Form.Label>
                  <Form.Control
                    readOnly
                    as="textarea"
                    value={
                      projectdata.details.impact
                        ? projectdata.details.impact
                        : ""
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-3 mb-3">
              <Col>
                <Form.Group
                  size="lg"
                  className="input-container"
                  controlId="name"
                >
                  <Form.Label className="regular-font">Benefit</Form.Label>
                  <Form.Control
                    readOnly
                    as="textarea"
                    value={
                      projectdata.details.benefit
                        ? projectdata.details.benefit
                        : ""
                    }
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Form.Group className="ml-3">
                <Form.Label className="mt-5 regular-font">Requirements</Form.Label>

                {projectdata.requirements
                  ? projectdata.requirements.map((item, index) =>
                      item.is_other == 0 ? (
                        <Form.Check
                          disabled={true}
                          key={index.toString()}
                          type="checkbox"
                          label={item.name}
                          name={item.name}
                          checked={item.checked}
                          className="VisiaPro-ExtraBold fs-20"
                        />
                      ) : (
                        ""
                      )
                    )
                  : ""}
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="ml-3 grid">
                <Form.Label className="mt-5 blur fs-20 regular-font">Other</Form.Label>

                {projectdata.requirements
                  ? projectdata.requirements.map((item, index) =>
                      item.is_other == 1 ? (
                        <Form.Label
                          className="ml-4 VisiaPro-ExtraBold fs-20"
                          key={index.toString()}
                          name={item.name}
                        >
                          {item.name}
                        </Form.Label>
                      ) : (
                        ""
                      )
                    )
                  : ""}
              </Form.Group>
            </Row>

            <Row>
              <Form.Group className="ml-3">
                <Form.Label className="mt-5 regular-font">Considerations</Form.Label>
                <div className="considerations">
                  {projectdata.considerations
                    ? projectdata.considerations.map((item, index) => (
                        <Form.Label key={index.toString()} className="regular-font">
                          {item.name}
                        </Form.Label>
                      ))
                    : ""}
                </div>
              </Form.Group>
            </Row>

            <Form.Group className="mt-3">
              <Form.Group className="d-flex">
                <Form.Label className="VisiaPro-ExtraBold fs-20">Wheel Chair Accessible</Form.Label>
                <Form.Label className="switch">
                  <input
                    disabled={true}
                    type="checkbox"
                    checked={projectdata.details.event_wheelchair}
                  />
                  <span className="slider">
                    <span>No</span>
                    <span>Yes</span>
                  </span>
                </Form.Label>
              </Form.Group>

              <Form.Group className="d-flex">
                <Form.Label className="VisiaPro-ExtraBold fs-20">Trigger Animal Allergies</Form.Label>
                <Form.Label className="switch">
                  <input
                    type="checkbox"
                    disabled={true}
                    checked={projectdata.details.event_animal_allergies}
                  />
                  <span className="slider">
                    <span>No</span>
                    <span>Yes</span>
                  </span>
                </Form.Label>
              </Form.Group>

              <Form.Group className="d-flex">
                <Form.Label className="VisiaPro-ExtraBold fs-20">Trigger Hayfer</Form.Label>
                <Form.Label className="switch">
                  <input
                    type="checkbox"
                    disabled={true}
                    checked={projectdata.details.event_hay_fever}
                  />
                  <span className="slider">
                    <span>No</span>
                    <span>Yes</span>
                  </span>
                </Form.Label>
              </Form.Group>
            </Form.Group>

            <Row className="projects table_div ">
              <div className="table_heading">
                <h3 className="text-center white VisiaPro-ExtraBold fs-20">User Management</h3>
              </div>
              <br/><br/>
              <div className="col-md-12 d-flex">
                <div className="col-md-6">
                  <div className="text-left">
                    <Form.Label className="white regular-font">
                      Users in Project: {projectdata.users.accepted.count}
                    </Form.Label>
                  </div>
                  <div className="table_back">
                    <div className="table_wraper">
                      <Table
                        className="border_none profile_table max-ht"
                        striped
                        hover
                        size="sm"
                      >
                        <thead className="regular-font fs-20">
                          <tr>
                            <th scope="col">Username</th>
                            <th scope="col">Role</th>
                            <th scope="col">Amount/Supply</th>
                          </tr>
                        </thead>
                        <tbody className="regular-font">
                          {projectdata.users.accepted.list.map(
                            (item, index) => (
                              <tr key={index.toString()}>
                                <td
                                  className="pointer"
                                  onClick={() => viewUser(item.id)}
                                >
                                  {item.username}
                                </td>
                                <td>
                                  <Multiselect
                                    disable={true}
                                    displayValue="key"
                                    selectedValues={[{ key: item.role }]}
                                  />
                                </td>
                                <td>{item.amount_supply}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="text-left">
                    <Form.Label className="white regular-font">
                      Pending:{projectdata.users.pending.count}
                    </Form.Label>
                  </div>
                  <div className="table_back">
                    <div className="table_wraper">
                      <Table
                        className="border_none profile_table max-ht"
                        striped
                        hover
                        size="sm"
                      >
                        <thead className="regular-font fs-20">
                          <tr>
                            <th scope="col">Username</th>
                            <th scope="col">Role</th>
                            <th scope="col">Amount/Supply</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        <tbody className="regular-font">
                          {projectdata.users.pending.list.map((item, index) => (
                            <tr key={index.toString()}>
                              <td>{item.username}</td>
                              <td>
                                <Multiselect
                                  disable={true}
                                  displayValue="key"
                                  selectedValues={[{ key: item.role }]}
                                />
                              </td>
                              <td>{item.amount_supply}</td>
                              <td>{item.status}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
            <Form.Group className="form_buttons_wraper">
              <Button className="regular-font"
                onClick={() => toggleDeleteProjectModal(projectdata.details.id, projectdata.details.name)}
                variant="danger"
              >
                Delete Project
              </Button>
              <Button className="regular-font" onClick={() => addActiveClass(1)} variant="primary">
                Return
              </Button>
            </Form.Group>
          </Form>
        ) : null}
      </div>

      {/* view project code ends here................................. */}

      {/* view profile code Starts here................................. */}

      <div
        className={
          active == 4 ? "admincontent mb-5" : "admincontent mb-5 d-none"
        }
      >
        {" "}
        {profiledata.profile ? (
          <Form noValidate autoFocus autoComplete="off">
            <Row className="mt-3 mb-3">
              <div className="col-md-2 mt-4">
                <p className="backbutton VisiaPro-ExtraBold" onClick={() => addActiveClass(2)}>
                  <i className="fa fa-chevron-left mr-2"></i>
                  Back
                </p>
              </div>
              <div className="col-md-8 mt-4">
                <h5 className="text-center regular-text fs-16">Username</h5>
                <h3 className="text-center VisiaPro-ExtraBold fs-20">
                  {profiledata.profile.firstname +
                    " " +
                    profiledata.profile.lastname}
                </h3>
              </div>
            </Row>
            <Row className="col-md-12">
              <div className="col-md-2"></div>
              <div className="col-md-4">
                <Form.Group>
                  <Form.Label className="regular-font">Email</Form.Label>
                  <h4 className="VisiaPro-ExtraBold fs-20">{profiledata.profile.email}</h4>
                </Form.Group>
              </div>
              <div className="col-md-3"></div>

              <div className="col-md-3">
                <Form.Group>
                  <Form.Label className="regular-font">Zip</Form.Label>
                  <h4 className="VisiaPro-ExtraBold fs-20">{profiledata.profile.postal_code}</h4>
                </Form.Group>
              </div>
            </Row>
            <Row className="col-md-12 mt-3 mb-3">
              <div className="col-md-2"></div>
              <div className="col-md-4">
                <Form.Group>
                  <Form.Label className="regular-font">Profile Photo</Form.Label>
                  <Form.Group>
                    <img
                      className="profileImg"
                      src={profiledata.profile.profile_pic_url}
                    ></img>
                    <span className="ml-2 regular-font">
                      {profiledata.profile.profile_pic}
                    </span>
                  </Form.Group>
                </Form.Group>
              </div>
              <div className="col-md-3"></div>

              <div className="col-md-3 mt-3 mb-3">
                <Form.Group>
                  <Form.Label className="regular-font">Banner Photo</Form.Label>
                  <Form.Group>
                    <img
                      className="profileImg"
                      src={profiledata.profile.banner_pic_url}
                    ></img>
                    <span className="ml-2 regular-font">
                      {profiledata.profile.banner_pic}
                    </span>
                  </Form.Group>
                </Form.Group>
              </div>
            </Row>

            <Row className="mt-3 mb-3">
              <Col>
                <Form.Group
                  size="lg"
                  className="input-container"
                  controlId="name"
                >
                  <Form.Label className="px-2 regular-font">Profile Description</Form.Label>
                  <Form.Control
                    readOnly
                    as="textarea"
                    value={
                      profiledata.profile.description
                        ? profiledata.profile.description
                        : ""
                    }
                  />

                  <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-3 mb-3">
              <Col>
                <Form.Group
                  size="lg"
                  className="input-container"
                  controlId="name"
                >
                  <Form.Label className="px-2 regular-font">Interests</Form.Label>
                  <Form.Control
                    readOnly
                    as="textarea"
                    value={
                      profiledata.profile.description
                        ? profiledata.profile.description
                        : ""
                    }
                  />
                  <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Form.Group className="ml-3">
                <Form.Label className="px-2 regular-font">Skill/Expertise</Form.Label>

                {profiledata.skills
                  ? profiledata.skills.map((item, index) =>
                      item.is_other == 0 ? (
                        <Form.Check
                          disabled={true}
                          key={index.toString()}
                          type="checkbox"
                          label={item.name}
                          name={item.name}
                          checked={item.checked}
                          className="VisiaPro-ExtraBold fs-20"
                        />
                      ) : (
                        ""
                      )
                    )
                  : ""}
              </Form.Group>
            </Row>
            <br/>
            <Row>
              <Form.Group className="ml-3 grid">
                <Form.Label className="regular-font color-text-gray fs-20">{Others}</Form.Label>

                {profiledata.skills
                  ? profiledata.skills.map((item, index) =>
                      item.is_other == 1
                        ? (Others == "" ? setOthers("Others") : "",
                          (
                            <Form.Label
                              className="ml-4 VisiaPro-ExtrsBold fs-20"
                              key={index.toString()}
                              name={item.name}
                            >
                              {item.name}
                            </Form.Label>
                          ))
                        : ""
                    )
                  : ""}
              </Form.Group>
            </Row>

            <Row className="table_div">
              <div className="col-md-12 ">
                <h3 className="text-center white VisiaPro-ExtraBold fs-20">Projects</h3>
              </div>
              <div className="text-left">
                <Form.Label className="white regular-font">
                  Projects Involved In: <span className="VisiaPro-ExtraBold">{profiledata.projects.count}</span>
                </Form.Label>
              </div>
              <div className="table_back">
                <div className="table_wraper">
                  <Table
                    className="border_none profile_table max-ht"
                    striped
                    hover
                    size="sm"
                  >
                    <thead className="VisiaPro-ExtraBold fs-20">
                      <tr>
                        <th scope="col">Project Name</th>
                        <th scope="col">Role</th>
                        <th scope="col">Amount/Supply</th>
                      </tr>
                    </thead>
                    <tbody className="regular-font">
                      {profiledata.projects.list.map((item, index) => (
                        <tr key={index.toString()}>
                          <td
                            className="pointer"
                            onClick={() => viewProject(item.id)}
                          >
                            {item.project_name}
                          </td>
                          <td>
                            <Multiselect
                              disable={true}
                              displayValue="key"
                              selectedValues={[{ key: item.role }]}
                            />
                          </td>
                          <td>{item.amount_supply}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>

              <div className="text-left mt-5">
                <Form.Label className="white regular-font">
                  Projects Invites and Applications:{" "}
                  <span className="VisiaPro-ExtraBold">{profiledata.projects.count}</span>
                </Form.Label>
              </div>
              <div className="table_back">
                <div className="table_wraper">
                  <Table
                    className="border_none profile_table max-ht"
                    striped
                    hover
                    size="sm"
                  >
                    <thead className="VisiaPro-ExtraBold fs-20">
                      <tr>
                        <th scope="col">Project Name</th>
                        <th scope="col">Role</th>
                        <th scope="col">Type</th>
                      </tr>
                    </thead>
                    <tbody className="regular-font">
                      {profiledata.projects.list.map((item, index) => (
                        <tr key={index.toString()}>
                          <td
                            className="pointer"
                            onClick={() => viewProject(item.id)}
                          >
                            {item.project_name}
                          </td>
                          <td>
                            <Multiselect
                              disable={true}
                              displayValue="key"
                              selectedValues={[{ key: item.role }]}
                            />
                          </td>
                          <td>{item.type}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Row>

            <Form.Group className="form_buttons_wraper">
              <Button className="regular-font"
                onClick={() => toggleDeleteUserModal(profiledata.profile.id, profiledata.profile.firstname + ' ' + profiledata.profile.lastname)}
                variant="danger"
              >
                Delete user
              </Button>
              <Button className="regular-font" onClick={() => addActiveClass(2)} variant="primary">
                Return
              </Button>
            </Form.Group>
          </Form>
        ) : null}
      </div>

      {/* view profile code ends here................................. */}

      {/* reported code starts from here...................................... */}
      <div
        className={
          active == 3 ? "admincontent mb-5" : "admincontent mb-5 d-none"
        }
      >
        <div className="row mt-4">
          <div className="col-md-4 ">
            <h1 className="VisiaPro-ExtraBold">Reporting</h1>
          </div>

          <div className="col-md-6 search">
            <input
              className="form-control m-auto input-search"
              id="myInput"
              type="text"
              value={reportingSearchValue}
              placeholder="Search.."
              onChange={(e) => reportingsearch(e.target.value)}
            />
            <AiOutlineSearch
              onClick={() => reportingsearch(reportingSearchValue)}
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <Table striped bordered hover size="sm">
              <thead className="VisiaPro-ExtraBold">
                <tr>
                  <th scope="col">Case No.</th>
                  <th scope="col">Reported User</th>
                  <th scope="col">Reporting user</th>
                  <th scope="col">Report Date</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {ReportedList && ReportedList.length > 0 ? (
                  <>{ReportedListData}</>
                ) : (
                  <tr>
                    <td colSpan={4}>No Record Found!!!</td>
                  </tr>
                )}
              </tbody>
            </Table>
            {ReportedTotalPages > 0 ? (
              <nav className="d-flex justify-content-center">
                <ReactPaginate
                  previousLabel={<AiOutlineLeft />}
                  nextLabel={<AiOutlineRight />}
                  breakLabel={"..."}
                  pageCount={ReportedTotalPages}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={ReportingPageChange}
                  containerClassName={"pagination"}
                  activeClassName={"active"}
                ></ReactPaginate>
              </nav>
            ) : null}
          </div>
        </div>
      </div>
      {/* reported code ends here................................................ */}

      {/* Reported View  code Starts here................................. */}

      <div
        className={
          active == 7 ? "admincontent mb-5" : "admincontent mb-5 d-none"
        }
      >
        {" "}
        {reporteddata ? (
          <Form noValidate autoFocus autoComplete="off">
            <div className="text-center">
              <Row className="mt-3 mb-3">
                <div className="col-md-2 mt-4">
                  <p className="backbutton VisiaPro-ExtraBold" onClick={() => addActiveClass(2)}>
                    <i className="fa fa-chevron-left mr-2"></i>
                    Back
                  </p>
                </div>
                <div className="col-md-8 mt-4">
                  <h1 className="text-center VisiaPro-ExtraBold fs-20">Case No.:{reporteddata.id}</h1>
                </div>
              </Row>
              <Row className="mt-3 mb-3">
                <Col>
                  <Form.Group>
                    <Form.Label className="regular-font">Reported</Form.Label>
                    <Form.Group>
                      <img
                        className="profileImg"
                        src={reporteddata.user_reported_profile}
                      ></img>
                      <span className="ml-2 VisiaPro-ExtraBold fs-20 pointer"
                                  onClick={() => viewUser(reporteddata.uid_reported)}>{reporteddata.user_reported}</span>
                    </Form.Group>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label className="regular-font">Reporting</Form.Label>
                    <Form.Group>
                      <img
                        className="profileImg"
                        src={reporteddata.user_reporting_profile}
                      ></img>
                      <span className="ml-2 VisiaPro-ExtraBold fs-20 pointer" onClick={() => viewUser(reporteddata.uid_reporting)} >
                        {reporteddata.user_reporting}
                      </span>
                    </Form.Group>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mt-3 mb-3">
                <Col>
                  <Form.Group>
                    <Form.Label className="regular-font">Reported Date</Form.Label>
                    <h4 className="VisiaPro-ExtraBold fs-20">{reporteddata.reported_date}</h4>
                  </Form.Group>
                </Col>
                <Col></Col>
              </Row>
            </div>

            <Row className="table_div">
              <div className="text-left">
                <Form.Label className="white regular-font">Message between Users</Form.Label>
              </div>
              <div className="table_back">
                <div className="table_wraper">
                  <Table
                    className="border_none profile_table max-ht"
                    striped
                    hover
                    size="sm"
                  >
                    <thead className="VisiaPro-ExtraBold fs-20">
                      <tr>
                        <th scope="col">Chat Type</th>
                        <th className="text-center" scope="col">
                          Chat Name
                        </th>
                        <th className="text-center" scope="col"></th>
                      </tr>
                    </thead>
                    <tbody className="regular-font">
                      {chat ? (
                        <tr>
                          <td>{"DM"}</td>
                          <td className="text-center">{reportedname}</td>
                          <td className="text-center">
                            <Button
                              onClick={() => viewchat("DM", chat, reportedname)}
                              variant="dark"
                            >
                              View chat
                            </Button>
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}

                      {projectschat.map((item, index) =>
                            
                            <tr key={index.toString()}>
                              <td>Projects</td>
                              <td className="text-center">
                                { projectsIds[index].name }
                              </td>
                              <td className="text-center">
                                <Button
                                  onClick={() => viewchat("Projects", item, projectsIds[index].name)}
                                  variant="dark"
                                >
                                  View chat
                                </Button>
                              </td>
                            </tr>
                            
                      )}

                      {groupschat.map((item, index) =>
                  
                        <tr key={index.toString()}>
                          <td>Groups</td>
                          <td className="text-center">
                            { groupIds[index].name }
                          </td>
                          <td className="text-center">
                            <Button
                              onClick={() => viewchat("Groups", item, groupIds[index].name)}
                              variant="dark"
                            >
                              View chat
                            </Button>
                          </td>
                        </tr>

                      )}
                      
                    </tbody>
                  </Table>
                </div>
              </div>
            </Row>

            <Form.Group className="form_buttons_wraper">
              <Button
                onClick={() => toggleDeleteUserModal(reporteddata.uid_reported, reporteddata.user_reported, true)}
                variant="danger"
              >
                Delete user
              </Button>
              <Button onClick={() => toggleCancelReportModal(reporteddata.id)} variant="secondary">
                Disregard
              </Button>
            </Form.Group>
          </Form>
        ) : null}
      </div>

      {/* Reported View code ends here................................. */}
      {Chat_modal()}
      {delProject ? deleteProjectModal() : ''}
      {delUser ? deleteUserModal() : ''}
      {cancelUserReport ? disregardUserReportModal() : ''}
    </div>
  );
};
export default Admin;

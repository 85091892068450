import React, { useState, useEffect } from "react";
import {
  ProfileImage,
  Background,
  leftBack,
  buttonLoader,
  flag,
  crossX
} from "../assets/images";
import { useNavigate, Link, useParams, Form } from "react-router-dom";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { actionSuccess } from "../redux/actions/loginAction";
import { getUserProfileRequest } from "../redux/actions/userProfileAction";
import { getProjectListingRequest } from "../redux/actions/projectListingAction";
import { setUserImagesRequest } from "../redux/actions/userImagesAction";
import { getProjectHelpRequest } from "../redux/actions/projectHelpAction";
import { getUserAuthorizedProjectsRequest } from "../redux/actions/userAuthorizedProjectAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faBell, faUserCircle, faLocationArrow, faTimes } from "@fortawesome/free-solid-svg-icons";
import { getUserListingRequest } from "../redux/actions/userListingAction";
import { groupPageCreateRequest } from "../redux/actions/groupPageCreateAction";
import { getUserUpdateProfileRequest } from "../redux/actions/userUpdateProfileAction";
import { groupInvitationRequest } from "../redux/actions/groupInvitationAction";
import { serviceGetRequest } from "../redux/actions/serviceGetAction";
import ServiceInvitation from "../components/ServiceInvitation";
import { serviceInvitationSendRequest } from "../redux/actions/serviceInvitationSendAction";
import UserGroupsList from "../components/user_groups_list";
import AlertModal from "../components/Alert";
import MenuToggle from "../components/MenuToggle";
import { projectInvitationMessageRequest } from "../redux/actions/projectInvitationMessageAction";
import { projectInvitationRespondRequest } from "../redux/actions/projectInvitationRespondAction";
import { projectInvitationReadRequest } from "../redux/actions/projectInvitationReadAction";
import { stripeBankAccountUpdateRequest } from "../redux/actions/stripeBankAccountAction"

import Utility from "../utility/utility";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Modal from 'react-bootstrap/Modal'

const LIMIT_INVITATION_MESSAGE_LISTING = 8;
const LIMIT_PROJECT_LISTING = 50;
var projectScrollTimeout = null, projectListingOffset = 0;
const LIMIT_USER_LISTING = 8;
var userListingOffset = 0, userSearchTimeout = null, userScrollTimeout = null;
var alertScrollTimeout = null, alertListingOffset = 0, offsetInvitationMessageListing = 0;

const PROFILE_TOGGLE_GALLERY_IDX_PROJECTS = 0;
const PROFILE_TOGGLE_GALLERY_IDX_SERVICES = 1;
const PROFILE_TOGGLE_GALLERY_IDX_GROUPS = 2;
const PROFILE_TOGGLE_GALLERY_IDX_ALL = 3;

const LIST_TYPE_PROJECTS = 'p';
const LIST_TYPE_SERVICES = 's';

const Profile2 = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const [showChoose, setChoose] = useState(true);
  const [settings, setSettings] = useState(false);
  const [userListing, setUserListing] = useState([]);
  const [userListingLoader, setUserListingLoader] = useState(false);
  const [showDropdownContent, setShowDropdownContent] = useState(false);
  const [skill, setSkill] = useState([]);
  const [userSearch, setUserSearch] = useState('');
  const [profile, setProfile] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [srcProfileImg, setSrcProfileImg] = useState(null);
  const [crop, setCrop] = useState({aspect: 1, unit: '%', x: 25, y: 5, width: 50});
  const [profileResult, setProfileResult] = useState(null);
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  //const [bannerBckgImg, setBannerBckgImg] = useState('');
  const [editDescription, setEditDesctiption] = useState(false);
  const [editUserSkillExpertise, setEditUserSkillExpertise] = useState(false);
  const [editUserInterests, setEditUserInterests] = useState(false);
  const [description, setDescription] = useState('');
  const [userFirstName, setUserFirstName] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const [userCity, setUserCity] = useState('');
  const [userState, setUserState] = useState('');
  const [skillOptions, setSkillOptions] = useState([]);
  const [userSkillOptions, setUserSkillOptions] = useState([]);
  const [addedUserSkillOptions, setAddedUserSkillOptions] = useState([]);
  const [removedUserSkillOptions, setRemovedUserSkillOptions] = useState([]);
  const [userSkillOthers, setUserSkillOthers] = useState([]);
  const [addedUserSkillOthers, setAddedUserSkillOthers] = useState([]);
  const [removedUserSkillOthers, setRemovedUserSkillOthers] = useState([]);
  const [userInterests, setUserInterests] = useState([]);
  const [addedUserInterests, setAddedUserInterests] = useState([]);
  const [removedUserInterests, setRemovedUserInterests] = useState([]);
  const [userSkillOtherName, setUserSkillOtherName] = useState('');
  const [userInterestName, setUserInterestName] = useState('');
  const [userSkillOtherKey, setUserSkillOtherKey] = useState(1);
  const [userInterestKey, setUserInterestKey] = useState(1);
  const [createGroupModalShow, setCreateGroupModalShow] = useState(false); 
  const [inviteToGroupModalShow, setInviteToGroupModalShow] = useState(false);
  const [alertsModalShow, setAlertsModalShow] = useState(false);
  const [userAuthorizedProjects, setUserAuthorizedProjects] = useState([]);
  const [groupName, setGroupName] = useState('');
  const [userGroups, setUserGroups] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [profileGroups, setProfileGroups] = useState([]);
  const [serviceList, setServiceList] = useState();
  const [currentUserGroups, setCurrentUserGroups] = useState([]);  
  const [concatList, setConcatList] = useState([]);
  const [profileToggleGalleryIdx, setProfileToggleGalleryIdx] = useState(PROFILE_TOGGLE_GALLERY_IDX_PROJECTS);
  const [userGroupId, setUserGroupId] = useState(null);
  const [isUpdating, setUpdating] = useState(false);
  const [newNotification, setNewNotification] = useState(0);  
  const [invitationMessages, setInvitationMessages] = useState([]);  
  const [bankAccountModalShow, setBankAccountModalShow] = useState(false);
  const [accountNumber, setAccountNumber] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [businessTaxId, setBusinessTaxId] = useState('');
  const [businessPhone, setBusinessPhone] = useState('');
  const [dob, setDob] = useState('');
  const [bankAccDocVerificationImg, setBankAccDocVerificationImg] = useState(null);
  const [individualVerificationImg, setIndividualVerificationImg] = useState(null);
  const [bankAccFormLoading, setBankAccFormLoading] = useState(false);
  const [serviceInvitationModal, setServiceInvitationModal] = useState(false);
  const [serviceInvitationData, setServiceInvitationData] = useState({});

  const UserData = useSelector(
    (state: RootStateOrAny) => state.loginReducer.data
  );

  //Current loggedin user
  const currentUid = UserData.id;
  //User whose profile we need to get
  const { uid } = useParams();
  const profileUid = (uid == undefined) ? currentUid : parseInt(uid);
  //Whether the given profile id is for the current user? If yes, then that means the profile is editable
  const isEditable = (uid == undefined || uid == currentUid);

  useEffect( () => {
    setCurrentUserGroups(UserData.groups);
  }, [UserData]);

  //Render group listing for current user, fetch only those groups which are created by the current loggedin user
  const RenderUserGroupList = () => {
    return UserGroupsList({ id: 'select-group', type: 'select-input-control', groups: currentUserGroups, selected_group: userGroupId, set_user_group_id: setUserGroupId });
  }

  useEffect( () => {
    RenderUserGroupList();
  }, [currentUserGroups]);

  //Dispatch request for fetching user profile on page load
  useEffect(() => {
    dispatch(getUserProfileRequest({ 'uid' : profileUid, showSuccessToast: false }, onUserProfileRenderSuccess, onError));
    dispatch( projectInvitationMessageRequest({ uid_receiver: currentUid, offset: offsetInvitationMessageListing, limit: LIMIT_INVITATION_MESSAGE_LISTING, showSuccessToast: false  }, onInvitationMessageSuccess, onError) )
  }, []);

  //Dispatch request for fetching skills on page load
  useEffect(()=> {
    
    dispatch(getProjectHelpRequest({}, function(resp){
      setSkillOptions(resp);
    }, onError));

  },[]);    

  useEffect( () => {

    const projectListingParam = {
      offset: 0,
      limit: LIMIT_PROJECT_LISTING,
      uid: profileUid,
      showSuccessToast: false
    };

    dispatch(getProjectListingRequest(projectListingParam, function(resp){
      console.log("resp: ", resp);
      setProjectList(resp.filter( item => item.type == LIST_TYPE_PROJECTS ));
      setServiceList(resp.filter( item => item.type == LIST_TYPE_SERVICES ));
    }, onError));


    dispatch( getUserAuthorizedProjectsRequest({
      uid: profileUid, showSuccessToast: false, stripe_filter: true
    }, function(resp){
      const nonCreatedProjects = resp.filter(p => p.role!="Creator" && p.role!="creator");
      setUserAuthorizedProjects(nonCreatedProjects);
    }, onError) );

  },[]);


  const onInvitationMessageSuccess = resp => {
    const arr = invitationMessages.concat(resp.alerts);
    setInvitationMessages(arr);
    setNewNotification(resp.total_unread_alerts);
  }

  const readAlertMsg = (e, id) =>{

      dispatch( projectInvitationReadRequest({ id: id}, async function(){
        const updatedInvitationMessages = invitationMessages.filter(msg => {
          if(msg.id == id) return false;
          return true;
        });
        setInvitationMessages(updatedInvitationMessages);
        setNewNotification(newNotification-1);
      }, onError) )

  }


  const navigateContributionRequest = (id, amount) => {
  
    if(amount == null || isNaN(amount)){

      const payLoad = {
        service_id: id,
        uid: currentUid
      }
      dispatch(serviceInvitationSendRequest(payLoad, onInvitationSendSuccess, onError));

    }else{

      navigate('/service-contributor', {state: {service_id: id, contribution_amount: parseFloat(amount)}});

    }
  
  }

  const onInvitationSendSuccess = resp => {
    onServiceModalHide();
    navigate('/thankyou/service-offer')
  }  

  /*
    * Function to respond to project request
    *
    * @params:
    *   {int} 'id': Record id
    *   {int} 'if_accepted' : 1 => accepted, 0 => denied
    */
  const RespondProjectRequest = (id, if_accepted, this_obj, type) => {
        
      dispatch( projectInvitationRespondRequest({'id': id, 'accepted': if_accepted}, function(){
        
        const updatedInvitationMessages = invitationMessages.filter(val => {
          return !Boolean(val.id == id);
        });
        setInvitationMessages(updatedInvitationMessages);
        setNewNotification(newNotification-1);

      }, onError) );

  }

  const handleAlertMessagesScroll = e => {

    const listingDiv = e.target;

    if (listingDiv.offsetHeight + listingDiv.scrollTop >= listingDiv.scrollHeight-1) {  
      
      clearTimeout(alertScrollTimeout);
      alertScrollTimeout = setTimeout(function(){
        alertListingOffset += LIMIT_INVITATION_MESSAGE_LISTING;
        dispatch(projectInvitationMessageRequest({uid_receiver: currentUid, offset: alertListingOffset, limit: LIMIT_INVITATION_MESSAGE_LISTING, showSuccessToast:false}, onInvitationMessageSuccess, onError));
        //setModalOrgLoader(false);
      }, 2500);

    }

  }

  async function onUserProfileRenderSuccess(resp){

    setProfile(resp);
    setDescription(resp.description ? resp.description : '');
    setUserCity(resp.city ? resp.city: '');
    setUserState(resp.state ? resp.state: '');
    setUserFirstName(resp.firstname);
    setUserLastName(resp.lastname);
    setSkill(resp.skills_expertise);    
    setUserInterests(resp.interests);
    updateUserSkillsFromProfile(resp.skills_expertise);
    setProfileGroups(resp.groups);

  }


  const updateUserSkillsFromProfile = skills_expertise => {

    let userOptionSkills = [], userOtherSkills = [];
    skills_expertise.filter(option => option.is_other == 0).map( option => {
      userOptionSkills = userOptionSkills.concat([option]);
    } );
    setUserSkillOptions(userOptionSkills);

    skills_expertise.filter(option => option.is_other == 1).map( option => {
      userOtherSkills = userOtherSkills.concat([option]);
    } );
    setUserSkillOthers(userOtherSkills);    

  }


  const RenderUserProfileSkills = function(){

    if(editUserSkillExpertise){

      return(
        <div className="col-md-12 font-manrope-semibold fs-14">
          {
            skillOptions.map( (option, i) => (
              <div key={i}>
                <label className="containers">
                  { option.name }
                  <input name="skillSelected" type="checkbox" checked={isOtherOptionChecked(option)} onChange={e => addRemoveUserSkillOptions(option)} value={option.id} />
                  <span className="checkmark"></span>
                </label> 
              </div>  
            ))        
          }
          <ul className="Other_skills_list">       
          {
            userSkillOthers.filter(v => v.is_other == 1).map( (option, i) => (
              <li key={i} className="font-manrope-semibold fs-14">
                {option.name}
                <span className="remove_Skill pointer" onClick={e => removeOtherSkill(option)}><img width="12" src={crossX}/></span>
              </li>  
            ))    
          }
          </ul>                      
        </div>
      );

    }else{

      return (
        <ul className="a font-manrope-semibold fs-14 pl-4">
          {skill.map( (option, i) => (
            <li key={i}>{option.name}</li>
          ))}
        </ul>
      )  

    }

  }


  const RenderUserProfileInterests = function(){

    if(editUserInterests){

      return(
        <div className="col-md-12 font-manrope-semibold fs-14">
          <ul className="Other_skills_list">       
          {
            userInterests.map( (option, i) => (
              <li className="font-manrope-semibold fs-14">
                {option.name}
                <span className="remove_Skill pointer" onClick={e => 
                  (option)}><img width="12" onClick={e => removeInterests(option)} src={crossX}/></span>
              </li>  
            ))    
          }
          </ul>                      
        </div>
      );

    }else{

      return (
        <ul className="a font-manrope-semibold fs-14">
          {userInterests.map( (option, i) => (
            <li>{option.name}</li>
          ))}
        </ul>
      )  

    }

  }


  const isOtherOptionChecked = option => {
    return userSkillOptions.some(v => v.id == option.id) || (option.is_other == 1 && userSkillOthers.length > 0);
  }


  const handleUserSkillsKeyDown = (e) => {

    if(e.key == 'Enter'){
      const val = e.target.value.trim();
      if(val != ''){
        const obj = [{id: null, is_other: 1, name: val, key: userSkillOtherKey}];
        const newArr = userSkillOthers.concat(obj);
        setAddedUserSkillOthers( addedUserSkillOthers.concat(obj) );
        setUserSkillOthers(newArr);
        setUserSkillOtherName('');
        setUserSkillOtherKey(userSkillOtherKey+1);
      }
    }

  }

  const handleUserInterestsKeyDown = (e) => {

    if(e.key == 'Enter'){
      const val = e.target.value.trim();
      if(val != ''){
        const obj = [{id: null, name: val, key: userInterestKey}];
        setAddedUserInterests( addedUserInterests.concat(obj) );
        setUserInterests( userInterests.concat(obj) );
        setUserInterestName('');
        setUserInterestKey(userInterestKey+1);
      }
    }

  }



  const addRemoveUserSkillOptions = option => {

    let newArr = userSkillOptions;

    if( userSkillOptions.some(v => v.id == option.id) ){
      newArr = userSkillOptions.filter( op => op.id != option.id );
      setRemovedUserSkillOptions( removedUserSkillOptions.concat([option]) );
    }else{
      newArr = userSkillOptions.concat([option]);
      setAddedUserSkillOptions( addedUserSkillOptions.concat([option]) )
    }

    setUserSkillOptions(newArr);

  }


  const removeOtherSkill = (option) => {

    setRemovedUserSkillOthers( removedUserSkillOthers.concat(option) );

    const newArr = userSkillOthers.filter( val => {

      if(val.id == null)
        return (val.is_other == 0) || (val.is_other == 1 && val.key != option.key)
      else
        return (val.is_other == 0) || (val.is_other == 1 && val.id != option.id)

    } ); 

    //Unselect 'Other' option in case if no 'other' skill is remaining
    if(newArr.length == 0){
      const newUserSkillOptions = userSkillOptions.filter(v => v.is_other == 0);
      setUserSkillOptions(newUserSkillOptions);
    }

    setUserSkillOthers(newArr);

  }

  const removeInterests = (option) => {

    setRemovedUserInterests( removedUserInterests.concat(option) );

    const newArr = userInterests.filter( (val, i) => {

      if(option.id == null)
        return val.key != option.key;
      else
        return val.id != option.id;

    } ); 

    setUserInterests(newArr);

  }


  // async function onSkillRenderSuccess(resp) {
  //   skillOptions = resp;
  //   let skillVal = skillOptions.find(
  //     (o) => o.id == UserData.skills_expertise_id
  //   );
  //   if (skillVal && skillVal.name.toLowerCase() != "other")
  //     skillVal = skillVal.name;
  //   else skillVal = UserData.skills_expertise;
  //   setSkill(skillVal);
  // }

  async function onError(err) {
    setUserListingLoader(false);
    console.log("Error", err);
  }

  const openServiceInvitationModal = service_id => {

    dispatch( serviceGetRequest({showSuccessToast: false, id: service_id}, onServiceGetSuccess, onError) );

  }


  const onServiceGetSuccess = resp => {
    setServiceInvitationData(resp);
    setServiceInvitationModal(true);
    setUpdating(!isUpdating);
  }

  const onServiceModalHide = () => {
    setServiceInvitationData({});
    setServiceInvitationModal(false);
  }

  const RenderListing = () => {

    if(profileToggleGalleryIdx == PROFILE_TOGGLE_GALLERY_IDX_PROJECTS){

      if(projectList.length == 0) return (<RenderNoRecordListing/>);

      return projectList.map( (option, i) => {
        return (
          RenderListingContent(i, option, PROFILE_TOGGLE_GALLERY_IDX_PROJECTS)
        )
      });

    }

    if(profileToggleGalleryIdx == PROFILE_TOGGLE_GALLERY_IDX_SERVICES){

      if(serviceList.length == 0) return (<RenderNoRecordListing/>);

      return serviceList.map( (option, i) => {
        return (
          RenderListingContent(i, option, PROFILE_TOGGLE_GALLERY_IDX_SERVICES)
        )
      });

    }

    if(profileToggleGalleryIdx == PROFILE_TOGGLE_GALLERY_IDX_GROUPS){

      if(profileGroups.length == 0) return (<RenderNoRecordListing/>);

      return profileGroups.map( (val, i) => {
        return RenderListingContent(i, val, PROFILE_TOGGLE_GALLERY_IDX_GROUPS)
      } );

    }

    if(profileToggleGalleryIdx == PROFILE_TOGGLE_GALLERY_IDX_ALL){

      if(concatList.length == 0) return (<RenderNoRecordListing/>);

      return concatList.map( (val, i) => {
        return RenderListingContent(i, val, val.type);
      } );      

    }

  }


  const RenderNoRecordListing = () => {
    return (<p className="text-center font-manrope-regular fs-14">No records found</p>);
  }

  const RenderListingContent = (i, val, type) => {

    const id = Number(val.id);
    const linkTo = getListLinkTo(type, id);
    const typeDesc = getListType(type);
    const buttonText = getButtonText(type);

    return(

      type == PROFILE_TOGGLE_GALLERY_IDX_PROJECTS
      ?
      <div key={i} className="col-md-4 project-listing py-3">
        <Link to={linkTo}>
          <div style={{position: 'relative'}}>
            <img src={val.image} className="img-fluid mark-img-radius project-images-home drop-shadow" alt={`${typeDesc} image`}/>
            <span className="project-image-sticker fs-14 font-manrope-bold">{typeDesc}</span>
          </div>
          <h6 className="mt-3 font-manrope-semibold">{val.name}</h6>
          {
            type == PROFILE_TOGGLE_GALLERY_IDX_PROJECTS
            ?
            <><div className="py-1"><span className="boundary-word">{val.category}</span></div>
            <div className="fs-14 font-manrope-medium py-1"><span className="boundary-word">${val.money ? val.money: 0}</span>&nbsp;<span className="boundary-word">{val.resources_count} people</span></div></>
            :
            <></>  
          }
          <div><button className="profile-contribute-project border-none bckg-color px-4 py-1 font-manrope-regular fs-16">{buttonText}</button></div>
        </Link>
      </div>
      :
      <div key={i} className="col-md-4 project-listing py-3">
          <div style={{position: 'relative'}}>
            <img src={val.image} className="img-fluid mark-img-radius project-images-home drop-shadow" alt={`${typeDesc} image`}/>
            <span className="project-image-sticker fs-14 font-manrope-bold">{typeDesc}</span>
          </div>
          <h6 className="mt-3 font-manrope-semibold">{val.name}</h6>
          {
            type == PROFILE_TOGGLE_GALLERY_IDX_PROJECTS
            ?
            <><div className="py-1"><span className="boundary-word">{val.category}</span></div>
            <div className="fs-14 font-manrope-medium py-1"><span className="boundary-word">${val.money ? val.money: 0}</span>&nbsp;<span className="boundary-word">{val.resources_count} people</span></div></>
            :
            <></>  
          }
          <div><button onClick={() => openServiceInvitationModal(id)} className="profile-contribute-project border-none bckg-color px-4 py-1 font-manrope-regular fs-16">{buttonText}</button></div>
      </div>
    );
    
  }

  const getListLinkTo = (type, id) => {

    switch(type){

      case 0:
        return `/project/${id}`;
      case 1:
          return `/service/${id}`;        
      case 2:
        return `/group/${id}`;
      default:
        return '/';  

    }

  }


  const getListType = type => {

    switch(type){

      case 0:
        return 'Project';
      case 1:
        return 'Service';
      case 2:
        return 'Group';
      default:
        return '';

    }

  }

  const getButtonText = type => {

    switch(type){
      
      case 1:
        return 'Invite to Work';
      default:
        return 'View';

    }

  }

  const concatListing = (arr1 = [], type1, arr2 = [], type2, arr3 = [], type3) => {
    
    const res1 = arr1.map(obj => {
      obj.type = type1
      return obj;
    });

    const res2 = arr2.map(obj => {
      obj.type = type2
      return obj;
    });

    const res3 = arr3.map(obj => {
      obj.type = type3
      return obj;      
    });

    return res1.concat(res2, res3);

  };

  //Merge the three arrays (projects, groups, services) based on type
  useEffect( () => {

    const newMergedList = concatListing(projectList, PROFILE_TOGGLE_GALLERY_IDX_PROJECTS, profileGroups, PROFILE_TOGGLE_GALLERY_IDX_GROUPS, serviceList, PROFILE_TOGGLE_GALLERY_IDX_SERVICES);
    setConcatList(newMergedList);

  }, [projectList, profileGroups, serviceList]);

  // const handleProjectListingScroll = (e) => {
    
  //   const listingDiv = e.target;

  //   if (listingDiv.offsetHeight + listingDiv.scrollTop >= listingDiv.scrollHeight) {  
      
  //     clearTimeout(projectScrollTimeout);
  //     projectScrollTimeout = setTimeout(function(){
        
  //       projectListingOffset += LIMIT_PROJECT_LISTING;

  //       const projectListingParam = {
  //         offset: projectListingOffset,
  //         limit: LIMIT_PROJECT_LISTING,
  //         uid: profileUid,
  //         showSuccessToast: false
  //       };
  //       dispatch(getProjectListingRequest(projectListingParam, function(resp){
  //         setProjectList([...projectList, resp]);
  //       }, onError));

  //     }, 2500);

  //   }

  // }

  const getCroppedImg = async () => {

    try {
        const canvas = document.createElement("canvas");
        const scaleX = profileImage.naturalWidth / profileImage.width;
        const scaleY = profileImage.naturalHeight / profileImage.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(
          profileImage,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );

        const base64Image = canvas.toDataURL("image/jpeg", 1);

        setProfileResult(base64Image);

    } catch (e) {
        console.log("crop the image");
    }

  };


  const saveCroppedImage = () => {

    if(profileResult == null){

      alert("Please select and crop image");

    }else{

      dispatch(setUserImagesRequest({uid: profileUid, profile_pic: profileResult}, function(resp){

        const userProfile = profile;
        userProfile.profile_pic = resp.profile_pic;
        setProfile(userProfile);
        setProfileModalOpen(false);
        setProfileResult(null);

        let UserDataStore = UserData;
        UserDataStore.profile_pic = resp.profile_pic;
        dispatch(actionSuccess(UserDataStore));

      }, function(err){
        Utility.showToast('error', err.data.message);
      }
      ));

    }

  }  


  const uploadProfilePic = file_obj => {
    
    const srcImg = URL.createObjectURL(file_obj);
    setSrcProfileImg(srcImg);
    setProfileModalOpen(true);

  }

  const validateUserProfileFields = () => {

    let valid = true;

    if(userFirstName.trim() == ''){
      valid = false;
      Utility.showToast('error', "Enter your firstname");
    }else if(userLastName.trim() == ''){
      valid = false;
      Utility.showToast('error', "Enter your lastname");
    }

    return valid;

  }

  const searchUsers = val => {

    setUserSearch(val);
    clearTimeout(userSearchTimeout);
    setUserListingLoader(true);
    userSearchTimeout = setTimeout(() => {
      if (val) {
        userListingOffset = 0;
        dispatch(getUserListingRequest({offset:userListingOffset, limit:LIMIT_USER_LISTING, name:val, exclude_uids: [currentUid], showSuccessToast:false}, onUserListingRequestSuccess, onError));
      }
    }, 1500);    

  }

  const handleUserListingScroll = e => {

    const listingDiv = e.target;

    if (listingDiv.offsetHeight + listingDiv.scrollTop >= listingDiv.scrollHeight-1) {  
      
      setUserListingLoader(true);
      clearTimeout(userScrollTimeout);
      userScrollTimeout = setTimeout(function(){
        userListingOffset += LIMIT_USER_LISTING;
        dispatch(getUserListingRequest({offset:userListingOffset, limit:LIMIT_USER_LISTING, name:userSearch, showSuccessToast:false}, function(resp){
          setUserListing(userListing.concat(resp));
        }, onError));
        setUserListingLoader(false);
      }, 2500);

    }

  }

  const onUserListingRequestSuccess = resp => {
    setUserListingLoader(false);
    setUserListing(resp);
  }

  const navigateProfile = id =>{
    setCreateGroupModalShow(false);
    navigate("/profile/"+id);
  }  

  const createGroupValidation = e => {

    let valid = true;

    if(groupName == undefined || groupName.trim() == ''){
      valid = false;
      Utility.showToast('error', "Enter group name");
    }

    return valid;

  }

  const createGroup = e => {

    e.preventDefault();

    if(createGroupValidation(e)){
      const payLoad = {
        name: groupName.trim(), 
        uids: userGroups, 
        created_by: currentUid, 
        showSuccessToast:true
      }
      dispatch(groupPageCreateRequest(payLoad, onCreateGroupSuccess, onError));
    }

  }

  const clearCreateGroupForm = () => {
    setGroupName('');
    setUserSearch('');
    setUserGroups([]);
    setUserListing([]);
  }

  const hideCreatGroupModal = () => {
    setCreateGroupModalShow(false);
    clearCreateGroupForm();
  }


  const onCreateGroupSuccess = resp => {
    
    hideCreatGroupModal();
    let UserDataStore = UserData;
    UserDataStore.groups = [...UserData.groups, resp.details];
    dispatch(actionSuccess(UserDataStore));
    
    //Update current user-groups state so that its rendered on Invite group users listing
    const currentUserGroupsUpdated = [...currentUserGroups, resp.details];
    setCurrentUserGroups(currentUserGroupsUpdated);
    
    //If current loggedin user is same as the selected profile user, then update the profile user groups state as well so that the created group is rendered on listing
    if(isEditable){
      const profileUserGroupsUpdated = [...profileGroups, resp.details];
      setProfileGroups(profileUserGroupsUpdated);
    }
  
  }

  const addUserToGroup = user => {
    if(userGroups.indexOf(user.id) == -1) setUserGroups([...userGroups, user.id]);
  }

  const cancelUserFromGroup = uid => {
    let userGroupsClone = userGroups;
    const index = userGroupsClone.indexOf(uid);
    if (index !== -1) {
      userGroupsClone.splice(index, 1);
    }    
    setUserGroups(userGroupsClone);
    setUpdating(!isUpdating);
  }

  const RenderUsersListing = () => {

    if(userListing.length == 0){

      return(<p className="text-center font-manrope-regular">No Records</p>);

    }else{

      return(
        <>
        {
          userListing.map( (option, i) => (
            <div key={i} className="col-md-12 fl pt-2 pb-2 px-0 user-group-container">
              <div className="user-info col-md-7 fl text-left">
                <img width="35" src={option.profile_pic} className="project-team-user-image organizer-profile-pic" alt="user profile" onClick={(e) => navigateProfile(option.id)} />
                <span className="ml-3 mt-3 project-team-user font-manrope-semibold" onClick={(e) => navigateProfile(option.id)}>{option.firstname} {option.lastname}<br></br>
                  <span className="text-grey font-creatore project-team-designation"></span>
                </span>  
              </div>
              <div className="col-md-5 fl text-right font-manrope-regular">
                {userGroups.indexOf(option.id) == -1 ? <button className="add-user-to-group drop-shadow-3 bckg-color border-none" type="text" onClick={e => addUserToGroup(option)}>Add</button> : <><span className="font-manrope-medium fs-14">Added</span>&nbsp;<FontAwesomeIcon icon={faTimes} title="Remove user" className="pointer" onClick={() => cancelUserFromGroup(option.id)}/></>}
              </div>
            </div>
          ))
        }
        </>
      );

    }

  }

  const editFinishSettings = () => {

    //Save the changes
    if(settings){
      
      if( validateUserProfileFields() ){
        const payLoad = {
          uid: profileUid,
          firstname: userFirstName.trim(),
          lastname: userLastName.trim(),
          description: description.trim(),
          city: userCity.trim(),
          state: userState.trim(),
          skills_expertise: {
            options:{
              added: addedUserSkillOptions,
              removed: removedUserSkillOptions 
            },
            others:{
              added: addedUserSkillOthers,
              removed: removedUserSkillOthers 
            }
          },
          interests: {
            added: addedUserInterests,
            removed: removedUserInterests
          }
        }
        dispatch( getUserUpdateProfileRequest(payLoad, function(resp){
          setEditDesctiption(false);
          setEditUserSkillExpertise(false)
          setEditUserInterests(false);
          setUserFirstName(userFirstName.trim());
          setUserLastName(userLastName.trim());
          if(description.trim()){
            setDescription(description.trim());
          }
          if(userCity.trim()){
            setUserCity(userCity.trim());
          }
          if(userState.trim()){
            setUserState(userState.trim());
          }          
          setSkill( userSkillOptions.concat(userSkillOthers) );
          setAddedUserSkillOptions([]);
          setRemovedUserSkillOptions([]);
          setAddedUserSkillOthers([]);
          setRemovedUserSkillOthers([]);
          setAddedUserInterests([]);
          setRemovedUserInterests([]);
          updateUserSkillsFromProfile(resp.skill_expertise)
          setUserInterests(resp.interests);
        }), function(err){
          console.log("err", err);
        } );  
      }

    }

    setSettings(!settings);

  }

  useState( () => {
    RenderListing();
  }, [profileToggleGalleryIdx]);

  const hideInviteToGroupModalShow = () => {
    setInviteToGroupModalShow(false);
    clearInviteToGroupForm();
  }  

  const validateInviteToGroupForm = () => {

    let valid = true;

    if(userGroupId == null || userGroupId.value == undefined){
      valid = false;
      Utility.showToast('error', 'Please select a group');
    }
    //If selected profile is the current loggedin profile, only then render the "Select User" searchbox and validate to select atleast one user for invitation
    //Else if someone else's profile, then no need to render the "Select User" searchbox and no need to validate it  
    if(isEditable && userGroups.length == 0){
      valid = false;
      Utility.showToast('error', 'Please select an user to send invititation');
    }

    return valid;

  }

  /**
   * If selected profile is the current loggedin profile, then uid receiver (for invitation) should be the users selected from the searchbox
   * else if someone else's profile, then uid received (for invitation) should be that selected profile  only (static)
   * @returns array (int)
   */
  const getUidInvitationReceiver = () => {

    if(isEditable) return userGroups;
    else return [profileUid]

  }

  const inviteToGroup = e => {

    e.preventDefault();
    if(validateInviteToGroupForm()){
      const payLoad = {
        group_id: userGroupId.value, 
        uid_sender: currentUid, 
        uid_receiver: getUidInvitationReceiver()
      }
      dispatch( groupInvitationRequest(payLoad, hideInviteToGroupModalShow, onError) );
    }

  }

  const clearInviteToGroupForm = () => {
    setUserGroupId(null);
    setUserGroups([]);
  }

  const saveBankDetails = e => {

    e.preventDefault();
    setBankAccFormLoading(true);

    if( validateBankDetails() ){

      dispatch( stripeBankAccountUpdateRequest({
        uid: profileUid,
        account: {
          number: accountNumber.trim(),
          routing: routingNumber.trim()
        },
        business: {
          tax_id: businessTaxId,
          phone: businessPhone
        },
        dob: dob,
        bank_account_verification_img: bankAccDocVerificationImg,
        identity_verification_img: individualVerificationImg
      }, 
      function(resp){
        
        //console.log("bank details", resp)
        setBankAccFormLoading(false);
        setBankAccountModalShow(false);
        
        //Update the newly created stripe connect acc id on the current user
        let UserDataStore = UserData;
        UserDataStore.stripe_connect_account_id = resp.stripe_connect_account_id;
        dispatch(actionSuccess(UserDataStore));

      }, onError) );

    }else{

      setBankAccFormLoading(false);

    }

  }

  const validateBankDetails = () => {

    let valid = true;
   
    if(businessTaxId.trim() == ''){
      valid = false;
      Utility.showToast('error', "Enter Business Tax ID");
    }
    else if(businessPhone.trim() == ''){
      valid = false;
      Utility.showToast('error', "Enter Business phone number");
    }  
    else if(dob.trim() == ''){
      valid = false;
      Utility.showToast('error', "Enter Date of Birth");
    }                
    else if(accountNumber.trim() == ''){
      valid = false;
      Utility.showToast('error', "Enter Account number");
    }
    else if(routingNumber.trim() == ''){
      valid = false;
      Utility.showToast('error', "Enter Routing number");      
    }    
    
    return valid;

  }


  return (
    <main className="bckg-gray">
      <div className="profile bckg">
        <div className="">
          <div className="col-md-12 p-0">
            <div>
              <div className="container">
                <div className="row">
                  <div className="col-md-10 markplace-pc">
                    <div className="col-md-12 p-0">
                      <div className="row">
                        <div className="mt-2">
                          <img
                            src={leftBack}
                            className="img-fluid  w-mobilw ml-3 mt-2"
                          />
                        </div>
                        <div className="ml-3 mt-3">
                          <h5 className="font-manrope-extra-bold margin-home-btn fs-16">
                            <Link to="/home">Back to Home</Link>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="display-inline-block profile-upload2">
                      {settings && isEditable ? (
                        <input
                          id="profile-input"
                          className="file-input"
                          type="file"
                          accept="image/*"
                          onChange={e => uploadProfilePic(e.target.files[0])}
                        />
                      ) : (
                        ""
                      )}

                      {
                        isEditable && settings ? <div className="text-center edit-photo-item font-manrope-regular">
                          <i className="fa fa-camera"></i><br/>
                          <span className="fs-16 font-manrope-semibold">Change Photo</span>
                        </div> : <></>
                      }

                      <img
                        htmlFor="profile-input"
                        width="92"
                        src={profile ? profile.profile_pic : ProfileImage}
                        className={"img-fluid profile-image-radius" + (isEditable && settings ? ' opaque': '')}
                        alt="..."
                      />
                    </div>   
                    &nbsp;&nbsp;&nbsp;  
                    <div className="display-inline-block">
                      <span className="fs-24 font-manrope-bold">
                        {isEditable && settings 
                          ?
                          <>
                            <input type="text" className="fs-18 edit-profile-text" value={userFirstName} onChange={e => setUserFirstName(e.target.value)} placeholder="First Name"/>
                            &nbsp;
                            <input type="text" className="fs-18 edit-profile-text" value={userLastName} onChange={e => setUserLastName(e.target.value)} placeholder="Last Name"/>                        
                          </> 
                          :
                          (profile ? userFirstName+' '+userLastName: '')
                        }
                      </span>  
                    </div>
                    {
                      userCity || userState || (isEditable && settings) ? 
                      <div className="display-inline-block">
                          <ul className="a margib-ul-profile">
                            <li className="color-ul-profile">
                              {isEditable && settings ? 
                              <><input type="text" className="fs-18 edit-profile-text" placeholder="Enter city" value={userCity} onChange={e => setUserCity(e.target.value)} />&nbsp;<input type="text" className="fs-18 edit-profile-text" placeholder="Enter state" value={userState} onChange={e => setUserState(e.target.value)} /></> : 
                              <><span className="font-manrope-extra-bold color-text-gray">{userCity}</span>&nbsp;<span className="font-manrope-extra-bold color-text-gray">{userState}</span></>
                              }
                            </li>
                          </ul>
                      </div>
                      :
                      <></>
                    }                 
                    </div>
                  </div>
                  <div className="col-md-2 col-2">
                    <div className="float-right cursor-pointer toggle-settings">
                        <div className="ml-3 mt-3 text-right">
                          {isEditable ? 
                          <span onClick={() => editFinishSettings()}><h5 className="display-inline-block fs-16 font-manrope-bold">{settings ? 'Finish Editing' : 'Edit'}</h5>&nbsp;&nbsp;</span> : 
                          <></>
                          }
                          <MenuToggle notification_count={newNotification} set_show_dropdown_content={setShowDropdownContent} show_dropdown_content={showDropdownContent} set_alerts_modal_show={setAlertsModalShow} />
                        </div>
                        <div className="mt-3 text-center">
                          {
                            currentUserGroups.length > 0 
                            ? 
                            <button onClick={() => setInviteToGroupModalShow(true)} className="profile-invite-group border-none bckg-color px-3 py-2 font-manrope-regular fs-12">Invite to group</button>
                            :
                            <></>
                          }
                        </div>
                        {
                          isEditable ? 
                          <div className="mt-3 text-center">
                            <button onClick={() => setCreateGroupModalShow(true)} className="create-group border-none bckg-color px-3 py-2 font-manrope-regular fs-12">Create group</button>
                          </div>
                          :
                          <></>
                        }
                      </div>
                    </div>
                </div>
                <div className="row">
                  <div className="col-md-6"></div>
                  <div className="col-md-6 float-right" style={{'minHheight': '25px'}}>
                    &nbsp;
                  </div>
                </div> 
                <div className="row">
                  <div className="col-md-2-5 padding-0 moble-padding-profile">

                    <div className="col-md-12">
                      
                      <div className="card profile-sidebar mt-4 profile-radius drop-shadow">

                        <div className="col-md-12 padding-0">

                          <div className="col-md-12">
                            <div className="col-md-8 fl">
                              <h4 className="text-right mt-3 font-skill font-manrope-bold">
                                About
                              </h4>  
                            </div>
                            {
                              settings && isEditable ? 
                              <div className="col-md-4 p-0 text-right float-left">
                                <h6 className="mt-3 font-manrope-regular pointer" onClick={e => setEditDesctiption(!editDescription)}><u>{editDescription ? 'Cancel' : 'Edit'}</u></h6>
                              </div> : 
                              <></>
                            }
                          </div>

                        </div>

                        <div className="col-md-12 font-manrope-semibold fs-14 mb-3">
                          {
                            editDescription ? 
                            <textarea rows="5" className="col-md-12 font-manrope-regular" onChange={e => setDescription(e.target.value)}>{description}</textarea>
                            : description
                          }
                        </div>

                      </div>

                      <div className="card profile-sidebar mt-4 profile-radius drop-shadow">
                        
                        <div className="col-md-12 padding-0">

                          <div className="col-md-12">
                            <div className="col-md-8 fl">
                              <h4 className="mt-3 text-right font-skill font-manrope-bold">
                                Skills
                              </h4>
                            </div>
                            {
                              settings && isEditable ? 
                              <div className="col-md-4 p-0 text-right float-left">
                                <h6 className="mt-3 font-manrope-regular pointer" onClick={e => setEditUserSkillExpertise(!editUserSkillExpertise)}><u>{editUserSkillExpertise ? 'Cancel' : 'Edit'}</u></h6>
                              </div> : 
                              <></>
                            }
                          </div>

                        </div>

                        {<RenderUserProfileSkills/>}

                        <div className={"col-md-12" + ( editUserSkillExpertise && (userSkillOptions.some(v => v.is_other == 1) || userSkillOthers.length > 0) ? '': ' hide')}>
                          <input type="text" className="font-family-semibold form-control fs-14" placeholder="Press Enter to Add" value={userSkillOtherName} onKeyDown={e => handleUserSkillsKeyDown(e)} onChange={(e) => setUserSkillOtherName(e.target.value)} />
                          <br/>
                        </div>                      
                      
                      </div>

                      {/* <div className="card profile-sidebar profile-radius mt-4 drop-shadow">
                        
                        <div className="col-md-12 padding-0">

                          <div className="col-md-12 float-left">
                            <h4 className="text-center mt-3 font-skill font-manrope-bold">
                              Interests
                            </h4>
                          </div>
                          {
                            settings && isEditable ? 
                            <div className="col-md-4 text-right float-left">
                              <h6 className="mt-3 font-manrope-regular pointer" onClick={e => setEditUserInterests(!editUserInterests)}><u>{editUserInterests ? 'Cancel' : 'Edit'}</u></h6>
                            </div> : 
                            <></>
                          }

                        </div>

                        {<RenderUserProfileInterests/>}

                        {
                          editUserInterests ?
                          <div className="col-md-12">
                            <input type="text" className="form-control fs-14" placeholder="Press Enter to Add" value={userInterestName} onKeyDown={e => handleUserInterestsKeyDown(e)} onChange={e => setUserInterestName(e.target.value)} />
                            <br/>
                          </div>
                          :
                          <></>
                        }

                      </div> */}

                      {/* <div className="card profile-sidebar profile-radius mt-4 drop-shadow">
                        
                        <div className="col-md-12 padding-0">

                          <div className="col-md-12 float-left">
                            <h4 className="text-center mt-3 font-skill font-manrope-semibold">
                              Bank Account Details
                            </h4>
                          </div>
                          {
                            settings && isEditable ? 
                            <div className="col-md-3 text-right float-left">
                              <h6 className="mt-3 font-manrope-regular pointer" onClick={e => setBankAccountModalShow(!bankAccountModalShow)}><u>Edit</u></h6>
                            </div> : 
                            <></>
                          }

                        </div>

                        <div className="col-md-12">
                          <p className="color-black profile-font font-manrope-semibold pl-3"></p>
                          <p className="color-black profile-font font-manrope-semibold pl-3"></p>
                        </div>

                      </div> */}

                      {
                        isEditable ? 
                        <div className="card profile-sidebar profile-radius mt-4 drop-shadow">
                        
                          <div className="col-md-12 padding-0">

                            <div className="col-md-8 float-left">
                              <h4 className="text-center mt-3 font-skill font-manrope-semibold">
                                Bank Details
                              </h4>
                            </div>
                            {
                              settings && isEditable ? 
                              <div className="col-md-4 text-right float-left">
                                <h6 className="mt-3 font-manrope-regular pointer" onClick={e => setBankAccountModalShow(!bankAccountModalShow)}><u>Edit</u></h6>
                              </div> : 
                              <></>
                            }

                          </div>

                          <div className="col-md-12">
                            <p className="color-black profile-font font-manrope-semibold">{profile && profile.stripe_connect_account_id ? 'Updated': ''}</p>
                          </div>

                        </div>
                        :
                          <></>
                      }

                    </div>

                  </div>
                  <div className="col-md-9-5 marhgin-top-progress col-6">
                    <div className="markplace-pc">
                      <div className="row">
                        <div className="col-md-10 p-0 mt-4">
                          <button className={"fs-14 font-manrope-bold profile-toggle-gallery border-none px-3 py-2" + (profileToggleGalleryIdx == PROFILE_TOGGLE_GALLERY_IDX_PROJECTS ? ' active': '')} onClick={() => setProfileToggleGalleryIdx(PROFILE_TOGGLE_GALLERY_IDX_PROJECTS)}>Projects</button>&nbsp;&nbsp;&nbsp;
                          <button className={"fs-14 font-manrope-bold profile-toggle-gallery border-none px-3 py-2" + (profileToggleGalleryIdx == PROFILE_TOGGLE_GALLERY_IDX_SERVICES ? ' active': '')} onClick={() => setProfileToggleGalleryIdx(PROFILE_TOGGLE_GALLERY_IDX_SERVICES)}>Services</button>&nbsp;&nbsp;&nbsp;
                          <button className={"fs-14 font-manrope-bold profile-toggle-gallery border-none px-3 py-2" + (profileToggleGalleryIdx == PROFILE_TOGGLE_GALLERY_IDX_GROUPS ? ' active': '')} onClick={() => setProfileToggleGalleryIdx(PROFILE_TOGGLE_GALLERY_IDX_GROUPS)}>Groups</button>&nbsp;&nbsp;&nbsp;
                          <button className={"fs-14 font-manrope-bold profile-toggle-gallery border-none px-3 py-2" + (profileToggleGalleryIdx == PROFILE_TOGGLE_GALLERY_IDX_ALL ? ' active': '')} onClick={() => setProfileToggleGalleryIdx(PROFILE_TOGGLE_GALLERY_IDX_ALL)}>All</button>
                        </div>
                        <div className="col-md-2 padding-0">
                        </div>
                      </div>
                    </div>
                    <div className="markplace-pc">
                      <div className="row mt-4">
                        {<RenderListing/>}
                      </div>
                    </div>

                  </div>
                </div>
                <div className="row mt-4 markplace-phone">
                  <div className="col-md-12">
                  <div id="demo" className="carousel slide" data-ride="carousel">
                      <ul className="carousel-indicators">
                        <li
                          data-target="#demo"
                          data-slide-to="0"
                          className="active"
                        ></li>
                        <li data-target="#demo" data-slide-to="1"></li>
                        <li data-target="#demo" data-slide-to="2"></li>
                      </ul>
                      <div className="carousel-inner">
                        <div className="carousel-item active">
                          <div className="row">
                            <img
                              src={Background}
                              className="img-fluid mark-img-radius ml-2 mr-2"
                              alt="..."
                            />
                            <img
                              src={Background}
                              className="img-fluid mark-img-radius ml-2 mr-2"
                              alt="..."
                            />
                          </div>
                        </div>
                        <div className="carousel-item">
                        <div className="row">
                            <img
                              src={Background}
                              className="img-fluid mark-img-radius ml-2 mr-2"
                              alt="..."
                            />
                            <img
                              src={Background}
                              className="img-fluid mark-img-radius ml-2 mr-2"
                              alt="..."
                            />
                          </div>
                        </div>
                        <div className="carousel-item">
                        <div className="row">
                            <img
                              src={Background}
                              className="img-fluid mark-img-radius ml-2 mr-2"
                              alt="..."
                            />
                            <img
                              src={Background}
                              className="img-fluid mark-img-radius ml-2 mr-2"
                              alt="..."
                            />
                          </div>
                        </div>
                      </div>
                      {/* <a
                        className="carousel-control-prev"
                        href="#demo"
                        data-slide="prev"
                      >
                        <span className="carousel-control-prev-icon"></span>
                      </a>
                      <a
                        className="carousel-control-next"
                        href="#demo"
                        data-slide="next"
                      >
                        <span className="carousel-control-next-icon"></span>
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <Modal onHide={() => setProfileModalOpen(false)} show={profileModalOpen}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Profile</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {srcProfileImg && (
            <div>
              <ReactCrop  style={{maxWidth: "100%"}}  src={srcProfileImg}
                onImageLoaded={(img) => {
                  setProfileImage(img);
                  //this.makeClientCrop(crop);
                }}
                crop={crop}
                onChange={setCrop}
              />
                <div>
                  <button className="crop image-button" onClick={getCroppedImg}>Crop</button>
                </div>
                <br/>
              </div>
            )}
            {profileResult && (
              <div>
                <img src={profileResult} alt="cropped image"/>
              </div>
            )}
        </Modal.Body>

        <Modal.Footer>
          <button className="save-project-crop-image image-button" onClick={saveCroppedImage}>Save</button>
        </Modal.Footer>
      </Modal>


      <Modal show={createGroupModalShow} onHide={e => hideCreatGroupModal()}>
        
        <Modal.Header>
          <Modal.Title className="font-manrope-bold">Create Group</Modal.Title>
          <img width={18} onClick={e => hideCreatGroupModal()} className="pointer" src={crossX}/>
        </Modal.Header>

        <Modal.Body className="font-manrope-regular">
          <form onSubmit={e => createGroup(e)}>
          
            <div className="form-group">
              <label className="mobile-overview mb-1 font-manrope-bold">Name</label>
              <input value={groupName} onChange={e => setGroupName(e.target.value)} type="text" placeholder="Enter group name" className="fs-14 drop-shadow font-manrope-light form-control form-control-3" maxLength={255} />
            </div>

            <div className="form-group">
              <label className="mobile-overview mt-1 mb-1 font-manrope-bold">Invite Users</label>
              <input type="text" className="form-control-3 form-control font-manrope-light fs-14" placeholder="Search Users" value={userSearch} onChange={(e) => searchUsers(e.target.value)} />
              <div className="loader-holder text-center">{ userListingLoader ? <img src={buttonLoader} /> : <></> }</div>
              <div className="user-search-listing" onScroll={(e) => handleUserListingScroll(e)}><br/>{<RenderUsersListing exclude_group={false} />}</div>
            </div>
            <br/>

            <div className="form-group text-center">
              <button type="submit" className="font-manrope-regular bckg-color drop-shadow create-group-submit border-none fs-14 px-3 py-2">Create</button>
            </div>

          </form>
        </Modal.Body>

    </Modal>


    <Modal show={inviteToGroupModalShow} onHide={e => hideInviteToGroupModalShow()}>
        
        <Modal.Header>
          <Modal.Title className="font-manrope-bold">Invite to Group</Modal.Title>
          <img width={18} onClick={e => hideInviteToGroupModalShow()} className="pointer" src={crossX}/>
        </Modal.Header>

        <Modal.Body className="font-manrope-regular">
          <form onSubmit={e => inviteToGroup(e)}>
          
            <div className="form-group">
              <label className="font-manrope-bold fs-16" htmlFor="select-group">Select Group</label>
              {<RenderUserGroupList/>}
            </div>            

            {
              isEditable ? 
              <div className="form-group">
                <label className="font-manrope-bold fs-16" htmlFor="select-users">Invite Users</label>
                <input type="text" className="form-control-3 form-control font-manrope-light fs-14" placeholder="Search Users" value={userSearch} onChange={(e) => searchUsers(e.target.value)} />
                <div className="loader-holder text-center">{ userListingLoader ? <img src={buttonLoader} /> : <></> }</div>
                <div className="user-search-listing" onScroll={(e) => handleUserListingScroll(e)}><br/>{<RenderUsersListing exclude_group={true}/>}</div>              
              </div>
              :
              <></>
            }   

            <div className="form-group text-center">
              <button type="submit" className="font-manrope-regular bckg-color drop-shadow create-group-submit border-none fs-14 px-3 py-2">Invite</button>
            </div>

          </form>
        </Modal.Body>

    </Modal> 


    <Modal size="lg" show={bankAccountModalShow} onHide={e => setBankAccountModalShow(!bankAccountModalShow)}>
        
        <Modal.Header>
          <Modal.Title className="font-manrope-extra-bold">Bank Account and Business Details</Modal.Title>
          <img onClick={e => setBankAccountModalShow(!bankAccountModalShow)} className="pointer" src={crossX}/>
        </Modal.Header>

        <Modal.Body className="regular-font">
          <form onSubmit={e => saveBankDetails(e)}>

            <div className="col-md-12 padding-0 float-left">
              <div className="form-group col-md-6 float-left">
                <label for="key_phone"><h5 className="my-0 font-manrope-extra-bold">Phone Number</h5></label>
                <input id="key_phone" type="text" className="form-control drop-shadow fs-16" placeholder="Phone Number" value={businessPhone} onChange={e => setBusinessPhone(e.target.value)} />
              </div>
              <div className="form-group col-md-6 float-left">
                <label for="key_dob"><h5 className="my-0 font-manrope-extra-bold">Date of Birth</h5></label>
                <input id="key_dob" type="date" className="form-control drop-shadow fs-16" placeholder="Date of Birth" value={dob} onChange={e => setDob(e.target.value)} />
              </div>               
            </div>

            <div className="col-md-12 padding-0 float-left">
              <div className="col-md-6 float-left form-group">
                <label for="key_acc_no"><h5 className="my-0 font-manrope-extra-bold">Account Number</h5></label>
                <input id="key_acc_no" type='text' className="form-control drop-shadow fs-16" placeholder="Account Number" value={accountNumber} onChange={e => setAccountNumber(e.target.value)} />
              </div>
              <div className="col-md-6 float-left form-group">
                <label for="key_rounting_no"><h5 className="my-0 font-manrope-extra-bold">Routing Number</h5></label>
                <input id="key_routing_no" type='text' className="form-control drop-shadow fs-16" placeholder="Routing Number" value={routingNumber} onChange={e => setRoutingNumber(e.target.value)} />
              </div>                                                                 
            </div>  

            <div className="col-md-12 padding-0 float-left">                          
              <div className="form-group col-md-6 float-left">
                <label for="key_id_number"><h5 className="my-0 font-manrope-extra-bold">Tax ID Number</h5></label>
                <input id="key_id_number" type="text" className="form-control drop-shadow fs-16" placeholder="Tax ID Number" value={businessTaxId} onChange={e => setBusinessTaxId(e.target.value)} />
              </div>
            </div>
                                              
            <div className="form-group sf-pro mt-3">
              <button type="submit" className="fr btn-primary stripe-buttons" disabled={bankAccFormLoading}>Submit {bankAccFormLoading ? <i className='fa fa-circle-o-notch fa-spin'></i> : <></>}</button>
            </div>

          </form>
        </Modal.Body>

    </Modal>

    <ServiceInvitation submit_invitation_handler={navigateContributionRequest} data={serviceInvitationData} modal_show={serviceInvitationModal} set_modal_show={() => setServiceInvitationModal(!serviceInvitationModal)} set_modal_hide={() => onServiceModalHide()} />

    <AlertModal invitation_messages={invitationMessages} alerts_modal_show={alertsModalShow} set_alerts_modal_show={setAlertsModalShow} handle_message_scroll={handleAlertMessagesScroll}  read_alert_msg={readAlertMsg} respond_project_request={RespondProjectRequest} />

    </main>
  );
};

export default Profile2;

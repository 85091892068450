import { put, call, takeLatest } from "redux-saga/effects";
import { CONTRIBUTORS_LIST_REQUEST } from "../actionTypes/type";
import { actionSuccess, actionError } from "../actions/contributorsListingAction";
import { postApiCall } from "../../service/PostApiCall";
import { CONTRIBUTORS_GET } from "../../service/apiUrl";

export function* contributorsListingSaga(action) {
  try {
    const response = yield call(postApiCall, action.params, CONTRIBUTORS_GET);
    yield put(actionSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionError(error));
    action.onError();
  }
}

export function* watchContributorsListingSaga() {
  yield takeLatest(CONTRIBUTORS_LIST_REQUEST, contributorsListingSaga);
}

import {
    ADMIN_CONTRIBUTORS_LISTING_REQUEST,
    ADMIN_CONTRIBUTORS_LISTING_REQUEST_SUCCESS,
    ADMIN_CONTRIBUTORS_LISTING_REQUEST_ERROR,
  } from "../actionTypes/type";
  
  export const adminContributorsListRequest = (paramsData, onSuccess, onError) => {
    return {
      type: ADMIN_CONTRIBUTORS_LISTING_REQUEST,
      params: paramsData,
      onSuccess,
      onError
    };
  };
  
  export const actionSuccess = (result) => {
    return {
      type: ADMIN_CONTRIBUTORS_LISTING_REQUEST_SUCCESS,
      payload: result,
      error: null,
    };
  };
  
  export const actionError = (error) => {
    return {
      type: ADMIN_CONTRIBUTORS_LISTING_REQUEST_ERROR,
      error: error,
      payload: null,
    };
  };
  

  
  
import {
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_REQUEST_SUCCESS,
  ADMIN_LOGIN_REQUEST_ERROR,
  ADMIN_LOGOUT_REQUEST,
} from "../actionTypes/type";

export const adminloginRequest = (paramsData, onSuccess, onError) => {
  return {
    type: ADMIN_LOGIN_REQUEST,
    params: paramsData,
    onSuccess,
    onError
  };
};

export const actionSuccess = (result) => {
  return {
    type: ADMIN_LOGIN_REQUEST_SUCCESS,
    payload: result,
    error: null,
  };
};

export const actionError = (error) => {
  return {
    type: ADMIN_LOGIN_REQUEST_ERROR,
    error: error,
    payload: null,
  };
};

export function adminLogout() {
  return {
    type: ADMIN_LOGOUT_REQUEST,
    payload: {},
  };
}

import {
  PROJECT_SUPPLIES_REQUEST,
  PROJECT_SUPPLIES_REQUEST_ERROR,
  PROJECT_SUPPLIES_REQUEST_SUCCESS,
} from "../actionTypes/type";

const initialState = {
  data: null,
  isLoading: false
}

const projectSuppliesReducer = (state = initialState, action) => {
  let object;
  switch (action.type) {
    case PROJECT_SUPPLIES_REQUEST:
      object = {
        ...state,
        isLoading: true
      };
      break;

    case PROJECT_SUPPLIES_REQUEST_ERROR:
      object = {
        ...state,
        isLoading: false
      };
      break;

    case PROJECT_SUPPLIES_REQUEST_SUCCESS:
      object = {
        ...state,
        data: action.payload,
        isLoading: false
      };
      break;

    default:
      object = state;
      break;
  }
  return object;
}

export default projectSuppliesReducer;

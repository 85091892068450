import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { leftBack } from "../assets/images";
import { useNavigate, Link, useParams, useLocation } from "react-router-dom";
import "../assets/css/contribution.css";
import RangeSlider from "../components/slider";
import Utility from "../utility/utility";
import { getProjectDetailsRequest } from "../redux/actions/projectDetailsAction";
import { getUserProfileRequest } from "../redux/actions/userProfileAction";
import { projectContributionSkillRequest } from "../redux/actions/projectContributionSkillAction";
import { projectContributionMaterialRequest } from "../redux/actions/projectContributionMaterialAction";
import { stripeDetailsRequest } from "../redux/actions/stripeDetailsAction";
import {paymentRequest} from "../redux/actions/paymentAction";

const ProjectContribution = () => {

    //Get project id
    const { id } = useParams();
    const projectId = parseInt(id);

    const dispatch = useDispatch();
    const navigate = useNavigate();   
    const location = useLocation();

    const [activeClass, setActiveClass] = useState(1);
    const [contributionType, setContributionType] = useState('');
    const [projectHelp, setProjectHelp] = useState([]);
    const [projectEquipments, setProjectEquipments] = useState([]);
    const [projectSupplies, setProjectSupplies] = useState([]);
    const [contributionHelp, setContributionHelp] = useState({});
    const [contributionMaterials, setContributionMaterials] = useState({});
    const [userSkillsExpertise, setUserSkillsExpertise] = useState([]);
    const [contributionSkills, setContributionSkills] = useState({});
    const [ifPaid, setIfPaid] = useState(null);
    const [price, setPrice] = useState('');
    const [funds, setFunds] = useState(50);
    const [isStripeValid, setIsStripeValid] = useState(false);
    const [contributeTypeAs, setContributeTypeAs] = useState(undefined);
    const [loader, setLoader] = useState(false);
    const userStripeConnectId = useRef(null);
    const creatorStripeConnectId = useRef(null);
    
    useEffect( () => {

        const contributeTypeAs = location.state == null ? null : Number(location.state.user_group_id);

        if(contributeTypeAs == null || isNaN(contributeTypeAs)){
            Utility.showToast('error', 'Contributor group type not set');
        }else{
            setContributeTypeAs(contributeTypeAs);
        }

        userStripeConnectId.current = location.state.user_stripe_connect_id;
        creatorStripeConnectId.current = location.state.creator_stripe_connect_id;

        setIsStripeValid( Boolean(userStripeConnectId.current) && Boolean(creatorStripeConnectId.current) );

    },[location.state]);

    const UserData = useSelector(
        (state: RootStateOrAny) => state.loginReducer.data
    );
    
    const uid = UserData.id;

    useEffect( () => {

        dispatch(getProjectDetailsRequest( {id: projectId, showSuccessToast: false}, onProjectDetailsSuccess, onError) );

        dispatch( getUserProfileRequest({uid: uid, showSuccessToast: false}, onProfileSuccess, onError) );

        // dispatch(stripeDetailsRequest({ 'project_id': projectId, showSuccessToast: false }, function(resp){
        //     if(resp != null) setIsStripeValid(true);
        // }, onError));

    },[]);

    const handleContributionType = type => {
        if(type == 'f' && isStripeValid == false){}
        else setContributionType(type);
    }


    function onProjectDetailsSuccess(resp){

        const supplies = [], equipments = [], help = [];

        resp.requirements.forEach(function(val){
          help.push({val: val.id, label: val.name, is_other: val.is_other});
        });
        setProjectHelp(help);
    
        resp.supplies.forEach(function(val){
          supplies.push({val: val.id, supply_id: val.id, label: val.name, is_other: val.is_other, type: 's'});
        });
        setProjectSupplies(supplies);
    
        resp.equipments.forEach(function(val){
          equipments.push({val: val.id, equipment_id: val.id, label: val.name, is_other: val.is_other, type: 'e'});
        });
        setProjectEquipments(equipments);

    }


    const onProfileSuccess = resp => {
        setUserSkillsExpertise(resp.skills_expertise);
    }

    const onError = () => {
        console.log("Api Error");
        setLoader(false);
    }

    const next1 = () => {

        if(contributionType == ''){
            Utility.showToast('error', "Select contribution type");
        }else{
            if(contributionType == 's') setActiveClass(2);
            else if(contributionType == 'm') setActiveClass(3);
            else if(contributionType == 'f') setActiveClass(4);
        }

    }

    const next2 = () => {

        if( Object.keys(contributionHelp).length == 0 )
            Utility.showToast('error', "Please select an option");
        else
            setActiveClass(5);

    }

    const next3 = () => {

        if( Object.keys(contributionMaterials).length == 0 )
            Utility.showToast('error', "Please select an option");
        else
            setActiveClass(6);

    }

    const next4 = () => {

        if(funds == '' || Number(funds) == 0 || isNaN(funds)){
            Utility.showToast('error', "Please select the amount");
        }else{
            //navigate('/contributor', {state: {project_id: projectId, contribution_amount: parseFloat(funds), contribute_type_as: contributeTypeAs}});
            setLoader(true);
            dispatch(paymentRequest({
                amount: funds*100,
                currency: 'usd',
                source_acc_id: userStripeConnectId.current,
                destination_acc_id: creatorStripeConnectId.current,
                description: 'Contribution',
                project_id : projectId,
                uid: UserData.id,
                contribute_type_as: contributeTypeAs
            }, onPaymentSuccess, onError));
            
        }

    }

    const onPaymentSuccess = () => {
        setLoader(false);
        if(projectId > 0) 
          navigate(`/project/${projectId}`);
        else 
          navigate('/thankyou');
    }

    const next5 = () => {

        if( Object.keys(contributionSkills).length == 0 )
            Utility.showToast('error', "Please select an option");
        else
            setActiveClass(6);

    }

    const next6 = () => {

        if( ifPaid == null ){
            Utility.showToast('error', "Please select an option");
        }else{

            if(ifPaid){
                setActiveClass(7);
            } 
            else{
                if(contributionType == 's') submitServicesSkills();
                else if(contributionType == 'm') submitMaterials();
            } 

        }

    }

    const next7 = () => {
        
        if( price == '' || price == null ){
            Utility.showToast('error', "Please enter the price");
        }else{
            if(contributionType == 's') submitServicesSkills();
            else if(contributionType == 'm') submitMaterials();
        }

    }    


    const submitServicesSkills = () => {

        const payLoad = { project_id: projectId, uid: uid, role: 'c', skill_id: contributionSkills.id, is_other: contributionSkills.is_other, skill: contributionSkills.name, contribute_type_as: contributeTypeAs };
        dispatch(projectContributionSkillRequest( payLoad, function(resp){
            navigate(`/project/${projectId}`);
        }, 
        function(err){
            
        }
        ));

    }


    const submitMaterials = () => {

        const payLoad = {
            project_id: projectId,
            uid: uid,
            supplies: contributionMaterials.type == undefined || contributionMaterials.type == 'e' ? null : contributionMaterials,
            equipments: contributionMaterials.type == undefined || contributionMaterials.type == 's' ? null : contributionMaterials,
            contribute_type_as: contributeTypeAs
          }
    
          dispatch(projectContributionMaterialRequest( payLoad, function(resp){
            navigate(`/project/${projectId}`);
          }, 
          function(err){
    
          }
          ));

    }

    const handleSliderChange = val => {
       setFunds(val);
    }

    return (

        <div className="container project-edit">
            
                <div className="padding-0 col-md-12 mt-5">
                    
                    <div className="col-md-6 padding-0">
                        <h6 className="regular-font text-left">
                        <Link className="VisiaPro-ExtraBold fs-14" to="/home">
                            <img width="15" src={leftBack}></img>
                            &nbsp; Back to Home
                        </Link>
                        </h6>
                    </div>

                </div>

                <div className="jss13"><div className="MuiPaper-root MuiMobileStepper-root MuiMobileStepper-positionStatic fullWidth MuiPaper-elevation0"><div className="MuiLinearProgress-root MuiLinearProgress-colorPrimary MuiMobileStepper-progress MuiLinearProgress-determinate" role="progressbar" aria-valuenow="29" aria-valuemin="0" aria-valuemax="100"><div className="MuiLinearProgress-bar MuiLinearProgress-barColorPrimary MuiLinearProgress-bar1Determinate" style={{"transform": "translateX(100%)"}}></div></div></div></div>

                <div className={"jss5" + (activeClass == 1 ? '': ' hide')}><div className="jss6"><div className="MuiContainer-root jss30 jss32 MuiContainer-maxWidthLg"><div className="jss31"><div className="MuiBox-root jss35 jss33 jss34" state="entered"><div className="MuiGrid-root jss38 MuiGrid-container"><div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12"><h2 className="MuiTypography-root jss39 MuiTypography-h2 VisiaPro-ExtraBold text-center">What would you like to contribute?</h2></div><div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12">
                    <ul className="MuiList-root jss37 MuiList-padding">
                        {
                            projectHelp.length > 0 ? 
                            <li className="MuiListItem-root jss41 pill MuiListItem-gutters" onClick={e => handleContributionType('s')}><input className="jss44" id="selector.undefined.0" type="radio" name="" readonly="" value="services" checked={contributionType == 's'} /><div className="jss45 md" tabIndex="0"><div className="MuiGrid-root MuiGrid-container MuiGrid-wrap-xs-nowrap MuiGrid-align-items-xs-center"><div><p className="MuiTypography-root MuiTypography-body1 regular-font">Services</p></div></div></div></li>
                            :
                            <></>
                        }
                        {
                            projectEquipments.concat(projectSupplies).length > 0 ? 
                            <li className="MuiListItem-root jss41 pill MuiListItem-gutters" onClick={e => handleContributionType('m')}><input className="jss44" id="selector.undefined.1" type="radio" name="" readonly="" value="WITHIN_A_WEEK" checked={contributionType == 'm'} /><div className="jss45 md" tabIndex="0"><div className="MuiGrid-root MuiGrid-container MuiGrid-wrap-xs-nowrap MuiGrid-align-items-xs-center"><div><p className="MuiTypography-root MuiTypography-body1 regular-font">Materials</p></div></div></div></li>
                            :
                            <></>
                        }
                        { 
                            isStripeValid ?
                            <li className="MuiListItem-root jss41 pill MuiListItem-gutters" onClick={e => handleContributionType('f')}><input className="jss44" id="selector.undefined.2" type="radio" name="" readonly="" value="IN_1_2_MONTHS" checked={contributionType == 'f'} /><div className="jss45 md" tabIndex="0"><div className="MuiGrid-root MuiGrid-container MuiGrid-wrap-xs-nowrap MuiGrid-align-items-xs-center"><div><p className="MuiTypography-root MuiTypography-body1 regular-font">Funding</p></div></div></div></li>
                            : <></>
                        }
                    </ul>
                    </div><div className="MuiGrid-root jss36 MuiGrid-item MuiGrid-grid-xs-12 text-center mt-5 pl-5"><button className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-containedSizeLarge MuiButton-sizeLarge MuiButton-fullWidth regular-font" tabIndex="0" type="button" onClick={e => next1()}><span className="MuiButton-label">Next</span></button></div></div></div></div></div></div></div>

                <div className={"jss5" + (activeClass == 2 ? '': ' hide')}><div className="jss6"><div className="MuiContainer-root jss30 jss32 MuiContainer-maxWidthLg"><div className="jss31"><div className="MuiBox-root jss35 jss33 jss34" state="entered"><div className="MuiGrid-root jss38 MuiGrid-container"><div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12"><h2 className="MuiTypography-root jss39 MuiTypography-h2 VisiaPro-ExtraBold text-center">What services would you like to contribute?</h2></div><div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12"><ul className="MuiList-root jss37 MuiList-padding">
                    
                    {
                        projectHelp.map( h => {
                            return(
                                <li className="MuiListItem-root jss41 pill MuiListItem-gutters" onClick={e => setContributionHelp(h)}><input className="jss44" id="selector.undefined.0" type="radio" name="" readonly="" value={h.val} checked={contributionHelp.val != undefined & contributionHelp.val == h.val} /><div className="jss45 md" tabIndex="0"><div className="MuiGrid-root MuiGrid-container MuiGrid-wrap-xs-nowrap MuiGrid-align-items-xs-center"><div><p className="MuiTypography-root MuiTypography-body1 regular-font">{h.label}</p></div></div></div></li>
                            )
                        } )
                    }
                    
                </ul></div><div className="MuiGrid-root jss36 MuiGrid-item MuiGrid-grid-xs-12 text-center mt-5 pl-5"><button className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-containedSizeLarge MuiButton-sizeLarge MuiButton-fullWidth regular-font" tabIndex="0" type="button" onClick={e => next2()}><span className="MuiButton-label">Next</span></button></div></div></div></div></div></div></div>

                <div className={"jss5" + (activeClass == 3 ? '' : ' hide')}><div className="jss6"><div className="MuiContainer-root jss30 jss32 MuiContainer-maxWidthLg"><div className="jss31"><div className="MuiBox-root jss35 jss33 jss34" state="entered"><div className="MuiGrid-root jss38 MuiGrid-container"><div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12"><h2 className="MuiTypography-root jss39 MuiTypography-h2 VisiaPro-ExtraBold text-center">What materials would you like to contribute?</h2></div><div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12"><ul className="MuiList-root jss37 MuiList-padding">
                
                    {
                        projectEquipments.concat(projectSupplies).map( m => {
                            return(
                                <li className="MuiListItem-root jss41 pill MuiListItem-gutters" onClick={e => setContributionMaterials(m)}><input className="jss44" id="selector.undefined.0" type="radio" name="" readonly="" value={m.val} checked={contributionMaterials.val != undefined && contributionMaterials.val == m.val && contributionMaterials.is_other == m.is_other && contributionMaterials.type == m.type} /><div className="jss45 md" tabIndex="0"><div className="MuiGrid-root MuiGrid-container MuiGrid-wrap-xs-nowrap MuiGrid-align-items-xs-center"><div><p className="MuiTypography-root MuiTypography-body1 regular-font">{m.label}</p></div></div></div></li>
                            )
                        })
                    }
                
                </ul></div><div className="MuiGrid-root jss36 MuiGrid-item MuiGrid-grid-xs-12 text-center mt-5 pl-5"><button className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-containedSizeLarge MuiButton-sizeLarge MuiButton-fullWidth regular-font" tabIndex="0" type="button" onClick={e => next3()}><span className="MuiButton-label">Next</span></button></div></div></div></div></div></div></div>

                <div className={"jss5" + (activeClass == 4 ? '': ' hide')}><div className="jss6"><div className="MuiContainer-root jss30 jss32 MuiContainer-maxWidthLg"><div className="jss31"><div className="MuiBox-root jss35 jss33 jss34" state="entered"><div className="MuiGrid-root jss38 MuiGrid-container"><div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12"><h2 className="MuiTypography-root jss39 MuiTypography-h2 VisiaPro-ExtraBold text-center">How much funding would you like to contribute?</h2>
                <div className="mt-3" id="project-contribution-slider">
                <RangeSlider
                    label={""}
                    value={[50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700, 750, 800, 850, 900, 950, 1000]}
                    start={0}
                    handleSliderChange={handleSliderChange}
                />
                </div>
                </div></div></div></div></div>
                <div className="MuiGrid-root jss36 MuiGrid-item MuiGrid-grid-xs-12 text-center mt-5"><button className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-containedSizeLarge MuiButton-sizeLarge MuiButton-fullWidth regular-font" tabIndex="0" type="button" disabled={loader} onClick={e => next4()}><span className="MuiButton-label">Pay { loader ? <i className='fa fa-circle-o-notch fa-spin'></i>: <></>}</span></button></div>
                </div>
                </div>

                <div className={"jss5" + (activeClass == 5 ? '': ' hide')}><div className="jss6"><div className="MuiContainer-root jss30 jss32 MuiContainer-maxWidthLg"><div className="jss31"><div className="MuiBox-root jss35 jss33 jss34" state="entered"><div className="MuiGrid-root jss38 MuiGrid-container"><div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12"><h2 className="MuiTypography-root jss39 MuiTypography-h2 VisiaPro-ExtraBold text-center">What expertise do you have that can help this project?</h2></div><div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12"><ul className="MuiList-root jss37 MuiList-padding">
                
                    {
                        userSkillsExpertise.map( s => {
                            return(
                                <li className="MuiListItem-root jss41 pill MuiListItem-gutters" onClick={e => setContributionSkills(s)}><input className="jss44" id="selector.undefined.0" type="radio" name="" readonly="" value={s.id} checked={contributionSkills.id != undefined && contributionSkills.id == s.id} /><div className="jss45 md" tabIndex="0"><div className="MuiGrid-root MuiGrid-container MuiGrid-wrap-xs-nowrap MuiGrid-align-items-xs-center"><div><p className="MuiTypography-root MuiTypography-body1 regular-font">{s.name}</p></div></div></div></li>
                            )
                        } )
                    }
                
                </ul></div><div className="MuiGrid-root jss36 MuiGrid-item MuiGrid-grid-xs-12 text-center mt-5 pl-5"><button className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-containedSizeLarge MuiButton-sizeLarge MuiButton-fullWidth regular-font" tabIndex="0" type="button" onClick={e => next5()}><span className="MuiButton-label">Next</span></button></div></div></div></div></div></div></div>

                <div className={"jss5" + (activeClass == 6 ? '': ' hide' )}><div className="jss6"><div className="MuiContainer-root jss30 jss32 MuiContainer-maxWidthLg"><div className="jss31"><div className="MuiBox-root jss35 jss33 jss34" state="entered"><div className="MuiGrid-root jss38 MuiGrid-container"><div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12"><h2 className="MuiTypography-root jss39 MuiTypography-h2 VisiaPro-ExtraBold text-center">Would you like to get paid for your services?</h2></div><div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12">
                    {ifPaid == true ? <p>Must enter bank details in order to get paid</p> : <></>}
                    <ul className="MuiList-root jss37 MuiList-padding">
                    <li className="MuiListItem-root jss41 pill MuiListItem-gutters" onClick={e => setIfPaid(true)}><input className="jss44" id="selector.undefined.0" type="radio" name="" readonly="" value={true} checked={ifPaid == true} /><div className="jss45 md" tabIndex="0"><div className="MuiGrid-root MuiGrid-container MuiGrid-wrap-xs-nowrap MuiGrid-align-items-xs-center"><div><p className="MuiTypography-root MuiTypography-body1 regular-font">Yes</p></div></div></div></li><li className="MuiListItem-root jss41 pill MuiListItem-gutters" onClick={e => setIfPaid(false)}><input className="jss44" id="selector.undefined.1" type="radio" name="" readonly="" value={false} checked={ifPaid == false} /><div className="jss45 md" tabindex="0"><div className="MuiGrid-root MuiGrid-container MuiGrid-wrap-xs-nowrap MuiGrid-align-items-xs-center"><div><p className="MuiTypography-root MuiTypography-body1 regular-font">No thanks</p></div></div></div></li>
                </ul></div><div className="MuiGrid-root jss36 MuiGrid-item MuiGrid-grid-xs-12 text-center mt-5 pl-5"><button className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-containedSizeLarge MuiButton-sizeLarge MuiButton-fullWidth regular-font" tabIndex="0" type="button" onClick={e => next6()}><span className="MuiButton-label">Next</span></button></div></div></div></div></div></div></div>

                <div className={"jss5" + (activeClass == 7 ? '': ' hide')}><div className="jss6"><div className="MuiContainer-root jss30 jss32 MuiContainer-maxWidthLg"><div className="jss31"><div className="MuiBox-root jss35 jss33 jss34" state="entered"><div className="MuiGrid-root jss38 MuiGrid-container"><div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12"><h2 className="MuiTypography-root jss39 MuiTypography-h2 VisiaPro-ExtraBold text-center">Please select the price for your services.</h2></div><div className="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12">
                    <div className="mt-2 mb-2 text-center">
                        <label className="text-left MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled regular-font" data-shrink="true" for="zipCode" id="zipCode-label">Price US$</label>
                        <div className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl MuiInputBase-adornedEnd"><input aria-invalid="false" id="zipCode" name="zipCode" type="number" className="pl-3 MuiInputBase-input MuiInput-input MuiInputBase-inputAdornedEnd" value={price} onChange={e => setPrice(e.target.value)} aria-describedby="zipCode-helper-text" placeholder="0.00" /><div className="MuiInputAdornment-root MuiInputAdornment-positionEnd"></div></div>
                    </div>    
                </div><div className="MuiGrid-root jss36 MuiGrid-item MuiGrid-grid-xs-12 text-center mt-5 pl-5"><button className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-containedSizeLarge MuiButton-sizeLarge MuiButton-fullWidth regular-font" tabIndex="0" type="button" onClick={e => next7()}><span className="MuiButton-label">Next</span></button></div></div></div></div></div></div></div>

        </div>

    );

}

export default ProjectContribution;

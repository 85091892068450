import { put, call, takeLatest } from "redux-saga/effects";
import { ADMIN_USER_REQUEST, ADMIN_USER_PROFILE_REQUEST, ADMIN_USER_DELETE_REQUEST} from "../actionTypes/type";
import { actionSuccess, actionError, actionProfileSuccess, actionProfileError  } from "../actions/adminUserListAction";
import { postApiCall } from "../../service/PostApiCall";
import { ADMIN_USER_LISTING_URL, ADMIN_USER_PROFILE_URL, ADMIN_USER_DELETE_URL} from "../../service/apiUrl";

export function* adminUserListSaga(action) {
  try {
    const response = yield call(postApiCall, action.params, ADMIN_USER_LISTING_URL);
    yield put(actionSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionError(error));
    action.onError();
  }
}

export function* watchadminUserListSaga() {
  yield takeLatest(ADMIN_USER_REQUEST, adminUserListSaga);
}

export function* adminUserProfileSaga(action) {
  try {
    const response = yield call(postApiCall, action.params, ADMIN_USER_PROFILE_URL);
    yield put(actionProfileSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionProfileError(error));
    action.onError();
  }
}

export function* watchadminUserProfileSaga() {
  yield takeLatest(ADMIN_USER_PROFILE_REQUEST, adminUserProfileSaga);
}

export function* adminuserDeleteSaga(action) {
  try {
    const response = yield call(postApiCall, action.params, ADMIN_USER_DELETE_URL);
    yield put(actionSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionError(error));
    action.onError();
  }
}

export function* watchadminUserDeleteSaga() {
  yield takeLatest(ADMIN_USER_DELETE_REQUEST, adminuserDeleteSaga);
}
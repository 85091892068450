import {
    STRIPE_BANK_ACCOUNT_REQUEST,
    STRIPE_BANK_ACCOUNT_REQUEST_ERROR,
    STRIPE_BANK_ACCOUNT_REQUEST_SUCCESS
  } from "../actionTypes/type";
  
  const initialState = {
    data: null,
    isLoading: false
  };
  
  const stripeBankAccountUpdateReducer = (state = initialState, action) => {
    let object;
    switch (action.type) {
      case STRIPE_BANK_ACCOUNT_REQUEST:
        object = {
          ...state,
          isLoading: true
        };
        break;
  
      case STRIPE_BANK_ACCOUNT_REQUEST_ERROR:
        object = {
          ...state,
          isLoading: false
        };
        break;
  
      case STRIPE_BANK_ACCOUNT_REQUEST_SUCCESS:
        object = {
          ...state,
          data: action.payload,
          isLoading: false
        };
        break;
  
      default:
        object = state;
        break;
    }
    return object;
  }
  
  export default stripeBankAccountUpdateReducer;
  
import {
  PROJECT_INVITATION_RESPOND_REQUEST,
  PROJECT_INVITATION_RESPOND_REQUEST_ERROR,
  PROJECT_INVITATION_RESPOND_REQUEST_SUCCESS,
} from "../actionTypes/type";
const initialState = {
  data: null,
};

const projectInvitationRespondReducer = (state = initialState, action) => {
  let object;

  switch (action.type) {
    case PROJECT_INVITATION_RESPOND_REQUEST:
      object = {
        ...state,
      };
      break;

    case PROJECT_INVITATION_RESPOND_REQUEST_ERROR:
      object = {
        ...state,
      };
      break;

    case PROJECT_INVITATION_RESPOND_REQUEST_SUCCESS:
      object = {
        ...state,
        // data: action.payload,
      };
      break;

    default:
      object = state;
      break;
  }
  return object;
};

export default projectInvitationRespondReducer;

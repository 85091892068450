import {
    STRIPE_DETAILS_SAVE_REQUEST,
    STRIPE_DETAILS_SAVE_REQUEST_ERROR,
    STRIPE_DETAILS_SAVE_REQUEST_SUCCESS,
  } from "../actionTypes/type";
  const initialState = {
    data: null
  };
  
  const stripeSaveReducer = (state = initialState, action) => {
    let object;
  
    switch (action.type) {
      case STRIPE_DETAILS_SAVE_REQUEST:
        object = {
          ...state
        };
        break;
  
      case STRIPE_DETAILS_SAVE_REQUEST_ERROR:
        object = {
          ...state,
        };
        break;
  
      case STRIPE_DETAILS_SAVE_REQUEST_SUCCESS:
        object = {
          ...state,
          // data: action.payload,
        };
        break;
  
      default:
        object = state;
        break;
    }
    return object;
  };
  
  export default stripeSaveReducer;
  
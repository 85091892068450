/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import {
  circularTick
} from "../assets/images";
import { useNavigate } from "react-router-dom";

const Thankyou = props => {

  const navigate = useNavigate();

  const navigateHome = () => {
    document.body.classList.remove('background-congratulation', 'congratulation');
    document.getElementsByTagName("html")[0].style.minHeight="auto";
    navigate("/home");
  }

  useEffect( () => {
    document.getElementsByTagName("html")[0].style.minHeight="100%";
    document.body.classList.add('background-congratulation', 'congratulation');
  }, ['background-congratulation']);

  return (
    <>
      <div className="background-congratulation congratulation">
        <div className="col-md-12 text-center margin-top ">
          <img src={circularTick} />
        </div>
        <div className="col-md-12 margin-cong">
          <h1 className="text-center text-white regular-font">Congratulations!</h1>
          <p className="text-center text-white mt-4 regular-font">
            {props.message}
          </p>
        </div>
        <div className="col-md-12 text-center mt-4">
          <button
            onClick={navigateHome}
            type="button"
            className="text-white button-back regular-font shadow"
          >Back to Home
          </button>
          <br/><br/>
        </div>
      </div>
    </>
  );
};

export default Thankyou;

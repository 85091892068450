import {
    SERVICE_CREATE_REQUEST,
    SERVICE_CREATE_REQUEST_ERROR,
    SERVICE_CREATE_REQUEST_SUCCESS,
  } from "../actionTypes/type";
  const initialState = {
    data: null,
  };
  
  const serviceCreateReducer = (state = initialState, action) => {
    let object;
  
    switch (action.type) {
      case SERVICE_CREATE_REQUEST:
        object = {
          ...state,
        };
        break;
  
      case SERVICE_CREATE_REQUEST_ERROR:
        object = {
          ...state,
        };
        break;
  
      case SERVICE_CREATE_REQUEST_SUCCESS:
        object = {
          ...state,
          // data: action.payload,
        };
        break;
  
      default:
        object = state;
        break;
    }
    return object;
  };
  
  export default serviceCreateReducer;
  
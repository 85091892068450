/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Bell,
  Message,
  ProfileImage,
  Background,
  leftBack2,
  Setting,
  Flag,
  flag,
  BackgroundLight,
  Cross,
  crossX,
  addNewMessage,
  preLoader,
  buttonLoader,
  chat_dm,
  chat_group
} from "../assets/images";
import { useNavigate, Link } from "react-router-dom";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Sidebar from "../components/Sidebar";
import firebase from '../../firebase';
import moment from "moment";
import {getUserListingRequest} from "../redux/actions/userListingAction";
import {getAuthorizedProjectsListingRequest} from "../redux/actions/authorizedProjectsListingAction";
import {getProjectAuthorizedUsersListingRequest} from "../redux/actions/projectAuthorizedUsersListingAction";
import {groupCreateRequest} from "../redux/actions/groupCreateAction";
import {getUserGroupsRequest} from "../redux/actions/userGroupsListingAction";
import {getGroupUsersRequest} from "../redux/actions/groupUsersListingAction";
import {userReportRequest} from "../redux/actions/userReportAction";
import {BASE_URL} from "../service/environment";
import Utility from "../utility/utility";

const CHAT_PROJECTS = "chats/projects/";
const CHAT_INDIVIUALS = "chats/individuals/";
const CHAT_GROUPS = "chats/groups/";
const COLLECTION_PROJECTS = "projects/";
const COLLECTION_INDIVIDUALS = "users/";
const MAX_PROJECT_USERS_TOP_LISTING = 7;
const USER_DEFAULT_PROFILE_PIC = `${BASE_URL}/images/user_dummy.png`;
const USER_PROFILE_PIC_PATH = `${BASE_URL}/images/user/`;
const KEY_INDIVIDUAL = 'u';
const KEY_GROUP = 'g';
const TOGGLE_GROUP = 'tg';
const TOGGLE_USER = 'tu';
const KEY_CREATE_GROUP = 'c';

const LIMIT_USER_LISTING = 8;
var userListingOffset = 0, userSearchTimeout = null, userScrollTimeout = null;

const MessageGroups = () => {

  const chatsContainerElem = document.getElementById('chats-container');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [showNew, setShowNew] = useState(true);
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [cgroups, setCgroups] = useState([]);
  const [chatMsg, setChatMsg] = useState('');
  const [chatImg, setChatImg] = useState(null);
  const [chatImgName, setChatImgName] = useState('');
  const [chats, setChats] = useState([]);
  const [loader, setLoader] = useState(true);
  const [groupUsers, setGroupUsers] = useState([]);
  const [activeGroupName, setActiveGroupName] = useState('');
  const [groupUsersCard, setGroupUsersCard] = useState(false);
  const [userSearch, setUserSearch] = useState('');
  const [userListing, setUserListing] = useState([]);
  const [userListingLoader, setUserListingLoader] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [toggleGroupUser, setToggleGroupUser] = useState(TOGGLE_USER);
  const [createGroupUsers, setCreateGroupUsers] = useState([]);
  const [userBlockModalShow, setUserBlockModalShow] = useState(false);
  const [userReport, setUserReport] = useState(null);

  const [state, setState] = React.useState({
    activeGroupKey: null,
    activeGroupKey2: null
  });

  const UserData = useSelector(
    (state: RootStateOrAny) => state.loginReducer.data
  );

  const uid = UserData.id;

  useEffect(() => {

    (async () => {
        firebase.database().ref(CHAT_INDIVIUALS).on('value', resp => {
          
          const returnArr = [];
          const val = resp.val();
          const otherUsers = [];

          for(let i in val){

            const uidssArr = i.split('-');
            const uids = uidssArr.map(uid => parseInt(uid));
            if(uids.includes(uid)){
           
              const otherUid = uids[0] == uid ? uids[1] : uids[0];

              firebase.database().ref(COLLECTION_INDIVIDUALS + otherUid).on('value', response => {
                const user = response.val();
                user.key = otherUid;
                user.is_profile_url = false;
                otherUsers.push(user);
              });
              
            }

          }     
          
          setUsers(otherUsers);
          setLoader(false);
          renderProjectsAsGroups();
          scrollToBottom();
        
        });
    })();  

  }, []);


  function renderProjectsAsGroups(){

    //Get list of all projects the current user is authorized to
    dispatch(getAuthorizedProjectsListingRequest({uid: uid, showSuccessToast: false}, onProjectListingSuccess, onError));   
    document.body.style.overflow = "hidden";
    //dispatch(getUserListingRequest({offset:userListingOffset, limit:LIMIT_USER_LISTING, name:userSearch, showSuccessToast:false}, onUserListingRequestSuccess, onError));

  }


  function renderCreatedGroups(){
    //Get list of all the created groups
    dispatch(getUserGroupsRequest({uid: uid, showSuccessToast: false}, onCreatedGroupsSuccess, onError));   
  }


  const onUserListingRequestSuccess = resp => {
    setUserListingLoader(false);
    setUserListing(resp);
  }


  const searchUsers = val => {

    setUserSearch(val);
    clearTimeout(userSearchTimeout);
    userSearchTimeout = setTimeout(() => {
      if (val) {
        setUserListingLoader(true);
        userListingOffset = 0;
        dispatch(getUserListingRequest({offset:userListingOffset, limit:LIMIT_USER_LISTING, name:val, showSuccessToast:false}, onUserListingRequestSuccess, onError));
      }
    }, 1500);    

  }


  /*
  * Function to send request to fetch all authorized users (creator and contributor) for the given project
  */
  const requestProjectAuthorizedUsersListing = (project_id) => {
    dispatch(getProjectAuthorizedUsersListingRequest({project_id: project_id, showSuccessToast: false}, onUserListingSuccess, onError));
  }


  //List of all projects the current user is authorized to
  function onProjectListingSuccess(projects){

    (async () => {
      firebase.database().ref(COLLECTION_PROJECTS).on('value', resp => {
        setGroups([]);
        setGroups(filterProjects(projects, resp));
        setLoader(false);
        scrollToBottom();
        renderCreatedGroups();
      });

    })();

  }


  async function onCreatedGroupsSuccess(resp){
    setCgroups(resp);
  }

  //List of all users authorized for the current project
  //resp : An array of user objects
  function onUserListingSuccess(resp){
    setGroupUsers(resp);
  }


  //Render project users on topbar, at max we'll show top MAX_PROJECT_USERS_TOP_LISTING no. of users
  function RenderGroupUsersTop(){

    return(
      <>
      {
        groupUsers.filter( (option, i) => {
          return i < MAX_PROJECT_USERS_TOP_LISTING
        }).map( (user, i) => (
          //If this user is the creator of the project, then show (Project-Creator) next to it
          user.firstname + ' ' + user.lastname + (user.role ? " (" + user.role + ")" : '') + (i == groupUsers.length - 1 ? ' ' : ', ')
        ))
      }
      </>
    );

  }


  /*
  * Filter projects based on the current user authorization, i.e is render only those projects
  * which the current user is authorized to (projects created or contributed by the current user)
  */
  const filterProjects = (projects, snapshot) => {
    
    const returnArr = [];

    snapshot.forEach((childSnapshot) => {
      const item = childSnapshot.val();
      const key = childSnapshot.key;
      if(projects.some(v => v.id == key)){
        item.key = key;
        returnArr.push(item);
      }
    });

    return returnArr;

  }

  function onError(err){
    console.log("Api Error", err);
    setUserListingLoader(false);
  }


  function navigateToUserProfile(uid){
    const modal = document.getElementById('projectUserListingModal');
    modal.querySelector('button.close').click();
    document.body.style.overflow = "auto";
    navigate(`/profile/${uid}`);
  }

  useEffect(() => {

    const key = state.activeGroupKey;

    if(key != null){

      const keyId = parseInt(key.substring(1));
      const keyChar = key.charAt(0);

      if(keyChar == KEY_GROUP){

        setGroupUsersCard(true);

        firebase.database().ref(`${CHAT_PROJECTS}${keyId}/`).orderByChild('timestamp').on('value', resp => {
          setChats(snapshotToArray(resp));
          scrollToBottom();
        });

      }else if(keyChar == KEY_INDIVIDUAL){

        setGroupUsersCard(false);
        const userGroupId = getUserGroupdId(keyId);

        firebase.database().ref(`${CHAT_INDIVIUALS}${userGroupId}/`).orderByChild('timestamp').on('value', resp => {
          setChats(snapshotToArray(resp));
          scrollToBottom();
        });

      }else if(keyChar == KEY_CREATE_GROUP){

        setGroupUsersCard(true);

        firebase.database().ref(`${CHAT_GROUPS}${keyId}/`).orderByChild('timestamp').on('value', resp => {
          setChats(snapshotToArray(resp));
          scrollToBottom();
        });        

      }

      if(keyChar == KEY_GROUP){
      
        requestProjectAuthorizedUsersListing(keyId);
      
        const activeGroup = groups.filter( group => {
          return group.key == keyId
        });

        if(activeGroup.length > 0){
          setActiveGroupName(activeGroup[0].name);
        }

      }
      else if(keyChar == KEY_CREATE_GROUP){
        
        //Send request to fetch users for the given group
        dispatch( getGroupUsersRequest({group_id: keyId, showSuccessToast: false}, function(resp){
          setGroupUsers(resp);
        }) );

      }

    }

  },[state.activeGroupKey]);

  const RenderGroupUsers = () => {

    return(
      <>
      {
        groupUsers.map( (user, i) => (

          <div key={i} className="container">
            <div className="row">
              <div className="col-md-2">
                <img onClick={() => navigateToUserProfile(user.id)} src={user.profile_pic} className="pointer img-fluid sidebar-messages" alt="user profile picture" />
              </div>
              <div className="col-md-5 p-0 margin-left-sms">
                <h6 className="pointer font-manrope-regular" onClick={() => navigateToUserProfile(user.id)}>{user.firstname} {user.lastname}</h6>
                {user.creator == 1 ? <p className="creatore-message margin-top">Project Creator</p> : <></>}
              </div>
              <div className="col-md-5 text-right">
                <p className="creatore-message mt-2 font-manrope-regular">{user.projects_associated_count == 1 ? `${user.projects_associated_count} other project` : `${user.projects_associated_count} other projects` }</p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="border"></div>
              </div>
            </div>
          </div>

        ))
      }
      </>
    );

  }


  const RenderChats = function(){

    const dates = new Set();

    return(
        <>
        {

          chats.map( (option, i) => (
            <>
            {
              dates.has(option.date) 
              ? <></> 
              : 
              <div key={i} className="row mt-5">
                <p className="hide">{dates.add(option.date)}</p>
                <div className="col-md-5 col-5"><div className="border mt-2"></div></div>
                <div className="col-md-2 col-2 text-center"><p className="font-time font-manrope-regular">{option.date}</p></div>
                <div className="col-md-5 col-5"><div className="border mt-2"></div></div>
              </div>
            }   
            <div className="row">
              {<SetMsgAlignment uid={option.uid} />}
              <div className="col-md-6 col-10">
                {<RenderChatMessage message={option.message} uid={option.uid} image={option.image} />}
              </div>
            </div>   
            </>  
          ))
        }
        </>
      );  

  }  


  const RenderDate = props => {

    props.dates.add(props.date);
    return <p>{props.date}</p>
    
  }


  // const RenderChats = function(){

  //   return(
  //     <>
  //     {

  //       chats.map( (option, i) => (
  //         <>
  //         <div key={i} className={"row mt-5" + (i == 0 ? '': ' hide')}>
  //           <div className="col-md-5 col-5"><div className="border mt-2"></div></div>
  //           <div className="col-md-2 col-2 text-center"><p className="font-time font-manrope-regular">{option.date}</p></div>
  //           <div className="col-md-5 col-5"><div className="border mt-2"></div></div>
  //         </div>
  //         <div className="row">
  //               {<SetMsgAlignment uid={option.uid} />}
  //               <div className="col-md-6 col-10">
  //                 {<RenderChatMessage message={option.message} uid={option.uid} image={option.image} />}
  //               </div>
  //         </div>   
  //         </>  
  //       ))
  //     }
  //     </>
  //   );

  // }


  function groupedDays(messages) {

    return messages.reduce((acc, el, i) => {
      const messageDay = moment(el.timestamp).format('ll');
      if (acc[messageDay]) {
        return { ...acc, [messageDay]: acc[messageDay].concat([el]) };
      }
      return { ...acc, [messageDay]: [el] };
    }, {});

  }  

  const SetMsgAlignment = function(prop){

    //If the message's author is current user
    if(prop.uid == uid){
      return(
        <>
        {
          <div className="col-md-6 col-2"></div>
        }
        </>
      );
    }else{
      return(<></>);
    }

  }

  const RenderChatMessage = function(prop){

        //'c' : Created groups, 'g': Project groups, 'u': Individual users
        const type =  state.activeGroupKey.charAt(0);

        return(
        <>
          {
            <>
            <RenderImage image={prop.image}/>       
            <div className={"card " + (prop.uid == uid ? "bg-message-pink" : "bg-message-grey") + " mt-3 w-96 float-right drop-shadow-2"}>
              <div className="col-md-12">
                <h6 className="message-font-individual mt-1 font-manrope-regular">
                  {prop.message}
                  {
                    <RenderUserName uid={prop.uid} type={type} user={groupUsers.find(u => u.id == prop.uid)} />
                  }
                </h6>
              </div>
            </div> 
            </>       
          }
        </>
        );
    //if(prop.message){}
    // else if(prop.image){
    //   return(
    //     <>
    //     {
    //       <div className="mt-4">
    //         <img src={prop.image} className="img-fluid message-border-top w-96 float-right drop-shadow-2" alt="chat image" />
    //       </div>
    //     }
    //     </>
    //   );
    // }

  }

  const RenderImage = prop => {

    if(prop.image){
      
      return(
        <div className="mt-4">
          <img src={prop.image} className="img-fluid message-border-top w-96 float-right drop-shadow-2" alt="chat image" />
        </div>
      );

    }else{

      return (<></>);

    }

  }


  const RenderUserName = prop => {

    if( (prop.type == KEY_CREATE_GROUP || prop.type == KEY_GROUP) && (prop.uid != uid) ){

      return (
        <>
          <hr/>
          <span onClick={() => prop.user ? navigateToUserProfile(prop.user.id) : console.log('prop.user.id')} className={"chat-user-name" + (prop.user ? ' pointer': '')}>{ 
            prop.user ? `${prop.user.firstname} ${prop.user.lastname} ${prop.user.role ? ` (${prop.user.role})` : ''}` : 'Username'
          }</span>
        </>
      );
      
    }else{

      return(<></>);

    }

  }

  const snapshotToArray = (snapshot) => {
    const returnArr = [];

    snapshot.forEach((childSnapshot) => {
      const item = childSnapshot.val();
      item.key = childSnapshot.key;
      returnArr.push(item);
    });

    return returnArr;
  }

  const setActiveGroup = function(target){
    setLoader(true);
    setState({ activeGroupKey: target.getAttribute('data-id') });
    setLoader(false);
  }

  const setActiveGroupKeyByIdx = function(idx, key){
    if(idx == 0 && state.activeGroupKey == null) setState({ activeGroupKey: key });
  }

  //To submit a new chat message (when user press enter)
  const handleChatKeyDown = function(e){

    if(e.key == "Enter"){
      const msg = e.target.value.trim();
      if(msg != '' || chatImg != null) submitChatMsg(msg);
    }

  }

  const submitChatMsg = async function(msg){
    
    setLoader(true);

    const dateObj = new Date();
    const chat = {
      type: 'message',
      message: msg,
      timestamp: dateObj.getTime(),
      uid: uid,
      date: moment().format('ll')
    }

    const key = state.activeGroupKey.charAt(0);
    const keyId = parseInt(state.activeGroupKey.substring(1));

    if(key == KEY_GROUP){

      chat.image = (chatImg == null) ? null : await getChatImgUrl(chatImg, CHAT_PROJECTS);

      firebase.database().ref(`${CHAT_PROJECTS}${keyId}/`).push().set(chat).then(function(resp){
        scrollToBottom();
      });

    }else if(key == KEY_INDIVIDUAL){

      chat.image = (chatImg == null) ? null : await getChatImgUrl(chatImg, CHAT_INDIVIUALS);

      const userGroupId = getUserGroupdId(keyId);
      firebase.database().ref(`${CHAT_INDIVIUALS}${userGroupId}/`).push().set(chat).then(function(resp){
        scrollToBottom();
      });

    }else if(key == KEY_CREATE_GROUP){

      chat.image = (chatImg == null) ? null : await getChatImgUrl(chatImg, CHAT_GROUPS);

      firebase.database().ref(`${CHAT_GROUPS}${keyId}/`).push().set(chat).then(function(resp){
        scrollToBottom();
      });

    }

    setChatMsg('');
    updateChatImgName('');
    setLoader(false);
    setChatImg(null);

  }

  const uploadChatImg = (e) => {

    const file = e.target.files[0];
    const fileName = getFileName(file.type);

    if(file != null && file != undefined){
      const reader = new FileReader();
      reader.readAsDataURL(file); 
      reader.onload = function() {   
        setChatImg(reader.result);
      }
      updateChatImgName(file.name);
      document.getElementById('close-chat-img-modal').click();
    }

  }

  const getFileName = function(fileType){

    const fileExt = {'image/png' : '.png', 'image/jpg' : '.jpg', 'image/jpeg' : '.jpg'};
    const dateObj = new Date();
    return dateObj.getTime() + fileExt[fileType];

  }

  const uploadImageToCloud = async function(chat_img_data, dir){

    // Create a root reference
    const storageRef = firebase.storage().ref();

    const y = `${dir}${chatImgName}`;

    // Create a reference to the image
    const imageRef = storageRef.child(`${dir}${chatImgName}`);

    return imageRef.putString(chat_img_data, 'data_url').then((snapshot) => {
  
      if(snapshot.state == "success"){
        return snapshot.ref.getDownloadURL().then(function(downloadURL) {
          return downloadURL;
        });
      }

      return null;

    });

  }

  const getChatImgUrl = async function(chat_img_data, dir){
    return await uploadImageToCloud(chat_img_data, dir);
  }

  const updateChatImgName = function(name){
    setChatImgName(name);
  }

  const RenderGroups = function(){

    return(
      <>
      {
        groups.map( (option, i) => (
                    
          <>{ setActiveGroupKeyByIdx(i, KEY_GROUP + option.key) }
          <div key={i} data-key={i} data-id={KEY_GROUP + option.key} onClick={(e) => setActiveGroup(e.currentTarget)} className={"messages groups pointer" + (KEY_GROUP + option.key == state.activeGroupKey ? ' active' : '')}>
            <div className="col-md-12 px-3 py-2 col-12">
              <div className="row">
                <div className="col-md-3 col-3">
                  <img src={Background} className="img-fluid sidebar-message" alt="..." />
                </div>
                <div className="col-md-6 col-6 p-0 margin-left-font">
                  <h6 className="font-manrope-bold">{option.name}</h6>
                  <p className="sidebar-font font-manrope-regular color-text-gray">City, State</p>
                </div>
                <div className="col-md-3 col-3">
                  <i className="fa fa-users" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="border-bottom"></div>
          </div>
          </>

        ))
      }
      </>
    );

  }


  const RenderCreatedGroups = function(){

    return(
      <>
      {
        cgroups.map( (option, i) => (
                    
          <>{ setActiveGroupKeyByIdx(i, KEY_CREATE_GROUP + option.id) }
          <div key={i} data-key={i} data-id={KEY_CREATE_GROUP + option.id} onClick={(e) => setActiveGroup(e.currentTarget)} className={"messages groups pointer" + (KEY_CREATE_GROUP + option.id == state.activeGroupKey ? ' active' : '')}>
            <div className="col-md-12 px-3 py-2 col-12">
              <div className="row">
                <div className="col-md-3 col-3">
                  <i class="fa fa-group created-group-icon"></i>
                </div>
                <div className="col-md-6 col-6 p-0 margin-left-font">
                  <h6 className="font-manrope-bold">{option.name}</h6>
                  <p className="sidebar-font font-manrope-regular color-text-gray">City, State</p>
                </div>
                <div className="col-md-3 col-3">
                  <i className="fa fa-users" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="border-bottom"></div>
          </div>
          </>

        ))
      }
      </>
    );

  }  


  const RenderUsers = function(){

    return(
      <>
      {
        users.map( (option, i) => (
                    
          <>{ setActiveGroupKeyByIdx(i, KEY_INDIVIDUAL + option.key) }
          <div key={i} data-key={i} data-id={KEY_INDIVIDUAL + option.key} onClick={(e) => setActiveGroup(e.currentTarget)} className={"messages groups pointer" + (KEY_INDIVIDUAL + option.key == state.activeGroupKey ? " active" : '')}>
            <div className="col-md-12 px-3 py-2 col-12">
              <div className="row">
                <div className="col-md-3 col-3">
                  <img onClick={() => navigateProfile(option.key)} src={option.is_profile_url == true ? option.profile_pic : (option.profile_pic ? USER_PROFILE_PIC_PATH + option.profile_pic : USER_DEFAULT_PROFILE_PIC)} className="img-fluid sidebar-message" alt="user profile" />
                </div>
                <div className="col-md-6 col-6 p-0 margin-left-font">
                  <h6 onClick={() => navigateProfile(option.key)} className="font-manrope-bold">{option.first_name} {option.last_name}</h6>
                  <p className="sidebar-font font-manrope-regular color-text-gray">{option.city}, {option.country}</p>
                </div>
                <div className="col-md-3 col-3">
                  <img onClick={() => toggleUserBlockModalShow(true, option)} src={flag} />
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="border-bottom"></div>
          </div>
          </>

        ))
      }
      </>
    );

  }  


  const scrollToBottom = function(){

    if(chatsContainerElem != null){
      chatsContainerElem.scrollTo(0, chatsContainerElem.scrollHeight);
    }

  }

  /*
   * Function to create user doc group between two users for one to one chat
   * We concatenate the two uids (given uid and current uid) according to which one is
   * smaller. The smaller uid precedes the larget uid.
  */
  const getUserGroupdId = given_uid => {
    return (given_uid > uid) ? uid + '-' + given_uid : given_uid + '-' + uid;
  }


  const RenderUserListing = () => {

    if(userListing.length == 0){

      return(<p className="text-center font-manrope-regular">No Records</p>);

    }else{

      if(toggleGroupUser == TOGGLE_GROUP){

        return(
          <>
          {
            userListing.map( (option, i) => (
              <div className="col-md-12 fl pt-2 pb-2 user-group-container">
                <div className="user-info col-md-7 fl text-left">
                  <img width="35" src={option.profile_pic} className="project-team-user-image organizer-profile-pic" alt="user profile" onClick={(e) => navigateProfile(option.id)} />
                  <span className="ml-3 mt-3 project-team-user font-manrope-semibold" onClick={(e) => navigateProfile(option.id)}>{option.firstname} {option.lastname}<br></br>
                    <span className="text-grey font-creatore project-team-designation"></span>
                  </span>  
                </div>
                <div className="col-md-5 fl font-manrope-regular">
                  {
                    createGroupUsers.includes(option.id) ? <p className="money-green">Added</p> : <button className="add-user-to-group drop-shadow-3 bckg-color border-none" type="text" onClick={(e) => addUserToCreateGroup(e, option.id)}>Add</button>
                  }
                </div>
              </div>
            ))
          }
          </>
        );
    

      }else{

        return(
          <>
          {
            userListing.map( (option, i) => (
              <div className="col-md-12 fl pt-2 pb-2 user-group-container">
                <div className="user-info col-md-7 fl text-left">
                  <img width="35" src={option.profile_pic} className="project-team-user-image organizer-profile-pic" alt="user profile" onClick={(e) => navigateProfile(option.id)} />
                  <span className="ml-3 mt-3 project-team-user font-manrope-semibold" onClick={(e) => navigateProfile(option.id)}>{option.firstname} {option.lastname}<br></br>
                    <span className="text-grey font-creatore project-team-designation"></span>
                  </span>  
                </div>
                <div className="col-md-5 fl font-manrope-regular">
                  <button className="add-user-to-group drop-shadow-3 bckg-color border-none" type="text" onClick={e => addUserConversation(option)}>Message</button>
                </div>
              </div>
            ))
          }
          </>
        );
    

      }

    }

  }


  const addUserToCreateGroup = (e, uid) => {
    e.target.classList.add('active');
    e.target.textContent = "Added";
    setCreateGroupUsers([...createGroupUsers, uid]);
  }

  const handleUserListingScroll = e => {

    const listingDiv = e.target;

    if (listingDiv.offsetHeight + listingDiv.scrollTop >= listingDiv.scrollHeight-1) {  
      
      setUserListingLoader(true);
      clearTimeout(userScrollTimeout);
      userScrollTimeout = setTimeout(function(){
        userListingOffset += LIMIT_USER_LISTING;
        dispatch(getUserListingRequest({offset:userListingOffset, limit:LIMIT_USER_LISTING, name:userSearch, showSuccessToast:false}, function(resp){
          setUserListing(userListing.concat(resp));
        }, onError));
        setUserListingLoader(false);
      }, 2500);

    }

  }


  const removeImage = e => {
    updateChatImgName('');
    setChatImg(null);
  }


  const navigateProfile = id =>{
    closeUserListingModal();
    navigate("/profile/"+id);
  }

  const closeUserListingModal = () => {
    const modal = document.querySelector("div.modal#userListingModal");
    if(modal != null) modal.querySelector('button.close').click();
  }


  const addUserConversation = user => {

    //check if selected user already exists in the chat listing
    const ifUserExists = users.some(u => u.key === user.id);

    if(ifUserExists == false){

      const userObj = {
        key: user.id,
        city: user.city ? user.city : "City",
        country: user.country ? user.country : "Country",
        first_name: user.firstname,
        last_name: user.lastname,
        profile_pic: user.profile_pic,
        is_profile_url: true
      }
      setUsers([...users, userObj]);

      setTimeout( () => {
        closeUserListingModal();
        //Activate the user conversation
        document.querySelector(`.messages.groups.pointer[data-id="${KEY_INDIVIDUAL}${user.id}"]`).click();
      }, 500 );

    }

  }


  const switchGroupUser = () => {

    if(toggleGroupUser == TOGGLE_GROUP)
      setToggleGroupUser(TOGGLE_USER);
    else
      setToggleGroupUser(TOGGLE_GROUP);

    resetToggleUserGroupModal();

  }


  /*
  * Function to render users for groups or direct messages
  */
  const RenderToggleGroupsAndUsers = () => {

    return(<>
      { userListingLoader ? <img src={buttonLoader} /> : <></> }
      <div className="user-search-listing" onScroll={(e) => handleUserListingScroll(e)}><br/>{<RenderUserListing/>}</div>      
    </>);

  }


  const createGroup = () => {

    setCreateGroupUsers([]);

    let message = "";
    if (Utility.isEmpty(groupName.trim()))
      message = "Enter group";
    else if (createGroupUsers.length == 0)
      message = "Add user to the group";
    
    if (message != '') {
      Utility.showToast("error", message);
      return false;
    }

    createGroupUsers.push(uid);
    dispatch(groupCreateRequest({name: groupName, users: createGroupUsers}, async function(resp){
      
      const insertedId = resp.insert_id;
      const groupObj = {id: insertedId, name: groupName.trim()};
      setCgroups([...cgroups, groupObj]);

      closeUserListingModal();
      setCreateGroupUsers([]);
      resetToggleUserGroupModal();

    }, onError));   
    
  }


  const resetToggleUserGroupModal = function(){
    setUserListing([]);
    setGroupName('');
    setUserSearch('');
  }


  const navigateToHome = () => {
    document.body.style.overflow = "auto";
    navigate('/home');
  }


  const reportUser = e => {
    
    if(userReport && userReport.key){

      dispatch( userReportRequest({uid_reported: userReport.key, uid_reporting: uid}, function(){
        setUserBlockModalShow(false);
      }, onError) );  

    }else{

      Utility.showToast('error', "Invalid reported user");

    }

  }

  const toggleUserBlockModalShow = (state, user) => {
    console.log("user:", user);
    setUserReport(user);
    setUserBlockModalShow(state);
  }

  return (
    <>
      <div className="container individual-message" style={{"maxWidth":"100%"}}>
        <div className="row mess-pos">
          <div className="">
        <div className="sidebar">
          <div className="container" style={{"maxWidth":"100%"}}>
            <div className="col-md-12 col-12">
              <h1 className="mt-5 font-manrope-bold msg-text">Messages <img data-toggle="modal" data-target="#userListingModal" src={addNewMessage} className="fr pointer" /></h1>
            </div>
            <div className="borders mt-3"></div>
          </div>
          <div className="overflow">
            {<RenderUsers/>}
            {<RenderGroups/>}
            {<RenderCreatedGroups/>}
          </div>
          <div className="container">
            <div className="borders"></div>
          </div>
          <div>
            <a href="#" className="link-home font-manrope-bold" onClick={navigateToHome}><img width="15" src={leftBack2} />&nbsp;Back to Home</a>
          </div>
          <div className="col-md-12 hide position-abs ml-3 col-12">
            <div className="row positon-bottom-sidebar">
              <div className="mt-1">
                <img src={Bell} className="img-fluid  w-mobilw" alt="..." />
              </div>
              <div className="ml-3">
                <h5 className="font-manrope-extra-bold">Back to Home</h5>
              </div>
            </div>
          </div>
        </div>
        </div>
        <div className="">
        <div className="side-slider-message"> <i className="fa fa-caret-left d-none" aria-hidden="true"></i></div>
        </div>
        </div>
        <div className="content">

          <div className={"card position-set" + (groupUsersCard ? '': ' hide')}>
            <div className="container">
              <div className="col-md-12 col-12">
                <div className="row">
                  <div className="col-md-10 col-10">
                    <p className="header-color-message font-manrope-regular">
                      {<RenderGroupUsersTop/>}{groupUsers.length > MAX_PROJECT_USERS_TOP_LISTING ? ', ' + (groupUsers.length - MAX_PROJECT_USERS_TOP_LISTING) + ' Others' : ''}
                    </p>
                  </div>
                  <div className="col-md-2 col-2">
                    <p
                      className="header-color-message font-manrope-regular"
                      data-toggle="modal"
                      data-target="#projectUserListingModal"
                    >
                      See All
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div id="chats-container" className="overflow-y">
              {<RenderChats/>}
              <br/>
              <div className="col-md-12 position-input-set">
                <div className="row">
                  <div className="col-md-12">
                    <div className="bg-camera text-center drop-shadow fl">
                      <i className="fa fa-camera mt-1" aria-hidden="true" data-target="#exampleModalsr" data-toggle="modal"></i>
                    </div>
                    {
                      chatImgName ? 
                      <><span id="chat-image-name">&nbsp;{chatImgName}</span>&nbsp;
                      <img className="pointer" onClick={(e) => removeImage(e)} src={crossX} width="12" /> 
                      </>
                      : 
                      <></>
                    }
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-11 col-11">
                    <div className="card bg-signup-2 card-height-locations">
                      <div className="col-md-12">
                        <h6 className="mb-0 location-font mt-2 font-manrope-semibold color-text-gray">
                          Press Enter To Send
                        </h6>
                        <div className="form-group">
                          <input onKeyDown={(e)=>handleChatKeyDown(e)}
                          type="text" value={chatMsg}
                          onChange={(e)=>setChatMsg(e.target.value)}
                          className="form-control input-message-enter font-manrope-bold color-text-gray fs-16"
                          placeholder="Type Message"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade centered"
          id="exampleModals"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog centered modal-sm">
            <div className="modal-content siteImageButton m-50">
              <div className="modal-body">
                <div className="text-center">
                  <h1 className="model-font-side">Block/Report User?</h1>
                </div>
                <div className="row mt-4">
                  <div className="col-md-5">
                    <img
                      src={ProfileImage}
                      className="img-fluid  float-right pop--img-size"
                      alt="..."
                    />
                  </div>
                  <div className="col-md-6 p-0 mt-1">
                    <h6>Johany Blank</h6>
                    <p className="model-font-side">New York,New York</p>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-6 float-right">
                    <button
                      type="text"
                      className="float-right popup-cancel-message"
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col-md-6">
                    <button type="text" className="popup-report-message">
                      Report User
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade centered"
          id="exampleModalsr"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog centered">
            <div className="modal-content siteImageButton m-50 radius-choose-camera">
              <div className="modal-body">
               <div className="card set-width-message">
                 <div className="text-center">
                  <div className="chat-image-upload">
                    <input type="file" className="chat-image" accept="image/*" onChange={uploadChatImg} />
                    <i className="fa fa-camera font-camera-message pointer" aria-hidden="true"></i>
                  </div>
                  <h4 className="choose-photo-text">Choose Photo</h4>
               </div>
               </div>
               <br/>
               <div className="text-right"><button id="close-chat-img-modal" type="button" className="hide btn-close btn btn-secondary" data-dismiss="modal" aria-label="Close">Close</button></div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="projectUserListingModal" data-backdrop="static" data-keyboard="false"
          tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="float-left col-md-12">
                  <button type="button" className="pointer close float-left sms-close-font" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="col-md-12 text-center margin-group-1">
                    <h3 className="font-manrope-regular">Group {activeGroupName}</h3>
                    <h3 className="font-manrope-regular">Group Chat</h3>
                  </div>
                  {<RenderGroupUsers/>}
                </div>
              </div>
            </div>
          </div>
        </div>



        <Modal show={userBlockModalShow} onHide={() => setUserBlockModalShow(false)}>
        
        <Modal.Header>
          <Modal.Title className="font-manrope-extra-bold fs-26">Block/Report User?</Modal.Title>
          <img onClick={() => setUserBlockModalShow(false)} className="pointer" src={crossX}/>
        </Modal.Header>

        <Modal.Body className="font-manrope-regular">
          <div className="row mt-4">
            <div className="col-md-5">
              <img src={userReport && userReport.profile_pic ? USER_PROFILE_PIC_PATH + userReport.profile_pic : ProfileImage} className="img-fluid  float-right pop--img-size col-md-8"
                alt="..." />
            </div>
            <div className="col-md-6 p-0 mt-1">
              <h6 className="font-manrope-extra-bold">{userReport && userReport.first_name ? userReport.first_name: ''} {userReport && userReport.last_name ? userReport.last_name: ''}</h6>
              <p className="model-font-side font-manrope-regular fs-16">{userReport && userReport.city ? userReport.city: 'City'}, {userReport && userReport.country ? userReport.country: 'Country'}</p>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-6 float-right">
              <button type="text" onClick={() => setUserBlockModalShow(false)}
                className="float-right popup-cancel-message">
                Cancel
              </button>
            </div>
            <div className="col-md-6">
              <button onClick={e => reportUser()} type="text" className="popup-report-message">
                Report User
              </button>
            </div>
          </div>          
        </Modal.Body>

    </Modal>

      <div className="modal fade" id="userListingModal" data-backdrop="static" data-keyboard="false"
        tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="float-left col-md-12">
              <button type="button" className="pointer close float-right sms-close-font" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-md-12 text-center margin-group-1">
                <div className="text-left">
                  <span onClick={() => switchGroupUser()} className="pointer">{toggleGroupUser == TOGGLE_GROUP ? <img src={chat_dm} /> : <img src={chat_group} />}</span>
                  {toggleGroupUser == TOGGLE_GROUP ? <button className="fr py-1 ml-2 mr-2 create-group bckg-color border-none font-manrope-regular" onClick={(e) => createGroup()} type="text">Create</button> : <></>}
                </div>
                <br />
                {
                  (toggleGroupUser == TOGGLE_GROUP) ?
                    <>  <input type="text" className="form-control drop-shadow new-user-search font-manrope-regular fs-16" placeholder="New Group Name" value={groupName} onChange={(e) => setGroupName(e.target.value)} />
                      <br />
                    </>
                    : <></>
                }
                <input type="text" className="form-control drop-shadow new-user-search font-manrope-regular fs-16" placeholder="Search Users" value={userSearch} onChange={(e) => searchUsers(e.target.value)} />
                {<RenderToggleGroupsAndUsers />}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={"chat-loader" + (loader ? '' : ' hide')}><p className="color-black"><img src={preLoader} />Please Wait</p></div>
    </>
  );
};

export default MessageGroups;

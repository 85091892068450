import {
  PROJECT_FOCUS_REQUEST,
  PROJECT_FOCUS_REQUEST_ERROR,
  PROJECT_FOCUS_REQUEST_SUCCESS,
} from "../actionTypes/type";

const initialState = {
  data: null,
  isLoading: false
}

const projectFocusReducer = (state = initialState, action) => {
  let object;
  switch (action.type) {
    case PROJECT_FOCUS_REQUEST:
      object = {
        ...state,
        isLoading: true
      };
      break;

    case PROJECT_FOCUS_REQUEST_ERROR:
      object = {
        ...state,
        isLoading: false
      };
      break;

    case PROJECT_FOCUS_REQUEST_SUCCESS:
      object = {
        ...state,
        data: action.payload,
        isLoading: false
      };
      break;

    default:
      object = state;
      break;
  }
  return object;
}

export default projectFocusReducer;

import { put, call, takeLatest } from "redux-saga/effects";
import { PROJECT_DETAILS_REQUEST } from "../actionTypes/type";
import { actionSuccess, actionError } from "../actions/userListingAction";
import { postApiCall } from "../../service/PostApiCall";
import { PROJECT_DETAILS_URL } from "../../service/apiUrl";

export function* projectDetailsSaga(action) {
  try {
    const response = yield call(postApiCall, action.params, PROJECT_DETAILS_URL);
    yield put(actionSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionError(error));
    action.onError();
  }
}

export function* watchProjectDetailsSaga() {
  yield takeLatest(PROJECT_DETAILS_REQUEST, projectDetailsSaga);
}

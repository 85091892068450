/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from "react";
import {
  leftBack,
  choose4,
  crossX, 
  addNewPhoto
} from "../assets/images";
import Utility from "../utility/utility";
import { useDispatch, useSelector } from "react-redux";
// import { useLocation } from "react-router";
import { useNavigate, Link, useParams } from "react-router-dom";
import { getUserListingRequest } from "../redux/actions/userListingAction";
import { getProjectDetailsRequest } from "../redux/actions/projectDetailsAction";
import { projectUpdateRequest } from "../redux/actions/projectUpdateAction";
import { getProjectAuthorizedUsersListingRequest } from "../redux/actions/projectAuthorizedUsersListingAction";
import { projectInviteRequest } from "../redux/actions/projectInviteAction";
// import { getProjectHelpRequest } from "../redux/actions/projectHelpAction";
// import { getProjectSuppliesRequest } from "../redux/actions/projectSuppliesAction";
// import { getProjectEquipmentsRequest } from "../redux/actions/projectEquipmentsAction";
import { projectImageInsertRequest } from "../redux/actions/projectImageInsertAction";
import { projectImageDeleteRequest } from "../redux/actions/projectImageDeleteAction";
import { projectContributionSkillRequest } from "../redux/actions/projectContributionSkillAction";
import { projectContributionMaterialRequest } from "../redux/actions/projectContributionMaterialAction";
import { stripeAccountPaymentTransferRequest } from "../redux/actions/stripeAccountPaymentTransferAction";
import { stripeDetailsRequest } from "../redux/actions/stripeDetailsAction";
import UserGroupsList from "../components/user_groups_list";
import { getProjectSuppliesRequest } from "../redux/actions/projectSuppliesAction";
import { getProjectEquipmentsRequest } from "../redux/actions/projectEquipmentsAction";

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Modal from 'react-bootstrap/Modal';
import SweetAlert from "react-bootstrap-sweetalert";

const PROFILE_TYPE_GROUP = 'g';
const PROFILE_TYPE_PERSON = 'p';

const ROLE_CONTRIBUTOR = 'c';
const ROLE_ORGANIZER = 'o';
const LIMIT_USER_LISTING = 8;
var userListingOffset = 0, userSearchTimeout = null, userScrollTimeout = null;

const Project = (props) => {

  //Get project id
  const { id } = useParams();
  const projectId = parseInt(id);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showNew, setShowNew] = useState(true);
  const [searchOrgName, setSearchOrgName] = useState("");
  const [projectDetails, setProjectDetails] = useState({});
  const [projectUsers, setProjectUsers] = useState([]);
  const [userListing, setUserListing] = useState([]);
  const [modalOrgLoader, setModalOrgLoader] = useState(false);
  const [contributorAmt, setContributorAmt] = useState('');
  const [contributorAmtErrorMsg, setContributorAmtErrorMsg] = useState('');
  const [contributorTalentSkillErrorMsg, setContributorTalentSkillErrorMsg] = useState('');
  const [contributorMaterialErrorMsg, setContributorMaterialErrorMsg] = useState('');
  const [currUserContributedAmnt, setCurrUserContributedAmnt] = useState(0);
  const [isEditable, setIsEditable] = useState(false);
  const [isEditAuthentic, setIsEditAuthentic] = useState(false);
  const [requirement, setRequirement] = useState('');
  const [requirements, setRequirements] = useState([]);
  const [consideration, setConsideration] = useState('');
  const [considerations, setConsiderations] = useState([]);
  const [projectDesc, setProjectDesc] = useState('');
  const [projectRequirements, setProjectRequirements] = useState([]);
  const [projectConsiderations, setProjectConsiderations] = useState([]);
  const [projectPassion, setProjectPassion] = useState('');
  const [projectImpact, setProjectImpact] = useState('');
  const [projectBenefit, setProjectBenefit] = useState('');
  const [projectAwareness, setProjectAwareness] = useState('');
  const [projectOrganizer, setProjectOrganizer] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [projectOtherSkill, setProjectOtherSkill] = useState('');
  const [projectOtherSkillList, setProjectOtherSkillList] = useState([]);
  const [projectSupplies, setProjectSupplies] = useState([]);
  const [projectSuppliesRemoved, setProjectSuppliesRemoved] = useState([]);
  const [projectSuppliesAdded, setProjectSuppliesAdded] = useState([]);
  const [ProjectSuppliesOther, setProjectSuppliesOther] = useState('');
  const [ProjectSuppliesOtherList, setProjectSuppliesOtherList] = useState([]);
  const [ProjectSuppliesOtherAdded, setProjectSuppliesOtherAdded] = useState([]);
  const [projectSuppliesOptions, setProjectSuppliesOptions] = useState([]);
  const [projectEquipmentOther, setProjectEquipmentOther] = useState('');
  const [ProjectEquipmentsOtherList, setProjectEquipmentsOtherList] = useState([]);  
  const [projectEquipments, setProjectEquipments] = useState([]);
  const [projectEquipmentsRemoved, setProjectEquipmentsRemoved] = useState([]);
  const [projectEquipmentsOptions, setProjectEquipmentsOptions] = useState([]);
  const [projectEquipmentsAdded, setProjectEquipmentsAdded] = useState([]);
  const [ProjectEquipmentsOtherAdded, setProjectEquipmentsOtherAdded] = useState([]);
  const [projectHelp, setProjectHelp] = useState([]);
  const [projectBudget, setProjectBudget] = useState(undefined);
  const [projectResourcesCount, setProjectResourcesCount] = useState(0);
  const [projectCompensation, setProjectCompensation] = useState(0);
  //save the image that used to be crop
  const [image, setImage] = useState(null);
  const [srcImg, setSrcImg] = useState(null);
  //save the resulted image
  const [croppedResult, setCroppedResult] = useState(null);
  const [crop, setCrop] = useState({aspect: 1, unit: '%', x: 25, y: 5, width: 50, height: 50});
  const [result, setResult] = useState(null);
  const [imageFeatured, setImageFeatured] = useState(0);
  const [projectImages, setProjectImages] = useState([]);
  const [role, setRole] = useState(null);
  const [talentSkill, setTalentSkill] = useState(null);
  const [materialSupplies, setMaterialSupplies] = useState(null);
  const [materialEquipments, setMaterialEquipments] = useState(null);
  const [languages, setLanguages] = useState([]);
  const [contributorPayModalShow, setContributorPayModalShow] = useState(false);
  const [contributorPayAmt, setContributorPayAmt] = useState('');
  const [contributorPayAcc, setContributorPayAcc] = useState(null);
  const [showInviteOrgModal, setShowInviteOrgModal] = useState(false);
  const [inviteOrgUser, setInviteOrgUser] = useState({});
  const [invitedOrgUids, setInvitedOrgUids] = useState([]);
  const [isStripeValid, setIsStripeValid] = useState(false);
  const [selectGroupModal, setSelectGroupModal] = useState(false);
  const [userGroupId, setUserGroupId] = useState(undefined);  
  const createrStripeConnectId = useRef(null);

  const UserData = useSelector(
    (state: RootStateOrAny) => state.loginReducer.data
  );

  const uid = UserData.id;
  const userGroups = UserData.groups;
  const currentUserStripeConnectId = UserData.stripe_connect_account_id;
  const userGroupIds = userGroups.reduce( function(arr, obj){
    arr.push(obj.id);
    return arr;
  },[] );

  const UserGroupListOptions = UserGroupsList({ type: 'react-select', groups: userGroups, selected_group: userGroupId, set_user_group_id: setUserGroupId });
  const [constributeAsSelf, setContributeAsSelf] = useState(false);
  const [contributeAsGroup, setContributeAsGroup] = useState(false);

  useEffect( () => {

    dispatch(getProjectDetailsRequest( {id: projectId, showSuccessToast: false}, onProjectDetailsSuccess, onError) );
    dispatch(getProjectAuthorizedUsersListingRequest({project_id: projectId, showSuccessToast: false, stripe_filter: true}, onProjectUsersSuccess, onError));

    dispatch(getUserListingRequest({offset:userListingOffset, limit:LIMIT_USER_LISTING, name:searchOrgName, include_groups: true, showSuccessToast:false}, onUserListingRequestSuccess, onError));

    dispatch(stripeDetailsRequest({ 'project_id': projectId, showSuccessToast: false }, function(resp){
      if(resp != null){
        createrStripeConnectId.current = resp.stripe_connect_account_id;
        setIsStripeValid(true && Boolean(currentUserStripeConnectId) && Boolean(createrStripeConnectId.current) );
      } 
    }, onError));

    if(projectSuppliesOptions.length == 0)
      dispatch(getProjectSuppliesRequest({}, onSuppliesRequestSuccess, onError));
    if(projectEquipmentsOptions.length == 0)
      dispatch(getProjectEquipmentsRequest({}, onEquipmentsRequestSuccess, onError));

  },[]);

  const onSuppliesRequestSuccess = resp => {
    setProjectSuppliesOptions(resp.filter(val => val.is_other == 0));
  }

  const onEquipmentsRequestSuccess = resp => {
    setProjectEquipmentsOptions(resp.filter(val => val.is_other == 0));
  }

  useEffect( () => {

    //Calculate total amount (funds) contributed by the current user for the given project
    const contributedAmntTotal = projectUsers.filter( u => {
      return u.id == uid && u.amount > 0
    } ).reduce( (acc, u) => {
      return acc + Number( parseFloat(u.amount) );
    }, 0);

    setCurrUserContributedAmnt(contributedAmntTotal);

  },[projectUsers]);


  const validateContributorAmt = (val) => {
    if( isNaN(Number(val)) == false ){
      setContributorAmt(val);
    }
  }

  function onProjectDetailsSuccess(resp){
    
    setProjectDetails(resp);
    setProjectDesc(resp.description ?? '');
    setProjectPassion(resp.passion ?? '');
    setProjectImpact(resp.impact ?? '');
    setProjectBenefit(resp.benefit ?? '');
    setProjectAwareness(resp.awareness ?? '');
    setProjectOrganizer(resp.partnership_organization ?? '');
    setProjectRequirements(resp.requirements);
    setProjectConsiderations(resp.considerations);
    setProjectImages(resp.images);
    setProjectBudget(resp.budget ? resp.budget: undefined);
    setProjectResourcesCount( Number(resp.resources_count) );
    setProjectCompensation( Number(resp.resource_contribution) * Number(resp.resources_count) );

    const supplies = [], equipments = [], help = [], suppliesOther = [], equipmentsOther = [];

    resp.requirements.forEach(function(val){
      help.push({val: val.id, label: val.name, is_other: val.is_other});
    });
    setProjectHelp(help);

    resp.supplies.filter(val => val.is_other == 0).forEach(function(val){
      supplies.push({val: val.id, supply_id: val.id, label: val.name, is_other: val.is_other, option_id: val.option_id});
    });
    setProjectSupplies(supplies);

    resp.supplies.filter(val => val.is_other == 1).forEach(function(val){
      suppliesOther.push({val: val.id, supply_id: val.id, label: val.name, is_other: val.is_other, option_id: val.option_id, if_other: true});
    });
    setProjectSuppliesOtherList(suppliesOther);    

    resp.equipments.filter(val => val.is_other == 0).forEach(function(val){
      equipments.push({val: val.id, equipment_id: val.id, label: val.name, is_other: val.is_other, option_id: val.option_id});
    });
    setProjectEquipments(equipments);

    resp.equipments.filter(val => val.is_other == 1).forEach(function(val){
      equipmentsOther.push({val: val.id, equipment_id: val.id, label: val.name, is_other: val.is_other, option_id: val.option_id, if_other: true});
    });
    setProjectEquipmentsOtherList(equipmentsOther);

    setLanguages(resp.languages);

  }

  const authorizeProjectGroups = val => {
    return val.profile_type == 'g' && userGroupIds.includes(val.id);
  }

  const authorizeProjectUsers = val => {
    return val.profile_type == 'p' && val.id == uid;
  }

  function onProjectUsersSuccess(resp){
    
    setProjectUsers(resp);
    //If current user is creator or contributor or organizer or sponsor. If yes, then enabled
    //the EDIT option and INVITE button
    const ifUserAuthentic = resp.reduce(function(initial, val){
      return (initial || authorizeProjectUsers(val) || authorizeProjectGroups(val) );
    }, false);
    setIsEditAuthentic(ifUserAuthentic);

  }

  function onError(){
    console.log("Api error");
  }

  const searchOrganizers = event => {

    setModalOrgLoader(true);
    const searchVal = event.target.value.trim(); 
    setSearchOrgName(searchVal);
    clearTimeout(userSearchTimeout);
    
    userSearchTimeout = setTimeout(function(){
      userListingOffset = 0;
      dispatch(getUserListingRequest({offset:userListingOffset, limit:LIMIT_USER_LISTING, name:searchVal, include_groups: true, showSuccessToast:false}, onUserSearchRequestSuccess, onError));
      setModalOrgLoader(false);
    }, 2500);

  }

  const handleOrgListingScroll = (e) => {
    
    const listingDiv = e.target;

    if (listingDiv.offsetHeight + listingDiv.scrollTop >= listingDiv.scrollHeight) {  
      
      setModalOrgLoader(true);
      clearTimeout(userScrollTimeout);
      userScrollTimeout = setTimeout(function(){
        userListingOffset += LIMIT_USER_LISTING;
        dispatch(getUserListingRequest({offset:userListingOffset, limit:LIMIT_USER_LISTING, name:searchOrgName, include_groups: true, showSuccessToast:false}, onUserListingRequestSuccess, onError));
        setModalOrgLoader(false);
      }, 2500);

    }

  }

  function onUserListingRequestSuccess(resp){
    setUserListing([...userListing,...resp]);
  }

  function onUserSearchRequestSuccess(resp){
    setUserListing(resp);
  }  

  const RenderProjectUser = () => {

    const uids = new Array();

    return(
      <>
      {
        projectUsers.filter(function(user){

          if(uids.includes(user.id) == false){
            uids.push(user.id);
            return true;
          }

        }).map( (option, i) => {

          const linkTo = option.profile_type == PROFILE_TYPE_PERSON ? `/profile/${option.id}` : `/group/${option.id}`;
          return (
            <>
              <Link className="font-manrope-bold" to={linkTo}><img src={option.profile_pic} className={"img-fluid current-image-width mt-2" + (i > 0 ? ' ml-5' : '') } alt="user profile" /></Link>
              <h6 className="ml-3 mt-3 project-team-user"><Link to={linkTo}>{option.firstname} {option.lastname}</Link><br></br>
                <span className="text-grey font-creatore project-team-designation font-manrope-medium">{option.role}</span>
              </h6> 
            </>
          );

        })
      }
      </>
    );

  }


  const RenderContributors =  () => {

    const projectContributors = projectUsers.filter( (option) => (option.role == 'Contributor' || option.role == 'Sponsor') );

    if(projectContributors.length == 0){

        return(
          <>
          {
            <p className="text-center font-manrope-regular">No contributors</p>
          }
          </>
        );

    }else{

        return(
        <>
        {
          projectContributors.map( (option, i) => (
            <>
            <div className={"border" + (i == 0 ? "mt-4" : "mt-3")}></div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-8">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-4 p-0">
                        <div className="height">
                          <Link to={"/profile/" + option.id}><img src={option.profile_pic} className="img-fluid current-image-width mt-2" alt="contributor image"/></Link>
                        </div>
                      </div>
                      <div className="col-md-8 p-0">
                        <h6 className="ml-2 mt-2 font-manrope-bold"><Link to={"/profile/" + option.id}>{option.firstname} {option.lastname}</Link></h6>
                        <p className="ml-2 edit-skills-current font-manrope-regular">{option.role == 'Sponsor' ? 'Funds' : 'Talent/skills'}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 p-0">
                  <p className={"edit-skills-current font-manrope-extra-bold mb-0 mt-4"}>
                    {option.projects_associated_count > 0 ? (`${option.projects_associated_count - 1} Other Project${option.projects_associated_count == 2 ? '' : 's'}`) : '0 Other Projects'}
                  </p>
                  {/* {isEditAuthentic && option.stripe.account.id != null ? <p className="edit-skills-current pointer font-manrope-regular" onClick={e => openContributorPayModal(option.stripe.account.id)}><u>Pay</u></p> : <></>} */}
                </div>
              </div>
            </div>
            </>
          ))
        }
        </>
        );

    }

  }


  const openContributorPayModal = account_id => {

    if(account_id){
      setContributorPayAcc(account_id);
      setContributorPayModalShow(true);
    }

  }


  const closeContributorPayModal = () => {
    setContributorPayAcc(null);
    setContributorPayModalShow(false);
    setContributorPayAmt('');
  }


  function RenderOrganizerListing(){

    if(userListing.length > 0){

        return(
          <>
            { 
              userListing.map( (option, i) => (
                <OrganizerListing key={i} organizer={option}/>
              ))
            } 
          </>
        );
    
    }else{

        return(
          <p className="text-center">No record found</p>
        );

    }
 
  }

  const OrganizerListing = (param) => {

    const organizerUsr = param.organizer;
    const key = param.key;

    return(
      <>
        <div key={key} className="col-md-3 text-center organizer-block">

            <div className="padding-0 col-md-12">
              {
                organizerUsr.type == 'u' ? <img className="col-md-6 padding-0 organizer-profile-pic" src={organizerUsr.profile_pic} /> : <i className="fa fa-group created-group-icon"></i>
              }
            </div>

            <div className="col-md-12">
              <span className="username block semi-bold font-manrope-semibold">
                {
                  organizerUsr.type == 'u' ? organizerUsr.firstname + ' ' + organizerUsr.lastname : organizerUsr.name
                }
              </span>
              <span className="location block">{organizerUsr.city} {organizerUsr.country ? ',' + organizerUsr.country : ''}</span>
              <button onClick={(e) => openConfirmInvitationModal(e, organizerUsr)} uid={organizerUsr.id} type={organizerUsr.type} className={"bckg-color border-none px-4 py-1 drop-shadow-3 font-manrope-regular" + (invitedOrgUids.includes(organizerUsr.id) ? " invited" : '')} disabled={invitedOrgUids.includes(organizerUsr.id)}> {invitedOrgUids.includes(organizerUsr.id) ? "Invited": "Invite"} </button>
            </div>

        </div>
      </> 
    );

  }


  const inviteOrg = () => {
    // const elem = evt.target;
    // const uidSendTo = parseInt( elem.getAttribute('uid') );
    // const type = elem.getAttribute('type'); //Whether user or group
    // elem.innerText = "Invited";
    // elem.classList.add("invited");
    // elem.setAttribute("disabled", true);
    dispatch( projectInviteRequest({ project_id: projectId, uid_sender: uid, uid_receiver: inviteOrgUser.id, type: inviteOrgUser.type }, onInvitationSuccess, onError) );
  }  

  const onInvitationSuccess = () => {
    //Utility.showToast('success', "Invitation sent");
    const newArr = invitedOrgUids.concat([inviteOrgUser.id]);
    setInvitedOrgUids(newArr);
    setInviteOrgUser({});
    setShowInviteOrgModal(false);
  }

  const navigateToContributor = () => {
    
    if(contributorAmt)
      navigate('/contributor', {state: {project_id: projectId, contribution_amount: parseFloat(contributorAmt)}});  
    else
      setContributorAmtErrorMsg('Please enter fund amount');
    
  }

  //When enter key is pressed to add a new requirement
  const addRequirement = (e) => {

    if(e.key == 'Enter' && requirement != ''){
      setRequirements([...requirements, requirement]);
      setRequirement('');
    }

  }


  //When enter key is pressed to add a new consideration
  const addConsideration = (e) => {

    const val = e.target.value.trim();
    if(e.key == 'Enter' && val != ''){
      setConsiderations([...considerations, val]);
      setConsideration('');
    }

  }


  const saveChanges = e => {

    e.preventDefault();
    setButtonDisabled(true);
    let formIsValid = true;

    if(projectDesc.trim() == ''){
      formIsValid = false;
      Utility.showToast('error', "Enter project description");
    }
    else if(projectPassion.trim() == ''){
      formIsValid = false;
      Utility.showToast('error', "Enter project passion");
    }
    else if(projectImpact.trim() == ''){
      formIsValid = false;
      Utility.showToast('error', "Enter project vision");      
    }
    else if(projectBenefit.trim() == ''){
      formIsValid = false;
      Utility.showToast('error', "Enter project impact");      
    }    
    // else if(projectAwareness.trim() == ''){
    //   formIsValid = false;
    //   Utility.showToast('error', "Enter project awareness");       
    // }
    else if(projectBudget != undefined && (Number(projectBudget) < 0)){
      formIsValid = false;
      Utility.showToast('error', "Invalid project budget");
    }

    if(formIsValid){

      const payLoad = {
        description: projectDesc,
        passion: projectPassion,
        impact: projectImpact,
        benefit: projectBenefit,
        awareness: projectAwareness,
        project_id: projectId,
        requirements: requirements,
        considerations: considerations,
        supplies_extra: ProjectSuppliesOtherAdded.map(val => val.label),
        equipment_extra: ProjectEquipmentsOtherAdded.map(val => val.label),
        supplies_removed: projectSuppliesRemoved,
        equipments_removed: projectEquipmentsRemoved,
        supplies_added: projectSuppliesAdded,
        equipments_added: projectEquipmentsAdded        
      }

      if(projectBudget){
        payLoad.budget = Number(projectBudget);
      }

      dispatch(projectUpdateRequest( payLoad, onProjectUpdateSuccess, onError) );

    }

    setButtonDisabled(false);

  }

  function onProjectUpdateSuccess(resp){
    
    setIsEditable(false);

    const supplies = [], equipments = [], suppliedOther = [], equipmentsOther = [];
    resp.supplies.filter(val => val.is_other == 0).forEach(function(val){
      supplies.push({val: val.id, supply_id: val.id, label: val.name, is_other: val.is_other, option_id: val.option_id});
    });
    resp.supplies.filter(val => val.is_other == 1).forEach(function(val){
      suppliedOther.push({val: val.id, supply_id: val.id, label: val.name, is_other: val.is_other, option_id: val.option_id, if_other: true});
    });
    setProjectSupplies(supplies);
    setProjectSuppliesOtherList(suppliedOther);
    setProjectSuppliesOtherAdded([]);
    setProjectSuppliesAdded([]);
    setProjectSuppliesRemoved([]); 

    resp.equipments.filter(val => val.is_other == 0).forEach(function(val){
      equipments.push({val: val.id, equipment_id: val.id, label: val.name, is_other: val.is_other, option_id: val.option_id});
    });
    resp.equipments.filter(val => val.is_other == 1).forEach(function(val){
      equipmentsOther.push({val: val.id, equipment_id: val.id, label: val.name, is_other: val.is_other, option_id: val.option_id, if_other: true});
    });
    setProjectEquipments(equipments);
    setProjectEquipmentsOtherList(equipmentsOther);
    setProjectEquipmentsOtherAdded([]);
    setProjectEquipmentsAdded([]);
    setProjectEquipmentsRemoved([]);

  }


  function handleOtherSkillPress(key, val){

    if(key == 'Enter' && val.trim()!=''){
      setProjectOtherSkillList([...projectOtherSkillList, val.trim()]);
      setProjectOtherSkill('');
    }

  }

  const handleSuppliesKeyDown = function(e){

    if(e.key == 'Enter'){
      const val = e.target.value.trim();
      if(val != ''){
        setProjectSuppliesOtherList([...ProjectSuppliesOtherList, {'label': val, 'if_other': true} ]);
        setProjectSuppliesOtherAdded([...ProjectSuppliesOtherAdded, {'label': val, 'if_other': true} ])
        setProjectSuppliesOther('');
      }
    }

  }


  const handleEquipmentKeyDown = function(e){

    if(e.key == 'Enter'){
      const val = e.target.value.trim();
      if(val != ''){
        setProjectEquipmentsOtherList([...ProjectEquipmentsOtherList, {'label': val, 'if_other': true} ]);
        setProjectEquipmentsOtherAdded([...ProjectEquipmentsOtherAdded, {'label': val, 'if_other': true} ]);
        setProjectEquipmentOther('');
      }
    }

  }  


  const toggleProjectSupplies = (is_checked, object) => {

    if(object.if_other != undefined && object.if_other == true){

      const removedList = ProjectSuppliesOtherList.filter(function(value) {
        return value.label != object.label; 
      });
      setProjectSuppliesOtherList(removedList);
      const otherAddedList = ProjectSuppliesOtherAdded.filter(val => val.label != object.label);
      setProjectSuppliesOtherAdded(otherAddedList);
      setProjectSuppliesRemoved(projectSuppliesRemoved.concat([object]));

    }else{

      if(is_checked){

        setProjectSuppliesAdded(projectSuppliesAdded.concat([object.id]));
        setProjectSupplies([...projectSupplies, {val: object.id, label: object.name, is_other: object.is_other, option_id: object.id}]);
  
      }else{
  
        if(object.is_other){
          const updatedProjectSupplies = projectSupplies.filter( val => val.val != object.val );
          setProjectSupplies(updatedProjectSupplies);
          setProjectSuppliesRemoved(projectSuppliesRemoved.concat([object]));
        }else{
          const updatedProjectSupplies = projectSupplies.filter( val => val.option_id != object.id );
          setProjectSupplies(updatedProjectSupplies);
          setProjectSuppliesRemoved(projectSuppliesRemoved.concat([object]));
          //On deselecting, remove it if it was just added before saving
          const addedIdx = projectSuppliesAdded.indexOf(object.id);
          if(addedIdx > -1){
            projectSuppliesAdded.splice(addedIdx, 1);
            setProjectSuppliesAdded(projectSuppliesAdded);
          }
        }
  
      }
      
    }

  }


  const toggleProjectEquipments = (is_checked, object) => {

    if(object.if_other != undefined && object.if_other == true){

      const removedList = ProjectEquipmentsOtherList.filter(function(value) {
        return value.label != object.label; 
      });
      setProjectEquipmentsOtherList(removedList);
      const otherAddedList = ProjectEquipmentsOtherAdded.filter(val => val.label != object.label);
      setProjectEquipmentsOtherAdded(otherAddedList);
      setProjectEquipmentsRemoved(projectEquipmentsRemoved.concat([object]));

    }else{

      if(is_checked){

        setProjectEquipmentsAdded(projectEquipmentsAdded.concat([object.id]));
        setProjectEquipments([...projectEquipments, {val: object.id, label: object.name, is_other: object.is_other, option_id: object.id}]);

      }else{
  
        if(object.is_other){
        
          const updatedProjectEquipments = projectEquipments.filter( val => val.val != object.val );
          setProjectEquipments(updatedProjectEquipments);
          setProjectEquipmentsRemoved(projectEquipmentsRemoved.concat([object]));
        
        }else{
        
          const updatedProjectEquipments = projectEquipments.filter( val => val.option_id != object.id );
          setProjectEquipments(updatedProjectEquipments);
          setProjectEquipmentsRemoved(projectEquipmentsRemoved.concat([object]));
          //On deselecting, remove it if it was just added before saving
          const addedIdx = projectEquipmentsAdded.indexOf(object.id);
          if(addedIdx > -1){
            projectEquipmentsAdded.splice(addedIdx, 1);
            setProjectEquipmentsAdded(projectEquipmentsAdded);
          }
        
        }
  
      }
      
    }

  }  

  const removeOtherEquipments = val => {

    if(val.if_other != undefined){

      const removedList = ProjectEquipmentsOtherList.filter(function(value) {
        return value != val; 
      });
      setProjectEquipmentsOtherList(removedList);   

    }
    else if(val.is_other != undefined){

      const projectEquipmentsRemovedArr = projectEquipmentsRemoved;
      projectEquipmentsRemovedArr.push(val.val);
      setProjectEquipmentsRemoved(projectEquipmentsRemovedArr);

      const removedList = projectEquipments.filter(function(value) {
        return value != val; 
      });
      setProjectEquipments(removedList);

    }     

  }  

  const uploadProjectImage = e => {

    // const modal = document.getElementById('uploadPhotoModal');
    // const modalBody = modal.querySelector('.modal-body');

    const srcImg = URL.createObjectURL(e.target.files[0]);
    setSrcImg(srcImg);
    //console.log("image", srcImg);
    // const img = <ReactCrop style={{ maxWidth: "50%" }} src={srcImg} onImageLoaded={setImage} crop={crop} onChange={setCrop}/>
    // modalBody.appendChild(img);

    document.getElementById('openUploadPhotoModal').click();

  }

  const getCroppedImg = async () => {

    try {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );

        const base64Image = canvas.toDataURL("image/jpeg", 1);

        setResult(base64Image);

    } catch (e) {
        console.log("crop the image");
    }

  };


  const saveCroppedImage = () => {

    if(result == null){

      alert("Please select and crop image");

    }else{

      const ifImgFeatured = Boolean( Number(imageFeatured) );

      dispatch(projectImageInsertRequest({project_id: projectId, image: result, featured: ifImgFeatured}, function(resp){

        document.getElementById('uploadPhotoModal').querySelector('button.close').click();
        setProjectImages([{image_name: resp.image.url, id: resp.image.id}, ...projectImages]);
        setResult(null);
        setImageFeatured(0);

      }, function(err){
        Utility.showToast('error', err.data.message);
      }
      ));

    }

  }


  const removeImage = (e, id) => {

    if(window.confirm("Are you sure you want to delete?")){

      dispatch(projectImageDeleteRequest( {image_id: id}, function(resp){
        e.target.parentElement.remove();
      }, function(err){
        Utility.showToast('error', err.data.message);
      }) );

    }

  }

  const handleContributorPayAmt = e => {

    const key = e.charCode || e.which;
    let val = e.target.value;

    if(key < 46 || key > 57) e.preventDefault();

    if(val+='' && val.contains('.')){

      const decimalSplit = val.split('.');
      if(decimalSplit[1].length > 1) e.preventDefault();

    }

  }


  const RenderProjectImages = () => {

    if(projectImages.length == 0 && !isEditable){

      return(<>{<p className="ml-3 font-manrope-regular">No images</p>}</>);

    }

    return(
      <>
      {
        projectImages.map( (option, i) => (

          <li className={"project-img-block" + (i == 0 ? ' hover' : '')}>
            {isEditable ? <i onClick={(e) => removeImage(e, option.id)} className="fa fa-times-circle pointer remove-image"></i> : <></>}
            <img src={option.image_name} className="img-fluid project-edit-image drop-shadow" alt="..."/>
          </li>          

        ) )
      }
      </>
    );

  }


  const contributeTalentAndSkill = e => {
    
    let formIsValid = true;

    if(role != ROLE_ORGANIZER && role != ROLE_CONTRIBUTOR){
      setContributorTalentSkillErrorMsg("Select correct role");
      formIsValid = false;
    }
    else if(talentSkill == null){
      setContributorTalentSkillErrorMsg("Select skill");
      formIsValid = false;
    }

    if(formIsValid == true){

      setContributorTalentSkillErrorMsg('');
      const payLoad = { project_id: projectId, uid: uid, role: role, skill_id: talentSkill.val, is_other: talentSkill.is_other, skill: talentSkill.label };
      dispatch(projectContributionSkillRequest( payLoad, function(resp){
        
      }, 
      function(err){

      }
      ));

    }

  }


  const contriubuteMaterials = e => {

    if(materialSupplies == null && materialEquipments == null){
    
      setContributorMaterialErrorMsg("Select supply or material");
    
    }else{

      const payLoad = {
        project_id: projectId,
        uid: uid,
        supplies: materialSupplies,
        equipments: materialEquipments
      }

      dispatch(projectContributionMaterialRequest( payLoad, function(resp){
        
      }, 
      function(err){

      }
      ));

    }

  }


  const payContributor = e => {

    //Validation
    let valid = true;
    if(contributorPayAmt.trim() == '' || isNaN(contributorPayAmt)){
      valid = false;
      Utility.showToast('error', "Invalid amount");
    }
    else if(contributorPayAcc == null || contributorPayAcc == ''){
      valid = false;
      Utility.showToast('error', "Invalid contributor account");      
    }

    if(valid){

      dispatch( stripeAccountPaymentTransferRequest({
        project_id: projectId,
        account_id: contributorPayAcc.trim(),
        amount: Number( parseFloat(contributorPayAmt.trim()) )
      }, function(resp){
        setContributorPayAmt('');
        setContributorPayAcc(null);
        setContributorPayModalShow(false);
      }, onError) );

    }

  }


  const confirmInvitationModal = () => {

    return(

      <SweetAlert title="Invitation" confirmBtnText="CONFIRM"
          confirmBtnBsStyle="success" onConfirm={() => inviteOrg()}
          onCancel={() => closeConfirmInvitationModal()}
      >
        <h6>Are you sure you want to invite {inviteOrgUser ? `"${inviteOrgUser.firstname} ${inviteOrgUser.lastname}"` : ''} to your project?</h6>
      </SweetAlert>         

    );

  }


  const openConfirmInvitationModal = (evt, org_user) => {
    setInviteOrgUser(org_user);
    setShowInviteOrgModal(true);
  }


  const closeConfirmInvitationModal = () => {
    setInviteOrgUser({});
    setShowInviteOrgModal(false);
  }


  const validateGroupSelection = () => {

    if(userGroupId == undefined || isNaN(userGroupId)){
      Utility.showToast('error', 'Please select a group');
      return false;
    }
    return true;

  }

  const navigateToContributionPage = () => {

    if(validateGroupSelection()){
      navigate('contribution', {
        state:{user_group_id: userGroupId, user_stripe_connect_id: currentUserStripeConnectId, creator_stripe_connect_id: createrStripeConnectId.current}
      });
    }
    
  }

  const updateContributeAsSelf = () => {
    setContributeAsSelf(!constributeAsSelf);
    setContributeAsGroup(false);
    setUserGroupId(0);  
  }

  const updateContributeAsGroup = () => {
    setContributeAsSelf(false);
    setContributeAsGroup(!contributeAsGroup);
    
  }

  const setGroupSelection = () => {

    if(validateGroupSelection()){
      setSelectGroupModal(false);
    }

  }

  const RenderProjectSupplies = () => {

    const projectSuppliesConcatArr = projectSupplies.concat(ProjectSuppliesOtherList);
    const projectSuppliesIds = projectSupplies.filter(val => val.is_other == 0).map( val => val.option_id );
    const projectSuppliesOtherIds = projectSupplies.filter(val => val.is_other == 1).map(val => val.val);

    if(isEditable == false){

      if(projectSuppliesConcatArr.length == 0) return (<></>);

      return(
        <ul className="p-0">
          {
            projectSuppliesConcatArr.map( (val, i) => {
            
              return (
                      
                <li key={i} id={val.id} className="font-manrope-regular fs-14">
                  {val.label}
                  {
                    isEditable ? <span className="float-right pointer"></span> : <></>
                  }        
                </li>      

              )
            })
          }
        </ul>
      );

    } 

    return (
      <>
        {
          projectSuppliesOptions.map( (option, i) => {
            return (<div key={i} className="row"><label htmlFor={"project_supplies_" + option.id} className="containers font-manrope-medium">{option.name}<input type="checkbox" checked={projectSuppliesIds.includes(option.id)} onChange={e => toggleProjectSupplies(e.target.checked, option)} value={option.id} id={"project_supplies_" + option.id} name="project_supplies[]" /><span className="checkmark"></span></label></div>)
          } )
        }
        {
          projectSupplies.filter(v => v.is_other == 1).map( (option, i) => {
            return (<div className="row"><label htmlFor={"project_supplies_" + option.val} className="containers font-manrope-medium">{option.label}<input type="checkbox" checked={projectSuppliesOtherIds.includes(option.val)} onChange={e => toggleProjectSupplies(e.target.checked, option)} value={option.val} id={"project_supplies_" + option.val} name="project_supplies[]" /><span className="checkmark"></span></label></div>)
          } )
        }       
        {
          ProjectSuppliesOtherList.map( (option, i) => {
            return (<div className="row"><label htmlFor={"project_supplies_" + (i + 9999)} className="containers font-manrope-medium">{option.label}<input type="checkbox" checked={ProjectSuppliesOtherList.some(v => v.label == option.label)} onChange={e => toggleProjectSupplies(e.target.checked, option)} id={"project_supplies_" + (i+9999)} name="project_supplies[]" /><span className="checkmark"></span></label></div>)
          } )
        }
      </>
    )
  
  }

  const RenderProjectEquipments = () => {
    
    const projectEquipmentsConcatArr = projectEquipments.concat(ProjectEquipmentsOtherList);
    const projectEquipmentsIds = projectEquipments.filter(val => val.is_other == 0).map( val => val.option_id );
    const projectEquipmentsOtherIds = projectEquipments.filter(val => val.is_other == 1).map(val => val.val);

    if(isEditable == false){

      if(projectEquipmentsConcatArr.length == 0) return (<></>);

      return(
        <ul className="p-0">
          {
            projectEquipmentsConcatArr.map( (val, i) => {
            
              return (
                      
                <li key={i} id={val.id} className="font-manrope-regular fs-14">
                  {val.label}
                  {
                    isEditable ? <span className="float-right pointer"></span> : <></>
                  }        
                </li>      

              )
            })
          }
        </ul>
      );

    } 

    return (
      <>
        {
          projectEquipmentsOptions.map( (option, i) => {
            return (<div key={i} className="row"><label htmlFor={"project_equipments_" + option.id} className="containers font-manrope-medium">{option.name}<input type="checkbox" checked={projectEquipmentsIds.includes(option.id)} onChange={e => toggleProjectEquipments(e.target.checked, option)} value={option.id} id={"project_equipments_" + option.id} name="project_equipments[]" /><span className="checkmark"></span></label></div>)
          } )
        }
        {
          projectEquipments.filter(v => v.is_other == 1).map( (option, i) => {
            return (<div className="row"><label htmlFor={"project_equipments_" + option.val} className="containers font-manrope-medium">{option.label}<input type="checkbox" checked={projectEquipmentsOtherIds.includes(option.val)} onChange={e => toggleProjectEquipments(e.target.checked, option)} value={option.val} id={"project_equipments_" + option.val} name="project_equipments[]" /><span className="checkmark"></span></label></div>)
          } )
        }       
        {
          ProjectEquipmentsOtherList.map( (option, i) => {
            return (<div className="row"><label htmlFor={"project_equipments_" + (i + 9999)} className="containers font-manrope-medium">{option.label}<input type="checkbox" checked={ProjectEquipmentsOtherList.some(v => v.label == option.label)} onChange={e => toggleProjectEquipments(e.target.checked, option)} id={"project_equipments_" + (i+9999)} name="project_equipments[]" /><span className="checkmark"></span></label></div>)
          } )
        }
      </>
    )

  }

  return (
    <>
      <div className="container project-edit">
        <div className="row">
          <div className="col-md-12 mt-5">
            <h6 className="font-manrope-regular text-left">
              <Link className="font-manrope-extra-bold fs-14" to="/home">
                <img width="15" src={leftBack}></img>
                &nbsp; Back to Home
              </Link>
            </h6>
          </div>
        </div>
        {isEditAuthentic ? 
          <div className="row">
            <div className="col-md-12">
              <a href="#">
                <h6 className="text-right">
                  <u onClick={() => setIsEditable(!isEditable)} className="font-manrope-extra-bold pointer">{isEditable ? 'Cancel' : 'Edit'}</u>
                </h6>
              </a>
            </div>
          </div>
        : 
          <></>
        }
        
        <div className="row">
          <div className="col-md-12">
            <h1 className="head-name font-manrope-extra-bold">{projectDetails.name}</h1>
          </div>
        </div>
        <div className="col-md-12">
          <div className="row font-manrope-bold fs-15 color-text-gray">
            {
              (projectDetails.show_address || isEditAuthentic) && (projectDetails.address) ? <>
                <h6 className="font-manrope-regular fs-15"><u>{projectDetails.address}</u></h6>
                <div className="dot-project ml-2 mt-2"></div>              
              </> : 
              <>
                <h6 className="font-manrope-regular fs-15"><u>{projectDetails.region}</u></h6>
                <div className="dot-project ml-2 mt-2"></div>
              </>
            }
            <h6 className="ml-2 font-manrope-regular fs-15">{projectDetails.event_start_date}</h6>
            <div className="dot-project ml-2 mt-2"></div>
            <h6 className="ml-2 font-manrope-regular fs-15">{projectDetails.category}</h6>
            <div className="dot-project ml-2 mt-2"></div>
            <h6 className="ml-2 font-manrope-regular fs-15">{projectDetails.focus}</h6>
          </div>
        </div>
        <div className="row mt-3">
          <ul className="project-images-scroll font-manrope-regular">
            {isEditable ? 
            <li>
              <input id="file-input" accept="image/*" type="file" className="hide" onChange={(e) => uploadProjectImage(e)}/>
              <label htmlFor="file-input">
                <img src={addNewPhoto} className="img-fluid project-edit-image drop-shadow pointer" alt="project image"/>
              </label>  
            </li>
            :
            <></>
            }
          {<RenderProjectImages/>}    
          </ul>

        </div>

        <div className={"mt-4" + (isEditable ? " hide" : '')}>
          <div className="card project-team drop-shadow-2">
            <div className="col-md-12">
              <div className={"row mt-2" + (isEditAuthentic ? '' : " mb-2")}>
                <div className="col-md-3 col-3-width">
                  <h2 className={"text-center project-team-text font-manrope-bold" + (isEditAuthentic ? '' : " mt-3")}>Project Team</h2>
                  <div className="text-center">
                    <button type="text" data-toggle="modal" data-target="#inviteOrgModal" className={"mt-2 mb-2 invite-button drop-shadow bckg-color border-none font-manrope-regular" + (isEditAuthentic ? '': ' hide')}>
                      Invite
                    </button>
                  </div>
                </div>
                <div className="col-md-9 project-team-listing margin-left">
                  <div className={"row width-overflow" + (isEditAuthentic ? " mt-2" : '')}>
                    {<RenderProjectUser/>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="row mt-3">
          <div className="col-md-8">
            
            <h2 className="font-manrope-extra-bold fs-28 fw-800">Vision</h2>
            {isEditable ? 
            <div className="card bg-signup-2 w-100">
              <div className="col-md-12">
                <h6 className="mb-0 location-font mt-2 font-manrope-extra-bold">Type Vision</h6>
                <div className="row">
                  <div className="form-group w-100">
                    <textarea onChange={(e) => setProjectImpact(e.target.value)} className="form-control rounded-0 passion-border font-manrope-regular fs-14" rows="4">{projectImpact}</textarea>
                  </div>
                </div>
              </div>
            </div>
            :
            <div className="form-group w-100">
              <p className="font-manrope-regular fs-14 color-black">{projectImpact}</p>
            </div>
            }
            
            <div className="mt-3">
              <h2 className="font-manrope-extra-bold fs-28 fw-800">About</h2>
            </div>
            {isEditable ? 
            <div className="card bg-signup-2 w-100">
              <div className="col-md-12">
                <h6 className="mb-0 location-font mt-2 font-manrope-extra-bold">Type description</h6>
                <div className="row">
                  <div className="form-group w-100">
                    <textarea onChange={(e) => setProjectDesc(e.target.value)} className="form-control rounded-0 passion-border font-manrope-regular fs-14" rows="6">{projectDesc}</textarea>
                  </div>
                </div>
              </div>
            </div>  
            : 
            <div className="form-group w-100">
              <p className="font-manrope-regular fs-14 color-black">{projectDesc}</p>
            </div>
            }
            
            <div className="mt-3">
              <h2 className="font-manrope-extra-bold fs-28 fw-800">Project Requirements</h2>
            </div>
            <div className="col-md-12 mt-4">
              {
                projectRequirements.map((option, i) => (
                  <div className={"row" + (i == 0 ? ' mt-2' : '')}>
                    <div className="dot-project ml-2 mt-2"></div>
                    <h6 className="ml-2 font-manrope-regular">{option.name}</h6>
                  </div>
                ))
              }    
              {
                requirements.map((option, i) => (
                  <div className={"row" + (projectRequirements.length == 0 && i == 0 ? ' mt-2' : '')}>
                    <div className="dot-project ml-2 mt-2"></div>
                    <h6 className="ml-2 font-manrope-regular">{option}</h6>
                  </div>
                ))
              }    
              {
                isEditable ? 
                <>
                <div className="row mt-4">
                  <div className="ml-2"></div>
                  <i className="fa fa-plus mt-1"></i>&nbsp;
                  <h5 className="font-manrope-extra-bold fs-16">Add Requirement</h5>
                </div>
                <div className="card bg-signup-2 w-75 card-height-location mt-3">
                  <div className="col-md-12">
                    <h6 className="mb-0 location-font mt-2 font-manrope-semibold">New Requirement</h6>
                    <div className="form-group"><input value={requirement} onKeyPress={(e) => addRequirement(e)} onChange={(e) => setRequirement(e.target.value)} type="text" className="form-control w-100 p-0 font-manrope-regular fs-16" placeholder="Press Enter To Add"/></div>
                  </div>
                </div>  
                </>                
                : 
                <></>
              }                                  
            </div>
            
            <div className="mt-3">
              <h2 className="font-manrope-extra-bold fs-28 fw-800">Passion</h2>
            </div>
            {isEditable ? 
            <div className="card bg-signup-2 w-100">
              <div className="col-md-12">
                <h6 className="mb-0 location-font mt-2 font-manrope-extra-bold">Type passion</h6>
                <div className="row">
                  <div className="form-group w-100">
                    <textarea onChange={(e) => setProjectPassion(e.target.value)} className="form-control rounded-0 passion-border font-manrope-regular fs-14" rows="4">{projectPassion}</textarea>
                  </div>
                </div>
              </div>
            </div>  
            :
            <div className="form-group w-100">
              <p className="font-manrope-regular fs-14 color-black">{projectPassion}</p>
            </div>
            }
            
            <div className="mt-3">
              <h2 className="font-manrope-extra-bold fs-28 fw-800">Impact</h2>
            </div>
            {isEditable ? 
            <div className="card bg-signup-2 w-100">
              <div className="col-md-12">
                <h6 className="mb-0 location-font mt-2 font-manrope-extra-bold">Type Impact</h6>
                <div className="row">
                  <div className="form-group w-100">
                    <textarea onChange={(e) => setProjectBenefit(e.target.value)} className="form-control rounded-0 passion-border font-manrope-regular fs-14" rows="4">{projectBenefit}</textarea>
                  </div>
                </div>
              </div>
            </div>  
            : 
            <div className="form-group w-100">
              <p className="font-manrope-regular fs-14 color-black">{projectBenefit}</p>
            </div>
            }

            <div className="mt-3">
              <h2 className="font-manrope-extra-bold fs-28 fw-800">Considerations</h2>
            </div>
            <div className="col-md-12 mt-4">
              <div className="row">
                <div className="dot-project ml-2 mt-2"></div>
                <h6 className="ml-2 font-manrope-regular fs-14">Languages: {
                  languages.map( (option, i) => {
                    return (i != languages.length-1 ? option.name + ', ' : option.name) 
                  } )
                }</h6>
              </div>
              <div className="row  mt-2">
                <div className="dot-project ml-2 mt-2"></div>
                <h6 className="ml-2 font-manrope-regular fs-14">
                  Is this project wheelchair accessible? {projectDetails.event_wheelchair ? 'Yes' : 'No'}
                </h6>
              </div>
              <div className="row  mt-2">
                <div className="dot-project ml-2 mt-2"></div>
                <h6 className="ml-2 font-manrope-regular fs-14">
                Is this project open to accommodating other access needs, including for the visually or hearing-impaired? {projectDetails.event_animal_allergies ? 'Yes' : 'No'}
                </h6>
              </div>
              <div className="row  mt-2">
                <div className="dot-project ml-2 mt-2"></div>
                <h6 className="ml-2 font-manrope-regular fs-14">
                Could this project trigger allergies or potential health-related issues? {projectDetails.event_hay_fever ? 'Yes' : 'No'}
                </h6>
              </div>              
              {
                projectConsiderations.map((option, i) => (
                  <div className="row mt-2">
                    <div className="dot-project ml-2 mt-2"></div>
                    <h6 className="ml-2 font-manrope-regular fs-14">{option.name}</h6>
                  </div>
                ))
              } 
              {
                considerations.map((option, i) => (
                  <div className="row mt-2">
                    <div className="dot-project ml-2 mt-2"></div>
                    <h6 className="ml-2 font-manrope-regular fs-14">{option}</h6>
                  </div>
                ))
              }                           
              {
                isEditable ? 
                <>
                <div className="row mt-4">
                  <div className="ml-2"></div>
                  <i className="fa fa-plus mt-1"></i>&nbsp;
                  <h5 className="font-manrope-extra-bold fs-16">Add Consideration</h5>
                </div>
                <div className="card bg-signup-2 w-75 card-height-location mt-3">
                  <div className="col-md-12">
                    <h6 className="mb-0 location-font mt-2 font-manrope-semibold">New Consideration</h6>
                    <div className="form-group"><input value={consideration} onKeyPress={(e) => addConsideration(e)} onChange={(e) => setConsideration(e.target.value)} type="text" className="form-control w-100 p-0 font-manrope-regular fs-16" placeholder="Press Enter To Add"/></div>
                  </div>
                </div>  
                </>              
                : 
                <></>
              }
            </div>
            
            <div className="mt-3">
              <h2 className="font-manrope-extra-bold fw-800 fs-28">
                Partner Organization
              </h2>
            </div>
            <div className="form-group w-100"><p className="font-manrope-regular fs-14 color-black">{projectOrganizer}</p></div>

            <div className="mt-3">
              <h2 className="font-manrope-extra-bold fs-28 fw-800">Budget</h2>
            </div>
            {isEditable ? 
              <div className="card bg-signup-2 w-25">
                <div className="col-md-12">
                  <h6 className="mb-0 location-font mt-2 font-manrope-extra-bold">Type Impact</h6>
                  <div className="row">
                    <div className="form-group mb-0 mt-1 w-100">
                      <input min="0" type="number" name="budget" className="form-control font-manrope-light compensation-input" onChange={e => setProjectBudget(e.target.value)} value={projectBudget} />
                    </div>
                  </div>
                </div>
              </div>  
            : 
              <div className="form-group w-100">
                <p className="font-manrope-regular fs-14 color-black">{projectBudget ? `$${projectBudget}`: 'N/A'}</p>
              </div>
            }


            <div className="mt-3">
              <h2 className="font-manrope-extra-bold fs-28 fw-800">Supplies</h2>
            </div>
            <div className="col-md-12 mt-4"> 
              {<RenderProjectSupplies/>}                            
              {
                isEditable ? 
                <>
                <div className="row mt-4">
                  <div className="ml-2"></div>
                  <i className="fa fa-plus mt-1"></i>&nbsp;
                  <h5 className="font-manrope-extra-bold fs-16">Add Supplies</h5>
                </div>
                <div className="card bg-signup-2 w-75 card-height-location mt-3">
                  <div className="col-md-12">
                    <h6 className="mb-0 location-font mt-2 font-manrope-semibold">New Supplies</h6>
                    <div className="form-group"><input value={ProjectSuppliesOther} onKeyDown={(e) => handleSuppliesKeyDown(e)} onChange={(e) => setProjectSuppliesOther(e.target.value)} type="text" className="form-control w-100 p-0 font-manrope-regular fs-16" placeholder="Press Enter To Add"/></div>
                  </div>
                </div>  
                </>              
                : 
                <></>
              }
            </div>

            <div className="mt-3">
              <h2 className="font-manrope-extra-bold fs-28 fw-800">Equipment</h2>
            </div>
            <div className="col-md-12 mt-4"> 
              {<RenderProjectEquipments/>}                            
              {
                isEditable ? 
                <>
                <div className="row mt-4">
                  <div className="ml-2"></div>
                  <i className="fa fa-plus mt-1"></i>&nbsp;
                  <h5 className="font-manrope-extra-bold fs-16">Add Equipments</h5>
                </div>
                <div className="card bg-signup-2 w-75 card-height-location mt-3">
                  <div className="col-md-12">
                    <h6 className="mb-0 location-font mt-2 font-manrope-semibold">New Equipments</h6>
                    <div className="form-group"><input value={projectEquipmentOther} onKeyDown={(e) => handleEquipmentKeyDown(e)} onChange={(e) => setProjectEquipmentOther(e.target.value)} type="text" className="form-control w-100 p-0 font-manrope-regular fs-16" placeholder="Press Enter To Add"/></div>
                  </div>
                </div>  
                </>              
                : 
                <></>
              }
            </div>


          </div>
          <div className="col-md-4">
            <div className="card radius-card-edit drop-shadow-2">
              <div className="container">
                
                <div>
                  <h3 className="mt-3 font-manrope-extra-bold fs-24 fw-800">Looking for {projectResourcesCount} Contributors</h3>
                </div>
                <div className="bordermt-4"></div>
                {projectCompensation ? <p className="font-manrope-regular fs-14">${projectCompensation} Compensation</p> : <></> }
                <h6 className="mt-2 font-manrope-bold">Needing {projectEquipments.concat(projectSupplies, ProjectSuppliesOtherList, ProjectEquipmentsOtherList).length} Materials</h6>
                <p className="font-manrope-regular fs-12">{
                  projectEquipments.concat(projectSupplies, ProjectSuppliesOtherList, ProjectEquipmentsOtherList).map( (m, i) => {
                    return(<>{
                      m.label + (i == projectEquipments.concat(projectSupplies, ProjectSuppliesOtherList, ProjectEquipmentsOtherList).length - 1 ? '' : ', ')
                    }</>)
                  } )
                }</p>
                {projectBudget ? <h6 className="mt-2 font-manrope-bold fs-18">${projectBudget} Budget</h6> : <></> }
                <br/>
                <div className="select-group text-center">
                  <label className='font-manrope-semibold fs-18' htmlFor="select-group">Contribute as</label>
                  <div className="text-left contribute-as-container">
                    <fieldset><label className="font-manrope-semibold fs-16"><input className="contibute-as-check" type="checkbox" checked={constributeAsSelf} onChange={() => updateContributeAsSelf()} />&nbsp;&nbsp;&nbsp;&nbsp;Myself</label></fieldset>
                    <fieldset><label className="font-manrope-semibold fs-16"><input className="contibute-as-check" type="checkbox" checked={contributeAsGroup} onChange={() => updateContributeAsGroup()} />&nbsp;&nbsp;&nbsp;&nbsp;My Group</label></fieldset>
                  </div>
                  <div className="text-center">
                    {
                      contributeAsGroup ? <button type="button" onClick={e => setSelectGroupModal(true)} className="drop-shadow select-group font-manrope-medium mt-2 mb-2 px-3 py-2 bckg-color border-none fs-14">Select Group</button> : <></>
                    }
                  </div>
                </div>
                <div className="text-center">
                  <button disabled={projectEquipments.concat(projectSupplies).length == 0 && !isStripeValid} type="button" onClick={e => navigateToContributionPage()} className="drop-shadow button-markplace font-manrope-medium mt-2 mb-2 bckg-color">Contribute</button>
                </div>

              </div>
            </div>
            <div className="card radius-card-edit drop-shadow-2 mt-5">
              <div className="container">
                <div className="">
                  <h2 className="text-center mt-2 font-manrope-extra-bold fw-800 fs-26">Current Contributors</h2>
                  {<RenderContributors/>}
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="col-md-12 mt-5 mb-5">
          <div className="text-center">
            <button disabled={buttonDisabled} type="text" onClick={(e) => saveChanges(e)} className={"button-save-projectedit font-manrope-extra-bold fs-14 shadow" + (isEditable ? '' : ' hide')}>Save Changes</button>
          </div>
        </div>

        <div  className="modal fade" id="inviteOrgModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-title h4 font-manrope-bold">Invite Organizers</div>
              </div>
              <div className="modal-body">
                <h6 className="font-manrope-extra-bold"><strong>Search</strong></h6>
                <div className="padding-0 col-md-8"><input type="text" value={searchOrgName} className="form-control search-organizers border-none fs-14 font-manrope-light drop-shadow" placeholder="Find an Organizer to Invite" onChange={searchOrganizers} /></div> 
                <br></br>
                <div className={"listing" + (modalOrgLoader ? " loader" : '')} onScroll={handleOrgListingScroll}>
                  {<RenderOrganizerListing/>}
                </div>
              </div>
            </div>
          </div>
        </div>

        <button id="openUploadPhotoModal" className="hide" data-toggle="modal" data-target="#uploadPhotoModal"></button>

        <div className="modal fade" id="uploadPhotoModal" tabIndex="-1" aria-labelledby="uploadPhotoModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-title h4">Upload Photo</div>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                
                {srcImg && (
                  <div>
                    <ReactCrop
                      style={{maxWidth: "100%"}}
                      src={srcImg}
                      onImageLoaded={(img) => {
                        setImage(img);
                        this.makeClientCrop(crop);
                      }}
                      crop={crop}
                      onChange={setCrop}
                    />
                      <div>
                        <h6 className="font-manrope-regular fs-14 color-black">Do you want to set it as a featured image or cover image?</h6>
                        <input type="radio" id="featured_image_yes" name="featured_image" value="1" onChange={e => setImageFeatured(1)} checked={imageFeatured == 1} />&nbsp;
                        <label className="font-manrope-regular fs-14" htmlFor="featured_image_yes">Yes</label>&nbsp;
                        <input type="radio" id="featured_image_no" name="featured_image" value="0" onChange={e => setImageFeatured(0)} checked={imageFeatured == 0} />&nbsp;
                        <label className="font-manrope-regular fs-14" htmlFor="featured_image_no"> No</label>
                      </div>
                      <div>
                        <button className="crop image-button" onClick={getCroppedImg}>Crop</button>
                        <button className="save-project-crop-image image-button" onClick={saveCroppedImage}>Save</button>
                      </div>
                      <br/>
                  </div>
                )}
                {result && (
                  <div>
                    <img src={result} alt="cropped image"/>
                  </div>
                )}

              </div>
            </div>
          </div>
        </div>


        <Modal show={contributorPayModalShow}>
        
          <Modal.Header>
            <Modal.Title>Pay Contributor</Modal.Title>
            <img onClick={e => closeContributorPayModal()} className="pointer" src={crossX}/>
          </Modal.Header>

            <Modal.Body>
              <div className="form-group">
                <label htmlFor="key_amount"><h5 className="my-0 font-manrope-regular fs-16">Amount</h5></label>
                <div><span className="contributor-pay amount-currency pl-1 pt-2">US $</span><input id="key_amount" type="text" className="form-control drop-shadow fs-16 pl-5" placeholder="0.00" value={contributorPayAmt} onChange={e => setContributorPayAmt(e.target.value)} onKeyPress={e => handleContributorPayAmt(e)}/></div>
              </div> 
            </Modal.Body>

            <Modal.Footer>
              <button onClick={e => payContributor(e)} className="fr btn-primary stripe-buttons">Pay</button>
            </Modal.Footer>

        </Modal>

        <Modal show={selectGroupModal}>
        
          <Modal.Header>
            <section className="text-center w-100">
              <h3 className="font-manrope-bold ">Select Group</h3>
              <h4 className="font-manrope-bold ">to contribute as</h4>
            </section>
            <Modal.Title>
            <img onClick={e => setSelectGroupModal(false)} className="pointer" src={crossX}/>
            </Modal.Title>
          </Modal.Header>

            <Modal.Body>
              <div className="w-100 fl user-groups-listing">
                {UserGroupListOptions}
              </div>
            </Modal.Body>

            <Modal.Footer className="text-center">
              <div className="w-100"><button onClick={() => setGroupSelection()} type='button' className="drop-shadow select-group font-manrope-medium mt-2 mb-2 px-3 py-2 bckg-color border-none fs-14">Select Group</button></div>
            </Modal.Footer>

        </Modal>        

        {showInviteOrgModal ? confirmInvitationModal() : <></>}  

      </div>
    </>
  );
};

export default Project;

import {
    USER_IMAGES_REQUEST,
    USER_IMAGES_REQUEST_ERROR,
    USER_IMAGES_REQUEST_SUCCESS,
  } from "../actionTypes/type";
  
  const initialState = {
    data: null,
    isLoading: false
  };
  
  const userImagesReducer = (state = initialState, action) => {
    let object;
    switch (action.type) {
      case USER_IMAGES_REQUEST:
        object = {
          ...state,
          isLoading: true
        };
        break;
  
      case USER_IMAGES_REQUEST_ERROR:
        object = {
          ...state,
          isLoading: false
        };
        break;
  
      case USER_IMAGES_REQUEST_SUCCESS:
        object = {
          ...state,
          data: action.payload,
          isLoading: false
        };
        break;
  
      default:
        object = state;
        break;
    }
    return object;
  }
  
  export default userImagesReducer;
  
import {
  PROJECT_INVITE_REQUEST,
  PROJECT_INVITE_REQUEST_ERROR,
  PROJECT_INVITE_REQUEST_SUCCESS,
} from "../actionTypes/type";
const initialState = {
  data: null,
};

const projectInviteReducer = (state = initialState, action) => {
  let object;

  switch (action.type) {
    case PROJECT_INVITE_REQUEST:
      object = {
        ...state,
      };
      break;

    case PROJECT_INVITE_REQUEST_ERROR:
      object = {
        ...state,
      };
      break;

    case PROJECT_INVITE_REQUEST_SUCCESS:
      object = {
        ...state,
        // data: action.payload,
      };
      break;

    default:
      object = state;
      break;
  }
  return object;
};

export default projectInviteReducer;

import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { logo2 } from "../assets/images";
import Utility from "../utility/utility";
import APP_STRING from "../constants/strings";
import { useDispatch } from "react-redux";
import { validateForgotPasswordTokenRequest } from "../redux/actions/validateForgotPasswordTokenAction";
import { userPasswordUpdateRequest } from "../redux/actions/userPasswordUpdateAction";

const ChangePassword = () => { 

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    //Get forgot password token
    const token = searchParams.get('token') == null ? '': searchParams.get('token');

    const [newPassword, setNewPassword] = useState('');
    const [newConfirmPassword, setNewConfirmPassword] = useState('');
    const [uid, setUid] = useState(0);
    const [isLoading, setLoading] = useState(false);

    //Dispatch request for fetching user profile on page load
    useEffect(() => {
        dispatch(validateForgotPasswordTokenRequest({ 'token' : token, showSuccessToast: false }, onTokenRequestSuccess, onError));
    }, []);

    const onTokenRequestSuccess = async(resp) => {
        setUid(resp.uid);
    }

    const onError = err => {
        console.log("Error: ", err);
    }

    const validateFields = () => {
        let message = "";
        if(uid == 0){
           message = "Invalid token";
        }
        else if (Utility.isEmpty(newPassword)) {
           message = APP_STRING.ENTER_PASSWORD;
        } else if (newPassword.length < 6) {
           message = APP_STRING.ENTER_SIX_DIGIT_PASSWORD;
        } else if(Utility.isEmpty(newConfirmPassword)){
           message = APP_STRING.ENTER_CONFIRM_PASSWORD;
        } else if(!Utility.matchPasswords(newPassword, newConfirmPassword)){
           message = APP_STRING.PASSWORD_MITCHMATCH;
        }

        if (message === '') {
          return true;
        }
        Utility.showToast('error', message);
        return false;
    }

    async function submitForm(e) {
        e.preventDefault();
        if (validateFields()) {
          setLoading(true);
          const payload = {
            uid: uid,
            password: newPassword
          }
          dispatch(userPasswordUpdateRequest(payload, onPasswordUpdateSuccess, onError));
        }
    }

    const changePassword = e => {
        e.preventDefault();
        submitForm(e);
    }

    const onPasswordUpdateSuccess = async(resp) => {
        navigate("/login");
    }

    return (<>

        <div className="col-md-12 flex-row mt-5">
            <div className="row">
                <i className="fa fa-caret-left font-size-login mr-2 ml-2" aria-hidden="true"></i>
                <h6 className="text-left mt-1">
                    <Link className="VisiaPro-Bold" to="/register">Back to Sign Up</Link>
                </h6>
            </div>
        </div>

        <div className="container signup">

            <div>
                <h1 className="text-center font-size-signup VisiaPro-ExtraBold"><img src={logo2} width="250" alt="logo" /></h1>
                <h1 className="text-center signup-font VisiaPro-ExtraBold margin-login">Change Password</h1>
            </div>

            <div className="col-12">

                <br/>
                <form onSubmit={changePassword}>
                    
                    <div className="col-12 mt-3 mobile-padding">
                        <div className="form-group w-50-signup m-auto">
                            <label htmlFor="new-password"><h5 className="my-0 VisiaPro-ExtraBold">New Password</h5></label>
                            <input id="new-password" type="password" className="form-control drop-shadow" value={newPassword} onChange={(password) => setNewPassword(password.target.value)} placeholder="Enter new pasword here"/>
                        </div>
                    </div>

                    <div className="col-12 mt-3 mobile-padding">
                        <div className="form-group mt-3 w-50-signup m-auto">
                            <label htmlFor="new-confirm-password"><h5 className="my-0 VisiaPro-ExtraBold">Confirm Password</h5></label>
                            <input id="new-confirm-password" type="password" className="form-control drop-shadow" value={newConfirmPassword} onChange={(password) => setNewConfirmPassword(password.target.value)} placeholder="Confirm new password"/>
                        </div>
                    </div>                    

                    <div className="col-md-12 mt-5">
                        <div className="text-center mt-3">
                            <button type="submit" className="btn btn-primary sf-pro signup-button drop-shadow">Change Password</button>
                        </div>
                    </div>

                </form>

            </div>

        </div>

    </>);

}

export default ChangePassword;
import React, { useEffect, useState } from "react";
import {leftBack, crossX, addNewPhoto} from "../assets/images";
import Accordion from "react-bootstrap/Accordion";
import { Link, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import Utility from "../utility/utility";
import SignProjectOverview from "../components/SignProjectOverview";
import UserGroupsList from "../components/user_groups_list";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from 'react-bootstrap';
import { serviceCreateRequest } from "../redux/actions/serviceCreateAction";
import { getTypesFocusRequest } from "../redux/actions/serviceGetTypesFocusAction";

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const EVENT_TYPE_SINGLE = 's';
const EVENT_TYPE_ONGOING = 'o';

let payLoad = {};

const ServiceCreate = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [active, addActiveClass] = useState(1);    
    const [isActive1, setActive1] = useState(true);
    const [isActive2, setActive2] = useState(false);
    const [isActive3, setActive3] = useState(false);
    const [open_slider, setopen_slider] = useState(false);
    const [nameError, setNameError] = useState('');
    const [descError, setDescError] = useState('');
    const [typeError, setTypeError] = useState('');
    const [categoryError, setCategoryError] = useState("");
    const [createAsTypeError, setCreateAsTypeError] = useState("");
    const [constributeAsSelf, setContributeAsSelf] = useState(false);
    const [contributeAsGroup, setContributeAsGroup] = useState(false);
    const [userGroupId, setUserGroupId] = useState(undefined);
    const [selectGroupModal, setSelectGroupModal] = useState(false);
    const [Event, setEvent] = useState(EVENT_TYPE_SINGLE);
    const [regionError, setRegionError] = useState("");
    const [hourlyRateError, setHourlyRateError] = useState('');
    const [serviceTypes, setServiceTypes] = useState([]);
    const [service, setService] = useState([]);
    const [serviceList, setServiceList] = useState([]);
    //save the image that used to be crop
    const [image, setImage] = useState(null);
    const [srcImg, setSrcImg] = useState(null);
    const [showUploadCoverPhoto, setShowUploadCoverPhoto] = useState(false);
    //save the resulted image
    const [croppedResult, setCroppedResult] = useState(null);
    const [crop, setCrop] = useState({aspect: 1, unit: '%', x: 25, y: 5, width: 50});
    const [result, setResult] = useState(null);

    const UserData = useSelector(
        (state: RootStateOrAny) => state.loginReducer.data
    );

    payLoad = {...payLoad, uid : UserData.id}

    const UserGroupListOptions = UserGroupsList({ type: 'react-select', groups: UserData.groups, selected_group: userGroupId, set_user_group_id: setUserGroupId });

    useEffect( () => {
        dispatch(getTypesFocusRequest({ showSuccessToast: false }, onTypeFocusSuccess, onError));
    }, []);

    const onTypeFocusSuccess = resp => {
        setServiceTypes(resp.service_type);
        setServiceList(resp.select_service);
    }

    const handleSlider = (e) => {
        setopen_slider(!open_slider);
    }

    const handleToggle = (e) => {
        switch (e) {
          case 1:
            setActive1(!isActive1);
            break;
          case 2:
            setActive2(!isActive2);
            break;
          case 3:
            setActive3(!isActive3);
            break;
        }
    }

    const [state, setState] = React.useState({
        name: '',
        description: '',
        category: '',
        type: '',
        region: '',
        payment_status: false,
        rate: undefined
    })

    function handleChange(evt) {
        const value = evt.target.value;
        setState({
          ...state,
          [evt.target.name]: value,
        });
    }

    const handleServiceTypeChange = evt => {
        const val = evt.target.value;
        updateService(val);
        //Reset Service type state because now project focus options have been changed
        setState({
          ...state,
          [evt.target.name]: val,
          ['type']: []
        });       
    }

    const updateService = id => {
        const getServiceType = serviceTypes.find(v => v.id == id);
        if(getServiceType){
            const filteredService = serviceList.filter(val => val.type == getServiceType.type);
            setService(filteredService);
        }
    }

    const handlePaymentToggle = val => {
        setState({
            ...state,
            'payment_status': val
        });        
    }

    const updateContributeAsSelf = () => {
        setContributeAsSelf(!constributeAsSelf);
        setContributeAsGroup(false);
        //If 'self' option is selected, then groupid is taken as 0
        //Else if the option is de-selected, then reset groupid is take as undefined (for validation)
        setUserGroupId(constributeAsSelf == false ? 0: undefined);  
    }
    
    const updateContributeAsGroup = () => {
        setContributeAsSelf(false);
        setContributeAsGroup(!contributeAsGroup);    
    }

    const setGroupSelection = () => {

        if(userGroupId == undefined || isNaN(userGroupId)){
          Utility.showToast('error', 'Choose an option');
          return false;
        }
        
        setSelectGroupModal(false);
    
    }


    const validateOverviewCategory = (e, idx) => {

        if(idx >= 3) addActiveClass(3);
        else if(idx >= 2) addActiveClass(2);

    }


    const submitDescriptionSubForm = e => {

        let formIsValid = true;

        e.preventDefault();

        setCreateAsTypeError('');
        setNameError('');
        setDescError('');
        setTypeError('');
        setCategoryError('');

        if(userGroupId == undefined || isNaN(userGroupId)){
            setCreateAsTypeError('Choose an option');
            formIsValid = false;
            Utility.showToast('error', 'Choose an option');
        }
        else if(state.name.trim() == "") {
            setNameError("Enter Service Name");
            formIsValid = false;
            Utility.showToast('error', "Enter Service Name");
        }
        else if (state.description.trim() == "") {
            setDescError("Description Cannot be empty");
            formIsValid = false;
            Utility.showToast('error', "Enter Description");
        }
        else if (state.category == "" || isNaN(state.category)) {
            setTypeError("Choose service type");
            formIsValid = false;
            Utility.showToast('error', "Choose service type");
        } 
        else if (state.type == "" || isNaN(state.type)) {
            setCategoryError("Choose service");
            formIsValid = false;
            Utility.showToast('error', "Choose service");
        }      

        if(formIsValid) addActiveClass(2);

        payLoad = {
            ...payLoad,
            name: state.name.trim(),
            description: state.description.trim(),
            type: parseInt(state.category),
            category: parseInt(state.type),
            create_as_type: Number(userGroupId)        
        }

    }


    const submitProjectLocationSubForm = e => {

        e.preventDefault();

        const value = e.target.value;
        setRegionError("");
    
        e.preventDefault();
        let formIsValid = true;

        if (state.region == null || state.region.trim() == "") {
            setRegionError("Enter region");
            formIsValid = false;
            Utility.showToast('error', "Enter region");
        }

        if(formIsValid) addActiveClass(3);

        payLoad = {
          ...payLoad,
          region: state.region
        }

    }

    const submitForm = e => {

        //Validation
        let formValid = true;

        if(typeof state.payment_status != 'boolean'){
            formValid = false;
            Utility.showToast('error', 'Please select payment status');
        }
        else if(state.payment_status == true && (state.rate == undefined || state.rate.trim() == '' || isNaN(state.rate.trim()) || parseFloat(state.rate.trim()) == 0)  ){
            formValid = false;
            Utility.showToast('error', 'Please enter rate');
        }

        if(formValid){
            payLoad = {...payLoad, payment_status: state.payment_status, showSuccessToast: true}
            if(state.rate != undefined && state.rate.trim() != ''){
                payLoad.rate = parseFloat(state.rate)
            }
            if(croppedResult){
                payLoad.image = croppedResult;
            }
            dispatch( serviceCreateRequest(payLoad, onServiceCreateSuccess, onError) );
        }

    }

    const onServiceCreateSuccess = resp => {
        navigate('/thankyou/service-create');
    }

    const onError = err => {
        console.log('Error:', err);
    }

    const RenderServiceTypes = () => {

        return service.map( val => {
            return (<option value={val.id}>{val.name}</option>);
        });

    }

    function RenderTypeOptions(){

        return (
                <>
                <option key="0" value>Select</option>
                { 
                  serviceTypes.map( (option, i) => (
                    <option key={i} value={option.id}>{option.name}</option>
                  ))
                } 
                </>      
        );
  
    }


    const uploadCoverPhoto = e => {

        const srcImage = URL.createObjectURL(e.target.files[0]);
        setSrcImg(srcImage);
        setShowUploadCoverPhoto(true);

    }

    const getCroppedImg = async () => {

        try {
            const canvas = document.createElement("canvas");
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            canvas.width = crop.width;
            canvas.height = crop.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(
              image,
              crop.x * scaleX,
              crop.y * scaleY,
              crop.width * scaleX,
              crop.height * scaleY,
              0,
              0,
              crop.width,
              crop.height
            );
    
            const base64Image = canvas.toDataURL("image/jpeg", 1);
    
            setResult(base64Image);
    
        } catch (e) {
            console.log("unable to crop the image", e);
        }
    
    };

    const closeUploadCoverPhoto = e => {
        setSrcImg(null);
        setImage(null);
        setResult(null);
        setCroppedResult(null);
        setShowUploadCoverPhoto(false);
        document.getElementById('file-input').value = '';
    }
    
    
    const saveCroppedImage = () => {
        if(result){
            setCroppedResult(result);
        }
        setShowUploadCoverPhoto(false);
    }

    return (

        <>

        <div className="slidepage">
            <div className="slideitem">
                
                {/* Sidebar start */}
                <div className={ open_slider ? "sidebar pc_sidebar active" : "sidebar pc_sidebar" }>
                    <div className="container margin-pc-header sidebar_wraper">
                        <div className="sidebar_content">
                            <div className="row">
                                <div className="mt-2">
                                    <img className="img-fluid w-mobilw ml-3 mt-2" src={leftBack} width="14" />
                                </div>
                                <div className="ml-3 mt-3"><h5 className="font-manrope-extra-bold margin-home-btn fs-16"><Link to="/home">Back to Home</Link></h5></div>
                            </div>
                            <div className="slide_icon">
                                <h1 className="mt-5 mb-4 font-manrope-bold create-project-head">
                                    Create a New Service
                                </h1>
                                <div className="drawer_icon">
                                    <button onClick={(e) => handleSlider(true)} type="button"></button>
                                </div>
                            </div><br/>
                            <div className="col-md-12">
                                <Accordion>
                                    <div className={isActive1 ? "open" : ""}>
                                        <div
                                            className="accordian_header font-manrope-bold"
                                            onClick={() => handleToggle(1)}
                                        >Service Information
                                        </div>
                                        <div className={isActive1 ? "show" : "hide_accord"}>
                                            <div className="accordion-body">
                                                <ul className="sidebar_list font-manrope-medium">
                                                    <Link
                                                    className={active == 1 ? "active" : null}
                                                    to="#"
                                                    onClick={(e) => addActiveClass(1)}
                                                    >Description</Link>
                                                    <Link
                                                    className={active == 2 ? "active" : null}
                                                    to="#"
                                                    onClick={(e) => validateOverviewCategory(e, 2)}
                                                    >When &amp; Where</Link>
                                                    <Link
                                                    className={active == 3 ? "active" : null}
                                                    to="#"
                                                    onClick={(e) => validateOverviewCategory(e, 3)}
                                                    >Payment</Link>                                                    
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Sidebar end */}


                <div className="project_overview content pl-5">
                    
                    
                    {/* Description form starts */}
                    <div className={active == 1 ? "container overview" : "container overview d-none"}>
                        <div className="row">
                            <div className="w-75 mt-5 pl-5 pr-5 pb-5 drop-shadow-4 project-overview-form">
                                <h2 className="text-center mobile-font font-manrope-bold mt-4">
                                    Service Information
                                </h2>
                                <Form>
                                    <div className="">
                                        <h5 className="mobile-overview mt-4 mb-3 font-manrope-bold">
                                            Who's offering the service?
                                        </h5>
                                        <div className="card bg-signup-2">
                                            <div className="col-md-12">
                                                <div className="text-left py-2">
                                                    <fieldset>
                                                        <label className="font-manrope-semibold fs-16"><input className="contibute-as-check" type="checkbox" checked={constributeAsSelf} onChange={() => updateContributeAsSelf()} />&nbsp;&nbsp;&nbsp;&nbsp;An Individual</label>
                                                    </fieldset>
                                                    <fieldset>
                                                        <span className="w-50 display-inline-block"><label className="font-manrope-semibold fs-16"><input className="contibute-as-check" type="checkbox" checked={contributeAsGroup} onChange={() => updateContributeAsGroup()} />&nbsp;&nbsp;&nbsp;&nbsp;A Group</label></span>
                                                        <span className="w-50 display-inline-block text-right">
                                                        {
                                                            contributeAsGroup ? <button type="button" onClick={e => setSelectGroupModal(true)} className="drop-shadow select-group font-manrope-medium px-3 py-2 bckg-color border-none fs-12">Select Group</button> : <></>
                                                        }
                                                        </span>
                                                    </fieldset>
                                                </div>
                                            </div>
                                        </div>
                                        <span className="red_span">{createAsTypeError}</span>

                                        <div className="mt-3 mb-3">
                                            <h5 className="mobile-overview font-manrope-bold">
                                            What's the name of the service?
                                            </h5>
                                        </div>
                                        <div className="card bg-signup-2">
                                            <div className="col-md-12">
                                            <Form.Label htmlFor="name" className="project-overview-font font-manrope-semibold">
                                                Service Name{" "}
                                            </Form.Label>
                                            <div className="form-group">
                                                <input id="name" name="name" type="text" className="px-0 font-manrope-light form-control" onChange={handleChange} value={state.name} />
                                            </div>
                                        </div>
                                    </div>
                                    <span className="red_span">{nameError}</span>
                                    
                                    <div className="mt-3 mb-3">
                                        <h5 className="mobile-overview font-manrope-bold">
                                            Description
                                        </h5>
                                    </div>
                                    <div className="card bg-signup-2">
                                        <div className="col-md-12">
                                            <Form.Label htmlFor="description" className="project-overview-font font-manrope-semibold">
                                                Type Description
                                            </Form.Label>
                                            <div className="form-group">
                                                <textarea
                                                    className="form-control rounded-0 pl-0 font-manrope-light"
                                                    id="exampleFormControlTextarea1 over-view"
                                                    rows="8"
                
                                                    name="description"
                                                    value={state.description}
                                                    onChange={handleChange}
                                                ></textarea>
                                            </div>
                                            </div>
                                        </div>
                                        <span className="red_span">{descError}</span>
                                        
                                        <div className="mt-3 mb-3">
                                            <h5 className="mobile-overview font-manrope-bold">
                                            Select Service Type
                                            </h5>
                                        </div>
                                        <div className="card bg-signup-2">
                                            <div className="col-md-12">
                                            <Form.Label className="project-overview-font font-manrope-semibold">
                                                Choose Service Type
                                            </Form.Label>
                                            <Form.Select
                                                name="category"
                                                className="font-manrope-medium form-select w-100 select-drop mb-2"
                                                aria-label="Default select example"
                                                onChange={handleServiceTypeChange} value={state.category}
                                            >
                                                { <RenderTypeOptions/> }
                                            </Form.Select>
                                            </div>
                                        </div>
                                        <span className="red_span">{typeError}</span>

                                        <div className="mt-3 mb-3">
                                            <h5 className="mobile-overview font-manrope-bold">
                                            Select Service
                                            </h5>
                                        </div>
                                        <div className="card bg-signup-2">
                                            <div className="col-md-12">
                                                <Form.Label htmlFor="category" className="project-overview-font font-manrope-semibold">
                                                    Choose Category
                                                </Form.Label>
                                                <Form.Select
                                                    name="type" id="category"
                                                    className="font-manrope-medium form-select w-100 select-drop mb-2"
                                                    aria-label="Default select example"
                                                    onChange={handleChange} value={state.type}
                                                >
                                                    <option value="0">Select</option>
                                                    {<RenderServiceTypes/>}
                                                </Form.Select>
                                            </div>
                                        </div>
                                        <span className="red_span">{categoryError}</span>

                                        <div className="mt-3 mb-3">
                                            <h5 className="mobile-overview font-manrope-bold">Upload cover photo</h5>
                                        </div>
                                        <div className="card bg-signup-2">
                                            <div className="col-md-12">
                                                <input id="file-input" accept="image/*" type="file" class="py-2" onChange={e => uploadCoverPhoto(e)} />
                                            </div>
                                        </div>

                                    </div>
                                </Form>
                            </div>
                            <SignProjectOverview/>
                        </div>
                        <div className="row">
                            <div className="col-md-10"></div>
                            <div className="col-md-2 mt-3 mobile-center">
                                <button onClick={(e) => submitDescriptionSubForm(e)} type="button" className="border-none shadow float-right overviwe-next mt-51 font-manrope-regular fs-16 bckg-color">
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>      
                    {/* Description end */}


                    {/* When&Where form starts */}
                    <div className={
                        active == 2 ? "container overview project-location": "container overview project-location d-none"
                    }
                    >
                        <div className="row">
                            <div className="w-75 mt-5 pl-5 pr-5 pb-5 drop-shadow-4 project-overview-form">
                                <h2 className="text-center mobile-font mt-4 font-manrope-bold">When & Where</h2>
                                <Form>
                                    <div>
                                        <h5 className="project-location mt-5 font-manrope-bold">Location</h5>
                                        <div className="card bg-signup-2 w-46 card-height-location">
                                            <div className="col-md-12">
                                                <h6 className="mb-0 location-font mt-2 font-manrope-bold">Region</h6>
                                                <div className="form-group">
                                                    <input type="text"
                                                    name="region" className="form-control w-100 p-0 font-manrope-light"
                                                    onChange={handleChange}
                                                    value={state.region}
                                                    />
                                                </div> 
                                            </div>
                                        </div>
                                        <span className="red_span">{regionError}</span>                                    
                                    </div>
                                </Form>
                            </div>
                            <SignProjectOverview/>
                        </div>
                        <div className="row">
                            <div className="col-md-12 mobile-center">
                                <button onClick={(e) => submitProjectLocationSubForm(e)} type="button"
                                    className="float-right shadow overviwe-next mt-51 font-manrope-regular bckg-color border-none">
                                    Next
                                </button>
                            </div>                
                        </div>
                    </div>
                    {/* When&Where form ends */}


                    {/* Payment form starts */}
                    <div className={active == 3 ? "container overview" : "container overview d-none"}>
                        <div className="row">
                            <div className="w-75 mt-5 pl-5 pr-5 pb-5 drop-shadow-4 project-overview-form">
                                <h2 className="text-center mobile-font font-manrope-bold mt-4">Payment</h2>
                                <Form>
                                    <div>
                                        <h5 className="project-location mt-5 font-manrope-bold">Are you wanting to get paid for these services?</h5>
                                        <div className="col-md-12">
                                            <div className="row">
                                                <h6 className="text-dark font-manrope-bold">No</h6>
                                                <label className="switch ml-3">
                                                    <input type="checkbox" name="payment_status"
                                                        onChange={(e) => handlePaymentToggle(e.target.checked)}
                                                        checked={state.payment_status}
                                                    />
                                                    <span className="slider round"></span>
                                                </label>
                                                <h6 className="ml-3 text-dark font-manrope-bold">Yes</h6>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        state.payment_status ? 
                                        <div>
                                            <h5 className="project-location mt-5 font-manrope-bold">What lump sum amount would you like to get paid for these services?</h5>
                                            <div className="card bg-signup-2">
                                                <div className="col-md-12">
                                                    <label className="project-overview-font font-manrope-semibold form-label" htmlFor="rate">[ USD $]</label>
                                                    <div className="form-group mb-0">
                                                        <input id="rate" name="rate" type="number" className="px-0 font-manrope-light form-control" value={state.rate} onChange={handleChange}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <span className="red_span">{hourlyRateError}</span>
                                        </div>
                                        :
                                        <></>
                                    }
                                </Form>
                            </div>
                            <SignProjectOverview/>
                        </div>
                        <div className="row">
                            <div className="col-md-12 mobile-center">
                                <button onClick={(e) => submitForm(e)} type="button"
                                    className="float-right shadow overviwe-next mt-51 font-manrope-regular bckg-color border-none">
                                    Submit
                                </button>
                            </div>                
                        </div>
                    </div>
                    {/* Payment form ends */}

                </div>


            </div>
        </div>

        <Modal show={selectGroupModal}>
            
            <Modal.Header>
            <section className="text-center w-100">
                <h3 className="font-manrope-bold ">Select Group</h3>
                <h4 className="font-manrope-bold ">to create this project as</h4>
            </section>
            <Modal.Title>
            <img onClick={e => setSelectGroupModal(false)} className="pointer" src={crossX}/>
            </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div className="w-100 fl user-groups-listing">
                {UserGroupListOptions}
                </div>
            </Modal.Body>

            <Modal.Footer className="text-center">
                <div className="w-100"><button onClick={() => setGroupSelection()} type='button' className="drop-shadow select-group font-manrope-medium mt-2 mb-2 px-3 py-2 bckg-color border-none fs-14">Select Group</button></div>
            </Modal.Footer>

        </Modal>

        <Modal show={showUploadCoverPhoto}>
            
            <Modal.Header>
                Upload Cover Photo
                <Modal.Title>
                    <img onClick={e => closeUploadCoverPhoto(e)} className="pointer" src={crossX}/>
                </Modal.Title>
            </Modal.Header>
        
            <Modal.Body>

                {srcImg && (
                  <div>
                    <ReactCrop
                      style={{maxWidth: "100%"}}
                      src={srcImg}
                      onImageLoaded={(img) => {
                        setImage(img);
                        //this.makeClientCrop(crop);
                      }}
                      crop={crop}
                      onChange={setCrop}
                    />
                      <div>
                        <button className="crop image-button" onClick={getCroppedImg}>Crop</button>
                        <button className="save-project-crop-image image-button" onClick={saveCroppedImage}>Save</button>
                      </div>
                      <br/>
                  </div>
                )}
                {result && (
                  <div>
                    <img src={result} alt="cropped image"/>
                  </div>
                )}

            </Modal.Body>
        
        </Modal>

        </>

    );

}

export default ServiceCreate;
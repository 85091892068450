import { put, call, takeLatest } from "redux-saga/effects";
import { PROJECT_INVITATION_RESPOND_REQUEST } from "../actionTypes/type";
import { actionSuccess, actionError } from "../actions/projectInvitationRespondAction";
import { postApiCall } from "../../service/PostApiCall";
import { PROJECT_INVITATION_RESPOND_URL } from "../../service/apiUrl";

export function* projectInvitationRespondSaga(action) {
  try {
    const response = yield call(postApiCall, action.params, PROJECT_INVITATION_RESPOND_URL);
    yield put(actionSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionError(error));
    action.onError(error);
  }
}

export function* watchProjectInvitationRespondSaga() {
  yield takeLatest(PROJECT_INVITATION_RESPOND_REQUEST, projectInvitationRespondSaga);
}

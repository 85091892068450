import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { logoWhiteV1, landing1, landing2, landing3, arrowDown } from "../assets/images";

const Splash = () => {
  
  const navigate = useNavigate();
  const [active, addActiveClass] = useState(1);

  const navigateArrowDown = () => {
    //document.getElementById("content1").scrollIntoView({behavior: 'smooth'});
  }

  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({behavior: 'smooth'});
        }
      }, 0);
    }
  }, [pathname, hash, key]);

  return (
    <>
      <div className="header_wraper imagebg videobg text-center height-100 video-active" data-overlay="5">
        <video id="video" autoPlay="autoplay" muted="muted" loop="loop">
          <source src="/videos/intro-video.mp4" type="video/mp4"/>
        </video>  
        <div className="overlay">
          <div className="header_container landing-page">
            <nav className="header_navbar navbar  navbar-expand-lg navbar-light ">
              <div className="logo VisiaPro-BoldItalic">
                <Link to="/blank"><img src={logoWhiteV1} width="125" /></Link>
              </div>
              <div className="width">

                <div id="navbarContent" className="nav_custom">
                    <Link
                      className={"VisiaPro-Bold text-right " + (active == 1 ? "active" : null)}
                      to="" onClick={(e) => addActiveClass(1)}
                    >
                      Contributors
                    </Link>
                    <Link
                      className={"VisiaPro-Bold " + (active == 2 ? "active" : null)}
                      to="" onClick={(e) => addActiveClass(2)}
                    >
                      Projects
                    </Link>
                  </div>

              </div>
              <div className="button_wraper">
                <button
                  onClick={() => navigate("/login")}
                  className="signin_btn sf-pro my-2 my-sm-0"
                  type="submit"
                >
                  Sign In
                </button>
              </div>
            </nav>
            {/* <div className="bottom_lines">
              <div className="nav_bottom_first"></div>
              <div className="nav_bottom"></div>
            </div> */}
          </div>
        </div>
      
          <div className="App container landing-page">
          
            <div className="slider_inner">
              
              <div className="active carousel-item-start carousel-item"></div>
              <div className="carousel-item-next carousel-item-start carousel-item"></div>

              <div className={active == 1 ? "slider_item_active": "slider_item"}>
                <div className="tagline">
                  <h1 className="white visiapro-heavy">Reimagining the way change happens</h1>
                </div>
              </div>

              <div className={active == 2 ? "slider_item_active": "slider_item"}>
                <div className="tagline">
                  <h1 className="white visiapro-heavy">Lorem ipsum dolor sit amet, consectetur adipiscing elit</h1>
                </div>                
              </div>

            </div>
          
          </div>
          
          <div className="home_slider">
            <div className="slider_inner">
              <div className="active carousel-item-start carousel-item"></div>
              <div className="carousel-item-next carousel-item-start carousel-item"></div>

              <div className="slider_item_active">
                <div className="slide_height"></div>
                <div className="slider_caption">
                  <p className="visiapro-heavy">
                    A place for creating the world you want by doing what you love.
                  </p>
                </div>
                <div className="Navigation__Buttons">
                  <Link to="#content1"><img className="pointer" src={arrowDown} /></Link>
                </div>
              </div>          

            </div>
          </div>

 
      </div>

      <div id="content1" className="container landing-block">
        <div className="landing-content col-md-12 fl pt-3">
          <div className="col-md-7 fl">
            <p className="landing-content-text">Where there's no need to wait for change</p>
            <p className="mb-0">Connect with contributors prepared to tackle the world's most pressing social problems, one community at a time. Create groups, design tailor-made dream teams, and find the resources you need to create the change you want. Ready? The world is waiting.</p>
          </div>
          <div className="col-md-5 fl text-right">
            <div className="landing-img-container"><img className="pl-4 pr-4" src={landing1} /></div>
          </div>
        </div>
      </div>

      <div className="container landing-block">
        <div className="landing-content col-md-12 fl pt-3">
          <div className="col-md-5 fl">
            <div className="landing-img-container"><img className="pl-4 pr-4" src={landing2} /></div>
          </div>
          <div className="col-md-7 fl">
            <p className="landing-content-text">Where work becomes love</p>
            <p>Work on inspiring projects that make your heart full and community better. Join when it's convenient, support activities based on your interests, and earn extra money for your own goals. Make your mark by doing what you love.</p>
          </div>
        </div> 
      </div>
     
      <div className="container landing-block">
        <div className="landing-content col-md-12 fl pt-3 pb-3">
          <div className="col-md-7 fl">
            <p className="landing-content-text">Where it's easier to solve problems, together</p>
            <p>We believe people and organizations want to reshape society by working together. But organizing and mobilizing everyone? Not so simple. Now easily search, connect and coordinate the variety of resources you need - talent, funding, and materials - to create the change you believe in.</p>
          </div>
          <div className="col-md-5 fl text-right">
            <div className="landing-img-container"><img className="pl-4 pr-4" src={landing3} /></div>
          </div>
        </div>
      </div>

      <footer className="col-md-12 fl shadow sf-pro">
          <span className="fl heading">Airatae</span>
          <span className="fr copyright-text">
            <span>Copyright &nbsp; <i class="fa fa-copyright" aria-hidden="true"></i>
              &nbsp; {new Date().getFullYear()}</span><br/>
            <span>All Rights Reserved</span>
          </span>
      </footer>

    </>
  );
};

export default Splash;

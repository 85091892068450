import { put, call, takeLatest } from "redux-saga/effects";
import { GROUP_INVITATION_REQUEST } from "../actionTypes/type";
import { actionSuccess, actionError } from "../actions/groupInvitationAction";
import { postApiCall } from "../../service/PostApiCall";
import { GROUP_INVITATION_URL } from "../../service/apiUrl";

export function* groupInvitationSaga(action) {
  try {
    const response = yield call(postApiCall, action.params, GROUP_INVITATION_URL);
    yield put(actionSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionError(error));
    action.onError(error);
  }
}

export function* watchGroupInvitationSaga() {
  yield takeLatest(GROUP_INVITATION_REQUEST, groupInvitationSaga);
}

import { put, call, takeLatest } from "redux-saga/effects";
import { USER_REPORT_REQUEST } from "../actionTypes/type";
import { actionSuccess, actionError } from "../actions/userReportAction";
import { postApiCall } from "../../service/PostApiCall";
import { USER_REPORT_SUBMIT_URL } from "../../service/apiUrl";

export function* userReportSaga(action) {
  try {
    const response = yield call(postApiCall, action.params, USER_REPORT_SUBMIT_URL);
    yield put(actionSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionError(error));
    action.onError();
  }
}

export function* watchUserReportSaga() {
  yield takeLatest(USER_REPORT_REQUEST, userReportSaga);
}

import { put, call, takeLatest } from "redux-saga/effects";
import { STRIPE_ACCOUNT_PAYMENT_TRANSFER_REQUEST } from "../actionTypes/type";
import { actionSuccess, actionError } from "../actions/stripeAccountPaymentTransferAction";
import { postApiCall } from "../../service/PostApiCall";
import { STRIPE_ACCOUNT_PAYMENT_TRANSFER_URL } from "../../service/apiUrl";

export function* stripeAccountPaymentTransferSaga(action) {
  try {
    const response = yield call(postApiCall, action.params, STRIPE_ACCOUNT_PAYMENT_TRANSFER_URL);
    yield put(actionSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionError(error));
    action.onError(error);
  }
}

export function* watchStripeAccountPaymentTransferSaga() {
  yield takeLatest(STRIPE_ACCOUNT_PAYMENT_TRANSFER_REQUEST, stripeAccountPaymentTransferSaga);
}

import { put, call, takeLatest } from "redux-saga/effects";
import { STRIPE_DETAILS_GET_REQUEST } from "../actionTypes/type";
import { actionSuccess, actionError } from "../actions/stripeDetailsAction";
import { postApiCall } from "../../service/PostApiCall";
import { STRIPE_DETAILS_GET_URL } from "../../service/apiUrl";

export function* stripeDetailsSaga(action) {
  try {
    const response = yield call(postApiCall, action.params, STRIPE_DETAILS_GET_URL);
    yield put(actionSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionError(error));
    action.onError(error);
  }
}

export function* watchStripeDetailsSaga() {
  yield takeLatest(STRIPE_DETAILS_GET_REQUEST, stripeDetailsSaga);
}

import {
    FORGOT_PASSWORD_REQUEST,
    FORGOT_PASSWORD_REQUEST_ERROR,
    FORGOT_PASSWORD_REQUEST_SUCCESS,
  } from "../actionTypes/type";
  const initialState = {
    data: null
  };
  
  const forgotPasswordReducer = (state = initialState, action) => {
    let object;
  
    switch (action.type) {
      case FORGOT_PASSWORD_REQUEST:
        object = {
          ...state
        };
        break;
  
      case FORGOT_PASSWORD_REQUEST_ERROR:
        object = {
          ...state,
        };
        break;
  
      case FORGOT_PASSWORD_REQUEST_SUCCESS:
        object = {
          ...state,
          // data: action.payload,
        };
        break;
  
      default:
        object = state;
        break;
    }
    return object;
  };
  
  export default forgotPasswordReducer;
  
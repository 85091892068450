import React from "react";
import "./App.css";
import Navigator from "./app/navigation/router";
import {NotificationContainer} from 'react-notifications';
// import Protect from 'react-app-protect';
import 'react-app-protect/dist/index.css';

function App() {
  return <>
    <Navigator /><NotificationContainer/>
  </>;
}

export default App;

import { put, call, takeLatest } from "redux-saga/effects";
import { USER_PROFILE_REQUEST } from "../actionTypes/type";
import { actionSuccess, actionError } from "../actions/userListingAction";
import { postApiCall } from "../../service/PostApiCall";
import { USER_PROFILE_URL } from "../../service/apiUrl";

export function* userProfileSaga(action) {
  try {
    const response = yield call(postApiCall, action.params, USER_PROFILE_URL);
    yield put(actionSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionError(error));
    action.onError();
  }
}

export function* watchUserProfileSaga() {
  yield takeLatest(USER_PROFILE_REQUEST, userProfileSaga);
}

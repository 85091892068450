import { apiWithGetHeader } from "./api";
import { BASE_URL } from "./../service/environment";
import { API_STATUS } from "./apiUrl";

export const getApiCall = async (url, payload) => {

  const response = await apiWithGetHeader(BASE_URL, payload)
    .get(url)
    .catch(function (error) {
      console.log('Get Api call', error);
      console.log(
        "GET API CALL======= ",
        "error=====" + url,
        JSON.stringify(error)
      );
      return Promise.reject(error.response);
    });

  console.log("GET API CALL PARAMS RESPONSE======= ", JSON.stringify(response));
  if (response.data.status_code === API_STATUS.SUCCESS) {
    return response;
  } else {
    return Promise.reject(response);
  }
};

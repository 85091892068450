import { put, call, takeLatest } from "redux-saga/effects";
import { GROUP_PAGE_DELETE_REQUEST } from "../actionTypes/type";
import { actionSuccess, actionError } from "../actions/groupPageDeleteAction";
import { postApiCall } from "../../service/PostApiCall";
import { GROUP_PAGE_DELETE_URL } from "../../service/apiUrl";

export function* groupPageDeleteSaga(action) {
  try {
    const response = yield call(postApiCall, action.params, GROUP_PAGE_DELETE_URL);
    yield put(actionSuccess(response.data.data));
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put(actionError(error));
    action.onError(error);
  }
}

export function* watchGroupPageDeleteSaga() {
  yield takeLatest(GROUP_PAGE_DELETE_REQUEST, groupPageDeleteSaga);
}
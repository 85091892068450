import {
    ADMIN_CONTRIBUTORS_LISTING_REQUEST,
    ADMIN_CONTRIBUTORS_LISTING_REQUEST_SUCCESS,
    ADMIN_CONTRIBUTORS_LISTING_REQUEST_ERROR
  } from "../actionTypes/type";
  
  const initialState = {
    data: null,
    isLoading: false
  };
  const adminContributorsListReducer = (state = initialState, action) => {
    let object;
  
    switch (action.type) {
      case ADMIN_CONTRIBUTORS_LISTING_REQUEST:
        object = {
          ...state,
          isLoading: true
        };
        break;
  
      case ADMIN_CONTRIBUTORS_LISTING_REQUEST_ERROR:
        object = {
          ...state,
          isLoading: false
        };
        break;
  
      case ADMIN_CONTRIBUTORS_LISTING_REQUEST_SUCCESS:
        object = {
          ...state,
          data: action.payload,
          isLoading: false
        };
        break;
  
      default:
        object = state;
        break;
    }
    return object;
  }
  
  export default adminContributorsListReducer;